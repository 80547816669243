//Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Router } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

//Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

//Layouts
import { RootLayout, CorporateLayout } from './@pages/layouts';
//Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';

//Shared Layout Components
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from './@pages/components/quickview/quickview.component';
import { QuickviewService } from './@pages/components/quickview/quickview.service';
import { SearchOverlayComponent } from './@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from './@pages/components/shared.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';
import { pgDatePickerModule } from './@pages/components/datepicker/datepicker.module';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

//Basic Bootstrap Modules
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

//Pages Globaly required Components - Optional
//import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { ProgressModule } from './@pages/components/progress/progress.module';

//Thirdparty Components / Plugins - Optional
//import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';
import { BarChart, PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
//import { IsotopeModule } from 'ngx-isotope';

import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedMainModule } from './shared/shared-main.module';
import { AuthLayoutComponent } from './@pages/layouts/auth-layout/auth-layout.component';
import { pgUploadModule } from './@pages/components/upload/upload.module';
import { JwtInterceptor } from './shared/services/jwt-interceptor';
import { pgPaginationModule } from './@pages/components/pagination/pagination.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PublicLayoutComponent } from './@pages/layouts/public/public-layout.component';

import { NzNotificationModule } from 'ng-zorro-antd/notification';
// import { IntegrationComponent } from './integration/integration.component';

import { CountUpModule } from 'ngx-countup';
import { AiChatComponent } from './ai-search-chat/ai-search-chat.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { Page404Module } from './page404/page404.module';
import * as Sentry from "@sentry/angular";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

//Hammer Config Overide
//https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false }
    }
}

export function tokenGetter() {
    return localStorage.getItem('token');
}

export function jwtOptionsFactory() {
    return {
        tokenGetter: () => {
            return localStorage.getItem('token');
        },
        allowedDomains: ['localhost:4401', 'localhost:4200']
    }
}

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, LegendComponent, PieChart]);

@NgModule({
    declarations: [
        AppComponent,
        CorporateLayout,
        AuthLayoutComponent,
        SidebarComponent, QuickviewComponent, SearchOverlayComponent, HeaderComponent, HorizontalMenuComponent,
        RootLayout,
        AiChatComponent,
        PublicLayoutComponent,
        // IntegrationComponent,
    ],
    imports: [
        Page404Module,
        LoadingBarHttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        ProgressModule,
        pgListViewModule,
        pgCardModule,
        pgCardSocialModule,
        pgDatePickerModule,
        pgUploadModule,
        pgPaginationModule,
        NgxDatatableModule,
        ProgressModule,
        RouterModule.forRoot(AppRoutes, {}),
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CollapseModule.forRoot(),
        ProgressbarModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        //NvD3Module,
        //pgTabsModule,
        NgxEchartsModule.forRoot({echarts}),
       // IsotopeModule,
        QuillModule.forRoot(),
        PerfectScrollbarModule,
        pgSwitchModule,
        SharedMainModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: new Array(new RegExp('^null$'))
            }
        }),
        NzNotificationModule,
        CountUpModule,
        HighchartsChartModule,
    ],
    providers: [
        QuickviewService,
        pagesToggleService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: AppHammerConfig
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
