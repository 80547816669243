import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AdvizorProService } from '../../../shared/services/advizor-pro.service';
import { interval, Subscription } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
	selector: 'app-sync-modal',
	templateUrl: './sync-modal.component.html',
    styleUrls: ['./sync-modal.component.scss']
})
export class SyncModalComponent implements OnInit, OnDestroy {

	@Input() list: any = {};

	slideUp: any = {
		type: "md"
	}

	sync_process = true;
	sync_data:any = {};
	sync:any = {
		sync_firms: 1,
		sync_persons: 1
	}
	
	syncObserval: Subscription;
	sync_id: any;
	@Output() changeStatus: EventEmitter<any> = new EventEmitter();
	
	constructor(
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public advizorProService: AdvizorProService,
		public modelRef: NzModalRef,
	) {
	}
	
	ngOnInit() {
		
		this.saveSyncData();
		
	}

	saveSyncData() {
		let args = Object.assign({}, this.sync);
		args.type = this.list.type;
		args.search = Object.assign({}, this.list.search);
		args.crd = this.list.crd;
		args.npn = this.list.npn;
		args.firm_crd = this.list.firm_crd;
		args.firm_fo_id = this.list.firm_fo_id;
		args.person_fo_id = this.list.person_fo_id;

		this.advizorProService.saveAdvisorAsync(this.list.type, args).subscribe((data: any) => {
		
				this.sync_id = data.data;

				if (this.sync_id) {
					this.sync_process = true;
					if (this.sync_data.status != 'complete') {
						this.syncObserval = interval(3000)
						.subscribe(() => {
							this.getSyncStatus(this.sync_id);
						});
					}
				}
			
		});
	}

	saveSync() {
		this.saveSyncData();
	}

	getSyncStatus(id){
		console.log('getting status for id:' + id);
	
		this.advizorProService.getAdvisorAsyncStatus(id).subscribe((data: any) => {
		  this.sync_data = data.response;
	
		  if (this.sync_data.status == 'complete') {
			if (this.list.adv_type) {
				this.changeStatus.emit({ type: this.list.type});
			}
			if (this.syncObserval){
			  this.syncObserval.unsubscribe();
			}
		  }
		});
	}

	ngOnDestroy() {
		if (this.syncObserval){
		  this.syncObserval.unsubscribe();
		}
	}

	closeModel() {
		this.modelRef.destroy();
	}
}
