import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneMask' })
export class PhoneMaskPipe implements PipeTransform {
    transform(rawNum: any) {
        let newVal:string;

        if(typeof rawNum == 'number'){
            newVal = rawNum.toString();
        }else{
            newVal = rawNum;
        }
       
        newVal = newVal.replace(/\D/g, '');
        if (newVal && newVal.length <= 6) {
          newVal = newVal.substring(0, newVal.length - 1);
        }

        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        
        return newVal;
    }
}

