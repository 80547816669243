import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ProfileService } from "../../../services/profile.service";

interface DiversificationResponse {
  status: string;
  response: DiversificationData | null;
}

interface ChartResponse {
  status: string;
  response: ChartItem[];
}

interface DiversificationData {
  ack_id: string;
  grade: string;
  correlation: number;
  created_at: string;
  updated_at: string;
  num_of_f: string;
}

interface ChartData {
  label: string;
  label_name: string;
  data: {
    score: number | null;
    grade: string | null;
  };
}

interface ChartItem {
  label: string;
  label_name: string;
  data: ChartData[];
}

@Component({
  selector: "afm-tab-k-diversification",
  templateUrl: "./tab-401k-diversification.component.html",
  styleUrls: ["./tab-401k-diversification.component.scss"],
})
export class Tab401kDiversificationComponent implements OnChanges {
  public loading = true;
  public viewData: ChartItem[] = [];
  public diversificationGrade: DiversificationData | null = null;

  @Input() ackId: string | null = null;

  constructor(private readonly profileService: ProfileService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ackId) {
      if (!this.ackId) {
        return;
      }

      this.loading = true;
      this.viewData = [];
      this.diversificationGrade = null;

      this.getDiversificationGrade(this.ackId);
      this.getChart(this.ackId);

      this.loading = false;
    }
  }

  private getDiversificationGrade(ackId: string): void {
    this.profileService
      .getDiversificationGrade({ id: ackId })
      .subscribe((res: DiversificationResponse) => {
        if (res?.status === "200" && res?.response)
          this.diversificationGrade = res?.response;
      });
  }

  private getChart(ackId: string): void {
    this.profileService
      .getChart({ id: ackId })
      .subscribe((res: ChartResponse) => {
        if (res?.status === "200" && res?.response)
          this.viewData = res?.response;
      });
  }
}
