<div
  *ngIf="loading; else elseBlock"
  class="col align-self-center d-flex justify-content-center pt-4 pb-4"
>
  <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
</div>
<ng-template #elseBlock>
  <div class="row" class="content-fees pt-1 pb-1">
    <div class="col-sm-12 p-0">
      <h4 class="fs-12 bold m-0 mb-2">Fees Analysis</h4>
    </div>
    <div class="col-sm-12 p-0">
      <p class="fs-12">
        Reducing fees could save this plan
        <span class="label label-success p-t-5 mr-1 ml-1 p-b-5 inline fs-12">{{
          viewData?.anul_save_plan | currency : "USD" : "symbol" : "1.0-2"
        }}</span>
        over {{ viewData?.anul_horizon }} years, representing a
        <span class="label label-success p-t-5 mr-1 ml-1 p-b-5 inline fs-12"
          >{{ viewData?.anul_decrease_fee * 100 | number : "1.0-2" }}%</span
        >
        fee decrease.
      </p>
    </div>
  </div>
  <div class="row content-fees mt-2 mb-2">
    <div class="col-md-3 form-group annualtable fees-right">
      <label class="fs-12">Horizon (Years) :</label>
      <span class="anul_horizon_text showbox fs-12">{{
        viewData?.anul_horizon ?? 0
      }}</span>
    </div>
    <div class="col-md-3 form-group annualtable fees-right">
      <label class="fs-12">Annual Contribution :</label>
      <span class="anul_contribution_text showbox fs-12">{{
        !viewData?.anul_contribution
          ? 0
          : (viewData?.anul_contribution
            | currency : "USD" : "symbol" : "1.0-2")
      }}</span>
    </div>
    <div class="col-md-3 form-group annualtable fees-right">
      <label class="fs-12">Annual Rate of Return :</label>
      <span class="anul_rate_return_text showbox fs-12"
        >{{ viewData?.anul_rate_return * 100 | number : "1.2-2" }}%</span
      >
    </div>
    <div class="col-md-3 form-group annualtable fees-right">
      <label class="fs-12">Plan Fee Change :</label>
      <span class="anul_fee_increase_text showbox fs-12"
        >{{ viewData?.anul_fee_increase * 100 | number : "1.2-2" }}%</span
      >
    </div>
  </div>
  <div class="row content-fees mt-4 mb-3">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table class="table no-footer fees-tbl">
          <thead>
            <tr>
              <th class="current_plan largeth fs-11" width="45%">
                <strong>Current Plan Investments</strong>
              </th>
              <th class="alt_low_fee largeth fs-11" width="45%">
                <strong>Low Fee Alternative </strong>
              </th>
              <th class="fee_saving fs-11" width="14%">
                <strong> Saving</strong>
              </th>
              <th class="fee_saving" width="2%">
                <a href="javascript:void(0);" (click)="export()">
                  <img
                    src="assets/img/xlsx.ico"
                    width="21px"
                    class="pull-right"
                  />
                </a>
              </th>
            </tr>
          </thead>
        </table>
        <ngx-datatable
          class="table border mt-0 profile-fees_tbl"
          [rows]="viewData?.list"
          [columnMode]="columnModeSetting"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="1000"
          [messages]="messages"
          routeTransformer
          [scrollbarH]="scrollBarHorizontal"
          [externalSorting]="true"
          (sort)="onSort($event)"
        >
          <ngx-datatable-column
            [width]="65"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            name="Ticker"
            prop="current_ticker"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            name="Fund"
            prop="current_fund"
            cellClass="d-flex align-items-center fs-12"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              {{ row?.current_fund
              }}{{ row?.current_fund && row?.class ? "; " + row?.class : "" }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            name="Current Value"
            cellClass="d-flex align-items-center fs-12"
            prop="current_value"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span>
                {{
                  row?.current_value != ""
                    ? (row.current_value
                      | currency : "USD" : "symbol" : "1.0-2")
                    : "--"
                }}</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [width]="65"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            name="Expense Ratio"
            cellClass="d-flex align-items-center fs-12"
            prop="current_expense_ratio"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span>
                {{
                  row?.current_expense_ratio != ""
                    ? (row.current_expense_ratio * 100 | number : "1.0-2")
                    : "--"
                }}%</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [width]="65"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            name="Ticker"
            prop="alt_ticker"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [maxWidth]="180"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            name="Fee Alternative"
            prop="alt_low_fee"
            cellClass="d-flex align-items-center fs-12"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              {{ row?.alt_low_fee
              }}{{
                row?.alt_low_fee && row?.alt_class ? "; " + row?.alt_class : ""
              }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [width]="70"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            name="Correlation"
            prop="alt_correlation"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [width]="70"
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            [canAutoResize]="false"
            name="Expense Ratio"
            cellClass="d-flex align-items-center fs-12"
            prop="alt_expense_ratio"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span>
                {{
                  row?.alt_expense_ratio != ""
                    ? (row.alt_expense_ratio * 100 | number : "1.0-2")
                    : "--"
                }}%</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [resizeable]="false"
            [sortable]="true"
            [draggable]="false"
            name="Saving ($)"
            cellClass="d-flex align-items-center fs-12"
            prop="saving"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span class="text-success">
                {{
                  row?.saving != "--"
                    ? (row.saving | currency : "USD" : "symbol" : "1.0-2")
                    : row.saving
                }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <table class="table no-footer" *ngIf="viewData?.total_calculation">
          <thead>
            <tr>
              <th class="current_plan largeth fs-11" width="27%"></th>
              <th class="bold alt_low_fee largeth fs-11" width="16%">
                {{
                  viewData?.total_calculation?.current_value
                    | currency : "USD" : "symbol" : "1.0-2"
                }}
              </th>
              <th class="bold alt_low_fee largeth fs-11" width="41%">
                {{ viewData?.total_calculation?.total_exp | number : "1.0-2" }}%
              </th>
              <th class="bold alt_low_fee largeth fs-11" width="5%">
                {{
                  viewData?.total_calculation?.total_alt_exp | number : "1.0-2"
                }}%
              </th>
              <th class="bold fee_saving fs-11" width="16%">
                {{
                  viewData?.total_calculation?.saving
                    | currency : "USD" : "symbol" : "1.0-2"
                }}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="col-sm-12 mt-4">
      <p class="fs-12">
        <span class="text-danger bold fs-13">*</span> This analysis does not
        constitute professional investment advice and is provided solely for
        informational purposes. Past performance does not guarantee future
        results.
      </p>
    </div>
  </div>
</ng-template>

<nz-modal
  nzCentered
  [(nzVisible)]="waitingBox"
  [nzContent]="waitingBoxContent"
  [nzTitle]="null"
  [nzFooter]="null"
  (nzOnCancel)="closeModal()"
>
  <ng-template #waitingBoxContent>
    <div>
      <h4 class="p-b-5 fs-14">{{ downloadMessage.status_message }}</h4>
      <div class="mr-3">
        <div *ngIf="downloadMessage.status; else erroIcon">
          <nz-progress
            [nzPercent]="percent_complete"
            nzStatus="active"
          ></nz-progress>
        </div>
        <ng-template #erroIcon>
          <i
            class="fa fa-exclamation-triangle d-flex justify-content-center fa-2x text-danger"
            aria-hidden="true"
          ></i>
        </ng-template>
      </div>
    </div>
  </ng-template>
</nz-modal>
