import { Component, Input } from '@angular/core';
import * as _ from "underscore";

@Component({
	selector: 'insights-container',
	templateUrl: './insights-container.component.html',
	styleUrls: ['./insights-container.component.scss']
})
export class InsightsContainer {

    @Input() title: string
    @Input() insights: {insight: string}[]

}