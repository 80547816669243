<div class="card">
	<div class="d-flex flex-row">
		<span class="avatar" (click)="openModal()">
			<i class="avatar-initial">{{ getInitials(dba?.name) }}</i>
		</span>
		<div class="ml-3 content-card">
			<h2 class="font-weight-bold m-0 mb-1 content-name" (click)="openModal()">
				{{ dba?.name | uppercase }}
			</h2>
			<p class="text-secondary m-0 mb-1 subtitle" *ngIf="dba?.address">
				<i class="fa fa-map-marker"></i>
				<span class="ml-1">
					{{ dba?.address }}, {{ dba?.city }} {{ dba?.state }}
				</span>
			</p>
			<p class="text-secondary m-0 mb-1 subtitle" *ngIf="!dba?.address && dba?.city">
				<i class="fa fa-map-marker"></i>
				<span class="ml-1">
				   {{ dba?.city }}, {{ dba?.state }}
				</span>
			</p>
			<div class="mb-1 content-links">
				<a
					*ngIf="dba?.phone"
					href="tel:{{ dba?.phone }}"
					class="d-flex flex-row text-secondary align-baseline mr-4 link subtitle"
					title="phone"
				>
					<i class="link-item fa fa-phone-alt"></i>
					<span class="ml-1">
						{{ dba?.phone | phoneMask }}
					</span>
				</a>
				<a
					*ngIf="dba?.website"
					href="{{ dba?.website }}"
					class="d-flex flex-row text-secondary align-baseline link subtitle"
					title="website"
					rel="noreferrer noopener nofollow"
					target="_blank"
				>
					<i class="link-item fa fa-globe"></i>
					<span class="ml-1">
						<u>
							{{ dba?.website }}
						</u>
					</span>
				</a>
			</div>
			<div class="mt-1 content-mini-avatars">
				<div class="d-flex flex-row">
					<ng-template
						ngFor
						let-item
						[ngForOf]="miniAvatars"
						[ngForTrackBy]="trackByFn"
					>
						<span *ngIf="isAnUrl(item)" class="mini-circle-container">
							<img [src]="item" alt="person photo" />
						</span>
						<span *ngIf="!isAnUrl(item)" class="mini-avatar">
							<i class="mini-avatar-initial">{{ item }}</i>
						</span>
					</ng-template>
				</div>
				<a
					*ngIf="dba?.website"
					href="javascript:void(0)"
					class="d-flex flex-row text-secondary align-baseline link subtitle"
					title="team"
					(click)="openModal()"
				>
					<u> {{ labelNumberAdvisors(dba?.number_of_employees) }}</u>
				</a>
			</div>
		</div>
	</div>
</div>
