import { Component, OnInit, Input } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { Helper } from './../../helper';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';

@Component({
    selector: 'app-export-report',
    templateUrl: './export-report.component.html',
    styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnInit {
    followed_report: any = [];
    paginate: any = [];
    columnModeSetting = (window.innerWidth < 960) ? 'standard' : 'force';
    scrollBarHorizontal = (window.innerWidth < 960);
    messages = {
        emptyMessage: '<div class="m-2">No Record Found.</div>'
    };
    search_data: [];
    search_data_obj = [];
    search_data_obj1 = [];
    all = [];
    search_model = false;
    @Input('userId') userId: Subject<number>;
    @Input('limit') limit;
    user_id: number;
    paginateLimits:any = [50,100,150];

    constructor(
        private reportService: ReportService,
        public _helper: Helper,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.paginate.pageNumber = 0;
        this.paginate.limit = this.limit ? this.limit : 50;
        this.paginate.sortField = 0;
        this.paginate.sortOrder = 'asc';
        this.paginate.current_page = 0;
		this.paginate.per_page = 50;

        if (this.userId) {
            this.userId.subscribe(v => {
                this.followed_report = [];
                this.user_id = v;
                this.followedReport();
            });
        } else {
            this.followedReport();
        }
    }

    followedReport() {
        let args: any = {
            per_page: this.paginate.per_page,
            current_page: this.paginate.pageNumber + 1,
            user_id: this.user_id
        }

        this.reportService.getUsersReport(args, this.paginate.sortField, this.paginate.sortOrder).subscribe(
            (data: any) => {
                this.followed_report = data.response;
            },
        );
    }

    onSearch(search) {
        if (search) {
            this.search_data = search;
            this.search_data_obj1 = Object.keys(this.search_data).map((key) => [this.search_data[key]]);
            this.all = ['ALL'];
            if (this.search_data_obj1.length != 0) {
                this.search_data_obj1.forEach(() => {
                    this.search_data_obj = this.search_data_obj1;
                });
            } else {
                this.search_data_obj = this.all;
            }
            this.search_model = true;
        }
    }

    sortData($event: any) {
        this.paginate.pageNumber = $event.page - 1;
        this.paginate.current_page = $event.page - 1;
        this.followedReport();
    }

    onSort($event) {
        this.paginate.sortField = $event.sorts[0].prop;
        this.paginate.sortOrder = $event.sorts[0].dir;
        this.paginate.pageNumber = 0;
        this.followedReport();
    }

    runAgain(row) {
        if (row.type == 'Advanced Search') {
            this._helper.setCookie('searchData', row.search_data);
            this.router.navigate(['search']);
        } else {
            this._helper.setCookie('advizorSearchData', row.search_data);
            this.router.navigate(['advisor/search']);
        }
    }

    closeModal(type) {
        switch (type) {
            case 1:
                this.search_model = false;
                break;
            default:
        }
    }
}
