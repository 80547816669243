import { Component, Input } from '@angular/core'
import { getIconsByRowKeys, getIconsDataset } from '../../utils/icon-utils';
import { Icon } from '../icon-dataset/icons';
import { AdvizorProService } from '../../services/advizor-pro.service';
import { Auth } from '../../services/auth.service';

interface BubbleProperties {
	text: string;
	color: string;
}
@Component({
	selector: 'profile-contact-card',
	templateUrl: './profile-contact-card.component.html',
	styleUrls: ['./profile-contact-card.component.scss'],
})

export class ProfileContactCardComponent {
	@Input() dbaContact = null
	protected contactAddress: {
		address: string,
		extra: string
	}
	titleForDisplay = '';
	bubblesList: BubbleProperties[] = []
	toolTipIconOrnament: object;
	onlyInsuranceAccess = false;
	user = {};

	constructor(
		public advizorProService: AdvizorProService,
		public auth: Auth,
	) {
		this.advizorProService.$advTooltip.subscribe(value => {
			if (value) {
				this.toolTipIconOrnament = getIconsDataset(value as Record<string, string>);
			}
        });
	}

	ngOnInit() {
		this.user = this.auth.getUser();
		
		if (this.dbaContact?.broker)
			this.bubblesList.push(this.getBrokerBubbleProperties())
		if (this.dbaContact?.bt)
			this.bubblesList.push(this.getBTBubbleProperties())
		if (this.dbaContact?.insurance)
			//this.bubblesList.push(this.getInsuranceBubbleProperties())
		if (this.dbaContact?.fo)
			this.bubblesList.push(this.getFOBubbleProperties())
		//keep advizor last
		if (this.dbaContact?.advizor)
			this.bubblesList.push(this.getAdvizorBubbleProperties())
		
		if (this.dbaContact?.address)
			this.contactAddress = this.getAddress()
		
		this.titleForDisplay = this.getTitleForDisplay()
	}

	public getAddress() {
		return {
			address: this.dbaContact?.address?.toUpperCase(),
			extra: [
				this.dbaContact?.city?.toUpperCase(),
				this.dbaContact?.state?.toUpperCase(),
				this.dbaContact?.zip?.toUpperCase()
			]
			.filter(v => v != undefined)
			.join(', ')
		}
	}

	public getInitials(name: string | null): string {
		if (!name) return ''
		const words: string[] = name.replace(/[&/\\#,+()$~%.'":*?<>{}0-9\s]/g, '').split(' ')
		return words
			.slice(0, 1)
			.map((word) => word.charAt(0).toUpperCase())
			.join('')
	}

	private getAdvizorBubbleProperties(): BubbleProperties {
		return {
			text: 'IA',
			color: '#4472C4'
		}
	}

	private getBrokerBubbleProperties(): BubbleProperties {
		return {
			text: 'B',
			color: '#3CAF91'
		}
	}

	private getBTBubbleProperties(): BubbleProperties {
		return {
			text: 'BT',
			color: 'rgb(91, 1, 182)'
		}
	}

	private getInsuranceBubbleProperties(): BubbleProperties {
		return {
			text: 'INS',
			color: '#FF8C00'
		}
	}

	private getFOBubbleProperties(): BubbleProperties {
		return {
			text: 'FO',
			color: '#008000'
		}
	}

	private getTitleForDisplay(): string {
		if (this.dbaContact?.title == null) return ''
		return this.dbaContact?.title.split(',')[0].trim();
	}

	getIcons(row: Record<string, string>): Icon[] {
		return getIconsByRowKeys(row);
	}

	public getOwnership(): string {
		for (const firm of this.dbaContact.firms) {
			if (firm.ownership) {
				return firm.ownership;
			}
		}
		return '--';
	}

	getLinkType() {
		let linkType = [];
		if (this.onlyInsuranceAccess) {
			linkType = ['/insurance/person', this.dbaContact.npn];
		} else if (this.dbaContact?.person_crd && !this.onlyInsuranceAccess) {
			linkType = ['/advisor/person', this.dbaContact.person_crd];
		} else if (this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.onlyInsuranceAccess) {
			linkType = ['/insurance/person', this.dbaContact.npn];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && this.dbaContact?.fo_id) {
			linkType = ['/family/person', this.dbaContact.fo_id];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && this.dbaContact?.bt_id) {
			linkType = ['/bank/person', this.dbaContact.bt_id];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.dbaContact?.bt_id && this.dbaContact?.db) {
			linkType = ['/db/person', this.dbaContact.db];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.dbaContact?.bt_id && this.dbaContact?.dc) {
			linkType = ['/dc/person', this.dbaContact.dc];
		}
		return linkType;
	}
}