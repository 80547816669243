<nz-modal
	[(nzVisible)]="isOpen"
	nzWidth="1000px"
	[nzContent]="dbaContent"
	[nzFooter]="null"
	[nzTitle]="dbaTitle"
	(nzOnCancel)="closeModal()"
>
	<ng-template #dbaTitle>
		<div *ngIf="dbaData?.firm_name" class="d-flex flex-row pt-2 mb-3 pl-1">
			<a
				[routerLink]="['/advisor/firm', dbaData?.firm_crd]"
				target="_blank"
				class="fs-14 link-firm"
			>
				<u>
					{{ dbaData?.firm_name | uppercase }}
				</u>
				<i
					class="ml-1 link-item-firm fa fa-external-link-alt"
					aria-hidden="true"
				></i>
			</a>
		</div>
		<div class="d-flex flex-row">
			<span class="header-logo">
				<i class="dba-initial">{{ getInitials(dbaData?.name) }}</i>
			</span>
			<div class="ml-3 mt-2">
				<p class="m-0 mb-1 text-red font-weight-bold">Teams</p>
				<h2 class="font-weight-bold m-0 dba-name">
					{{ dbaData?.name | uppercase }}
				</h2>
				<p class="text-secondary m-0 mb-1 mt-1" *ngIf="dbaData?.address">
					<i class="fa fa-map-marker"></i>
					<span class="ml-1">
						{{ dbaData?.address }}, {{ dbaData?.city }} {{ dbaData?.state }}
						{{ dbaData?.zip }}
					</span>
				</p>
				<p class="text-secondary m-0 mb-1 mt-1" *ngIf="!dbaData?.address && dbaData?.city">
					<i class="fa fa-map-marker"></i>
					<span class="ml-1">
						{{ dbaData?.city }}, {{ dbaData?.state }}
						{{ dbaData?.zip }}
					</span>
				</p>
				<div class="d-flex flex-row">
					<a
						*ngIf="dbaData?.phone"
						href="tel:{{ dbaData?.phone }}"
						class="d-flex flex-row text-secondary align-baseline mr-4 link"
						title="phone"
					>
						<i class="link-item fa fa-phone-alt"></i>
						<span class="ml-1">
							{{ dbaData?.phone | phoneMask }}
						</span>
					</a>
					<a
						*ngIf="dbaData?.website"
						href="{{ dbaData?.website }}"
						class="d-flex flex-row text-secondary align-baseline link"
						title="website"
						rel="noreferrer noopener nofollow"
						target="_blank"
					>
						<i class="link-item fa fa-globe"></i>
						<span class="ml-1">
							<u>
								{{ dbaData?.website }}
							</u>
						</span>
					</a>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #dbaContent>
		<div class="pt-2 row contact-content">
			<div *ngIf="isLoading; else elseBlock">
				<nz-spin nzSimple [nzSize]="'small'"></nz-spin>
			</div>
			<ng-template #elseBlock class="row">
				<div
					*ngFor="let item of dbaContactData?.data; trackBy: trackByFn"
					class="col"
				>
					<contact-card [dbaContact]="item" [toolTipIconOrnament]="toolTipIconOrnament"></contact-card>
				</div>
				<div class="pagination-v2 pr-3">
					<span class="pl-3 text-secondary"
						>{{ dbaContactData?.total | number }} team members</span
					>
					<div class="d-flex">
						<button
							class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1"
							(click)="previousPage()"
							[disabled]="currentPage === 1"
							[class.btn-disable]="currentPage === 1"
						>
							<i class="fa fa-angle-left fs-12" aria-hidden="true"></i>
						</button>
						<button
							class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1"
							(click)="nextPage()"
							[disabled]="currentPage === totalPages"
							title="Next"
							[class.btn-disable]="currentPage === totalPages"
						>
							<i class="fa fa-angle-right fs-12" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</ng-template>
		</div>
	</ng-template>
</nz-modal>
