import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Page404Component } from './page404.component';



@NgModule({
  declarations: [Page404Component],
  imports: [
    CommonModule
  ],
})
export class Page404Module { }
