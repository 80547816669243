<div *nzModalTitle [ngClass]="{'p-2': !sync_process }">
    <span *ngIf="list.adv_type == 'search'">Sync Search to {{ list.type | titlecase }}</span>
    <span *ngIf="list.adv_type == 'person'">Sync Person to {{ list.type | titlecase }}</span>
    <span *ngIf="list.adv_type == 'firm'">Sync Firm to {{ list.type | titlecase }}</span>
   
</div>
<form name="sync_adv_modal" id="sync_adv_modal">
   
    <div class="row" *ngIf="sync_process else syncProcess">
        <div class="col-md-12 pl-0">        
            <div>
                <span class="bold inline">
                    Syncing Firm(s)
                </span>

                <span class="bold pull-right inline">
                    <i *ngIf="sync_data.status != 'complete' && sync_data.status != 'error'" class="fas fa-lg fa-circle-notch fa-spin" aria-hidden="true"></i>
                    <i *ngIf="sync_data.status == 'complete'" class="fas fa-lg fa-check-circle color-green"></i>
                    <i *ngIf="sync_data.status == 'error'" class="fa fa-lg fa-exclamation-triangle" style="color: red"></i>
                </span>
                <hr>
            </div>
           
            <div>
                <span class="bold inline">
                    Syncing Person(s)
                </span>
    
                <span class="bold pull-right inline">
                    <i *ngIf="sync_data.status != 'complete' && sync_data.status != 'error'" class="fas fa-lg fa-circle-notch fa-spin" aria-hidden="true"></i>
                <i *ngIf="sync_data.status == 'complete'" class="fas fa-lg fa-check-circle color-green"></i>
                <i *ngIf="sync_data.status == 'error'" class="fa fa-lg fa-exclamation-triangle" style="color: red"></i>
                </span>
            </div>
        </div>
    </div>
    <ng-template #syncProcess>
        <br>
        <div>
        <b>1 of 9,256,339</b> available export credits will be used.
        <br>
        <br>
        <i>Your account is currently allotted 100 exports credits per calendar month and 10,000,000 export credits per annum.
        Please reach out to support@advizorpro.com with any questions.</i>
        </div>
        <br>
        <div class="col-12 form-group pl-0 ml-2">
        <div class="checkbox m-0">
            <input type="checkbox" name="sync_firms" id="sync_firms" [(ngModel)]="sync.sync_firms" checked>
            <label for="sync_firms" class="m-0">Sync Firms ({{ list.firm_total }})</label>
        </div>
        </div>
        <div class="col-12 form-group pl-0 ml-2">
        <div class="checkbox m-0">
            <input type="checkbox" name="sync_persons" id="sync_persons" [(ngModel)]="sync.sync_persons"
            checked>
            <label for="sync_persons" class="m-0">Sync Persons ({{ list.person_total }})</label>
        </div>
        </div>

        <div class="col-md-12 form-group pl-0 ml-2">
        <label>Search Name (Optional)</label>
        <input type="text" class="form-control" name="searchOptionalName"
            [(ngModel)]="sync.searchOptionalName">
        </div>    
    </ng-template>
    <div *nzModalFooter>
        <div class="col-12 text-right pr-0">
            <button *ngIf="!sync_process" type="button" class="btn btn-cons primary-btn-grn" style="margin-right: 7px" (click)="saveSync()">Sync</button>
            <button type="button" (click)="closeModel()" *ngIf="sync_process" class="btn btn-cons primary-btn-grn">Close</button>
        </div>
    </div>
</form>
