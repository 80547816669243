import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DotLottie } from '@lottiefiles/dotlottie-web';

@Component({
	selector: 'app-page404',
	templateUrl: './page404.component.html',
	styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit{

	dotLottie: DotLottie
	constructor(
		public router: Router
	){}
	
	ngOnInit(): void {
		this.dotLottie = new DotLottie({
			autoplay: true,
			loop: true,
			canvas: document.querySelector('#dotlottie-canvas'),
			src: "https://lottie.host/3da393d8-f892-45cb-a555-012742b9223e/xsv6fjfUqc.json", // or .json file
		});
	}

	goHome(){
		// skipcq: JS-0098, JS-0328
		this.router.navigate(["/"])
	}

}
