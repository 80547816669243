/**
 * complex but work well
 * TODO: rebuild latter
 */
import { DOWN_ARROW, ENTER, TAB } from '@angular/cdk/keycodes';
import { CdkConnectedOverlay, ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { forwardRef, AfterContentChecked, AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { dropDownAnimation } from '../../animations/dropdown-animations';
import { tagAnimation } from '../../animations/tag-animations';
//import { LocaleService } from '../locale/index';
import { toBoolean } from '../util/convert';
import { pgOptionComponent } from './option.component';
import { OptionPipe } from './option.pipe';

@Component({
    selector           : 'pg-select',
    encapsulation      : ViewEncapsulation.None,
    providers          : [
        {
            provide    : NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => pgSelectComponent),
            multi      : true
        }
    ],
    animations         : [
        dropDownAnimation,
        tagAnimation
    ],
    templateUrl        :'./select.component.html',
    styleUrls          : [
        './style/index.scss',
    ]
})
export class pgSelectComponent implements OnInit, AfterContentInit, AfterContentChecked, ControlValueAccessor {
    private _allowClear = false;
    private _disabled = false;
    private _isTags = false;
    private _isMultiple = false;
    private _keepUnListOptions = false;
    private _showSearch = false;
    _el: HTMLElement;
    _isOpen = false;
    _prefixCls = 'pg-select';
    _classList: string[] = [];
    _dropDownClassMap;
    _dropDownPrefixCls = `${this._prefixCls}-dropdown`;
    _selectionClassMap;
    _selectionPrefixCls = `${this._prefixCls}-selection`;
    _size: string;
    _value: string[] | string;
    _placeholder = 'placeholder';
    _notFoundContent = "No Content";
    _searchText = '';
    _triggerWidth = 0;
    _selectedOption: pgOptionComponent;
    _operatingMultipleOption: pgOptionComponent;
    _selectedOptions: Set<pgOptionComponent> = new Set();
    _options: pgOptionComponent[] = [];
    _cacheOptions: pgOptionComponent[] = [];
    _filterOptions: pgOptionComponent[] = [];
    _tagsOptions: pgOptionComponent[] = [];
    _activeFilterOption: pgOptionComponent;
    _isMultiInit = false;
    _dropDownPosition: 'top' | 'center' | 'bottom' = 'bottom';
    _composing = false;
    _mode;
    // ngModel Access
    onChange: (value: string | string[]) => void = () => null;
    onTouched: () => void = () => null;
    @ViewChild('searchInput') searchInputElementRef;
    @ViewChild('trigger') trigger: ElementRef;
    @ViewChild('dropdownUl') dropdownUl: ElementRef;
    @Output() SearchChange: EventEmitter<string> = new EventEmitter();
    @Output() OpenChange: EventEmitter<boolean> = new EventEmitter();
    @Output() ScrollToBottom: EventEmitter<boolean> = new EventEmitter();
    @Input() Filter = true;
    @Input() MaxMultiple = Infinity;
    @ViewChild(CdkConnectedOverlay) _cdkOverlay: CdkConnectedOverlay;

    @Input()
    set AllowClear(value: boolean) {
        this._allowClear = toBoolean(value);
    }

    get AllowClear(): boolean {
        return this._allowClear;
    }

    @Input()
    set KeepUnListOptions(value: boolean) {
        this._keepUnListOptions = toBoolean(value);
    }

    get KeepUnListOptions(): boolean {
        return this._keepUnListOptions;
    }

    @Input()
    set Mode(value: string) {
        this._mode = value;
        if (this._mode === 'multiple') {
            this.Multiple = true;
        } else if (this._mode === 'tags') {
            this.Tags = true;
        } else if (this._mode === 'combobox') {
            this.ShowSearch = true;
        }
    }

    @Input()
    set Multiple(value: boolean) {
        this._isMultiple = toBoolean(value);
        if (this._isMultiple) {
            this.ShowSearch = true;
        }
    }

    get Multiple(): boolean {
        return this._isMultiple;
    }

    @Input()
    set PlaceHolder(value: string) {
        this._placeholder = value;
    }

    get PlaceHolder(): string {
        return this._placeholder;
    }

    @Input()
    set NotFoundContent(value: string) {
        this._notFoundContent = value;
    }

    get NotFoundContent(): string {
        return this._notFoundContent;
    }

    @Input()
    set Size(value: string) {
        this._size = { large: 'lg', small: 'sm' }[ value ];
        this.setClassMap();
    }

    get Size(): string {
        return this._size;
    }

    @Input()
    set ShowSearch(value: boolean) {
        this._showSearch = toBoolean(value);
    }

    get ShowSearch(): boolean {
        return this._showSearch;
    }

    @Input()
    set Tags(value: boolean) {
        const isTags = toBoolean(value);
        this._isTags = isTags;
        this.Multiple = isTags;
    }

    get Tags(): boolean {
        return this._isTags;
    }

    @Input()
    set Disabled(value: boolean) {
        this._disabled = toBoolean(value);
        this.closeDropDown();
        this.setClassMap();
    }

    get Disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set Open(value: boolean) {
        const isOpen = toBoolean(value);
        if (this._isOpen === isOpen) {
            return;
        }
        if (isOpen) {
            this.scrollToActive();
            this._setTriggerWidth();
        }
        this._isOpen = isOpen;
        this.OpenChange.emit(this._isOpen);
        this.setClassMap();
        if (this._isOpen) {
            setTimeout(() => {
                this.checkDropDownScroll();
            });
        }
    }

    get Open(): boolean {
        return this._isOpen;
    }

    /** new -option insert or new tags insert */
    addOption = (option) => {
        this._options.push(option);
        if (!this._isTags) {
            if (option.Value) {
                this.updateSelectedOption(this._value);
            } else {
                this.forceUpdateSelectedOption(this._value);
            }
        }
    }

    /** -option remove or tags remove */
    removeOption(option: pgOptionComponent): void {
        this._options.splice(this._options.indexOf(option), 1);
        if (!this._isTags) {
            this.forceUpdateSelectedOption(this._value);
        }
    }

    /** dropdown position changed */
    onPositionChange(position: ConnectedOverlayPositionChange): void {
        this._dropDownPosition = position.connectionPair.originY;
    }

    compositionStart(): void {
        this._composing = true;
    }

    compositionEnd(): void {
        this._composing = false;
    }

    /** clear single selected option */
    clearSelect($event?: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        this._selectedOption = null;
        this.Value = null;
        this.onChange(null);
    }

    /** click dropdown option by user */
    clickOption(option: pgOptionComponent, $event?: MouseEvent): void {
        if (!option) {
            return;
        }
        this.chooseOption(option, true, $event);
        this.clearSearchText();
        if (!this._isMultiple) {
            this.Open = false;
        }
    }

    /** choose option */
    chooseOption(option: pgOptionComponent, isUserClick = false, $event?: MouseEvent): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        this._activeFilterOption = option;
        if (option && !option.Disabled) {
            if (!this.Multiple) {
                this._selectedOption = option;
                this._value = option.Value;
                if (isUserClick) {
                    this.onChange(option.Value);
                }
            } else {
                if (isUserClick) {
                    this.isInSet(this._selectedOptions, option) ? this.unSelectMultipleOption(option) : this.selectMultipleOption(option);
                }
            }
        }
    }

    updateWidth(element: HTMLInputElement, text: string): void {
        if (text) {
            /** wait for scroll width change */
            setTimeout(_ => {
                this._renderer.setStyle(element, 'width', `${element.scrollWidth}px`);
            });
        } else {
            this._renderer.removeStyle(element, 'width');
        }
    }

    /** determine if option in set */
    isInSet(set: Set<pgOptionComponent>, option: pgOptionComponent): pgOptionComponent {
        return ((Array.from(set) as pgOptionComponent[]).find((data: pgOptionComponent) => data.Value === option.Value));
    }

    /** cancel select multiple option */
    unSelectMultipleOption = (option, $event?, emitChange = true) => {
        this._operatingMultipleOption = option;
        this._selectedOptions.delete(option);
        if (emitChange) {
            this.emitMultipleOptions();
        }

        // 对Tag进行特殊处理
        if (this._isTags && (this._options.indexOf(option) !== -1) && (this._tagsOptions.indexOf(option) !== -1)) {
            this.removeOption(option);
            this._tagsOptions.splice(this._tagsOptions.indexOf(option), 1);
        }
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }

    /** select multiple option */
    selectMultipleOption(option: pgOptionComponent, $event?: MouseEvent): void {
        /** if tags do push to tag option */
        if (this._isTags && (this._options.indexOf(option) === -1) && (this._tagsOptions.indexOf(option) === -1)) {
            this.addOption(option);
            this._tagsOptions.push(option);
        }
        this._operatingMultipleOption = option;
        if (this._selectedOptions.size < this.MaxMultiple) {
            this._selectedOptions.add(option);
        }
        this.emitMultipleOptions();

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }

    /** emit multiple options */
    emitMultipleOptions(): void {
        if (this._isMultiInit) {
            return;
        }
        const arrayOptions = Array.from(this._selectedOptions);
        this._value = arrayOptions.map(item => item.Value);
        this.onChange(this._value);
    }

    /** update selected option when add remove option etc */
    updateSelectedOption(currentModelValue: string | string[], triggerByNgModel = false): void {
        if (currentModelValue == null) {
            return;
        }
        if (this.Multiple) {
            const selectedOptions = this._options.filter((item) => {
                return (item != null) && (currentModelValue.indexOf(item.Value) !== -1);
            });
            if ((this.KeepUnListOptions || this.Tags) && (!triggerByNgModel)) {
                const _selectedOptions = Array.from(this._selectedOptions);
                selectedOptions.forEach(option => {
                    const _exist = _selectedOptions.some(item => item._value === option._value);
                    if (!_exist) {
                        this._selectedOptions.add(option);
                    }
                });
            } else {
                this._selectedOptions = new Set();
                selectedOptions.forEach(option => {
                    this._selectedOptions.add(option);
                });
            }

        } else {
            const selectedOption = this._options.filter((item) => {
                return (item != null) && (item.Value === currentModelValue);
            });
            this.chooseOption(selectedOption[ 0 ]);
        }
    }

    forceUpdateSelectedOption(value: string | string[]): void {
        /** trigger dirty check */
        setTimeout(_ => {
            this.updateSelectedOption(value);
        });
    }

    get Value(): string | string[] {
        return this._value;
    }

    set Value(value: string | string[]) {
        this._updateValue(value);
    }

    clearAllSelectedOption(emitChange = true): void {
        this._selectedOptions.forEach(item => {
            this.unSelectMultipleOption(item, null, emitChange);
        });
    }

    handleKeyEnterEvent(event: KeyboardEvent): void {
        /** when composing end */
        if (!this._composing && this._isOpen) {
            event.preventDefault();
            event.stopPropagation();
            this.updateFilterOption(false);
            this.clickOption(this._activeFilterOption);
        }
    }

    handleKeyBackspaceEvent(event: KeyboardEvent): void {
        if ((!this._searchText) && (!this._composing) && (this._isMultiple)) {
            event.preventDefault();
            const lastOption = Array.from(this._selectedOptions).pop();
            this.unSelectMultipleOption(lastOption);
        }
    }

    handleKeyDownEvent($event: MouseEvent): void {
        if (this._isOpen) {
            $event.preventDefault();
            $event.stopPropagation();
            this._activeFilterOption = this.nextOption(this._activeFilterOption, this._filterOptions.filter(w => !w.Disabled));
            this.scrollToActive();
        }
    }

    handleKeyUpEvent($event: MouseEvent): void {
        if (this._isOpen) {
            $event.preventDefault();
            $event.stopPropagation();
            this._activeFilterOption = this.preOption(this._activeFilterOption, this._filterOptions.filter(w => !w.Disabled));
            this.scrollToActive();
        }
    }

    preOption(option: pgOptionComponent, options: pgOptionComponent[]): pgOptionComponent {
        return options[ options.indexOf(option) - 1 ] || options[ options.length - 1 ];
    }

    nextOption(option: pgOptionComponent, options: pgOptionComponent[]): pgOptionComponent {
        return options[ options.indexOf(option) + 1 ] || options[ 0 ];
    }

    clearSearchText(): void {
        this._searchText = '';
        this.updateFilterOption();
    }

    updateFilterOption(updateActiveFilter = true): void {
        if (this.Filter) {
            this._filterOptions = new OptionPipe().transform(this._options, {
                'searchText'     : this._searchText,
                'tags'           : this._isTags,
                'notFoundContent': this._isTags ? this._searchText : this._notFoundContent,
                'disabled'       : !this._isTags,
                'value'          : this._isTags ? this._searchText : 'disabled'
            });
        } else {
            this._filterOptions = this._options;
        }

        /** TODO: cause pre & next key selection not work */
        if (updateActiveFilter && !this._selectedOption) {
            this._activeFilterOption = this._filterOptions[ 0 ];
        }
    }

    onSearchChange(searchValue: string): void {
        this.SearchChange.emit(searchValue);
    }

    @HostListener('click', [ '$event' ])
    onClick(e: MouseEvent): void {
        e.preventDefault();
        if (!this._disabled) {
            this.Open = !this.Open;
            if (this.ShowSearch) {
                /** wait for search display */
                setTimeout(_ => {
                    this.searchInputElementRef.nativeElement.focus();
                });
            }
        }
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(e: KeyboardEvent): void {
        const keyCode = e.keyCode;
        if (keyCode === TAB && this.Open) {
            this.Open = false;
            return;
        }
        if ((keyCode !== DOWN_ARROW && keyCode !== ENTER) || this.Open) {
            return;
        }
        e.preventDefault();
        if (!this._disabled) {
            this.Open = true;
            if (this.ShowSearch) {
                /** wait for search display */
                setTimeout(_ => {
                    this.searchInputElementRef.nativeElement.focus();
                });
            }
        }
    }

    closeDropDown(): void {
        if (!this.Open) {
            return;
        }
        this.onTouched();
        if (this.Multiple) {
            this._renderer.removeStyle(this.searchInputElementRef.nativeElement, 'width');
        }
        this.clearSearchText();
        this.Open = false;
    }

    setClassMap(): void {
        this._classList.forEach(_className => {
            this._renderer.removeClass(this._el, _className);
        });
        this._classList = [
        this._prefixCls,
        (this._mode === 'combobox') && `${this._prefixCls}-combobox`,
        (!this._disabled) && `${this._prefixCls}-enabled`,
        (this._disabled) && `${this._prefixCls}-disabled`,
        this._isOpen && `${this._prefixCls}-open`,
        this._showSearch && `${this._prefixCls}-show-search`,
        this._size && `${this._prefixCls}-${this._size}`
        ].filter((item) => {
            return !!item;
        });
        this._classList.forEach(_className => {
            this._renderer.addClass(this._el, _className);
        });
        this._selectionClassMap = {
            [this._selectionPrefixCls]               : true,
            [`${this._selectionPrefixCls}--single`]  : !this.Multiple,
            [`${this._selectionPrefixCls}--multiple`]: this.Multiple
        };
    }

    setDropDownClassMap(): void {
        this._dropDownClassMap = {
            [this._dropDownPrefixCls]                          : true,
            ['component-select']                               : this._mode === 'combobox',
            [`${this._dropDownPrefixCls}--single`]             : !this.Multiple,
            [`${this._dropDownPrefixCls}--multiple`]           : this.Multiple,
            [`${this._dropDownPrefixCls}-placement-bottomLeft`]: this._dropDownPosition === 'bottom',
            [`${this._dropDownPrefixCls}-placement-topLeft`]   : this._dropDownPosition === 'top'
        };
    }

    scrollToActive(): void {
        /** wait for dropdown display */
        setTimeout(_ => {
            if (this._activeFilterOption?.Value) {
                const index = this._filterOptions.findIndex(option => option.Value === this._activeFilterOption.Value);
                try {
                    const scrollPane = this.dropdownUl.nativeElement.children[ index ] as HTMLLIElement;
                    // TODO: scrollIntoViewIfNeeded is not a standard API, why doing so?
                    /* tslint:disable-next-line:no-any */
                    (scrollPane as any).scrollIntoViewIfNeeded(false);
                } catch (e) {
                }
            }
        });
    }

    flushComponentState(): void {
        this.updateFilterOption();
        if (!this.Multiple) {
            this.updateSelectedOption(this._value);
        } else {
            if (this._value) {
                this.updateSelectedOption(this._value);
            }
        }
    }

    _setTriggerWidth(): void {
        this._triggerWidth = this._getTriggerRect().width;
        /** should remove after after https://github.com/angular/material2/pull/8765 merged **/
        if (this._cdkOverlay?.overlayRef) {
            this._cdkOverlay.overlayRef.updateSize({
                width: this._triggerWidth
            });
        }
    }

    _getTriggerRect(): ClientRect {
        return this.trigger.nativeElement.getBoundingClientRect();
    }

    writeValue(value: string | string[]): void {
        this._updateValue(value, false);
    }

    registerOnChange(fn: (value: string | string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.Disabled = isDisabled;
    }

    dropDownScroll(ul: HTMLUListElement): void {
        if (ul && (ul.scrollHeight - ul.scrollTop === ul.clientHeight)) {
            this.ScrollToBottom.emit(true);
        }
    }

    checkDropDownScroll(): void {
        if (this.dropdownUl && (this.dropdownUl.nativeElement.scrollHeight === this.dropdownUl.nativeElement.clientHeight)) {
            this.ScrollToBottom.emit(true);
        }
    }

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
        this._el = this._elementRef.nativeElement;
    }

    ngAfterContentInit(): void {
        if (this._value != null) {
            this.flushComponentState();
        }
    }

    ngOnInit(): void {
        this.updateFilterOption();
        this.setClassMap();
        this.setDropDownClassMap();
    }

    ngAfterContentChecked(): void {
        if (this._cacheOptions !== this._options) {
            /** update filter option after every content check cycle */
            this.updateFilterOption();
            this._cacheOptions = this._options;
        } else {
            this.updateFilterOption(false);
        }
    }

    private _updateValue(value: string[] | string, emitChange = true): void {
        if (this._value === value) {
            return;
        }
        if ((value == null) && this.Multiple) {
            this._value = [];
        } else {
            this._value = value;
        }
        if (!this.Multiple) {
            if (value == null) {
                this._selectedOption = null;
            } else {
                this.updateSelectedOption(value);
            }
        } else {
            if (value) {
                if (value.length === 0) {
                    this.clearAllSelectedOption(emitChange);
                } else {
                    this.updateSelectedOption(value, true);
                }
            } else if (value == null) {
                this.clearAllSelectedOption(emitChange);
            }
        }
    }
}
