import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScoreService {
    paginationData: {};
    search: {};
    public _subject = new Subject<object>();
    public event = this._subject.asObservable();
    constructor(
        private http: HttpClient
    ) { }

    public publish(data: any)
    {
        this._subject.next(data);
    }

    deleteScore(id) {
        return this.http.delete('/api/advisor/score/' + id);
    }

    saveScore(data) {
        return this.http.post('/api/advisor/save-score', data);
    }

    getScore(data) {
        return this.http.post('/api/advisor/score', data);
    }

    setScore(scoreId: number) {
        return this.http.post('/api/advisor/set-score', {
            scoreId: scoreId
        });
    }
}
