import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipes } from './pipes';
import { Services } from './services';
import { Helper } from './helper';
import { Components } from './components';
import { Modules } from './modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from '../@pages/components/message/message.module';
import { MessageService } from '../@pages/components/message/message.service';
import { SharedModule } from '../@pages/components/shared.module';
import { RouteTransformerDirective } from './route-transformer.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { QuillModule } from 'ngx-quill';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { InsightsContainerModule } from '../@pages/components/insights-container/insights-container.module';
import { Providers401kTableModule } from './components/advisor_firm_modal/providers-401k-table/providers-401k-table.module';

@NgModule({
    declarations: [
        ...Pipes,
        ...Components,
        RouteTransformerDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MessageModule,
        InsightsContainerModule,
        Providers401kTableModule,
        SharedModule,
        ModalModule.forRoot(),
        TypeaheadModule,
        QuillModule.forRoot(),
        NzToolTipModule,
        ...Modules,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        TypeaheadModule,
        NzToolTipModule,
        ...Modules,
        ...Pipes,
        ...Components,
        RouteTransformerDirective
    ]
})
export class SharedMainModule {
    static forRoot(): ModuleWithProviders<SharedMainModule> {
        return {
            ngModule: SharedMainModule,
            providers: [
                Helper,
                MessageService,
                ...Services,
            ]
        };
    }
}
