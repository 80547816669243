import { Component, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VersionCheckService } from './shared/services/version-check.service'
import { DOCUMENT } from '@angular/common';
 
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>    <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  currentDomain: string;

  constructor(
    public http: HttpClient,
    public versionCheckService: VersionCheckService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentDomain = this.document.location.protocol + '//' + this.document.location.hostname;
  }
  
  ngOnDestroy() {
  }

  ngOnInit(){
    let url = this.currentDomain + '/version.json';
    console.log(url);
    this.versionCheckService.initVersionCheck(url);
  }
}
