<div
  *ngIf="loading; else elseBlock"
  class="col align-self-center d-flex justify-content-center pt-4 pb-4"
>
  <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
</div>
<ng-template #elseBlock>
  <div class="row" class="content-diversification pt-1 pb-4">
    <div class="col-sm-12 p-0">
      <h4 class="fs-12 bold m-0 mb-3">Diversification Analysis</h4>
    </div>
    <div class="row mb-3">
      <div class="col-md-7">
        <p class="fs-12">
          This plan scores a
          <span
            *ngIf="diversificationGrade?.grade"
            class="grade-label grade-label-inline ml-0 mb-2 grade-{{
              diversificationGrade?.grade
            }}"
          >
            {{ diversificationGrade?.grade }}
          </span>
          for diversification due to investment correlations. Its investment
          menu has
          <span
            *ngIf="diversificationGrade?.num_of_f"
            class="label label-success p-t-5 m-l-5 p-b-5 inline fs-12"
          >
            {{ diversificationGrade?.num_of_f }}
          </span>
          highly correlated fund pair(s) offering an opportunity to reduce
          options and simplify participant decisions.
        </p>
      </div>
    </div>
    <div class="mb-3 fs-12">
      <b>Key:</b>
    </div>
    <div class="row mb-3">
      <div class="col-md-11 m-auto text-center">
        <div class="d-flex justify-content-around tab-sec">
          <div class="tab-col">
            <div class="letter-cont fs-12">F</div>
            <div class="tab-des fs-12">
              Little diversification <br />benefit
            </div>
          </div>
          <div class="tab-col">
            <div class="letter-cont fs-12">D</div>
          </div>
          <div class="tab-col">
            <div class="letter-cont fs-12">C</div>
            <div class="tab-des fs-12">
              Medium diversification <br />benefit
            </div>
          </div>
          <div class="tab-col">
            <div class="letter-cont fs-12">B</div>
          </div>
          <div class="tab-col">
            <div class="letter-cont fs-12">A</div>
            <div class="tab-des fs-12">High diversification <br />benefit</div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <h4 class="fs-12 bold">Correlation Matrix</h4>
    <div class="table-responsive mb-3">
      <table
        class="table table-bordered table-detailed no-footer diversification-tbl"
      >
        <tr *ngIf="viewData?.length > '0'">
          <th>&nbsp;</th>
          <th
            class="tab-th font-weight-normal text-center fs-12"
            *ngFor="let item of viewData"
          >
            <span nz-tooltip [nzTooltipTitle]="item?.label_name">{{ item?.label }}</span>
          </th>
        </tr>
        <tr *ngFor="let item_row of viewData">
          <td class="tdlabel fs-12">
            <span nz-tooltip [nzTooltipTitle]="item_row?.label_name" nzTooltipPlacement="left">{{
              item_row?.label
            }}</span>
          </td>
          <td *ngFor="let item of item_row.data">
            <p
              [ngClass]="{ 'grade-label': item.data.grade != '' }"
              class="grade-{{ item.data.grade }} block fs-12"
            >
              {{ item.data.grade }}
            </p>
          </td>
        </tr>
      </table>
    </div>
    <div class="mb-3">
      <p class="fs-12">
        Correlation is a measure of how fund returns move in relation to one
        another. Lower correlations between funds improve a plan's
        diversification and are assigned higher scores. A score of A represents
        a correlation of less than 0.30, B represents a correlation of 0.31 -
        0.50, C represents a correlation of 0.51 - 0.70, D represents a
        correlation of 0.71 - 0.90, and F represents a correlation of greater
        than 0.90.
      </p>
    </div>
    <div>
      <p class="fs-12">
        This analysis does not constitute professional investment advice and is
        provided solely for informational purposes. Past performance does not
        guarantee future results.
      </p>
    </div>
  </div>
</ng-template>
