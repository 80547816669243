<div class="text-center" style="margin-top: 2rem;">
    <div class="botton" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: blue; z-index: -1;"></div>
    <div class="top body-bg-image" style="position: absolute; width: 100%; height: 50%; top: 0; left: 0; background-color: white; z-index: -1;"></div>

    <div style="align-items: center; margin: auto; width: 100%; height: 100%; padding: 10px;">
        <img style="width: 17%; height: 100%;" src="/assets/img/advizorpro_stacked_color_250.png">
        <div style="height: .5rem;"></div>
        <canvas id="dotlottie-canvas" style="width: 48%; height: 100%; margin-left: -2vw;"></canvas>
    </div>

    <div class="text" style="width: 30%; margin: auto;">
        <h2 style="color: whitesmoke">{{ "Page not found" }}</h2>
        <button type="button" style="width: 100%; background-color: #f3f3f3;" class="btn btn-light" (click)="goHome()">Go back</button>
        <p style="margin-top: 10px; color: whitesmoke;">{{ "If you think it's a bug, contact us" }}</p>
    </div>
</div>