<div class="row m-0 mb-4">
    <div class="col-lg-12 notes_container">
        <div class="row m-0 justify-content-between align-items-center">
            <h4 class="fs-12 bold m-0">Notes</h4>
            <a *ngIf="!user.no_notes_tasks" href="javascript:void(0)" (click)="openNote()" class="fs-12"><u>Add Note</u></a>
        </div>
        <div class="row" *ngIf="notes.length > 0">
            <div class="col-lg-12">
                <div class="c-note-single" *ngFor="let note of notes">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <div class="black-1 fs-12">{{note.note_text}}</div>
                        <div class="d-flex align-items-center">
                            <div class="pr-2">
                                <small class="grey-2">{{dateFormat(note?.created_at)}}</small>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="d-flex mr-2" (click)="openNote(note)">
                                    <i class="fas fa-edit fs-12"></i>
                                </span>
                                <span class="d-flex" (click)="deleteNote(note.id)">
                                    <i class="pg pg-close fs-12"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="d-block pb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex modi quis quo quod est porro voluptatum tempora ea saepe a! Dolorum qui, dolorem reiciendis accusamus ea molestias voluptatibus ad pariatur.</div> -->
                    <div>
                        <small class="ft-500 grey-2" style="padding-right: 10px;"> {{note?.visibility?'Team':'Private'}} </small>
                        <small class="ft-500 grey-2"> Created by <u> {{note?.created_by | titlecase}} </u></small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr class="w-100" />

    <div class="col-lg-12 task_container">
        <div class="row m-0 justify-content-between align-items-center">
            <h4 class="fs-12 bold m-0">Tasks</h4>
            <a *ngIf="!user.no_notes_tasks" href="javascript:void(0)" (click)="openTask()" class="fs-12"><u>Add Task</u></a>
        </div>
        <div class="row" *ngIf="tasks.length > 0">
            <div class="col-lg-12">
                <div class="c-note-single" *ngFor="let task of tasks">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <div class="black-1 fs-12">{{task.task_name}}</div>
                        <div class="d-flex align-items-center">
                            <div class="pr-2">
                                <small class="grey-2">{{dateFormat(task?.alert_due)}}</small>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="d-flex mr-2" (click)="openTask(task)">
                                    <i class="fas fa-edit fs-12"></i>
                                </span>
                                <span class="d-flex" (click)="deleteTask(task.id)">
                                    <i class="pg pg-close fs-12"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="d-block pb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex modi quis quo quod est porro voluptatum tempora ea saepe a! Dolorum qui, dolorem reiciendis accusamus ea molestias voluptatibus ad pariatur.</div> -->
                    <div>
                        <small class="ft-500 grey-2" style="padding-right: 10px;"> {{task?.visibility?'Team':'Private'}} </small>
                        <small class="ft-500 grey-2"> Created by <u> {{task?.created_by | titlecase}} </u></small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<nz-modal nzCentered [(nzVisible)]="edit_task" [nzContent]="editTaskContent" [nzTitle]="taskTitle" [nzFooter]="null" (nzOnCancel)="closeModal(1)">
    <ng-template #taskTitle>
        {{ editnote.id ? 'Edit' : 'Add' }} Task
    </ng-template>
    <ng-template #editTaskContent>
		<div>
            <form name="edit_task" id="edit_task">
                <div class="form-group">
                    <div class="row pl-2">
                        <div class="col-lg-2">
                            <label for="task_name">Task Name</label>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" class="form-control" name="task_name" [(ngModel)]="edittask.task_name">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row pl-2">
                        <div class="col-lg-2">
                            <label for="alert_due">Due Date</label>
                        </div>
                        <div class="col-lg-8">
                            <p-calendar [(ngModel)]="edittask.alert_due" [showTime]="true"
                                [ngModelOptions]="{standalone:true}" [hourFormat]="12" [minDate]="minDate"
                                [yearNavigator]="true" [yearRange]="year" [monthNavigator]="true"></p-calendar>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row pl-2">
                        <div class="col-lg-2">
                            <label for="visibility">Visibility</label>
                        </div>
                        <div class="col-lg-8">
                            <pg-select style="width: 100%;"
                            [PlaceHolder]="'Select Visibility'" [(ngModel)]="edittask.visibility"
                            [NotFoundContent]="'Not found'" name="visibility">
                                <pg-option *ngFor="let option of select_visibility;"
                                    [Label]="option.name" [Value]="option.id">
                                </pg-option>
                            </pg-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 offset-10">
                        <button type="submit" [disabled]="!edittask.task_name"  class="btn btn-rounded btn-block primary-btn-grn" (click)="submitTask()">Save</button>
                    </div>
                </div>
            </form>
		</div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="edit_note" [nzContent]="editNoteContent" [nzTitle]="editTitle" [nzFooter]="null" (nzOnCancel)="closeModal(2)">
    <ng-template #editTitle>
        {{ editnote.id ? 'Edit' : 'Add' }} Note
    </ng-template>
    <ng-template #editNoteContent>
		<div>
            <form name="edit_note" id="edit_note">
                <div class="form-group mb-2">
                    <div class="row pl-2">
                        <div class="col-lg-2">
                            <label for="note_name">Note Text</label>
                        </div>
                        <div class="col-lg-8">
                            <textarea class="form-control" rows="5" placeholder="Edit Note" [(ngModel)]="editnote.note_text" [ngModelOptions]="{standalone: true}" name="note_text"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-2">
                    <div class="row pl-2">
                        <div class="col-lg-2">
                            <label for="note_name">Visibility</label>
                        </div>
                        <div class="col-lg-8">
                            <pg-select style="width: 100%;" [PlaceHolder]="'Select Visibility'" [(ngModel)]="editnote.visibility" [NotFoundContent]="'Not found'" name="visibility">
                                <pg-option *ngFor="let option of select_visibility;" [Label]="option.name" [Value]="option.id">
                                </pg-option>
                            </pg-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-2 offset-10">
                        <button type="submit" class="btn btn-rounded primary-btn-grn btn-block" (click)="submitNote()" [disabled]="!editnote.note_text">Save</button>
                    </div>
                </div>
            </form>
		</div>
    </ng-template>
</nz-modal>