import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Helper } from '../../helper';
import { Auth } from '../../services/auth.service';
import { EmailAutomationService } from '../../services/email-automation.service';
import 'quill-mention';

import Quill from 'quill';
import { ImageHandler, Options } from 'ngx-quill-upload';

Quill.register('modules/imageHandler', ImageHandler);

@Component({
	selector: 'email_automation_setting',
	templateUrl: './email_automation_setting.component.html',
	styleUrls: ['./email_automation_setting.component.scss'],
	providers: []
})
export class EmailAutomationSettingComponent implements OnInit {
	email_template = {
		num_of_rep: '',
		template: ''
	};
	
	@Input() recipient_data: [];
	invalidTemplateField = [];
	email_templates = [];
	email_temaplate_form: any = {};
	email_template_preview: any = {};
	custom_template = false;
	@Output() TestmailSuccess: EventEmitter<any> = new EventEmitter();
	user: any = {}
	emailSignature = '';

	modules:any;
	mentionFields: any;

	constructor(
		public router: Router,
		private _helper: Helper,
		public auth: Auth,
		public emailAutomationService: EmailAutomationService,
		// private modalDialog: ModalDirective
	) {
		this.getFields();
		this.getEmailTemplates();
	}

	ngOnInit() {
		if (this.auth.loggedIn()) {
			this.user = this.auth.getUser();
			if (this.user.email_signature == null || this.user.email_signature == undefined) {
				this.emailSignature = '';
			} else {
				this.emailSignature = this.user.email_signature.trim();
			}
			this.email_temaplate_form.test_email = this.user.email;
		}
	}

	getFields() {
		this.emailAutomationService.getFields() // get filtered names
			.subscribe((data: any) => {
				this.mentionFields = [];
				data.forEach(element => {
					this.mentionFields.push({ id:element, value: element });
				});

				this.modules = {
					mention: {
						allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
						showDenotationChar: false,
						// onSelect: (item, insertItem) => {
						// 	// const editor = this.editor.quillEditor;
						// 	// insertItem(item.value);
						// 	// necessary because quill-mention triggers changes as 'api' instead of 'user'
						// 	// editor.insertText(editor.getLength() - 1, '', 'user');
						// },
						source: (searchTerm, renderList) => {
							console.log('source', this.mentionFields);
							
							if (searchTerm.length === 0) {
								renderList(this.mentionFields, searchTerm)
							} else {
								const matches = []

								this.mentionFields.forEach((entry) => {
									if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
										matches.push(entry);
									}
								})
								renderList(matches, searchTerm);
							}
						}
					},
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],        // toggled buttons
						['blockquote', 'code-block'],

						[{ 'header': 1 }, { 'header': 2 }],               // custom button values
						[{ 'list': 'ordered' }, { 'list': 'bullet' }],
						[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
						[{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
						[{ 'direction': 'rtl' }],                         // text direction

						[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
						[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

						[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
						[{ 'font': [] }],
						[{ 'align': [] }],

						['clean'],                                         // remove formatting button

						['link', 'image', 'video']
					],
					imageHandler: {
						upload: (file) => {
							return new Promise((resolve, reject) => {

								if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') { // File types supported for image
									if (file.size < 1000000) { // Customize file size as per requirement

										// Sample API Call
										const uploadData = new FormData();
										uploadData.append('file', file, file.name);

										return this.emailAutomationService.uploadImage(uploadData).toPromise()
											.then((result:any) => {
												resolve(result.imageUrl); // RETURN IMAGE URL from response
											})
											.catch(error => {
												reject('Upload failed');
												// Handle error control
												console.error('Error:', error);
											});
									} else {
										reject('Size too large');
										// Handle Image size large logic 
									}
								} else {
									reject('Unsupported type');
									// Handle Unsupported type logic
								}
							});
						},
						accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
					} as Options
				};
			});
	}

	getEmailTemplates() {
		this.emailAutomationService.getEmailTemplates({ per_page: 20, current_page: 1 }).subscribe(
			(data: any) => {
				var actionObj = {};
				actionObj['id'] = "0";
				actionObj['name'] = "Custom Email";
				data.response.data.unshift(actionObj);
				this.email_templates = data.response.data;
			},
			error => {
				this._helper.errorMessage(error, "Error while login, Please try again.");
			});
	}

	sendTestEmail() {
		// this.email_temaplate_form.body = document.getElementsByClassName('ql-editor')[0].innerHTML;
		this.invalidTemplateField = [];
		if (!this.email_temaplate_form.subject) {
			this.invalidTemplateField.push('subject');
		}
		if (!this.email_temaplate_form.body) {
			this.invalidTemplateField.push('body');
		}
		if (!this.email_temaplate_form.test_email) {
			this.invalidTemplateField.push('test_email');
		}
		if (this.invalidTemplateField.length) {
			return false;
		}
		
		const ack_id = this.recipient_data['ack_id'] ? this.recipient_data['ack_id'] : null;
		this.emailAutomationService.sendEmail({ template_id: this.email_template.template, type: this.recipient_data['type'], ack_id: ack_id, subject: this.email_temaplate_form.subject, body: this.email_temaplate_form.body, test_email: this.email_temaplate_form.test_email }).subscribe({
			next: () => {
				this._helper.successMessage('', "Email sent successfully.");
				//this.email_test_modal.show();
				//this.modalDialog.hide();
				this.TestmailSuccess.emit();
			},
			error: (error) => {
				this._helper.errorMessage(error, "Error while login, Please try again.");
			}
		});
	}

	sendEmail() {

		// this.email_temaplate_form.body = document.getElementsByClassName('angular-editor-textarea')[0].innerHTML;
		this.invalidTemplateField = [];
		if (!this.email_temaplate_form.subject) {
			this.invalidTemplateField.push('subject');
		}
		if (!this.email_temaplate_form.body) {
			this.invalidTemplateField.push('body');
		}

		if (this.invalidTemplateField.length) {
			return false;
		}

		const ack_id = this.recipient_data['ack_id'] ? this.recipient_data['ack_id'] : null;
		this.emailAutomationService.sendEmail({ recipients: this.recipient_data['recipients'], template_id: this.email_template.template, type: this.recipient_data['type'], ack_id: ack_id, subject: this.email_temaplate_form.subject, body: this.email_temaplate_form.body }).subscribe({
			next: () => {
				this._helper.successMessage('', "Email sent successfully.");
				// this.modalDialog.hide();
			},
			error: (error) => {
				this._helper.errorMessage(error, "Error while login, Please try again.");
			}
		});
	}

	deail(data) {
		if (data) {
			this.emailAutomationService.getEmailTemplatesPreview(data).subscribe(
				(data: any) => {
					if (!data.response) {
						this.custom_template = true;
						this.email_template_preview = data.response;
						this.email_temaplate_form.subject = "";
						if (this.emailSignature.trim()) {
							this.email_temaplate_form.body = "<br><br>" + this.emailSignature;
						}
					} else {
						this.custom_template = false;
						this.email_template_preview = data.response;
						this.email_temaplate_form.subject = data.response?.subject;
						this.email_temaplate_form.body = data.response?.body.replace("<br><br>" + this.emailSignature, '');
						this.email_temaplate_form.body = this.email_temaplate_form.body.replace(this.emailSignature, '');

						this.email_temaplate_form.body = this.email_temaplate_form.body + "<br><br>" + this.emailSignature;
					}

					this.invalidTemplateField = [];
				},
				error => {
					this._helper.errorMessage(error, "Error while login, Please try again.");
				});
		}

	}
}
