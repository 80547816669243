<ng-container *ngFor="let icon of icons">
  <!-- If it's an anchor tag -->
  <a *ngIf="isAnchor"
    [ngClass]="['mr-1', icon.className, iconSize === 'normal' ? 'normal-icon' : '', iconExtraClass]"
    [attr.href]="href[icon?.key] ? href[icon?.key] : null"
    [attr.target]="isAnchor ? '_blank' : null"
    nz-tooltip
    [nzTooltipColor]="icon.color"
    [nzTooltipTitle]="getTooltip(icon.type)">
      <span>{{ icon.label }}</span>
  </a>
  
  <!-- If it's a div -->
  <div 
    *ngIf="!isAnchor"
    [ngClass]="['mr-1', icon.className, iconSize === 'normal' ? 'normal-icon' : '', iconExtraClass]"
    nz-tooltip
    [nzTooltipColor]="icon.color"
    [nzTooltipTitle]="getTooltip(icon.type)">
      <span>{{ icon?.label }}</span>
  </div>
</ng-container>