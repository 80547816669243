<div class="card">
	<div class="content-bd-ria">
		<app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
			'advizor': dbaContact?.advizor,
			'broker': dbaContact?.broker,
			'insurance': dbaContact?.insurance,
			'fo': dbaContact?.fo_id,
			'bt': dbaContact?.bt_id,
			'dc': dbaContact?.dc,
			'db': dbaContact?.db
		})"
		[iconSize]="'normal'" />
	</div>
	<div class="d-flex flex-row">
		<div *ngIf="dbaContact?.photo_url">
			<a [routerLink]="getLinkType()" target="_blank" class="circle-container">
				<img [src]="dbaContact.photo_url" alt="person photo" />
			</a>
		</div>
		<span *ngIf="!dbaContact?.photo_url" class="avatar">
			<a [routerLink]="getLinkType()" target="_blank">
				<i class="avatar-initial">{{ getInitials(dbaContact?.first_name) + getInitials(dbaContact?.last_name) }}</i>
			</a>
		</span>
		<div class="ml-3">
			<a [routerLink]="getLinkType()" target="_blank">
				<h2 class="font-weight-bold m-0 content-name">
					{{ dbaContact?.first_name | uppercase }}
					{{ dbaContact?.middle_name | uppercase }}
					{{ dbaContact?.last_name | uppercase }}
				</h2>
			</a>
			<p class="m-0 location d-block">
				<span #locationTitle class="location-title" [nz-tooltip]="isTextOverflowing" [nzTooltipTitle]="isTextOverflowing ? (dbaContact?.title | uppercase) : ''">{{ dbaContact?.title | uppercase }}</span>
				<span>
					{{ dbaContact?.city | uppercase }},
					{{ dbaContact?.state | uppercase }}
				</span>
			</p>
			<div class="content-icons">
				<a
					href="mailto:{{ dbaContact?.email }}"
					target="_blank"
					*ngIf="dbaContact?.email"
					class="ml-1"
					title="{{ dbaContact?.email }}"
					><i class="fas fa-envelope" aria-hidden="true"></i
				></a>
				<a
					href="mailto:{{ dbaContact?.personal_email }}"
					target="_blank"
					*ngIf="dbaContact?.personal_email"
					class="ml-1"
					title="{{ dbaContact?.personal_email }}"
					><i class="far fa-envelope personal_email" aria-hidden="true"></i
				></a>
				<a
					href="tel:{{ dbaContact?.phone }}"
					title="{{ dbaContact?.phone }}"
					*ngIf="dbaContact?.phone"
					class="ml-1"
					><i class="fas fa-phone-alt" aria-hidden="true"></i
				></a>
				<a
					href="{{ dbaContact?.bio_url }}"
					title="{{ dbaContact?.bio_url }}"
					target="_blank"
					*ngIf="dbaContact?.bio_url"
					class="ml-1"
					><i class="fas fa-user" aria-hidden="true"></i
				></a>
				<a
					href="{{ dbaContact?.linkedin }}"
					title="{{ dbaContact?.linkedin }}"
					target="_blank"
					*ngIf="dbaContact?.linkedin"
					class="ml-1"
					><i class="fab fa-linkedin" aria-hidden="true"></i
				></a>
				<a
					href="{{ dbaContact?.team_website }}"
					rel="noreferrer noopener nofollow"
					title="{{ dbaContact?.team_website }}"
					target="_blank"
					*ngIf="dbaContact?.team_website"
					class="ml-1"
					><i class="fa fa-globe" aria-hidden="true"></i
				></a>
			</div>
		</div>
	</div>
</div>
