import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyMillion' })
export class CurrencyMillionPipe implements PipeTransform {

    transform(amount?: number): string {
        if (amount === undefined || amount === null) return "--";
        return "$" + Intl.NumberFormat().format(amount/1000000) + 'M';
    }
}

@Pipe({ name: 'valueOrDefault' })
export class ValueOrDefaultPipe implements PipeTransform {

    transform(value?: string | number, d = "--"): string | number {
        if (value === undefined || value === null) return d;
        return value
    }
}

@Pipe({ name: 'currencyOrDefault' })
export class CurrencyOrDefaultPipe implements PipeTransform {

    constructor(private currencyPipe:CurrencyPipe){}

    transform(value?: string | number, d = "--"): string | number {
        if (value === undefined || value === null) return d;
        return this.currencyPipe.transform(value)
    }
}

@Pipe({ name: 'percentOrDefault' })
export class PercentOrDefaultPipe implements PipeTransform {

    transform(amount?: string | number, min = 0, max = 1): string {
        if(amount === undefined || amount === null) return "--";
        const pct = 100*(Number(amount)/(min+max))

        return pct + '%'
    }
} 

@Pipe({ name: 'booleanOrDefault'})
export class BooleanOrDefaultPipe implements PipeTransform {
    transform(value?: boolean, d= "--"): string{
        if(value === undefined || value === null) return d;
        if(value) return "Yes";
        else return "No";
    }
}

@Pipe({
    name: 'titleCase',
  })
  export class TitleCasePipe implements PipeTransform {
    transform(value: string | null | undefined): string {
      if (!value) return '';
      return value
        .split(' ')
        .map((word) =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    }
}