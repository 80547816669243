<div class="flex-container">

    <!-- Excellent Badge Demo -->
    <div *ngIf="leadScore === 'Excellent'">
        <div class="badge" [class]="getBadgeClasses()" [ngStyle]="{display: 'flex', alignItems: 'center', gap: '4px'}">
            Excellent <img src="assets/img/star.png" [width]="getIconSize()" width="14px" [alt]="'star'"
                [ngStyle]="{verticalAlign: 'middle'}">
        </div>
    </div>

    <!-- Good Badge Demo -->
    <div *ngIf="leadScore === 'Good'">
        <div class="badge" [class]="getBadgeClasses()" [ngStyle]="{display: 'flex', alignItems: 'center', gap: '4px'}">
            Good <img src="assets/img/good.png" [width]="getIconSize()" width="14px" [alt]="'good'"
                [ngStyle]="{verticalAlign: 'middle'}">
        </div>
    </div>

    <!-- Fair Badge Demo -->
    <div *ngIf="leadScore === 'Fair'">
        <div class="badge" [class]="getBadgeClasses()" [ngStyle]="{display: 'flex', alignItems: 'center', gap: '4px'}">
            Fair <img src="assets/img/fair.png" [width]="getIconSize()" [alt]="'fair'"
                [ngStyle]="{verticalAlign: 'middle'}">
        </div>
    </div>

</div>