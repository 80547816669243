<input 
  [(ngModel)]="model" 
  [typeaheadAsync]="true" 
  [typeahead]="dataSource"
  [typeaheadMinLength]="0"
  (typeaheadLoading)="changeTypeaheadLoading($event)" 
  (typeaheadOnSelect)="typeaheadOnSelect($event)"
  [typeaheadOptionsLimit]="typeaheadOptionsLimit" 
  [placeholder]="placeholder" 
  [typeaheadOptionField]="typeaheadOptionField"
  [typeaheadItemTemplate]="typeaheadItemTemplate || typeaheadDefaultItemTemplate"
  class="form-control" 
  autocomplete="off"
  [name]="name"
  [disabled]="disable"
  popover="I will hide on blur"
  triggers="mouseenter:mouseleave"
  [ngStyle]="{ 'position': 'relative', 'padding-right': isSearchButtonVisible ? '30px' : '0' }"
>
  
<button 
  *ngIf="isSearchButtonVisible" 
  class="btn search-button" 
  (click)="onSearchClick()" 
  [title]="iconTitle" 
  (mouseenter)="onMouseEnter()" 
  (mouseleave)="onMouseLeave()"
>
  <i *ngIf="!isSearchButtonLoading"
    [class]="searchIconClass"
    [ngStyle]="{'color': searchButtonColor}"></i>
  <i *ngIf="isSearchButtonLoading"
    class="fa fa-spinner fa-spin"
    [ngStyle]="{'color': searchButtonColor}"></i>
</button>

<div class="typeahead-loading" *ngIf="typeaheadLoading">Loading...</div>

<ng-template #typeaheadDefaultItemTemplate let-item="item" let-index="index">
  <span *ngIf="isMultiple" (click)="preEvent($event)" class="multiple-select">
    <label><input type="checkbox" name="typehead[]" [value]="item" [checked]="chips.includes(item)?true:false" class="mr-1">{{ item }}</label>
  </span>
  <span *ngIf="!isMultiple">
    {{ item }}
  </span>
</ng-template>