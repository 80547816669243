import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserService {

	constructor(
		private http: HttpClient
	) { }

	addUser(data) {
        return this.http.post('/api/admin/users/save', data);
    }
    getUser(data) {
        return this.http.post('/api/admin/users', data);
    }
    getSubscriptions() {
        return this.http.get('/api/admin/subscriptions');
    }
    deleteUser(data){
    	return this.http.post('/api/admin/users/delete', data);
    }
    updateCurrentUser(data){
        return this.http.put('/api/user', data);
    }
    changeUser(data){
        return this.http.post('/api/auth/change-user', data);
    }
    supportFaq(access_type) {
        return this.http.get('/api/faq/' + access_type);
    }
    userAutocomplete(data){
        return this.http.post('/api/admin/user-autocomplete', data);
    }
    userReportCount(){
        return this.http.get('/api/me');
    }
    userSearchSetting() {
        return this.http.get('/api/user/search-setting');
    }
    notifyMe(data) {
        return this.http.post('/api/admin/notify-me', data);
    }
    saveUserSearchSetting(data) {
        return this.http.post('/api/user/search-setting', data);
    }
    getAccountCredit(data) {
        return this.http.post('/api/admin/users/account_credit', data);
    }
    getClient(data) {
        return this.http.post('/api/client/admin', data);
    }
    resetCredit(id) {
        return this.http.delete('/api/admin/reset-credit/' + id);
    }

    resetExportCredits(id) {
        return this.http.delete('/api/admin/reset-export-credits/' + id);
    }
}
