import { Component, Input } from '@angular/core';
import * as _ from "underscore";

@Component({
	selector: 'providers-401k-table',
	templateUrl: './providers-401k-table.component.html',
	styleUrls: ['./providers-401k-table.component.scss']
})
export class Providers401kTable {

	@Input() title: string
	@Input() providers: {
		provider_name: string,
		provider_role: string,
		year: number
	}[]

	public columnModeSetting = (window.innerWidth < 960) ? 'standard' : 'force';
	public scrollBarHorizontal = window.innerWidth < 960;

}