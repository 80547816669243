import { NgZone, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AdvizorProService } from '../shared/services/advizor-pro.service';
import { Router } from '@angular/router';
import { Helper } from '../shared/helper';
import {  take } from 'rxjs';
import { timeout } from 'rxjs/operators'
import { IGetMessagesResponse, IGetStatusResponse, ISubmitInputResponse } from './interfaces';

const delay = ms => new Promise(res => setTimeout(res, ms));
@Component({
	selector: 'app-ai-search-chat',
	templateUrl: './ai-search-chat.component.html',
	styleUrls: ['./ai-search-chat.component.scss'],
})
export class AiChatComponent implements OnInit {
	KNOWLEDGE_BASE_URL = 'https://support.advizorpro.com/en/advizorpro-knowledge-base/'
	MAX_REQUEST_ATTEMPS = 15

	isOpenModal = true;
	isLoading = false;
	isOptionLoading = false;
	isResponse = false;
	isMinimized = false;
	searchQuery = '';
	answer = '';
	conversationThreadID: string = null
	conversationRunID: string = null
	options = [{role: 'assistant',
		 content: 'Hello there, what kind of question do you have about our platform?', 
		 options: ['How can I add more users to AdvizorPro?', 'How can I connect Salesforce with AdvizorPro?', 'How can I filter on only RIAs?'] }, 
	];
	search: any = {};
	messages: any = [];
	@ViewChild('chatContainer') chatContainer: ElementRef;
	@ViewChild('chatTextarea') chatTextarea: ElementRef;

	constructor(
		public advizorProService: AdvizorProService,
		public router: Router,
		private _helper: Helper,
		private readonly ngZone: NgZone
	) {
		this.messages = this.options;
	}

	ngOnInit() {
	}

	openChatModal() {
		this.isOpenModal = true;
		if (this.isMinimized) {
			this.isOptionLoading = false;
			this.scrollToBottom();
		} else {
			this.isOptionLoading = true;
			setTimeout(() => {
				this.isOptionLoading = false;
			}, 2000);
		}
	}

	closeChatModal(type: string) {
		if (type === 'minimize') {
			this.isOpenModal = false;
			this.isMinimized = true;
		} else {
			this.isOpenModal = false;
			this.isLoading = false;
			this.isResponse = false;
			this.isMinimized = false;
			this.searchQuery = '';
			this.answer = '';
			this.messages = [];
		}
	}

	selectOption($event: any) {
		this.searchQuery = $event.target.innerText;
		this.submitInput()
	}
	

	submitInput(): void {
		this.isLoading = true;
		const searchQueryStage = this.searchQuery.trim();
		this.searchQuery = ''
		this.messages.push({role: 'user', content: searchQueryStage})
		this.scrollToBottom()
		if (searchQueryStage) {
		  const thread_id = sessionStorage.getItem("chat_ai_thread_id")
		  const run_id = sessionStorage.getItem("chat_ai_run_id")
	
		  this.advizorProService.aiSupportChat({
			prompt: searchQueryStage,
			assistant_type: "knowleage_base",
			thread_id,
			run_id,
			type: "run_with_messages"
		  })
			.pipe(take(1))
			.pipe(timeout(50000))
			.subscribe({
			  next: (res: ISubmitInputResponse) => {
	
				if(res?.data?.thread_id)
				  sessionStorage.setItem("chat_ai_thread_id", res.data.thread_id)
				if(res?.data?.run_id)
				  sessionStorage.setItem("chat_ai_run_id", res.data.run_id)
	
				if(res?.data?.thread_id && res?.data?.run_id)
				  delay(2000)
					.then(() => this.getStatus(res.data.thread_id, res.data.run_id))
					.catch(()=> this.launchError())
			  },
			  error: ()=> this.launchError()
			});
		}
	  }

	  getStatus(thread_id?: string, run_id?: string, attemp = 0 ) {
		thread_id = thread_id ? thread_id : sessionStorage.getItem("chat_ai_thread_id")
		run_id = run_id ? run_id : sessionStorage.getItem("chat_ai_run_id")
		this.advizorProService.aiSupportChat({
		  assistant_type: "knowleage_base",
		  thread_id,
		  run_id,
		  type: "get_status"
		})
		.pipe(take(1))
		.pipe(timeout(50000))
		.subscribe({
			next: (res: IGetStatusResponse) => {
				if (res.data === "completed")
					this.getMessage(thread_id, run_id)
				else if(attemp > this.MAX_REQUEST_ATTEMPS){
					this.isLoading = false;
					this._helper.errorMessage("", "We've got an issue, but we're working on a solution.");	
				}
				else 
					delay(1000)
						.then(()=> this.getStatus(thread_id, run_id, attemp + 1))
						.catch(()=> this.launchError())
			},
			error: ()=> this.launchError()
		})
	  }

	  getMessage(thread_id?: string, run_id?: string) {

		thread_id = thread_id ? thread_id : sessionStorage.getItem("chat_ai_thread_id")
		run_id = run_id ? run_id : sessionStorage.getItem("chat_ai_run_id")
		this.advizorProService.aiSupportChat({
		  assistant_type: "knowleage_base",
		  thread_id,
		  run_id,
		  type: "get_messages"
		})
		  .pipe(take(1))
		  .pipe(timeout(50000))
		  .subscribe({
	
			next: (res: IGetMessagesResponse) => {
				if (res.status) {
					this.isLoading = false;
					for( const message of res.data.messages){
						this.messages.push({
							role: "assistant", 
							content: message.text.replace(/【.*?】/gu, ''),
							references: Array.from(new Set(message.filenames)) //remove duplicates
						});	
					}	
					this.scrollToBottom()
				}
			},
			error: ()=> this.launchError()
		  });
	  }
	
	onKeydown(event) {
		event.preventDefault();
	}

	scrollToBottom(): void {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
			  try {
				this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
			  } catch (err) {
				console.error('Error scrolling to bottom:', err);
			  }
			}, 0);
		  });
	}

	launchError(){
		this.isLoading = false;
		this._helper.errorMessage("", "We've got an issue, but we're working on a solution.");
	}

	getReferenceLink(title: string): string{
		return title
			.replace('.pdf', '')
	   		.replace('$', this.KNOWLEDGE_BASE_URL )
	   		.replace('@', this.KNOWLEDGE_BASE_URL)
	}

	getReferenceName(title: string): string {
		const regex = /[-@$]|\.pdf$/g;
		const formattedTitle = title.replace(regex, (match) => {
		  switch (match) {
			case '-':
			  return ' ';
			case '@':
			case '$':
			case '.pdf':
			  return '';
			default:
			  return match; 
		  }
		});
		return formattedTitle.charAt(0).toUpperCase() + formattedTitle.slice(1);
	}

	boldFormat(message: string): string{
		return message.replace(/\*\*(.*?)\*\*/g, '<span class="bold-text">$1</span>');
	}
}


