<ng-container id="person-drawer">
    <div class="row c-main-advisor-firm">
        <div class="col-lg-4 advisor-details advisor-details-left scrollsection">
            <div class="card card-default">
                <div class="p-4 card-body person-icon-container">
                    <div>
                        <div *ngIf="!personProfile" class="text-center mb-2">
                                <div class="skeleton-logo"></div>
                        </div>
                        <div class="text-center mb-2" *ngIf="personProfile">
                            <span *ngIf="personProfile?.photo_url">
                                <div class="circle-container" 
                                    [ngStyle]="{'animation': score && score!= 'Not a Fit'  ? 'leadScorePulse 2s infinite' : 'none'}">
                                    <img [src]="personProfile.photo_url" alt="person photo"/>
                                </div>
                            </span>
                            <span class="header-logo" *ngIf="!personProfile?.photo_url"
                                [ngStyle]="{'animation': score && score!= 'Not a Fit'  ? 'leadScorePulse 2s infinite' : 'none'}">
                                <i class="user-initial">{{ personProfile?.logo_name }}</i>
                            </span>
                            <div class="position-relative">
                                <app-lead-score-badge [lead-score-type]="score" size="lg"/>
                            </div>
                        </div>

                        <div class="person-icon person-profile d-header-padding c-person-icon-header">
                            <div>
                                <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                    'advizor': personProfile?.advizor,
                                    'broker': personProfile?.broker
                                })"
                                [href]="{'advizor':iaProfile, 'broker': brokerProfile}"
                                [isAnchor]="'true'"
                                [iconSize]="'normal'" />

                                <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                    'fo': personProfile?.fo_id && user.has_access_family_office,
                                    'insurance': personProfile?.insurance && insurances && user.has_access_insurance,
                                    'o&e': personProfile?.owner,
                                    'bt': personProfile?.bt,
                                    'dc': personProfile?.dc,
                                    'db': personProfile?.db,
                                })" [iconSize]="'normal'" />
                            </div>
                            <div class="crd-npn">
                                <span class="fs-12" *ngIf="personProfile?.crd && personProfile?.crd < 100000000">CRD:
                                    {{ personProfile.crd }}</span>
                                <span class="fs-12"
                                    *ngIf="personProfile?.insurance && insurances && profileType != 'family' && personProfile?.npn < 100000000">NPN:
                                    {{ personProfile?.npn }}</span>
                            </div>
                        </div>

                        <div class="person-profile w-65 d-header-padding">
                            <h2 class="font-weight-bold m-0"
                                *ngIf="personProfile?.nick_name && personProfile?.middle_name">
                                {{personProfile?.first_name | uppercase}} "{{personProfile?.nick_name | uppercase}}"
                                {{personProfile?.middle_name | uppercase}} {{personProfile?.last_name | uppercase}}</h2>
                            <h2 class="font-weight-bold m-0"
                                *ngIf="personProfile?.nick_name && !personProfile?.middle_name">
                                {{personProfile?.first_name | uppercase}} "{{personProfile?.nick_name | uppercase}}"
                                {{personProfile?.last_name | uppercase}}
                            </h2>
                            <h2 class="font-weight-bold m-0"
                                *ngIf="!personProfile?.nick_name && personProfile?.middle_name">
                                {{personProfile?.first_name | uppercase}} {{personProfile?.middle_name | uppercase}}
                                {{personProfile?.last_name | uppercase}}
                            </h2>
                            <h2 class="font-weight-bold m-0"
                                *ngIf="!personProfile?.nick_name && !personProfile?.middle_name">
                                {{personProfile?.first_name | uppercase}} {{personProfile?.last_name | uppercase}}
                            </h2>
                            <div class="person-profile-title d-block" *ngIf="personTitle && personTitle.length > 0">
                                <h6><span>{{ personTitle.join(', ') }}</span></h6>
                            </div>

                            <p class="m-0 location d-block">
                                <span class="d-block">{{ personProfile?.full_address?.address_1 }} {{ personProfile?.full_address?.address_2 }}</span>
                                <span>
                                    {{ personProfile?.full_address?.city }}<span *ngIf="personProfile?.full_address?.city && personProfile?.full_address?.state">,&nbsp;</span>{{ personProfile?.full_address?.state }}
                                    {{ personProfile?.full_address?.zip }}
                                </span>
                            </p>
                        </div>

                        <div class="profile_link d-header-padding">
                            <ul class="p-0 m-0">
                                <li class="profile-link-item">
                                    <a target="_blank" *ngIf="attribute('linkedin', 'single')"
                                        href="{{attribute('linkedin', 'single')}}"><i
                                            class="icon-size fab fa-2x fa-linkedin"></i></a>
                                    <p class="inactive m-0 lh-0" *ngIf="!attribute('linkedin')"
                                        title="No LinkedIn for this contact"><i
                                            class="icon-size fab fa-2x fa-linkedin"></i></p>
                                </li>

                                <li class="profile-link-item">
                                    <a target="_blank" *ngIf="attribute('bio_url', 'single')"
                                        href="{{attribute('bio_url', 'single')}}"><i
                                            class="icon-size fa fa-2x fa-globe"></i></a>
                                    <p class="inactive m-0 lh-0" *ngIf="!attribute('bio_url')"
                                        title="No website for this contact"><i class="icon-size fa fa-2x fa-globe"></i>
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="firm-profile-button d-header-padding d-inline"
                                    *ngIf="profileType != 'insurance'">
                                    <button type="button" class="btn c-btn-blue-dark mr-2"
                                        [ngClass]="{'c-btn-blue-light':advisorFollow,'c-btn-blue-dark':!advisorFollow}"
                                        (click)="followAdvizor()">{{ (advisorFollow)?'Unfollow':'Follow'}}</button>
                                </div>
                                <a *ngIf="hubspotAccess" href="javascript:void(0)" class="pull-right mr-3 float-right"
                                    style="margin-top: 3px;">
                                    <img *ngIf="!hubspotImportData" src="assets/img/hubspot-off.png" alt="socail"
                                        (click)="importContact('hubspot', advizorPdf)">
                                    <img *ngIf="hubspotImportData" src="assets/img/hubspot-on.png" alt="socail"
                                        (click)="importContact('hubspot', advizorPdf)">
                                </a>
                                <a *ngIf="salesforceAccess" href="javascript:void(0)"
                                    class="pull-right mr-3 float-right" style="margin-top: 3px;">
                                    <img *ngIf="!salesforceImportData" src="assets/img/salesforce-off.png" width="29px"
                                        alt="socail" (click)="importContact('salesforce', advizorPdf)">
                                    <img *ngIf="salesforceImportData" src="assets/img/salesforce-on.png" width="29px"
                                        alt="socail" (click)="importContact('salesforce', advizorPdf)">
                                </a>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <div class="row d-firm-profile-rows ml-0">
                            <div class="col-md-12 pl-0">
                                <div *ngIf="attribute('email');" class="row c-row-details">
                                    <div class="col-md-3 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">{{(email && !personal_email) ?
                                            "" : "Business "}}Email</span>
                                    </div>
                                    <div class="col-md-9 text-right">
                                        <ng-container *ngIf="attribute('email');else dash">
                                            <span
                                                *ngFor="let email of attribute('email'); let i = index ; let first = first;">
                                                <a href="mailto:{{email}}" [ngClass]="{'a_email':!first}"><span
                                                        class="fs-12">{{email}}</span></a>
                                                <br />
                                            </span>
                                        </ng-container>
                                        <ng-template #dash><span>-</span></ng-template>
                                    </div>
                                </div>

                                <div *ngIf="attribute('personal_email');" class="row c-row-details">
                                    <div class="col-md-3 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Personal Email</span>
                                    </div>
                                    <div class="col-md-9 text-right">
                                        <ng-container *ngIf="attribute('personal_email');else dash">
                                            <span
                                                *ngFor="let email of attribute('personal_email'); let i = index ; let first = first;">
                                                <a href="mailto:{{email}}" [ngClass]="{'a_email':!first}"><span
                                                        class="fs-12">{{email}}</span></a>
                                                <br />
                                            </span>
                                        </ng-container>
                                        <ng-template #dash><span>-</span></ng-template>
                                    </div>
                                </div>

                                <div class="row c-row-details">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Phone</span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <ng-container *ngIf="attribute('phone', 'comma');else dashNumber">
                                            <p
                                                *ngFor="let phone of attributes['phone']; let i = index ; let last = last;">
                                                <a href="tel:{{phone.value | phoneMask }}"
                                                    class="text-capitalize fs-12"> {{ phone.value | phoneMask }} </a>
                                                <span class="black-1 fs-12">{{ phoneType(phone.type) }}</span>
                                            </p>
                                        </ng-container>
                                        <ng-template #dashNumber><span>-</span></ng-template>
                                    </div>
                                </div>


                                <div class="row c-row-details" *ngIf="profileType != 'family' && profileType != 'bank'">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Designations</span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <span *ngIf="attribute('designation', 'comma')"
                                            class="fs-12">{{attribute('designation', 'comma')}}</span>
                                        <span *ngIf="!attribute('designation', 'comma')">-</span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="profileType != 'family' && profileType != 'bank'">
                                    <div class="col-md-7 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Years of Experience</span>
                                    </div>
                                    <div class="col-md-5 text-right">
                                        <span *ngIf="!personProfile?.years_of_exp">-</span>
                                        <span *ngIf="personProfile?.years_of_exp" class="fs-12">
                                            {{personProfile?.years_of_exp |
                                            number: '1.0-0'}}</span>
                                    </div>
                                </div>

                                <div class="row c-row-details"
                                    *ngIf="personProfile?.advizor_dba_team?.length> 0 && onlyTeamAccess">
                                    <div class="col-md-3 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Team {{personProfile?.advizor_dba_team?.length > 1 ? 'Names' : 'Name' }}</span>
                                    </div>
                                    <div class="col-md-9 text-right">
                                        <div class="row">
                                            <div class="col-md-12" *ngFor="let dba of personProfile?.advizor_dba_team; let i = index">
                                                <a href="javascript:void(0)" (click)="openDbaContact(dba)" class="fs-12">{{
                                                    dba?.team?.name | uppercase }} ({{dba.team?.number_of_employees}})</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="hasRIAFirm()">
                                    <div class="col-md-2 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">RIA</span>
                                    </div>
                                    <div class="col-md-10 text-right">
                                        <ng-container
                                            *ngFor="let advizor_person_firm of personProfile?.advizor_person_firms; let i = index">
                                            <span *ngIf="advizor_person_firm?.advizor && advizor_person_firm?.firm?.advizor">
                                                <a [routerLink]="['/advisor/firm', advizor_person_firm?.firm?.crd]" target="_blank"
                                                    class="fs-12">{{advizor_person_firm?.firm?.firm_name}}</a>
                                                <br>
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row c-row-details"
                                    *ngIf="personProfile?.fo && user.has_access_family_office">
                                    <div class="col-md-3 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Family Office</span>
                                    </div>
                                    <div class="col-md-9 text-right">
                                        <span
                                            *ngFor="let firm of personProfile?.advizor_person_firms; let i = index; let last = last">
                                            <span *ngIf="firm?.fo">
                                                <a [routerLink]="['/family/firm', firm?.firm?.fo_id]" target="_blank"
                                                    class="fs-12">{{firm?.firm?.firm_name}}</a>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="hasBrokerFirm()">
                                    <div class="col-md-2 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">B-D</span>
                                    </div>
                                    <div class="col-md-10 text-right">

                                        <ng-container
                                            *ngFor="let advizor_person_firm of personProfile?.advizor_person_firms; let i = index">
                                        <span *ngIf="advizor_person_firm?.broker && advizor_person_firm?.firm?.broker">
                                            <a [routerLink]="['/advisor/firm', advizor_person_firm?.firm?.crd]" target="_blank"
                                                class="fs-12">{{advizor_person_firm?.firm?.firm_name}}</a>
                                            <br>
                                        </span>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="hasBankTrustFirm() && user.has_access_bank_and_trust">
                                    <div class="col-md-4 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Bank/Trust</span>
                                    </div>
                                    <div class="col-md-8 text-right">
                                        <span
                                            *ngFor="let advizor_person_firm of personProfile?.advizor_person_firms; let i = index; let last = last">
                                            <span *ngIf="advizor_person_firm?.bt">
                                                <a [routerLink]="['/bank/firm', advizor_person_firm?.firm?.bt_id]" target="_blank"
                                                    class="fs-12">{{advizor_person_firm?.firm?.firm_name | uppercase}}</a>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="row c-row-details"
                                    *ngIf="personProfile?.firm_custodians && (personProfile?.advizor || personProfile?.broker)">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Custodians</span>
                                    </div>
                                    <div class="col-md-7 text-right"
                                        *ngIf="personProfile?.firm_custodians.length > 0">
                                        <ng-container
                                            *ngFor="let firm of personProfile?.firm_custodians; let i = index;">
                                            <span class="ml-1 mb-1 badge badge-primary fs-12">{{firm}}</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-7 text-right"
                                        *ngIf="personProfile?.firm_custodians.length == 0">
                                        <span> - </span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="personProfile?.est_age || personProfile?.gender">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">
                                            <span *ngIf="personProfile?.est_age">Est. Age</span>
                                            <span
                                                *ngIf="personProfile?.est_age && personProfile?.gender">&nbsp;|&nbsp;</span>
                                            <span *ngIf="personProfile?.gender">Gender</span>
                                        </span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <span *ngIf="personProfile?.est_age" class="fs-12"> {{personProfile?.est_age |
                                            number:'1.0-0'}} </span>
                                        <span *ngIf="personProfile?.est_age && personProfile?.gender"
                                            class="fs-12">&nbsp;|&nbsp;</span>
                                        <span *ngIf="personProfile?.gender" class="fs-12"> {{personProfile?.gender |
                                            titlecase}} </span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="profileType != 'bank'">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Accolades</span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <span *ngIf="accolades.length <= 0">-</span>
                                        <span *ngIf="accolades.length > 0">
                                            <span *ngFor="let keyword of accolades;">
                                                <span class="ml-1 mb-1 badge badge-primary fs-12 person-category-badge"
                                                    [style.background-color]="keyword?.color" *ngIf="!keyword?.url">
                                                    {{ keyword?.keyword_display }}
                                                </span>
                                                <a href="{{keyword.url}}" target="_blank"
                                                    class="ml-1 mb-1 badge badge-primary fs-12 person-category-badge"
                                                    [style.background-color]="keyword?.color" *ngIf="keyword?.url">
                                                    {{ keyword?.keyword_display }}
                                                </a>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="profileType != 'bank'">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Alma Mater</span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <span *ngIf="alma_mater.length <= 0">-</span>
                                        <span *ngIf="alma_mater.length > 0">
                                            <span *ngFor="let keyword of alma_mater;">
                                                <span class="ml-1 mb-1 badge badge-primary fs-12 person-category-badge"
                                                    [style.background-color]="keyword?.color" *ngIf="!keyword?.url">
                                                    {{ keyword?.keyword_display }}
                                                </span>
                                                <a href="{{keyword.url}}" target="_blank"
                                                    class="ml-1 mb-1 badge badge-primary fs-12 person-category-badge"
                                                    [style.background-color]="keyword?.color" *ngIf="keyword?.url">
                                                    {{ keyword?.keyword_display }}
                                                </a>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="row c-row-details" *ngIf="personProfile?.firm_ownership">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Owner</span>
                                    </div>
                                    <div class="col-md-7 text-right fs-12">
                                        <span class="fs-12"> {{personProfile?.firm_ownership}}</span>
                                    </div>
                                </div>
                                <div class="row c-row-details" *ngIf="profileType == 'advisor'">
                                    <div class="col-md-5 text-left">
                                        <span class="c-text-color dm-sans-semibold fs-12">Disclosures</span>
                                    </div>
                                    <div class="col-md-7 text-right">
                                        <span class="fs-12">{{ personProfile?.advizor_disclosure?.length > 0 ? personProfile?.advizor_disclosure?.length : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-8 advisor-details-right c-section">

            
            <nz-tabset class="horizontal-tab">
                <nz-tab [nzTitle]="titleTemplate">
                    <ng-template #titleTemplate>
                        <b>General Information</b>
                    </ng-template>
                    
                    <div class="px-4">
                        <insights-container [insights]="insights" title="Insights" > </insights-container>
                        <ng-container *ngIf="personProfile?.bio">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h4 class="c-title">Biography</h4>
                                    <span *ngIf="!personProfile?.bio?.includes('<p>')" class="fs-12" [innerText]="personProfile?.bio"></span>
                                    <span *ngIf="personProfile?.bio?.includes('<p>')" class="fs-12" [innerHtml]="personProfile?.bio"></span>
                                </div>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container
                            *ngIf="personProfile?.fo && user.has_access_family_office && personProfile?.advizor_firm_asset_class?.length > 0">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex flex-column">
                                        <h4 class="c-title m-0">Asset Class Focus</h4>
                                        <div>
                                            <ul class="pl-3 m-0">
                                                <ng-container
                                                    *ngFor="let asset_class of personProfile?.advizor_firm_asset_class;">
                                                    <li class="mb-1 fs-12">{{ asset_class | titlecase }} </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container
                            *ngIf="personProfile?.fo && user.has_access_family_office && personProfile?.advizor_contact_industry_category?.length > 0">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex flex-column">
                                        <h4 class="c-title m-0">Industry Focus</h4>
                                        <div>
                                            <ul class="pl-3 m-0">
                                                <ng-container
                                                    *ngFor="let asset_class of personProfile?.advizor_contact_industry_category;">
                                                    <li class="mb-1 fs-12">{{ asset_class | titlecase }} </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container *ngIf="profileType != 'family'">
                            <ng-container
                                *ngIf="(profileType != 'insurance' && personProfile?.person_licenses_registrations?.length > 0) || (personRegisteredStateLimit?.length > 0)">
                                <div class="row">
                                    <div class="col-md-6" *ngIf="profileType != 'insurance'">
                                        <div class="d-flex flex-column">
                                            <h4 class="c-title m-0">Licenses & Exams</h4>
                                            <div>
                                                <ul class="pl-3 m-0" *ngIf="personLicenseLimit?.length > 0">
                                                    <ng-container
                                                        *ngFor="let licenses of personLicenseLimit; let i = index">
                                                        <li *ngIf="licenses" class="mb-1 fs-12">{{ licenses }} </li>
                                                    </ng-container>
                                                </ul>
                                                <span *ngIf="personProfile?.person_licenses_registrations?.length > 2">
                                                    <a href="javascript:void(0)" class="badge badge-primary fs-12"
                                                        (click)="seeMoreLicence()">+{{
                                                        personProfile?.person_licenses_registrations.length - 2 }}
                                                        more</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6" *ngIf="personRegisteredStateLimit?.length > 0">
                                        <div class="d-flex flex-column">
                                            <h4 class="c-title m-0">Registrations</h4>
                                            <div>
                                                <div *ngFor="let registration of personRegisteredStateLimit; let i = index"
                                                    class="mb-1">
                                                    <div class="d-inline">
                                                        <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                            'advizor': registration?.advizor,
                                                            'broker': registration?.broker,
                                                            'insurance': registration?.insurance && insurances && user.has_access_insurance
                                                        })" [iconSize]="'normal'" />
                                                        <span class="mr-2 fs-12"> {{ registration?.state }} </span>
                                                    </div>
                                                </div>
                                                <div class="registerationMore"
                                                    *ngIf="person_registered_state?.total > 2 ">
                                                    <a href="javascript:void(0)" class="badge badge-primary"
                                                        (click)="seeMoreRegisterations()">+{{
                                                        person_registered_state?.total - 2 }} more</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="personProfile?.person_tags?.length > 0 && person_tags_length">
                            <div class="row mb-3">
                                <ng-container *ngFor="let tags of personProfile?.person_tags;">
                                    <div class="col-md-3 person-person-tags" *ngIf="tags.person_tags.length > 0">
                                        <h4 class="c-title">{{ tags.tag_category }}</h4>
                                        <span *ngFor="let keyword of tags?.person_tags;">
                                            <span class="ml-1 mb-1 badge badge-primary"
                                                [style.background-color]="keyword?.color"
                                                *ngIf="!keyword?.url && keyword?.level == 'person'">
                                                {{ keyword?.keyword_display }}
                                            </span>

                                            <a href="{{keyword.url}}" target="_blank"
                                                class="ml-1 mb-1 badge badge-primary"
                                                [style.background-color]="keyword?.color"
                                                *ngIf="keyword?.url && keyword?.level == 'person'">
                                                {{ keyword?.keyword_display }}
                                            </a>
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container *ngIf="bank_trust_team_types.length">
                            <div class="row mb-3">
                                <div class="col-md-12 general-info c-section">
                                    <h4 class="c-title m-0">Bank & Trust Information</h4>
                                    <div class="d-flex">
                                        <div *ngFor="let teamType of bank_trust_team_types" class="c-flex-col pr-40 flex-fill">
                                          <span class="c-label">{{ teamType.label }}:
                                            <span class="fs-14" *ngIf="personProfile?.[teamType.key]; else showDashes"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                            <ng-template #showDashes>
                                              <span class="c-desc">--</span>
                                            </ng-template>
                                          </span>
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container *ngIf="personProfile?.person_tags?.length > 0 && firm_tags_length">
                            <div class="row mb-3">
                                <ng-container *ngFor="let tags of personProfile?.person_tags;">
                                    <div class="col-md-3 person-firm-tags" *ngIf="tags.firm_tags.length > 0">
                                        <h4 class="c-title">Firm - {{ tags.tag_category }}</h4>
                                        <span *ngFor="let keyword of tags.firm_tags;">
                                            <span class="ml-1 mb-1 badge badge-primary fs-12"
                                                [style.background-color]="keyword?.color"
                                                *ngIf="!keyword?.url && keyword?.level == 'firm'">
                                                {{ keyword?.keyword_display }}
                                            </span>
                                            <a href="{{keyword.url}}" target="_blank"
                                                class="ml-1 mb-1 badge badge-primary"
                                                [style.background-color]="keyword?.color"
                                                *ngIf="keyword?.url && keyword?.level == 'firm'">
                                                {{ keyword?.keyword_display }}
                                            </a>
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container *ngIf="profileType != 'family' && profileType != 'insurance' && profileType != 'bank'">
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <h4 class="c-title">Firm History</h4>
                                </div>
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <ngx-datatable class='table table-hover advisor-search-tbl material table-v2'
                                            [rows]="personFirmHistory?.data" [columnMode]="'flex'" [headerHeight]="43"
                                            [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="scrollBarHorizontal"
                                            [externalPaging]="true" [count]="personFirmHistory?.total"
                                            [offset]="paginate.pageNumber" [limit]="paginate.limit"
                                            (page)='pageChange($event)' [externalSorting]="true">

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="false" prop="name"
                                                name="Firm Name" [flexGrow]="2">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <app-icon-dataset
                                                            [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'advizor': row?.advisor})"
                                                            (click)="handleRoute(['/advisor/firm', row?.firm_crd])"
                                                            [isAnchor]="'true'"
                                                            [iconSize]="'normal'" />
                                                        <app-icon-dataset
                                                            [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({'broker': row?.broker})"
                                                            (click)="handleRoute(['/advisor/firm', row?.firm_crd])"
                                                            [isAnchor]="'true'"
                                                            [iconSize]="'normal'" />
                                                        <a [routerLink]="['/advisor/firm', row?.firm_crd]"
                                                            target="_blank"><span> {{ row?.firm_name }}</span></a>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="false" prop="type"
                                                name="Period" [flexGrow]="1">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <span>{{row?.begin | date:'MM/dd/yy'}} - {{(row?.end) ? (row?.end |
                                                        date:'MM/dd/yy'): 'Present'}}</span>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="false"
                                                prop="ownership" name="Ownership" [flexGrow]="1">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <span>{{row?.ownership ?row?.ownership :'-'}}</span>
                                                </ng-template>
                                            </ngx-datatable-column>

                                        </ngx-datatable>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </ng-container>

                        <ng-container *ngIf="onlyTeamAccess && profileType != 'insurance' && profileType != 'family' && profileType != 'bank'">
                            <div class="row align-items-center d-flex mb-3" id="team">
                                <ng-container *ngIf="personProfile?.advizor_dba_team?.length > 0 else defaultData">
                                    <div class="col-lg-2 mb-0 form-group">
                                        <input type="radio" name="search_type" (change)="teamSearchChange()"
                                            [(ngModel)]="search_type" value="1">
                                        <label for="private_label" class="fs-12 bold"
                                            style="margin-left:0.5rem;">Team</label>
                                    </div>
                                    <div class="col-lg-3 mb-0 form-group">
                                        <input type="radio" name="search_type" (change)="teamSearchChange()"
                                            [(ngModel)]="search_type" value="0">
                                        <label for="team_label" class="fs-12 bold"
                                            style="margin-left:0.5rem;">Branch</label>
                                    </div>
                                </ng-container>
                                <ng-template #defaultData>
                                    <div class="col-lg-5">
                                        <h4 class="c-title">Team</h4>
                                    </div>
                                </ng-template>

                                <div class="col-lg-3 offset-4 seach-export-section">
                                    <input type="text" name="team_search" class="form-control search-location"
                                        placeholder="Search" (keyup)="onKeyUp($event)">
                                </div>
                                <div class="col-lg-12 table-responsive person-team-tbl table-v2">
                                    <ngx-datatable class='table table-hover table-v2' [rows]="teams?.data"
                                        [columnMode]="columnModeSetting" [headerHeight]="43" [footerHeight]="50"
                                        [rowHeight]="'auto'" [scrollbarH]="scrollBarHorizontal" [externalPaging]="true"
                                        [count]="teams?.total" [offset]="paginateTeam.pageNumber"
                                        [limit]="paginateTeam.limit" [externalSorting]="true" (page)='sortData($event)'>

                                        <ngx-datatable-column [resizeable]="false"
                                            cellClass="d-flex align-items-center c-team-name" [sortable]="false"
                                            prop="name" name="Name" [minWidth]="350">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <a [routerLink]="['/advisor/person', row?.crd]" target="_blank"><span>{{
                                                        row?.first_name | uppercase}} {{ row?.last_name |
                                                        uppercase}}</span>
                                                </a>
                                                <p class="fs-10 mb-0">{{row?.title | uppercase}}</p>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center"
                                            [sortable]="false" name="BD" prop="broker" [minWidth]="40">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <span *ngIf="row?.broker"><i
                                                        class="circleColor fa fa-check-circle"></i></span>
                                                <span *ngIf="!row?.broker">-</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center"
                                            [sortable]="false" prop="investment_advisor" name="RIA" [minWidth]="40">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <span *ngIf="row?.advizor"><i
                                                        class="circleColor fa fa-check-circle"></i></span>
                                                <span *ngIf="!row?.advizor">-</span>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="advisor_action"
                                            [minWidth]="100" [sortable]="false" prop="advizor_person_attribute.value"
                                            name="Contact">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                <a href="mailto:{{row?.email}}" target="_blank" *ngIf="row?.email"
                                                    class="ml-1" title="{{row?.email}}"><i class="far fa-envelope"
                                                        aria-hidden="true"></i></a>
                                                <a href="mailto:{{row?.personal_email}}" target="_blank"
                                                    *ngIf="row?.personal_email" class="ml-1"
                                                    title="{{row?.personal_email}}"><i
                                                        class="far fa-envelope personal_email"
                                                        aria-hidden="true"></i></a>
                                                <a href="tel:{{row?.phone}}" title="{{row?.phone}}" *ngIf="row?.phone"
                                                    class="ml-1"><i class="fas fa-phone-alt" aria-hidden="true"></i></a>
                                                <a href="{{row?.bio_url}}" title="{{row?.bio_url}}" target="_blank"
                                                    *ngIf="row?.bio_url" class="ml-1"><i class="fas fa-user"
                                                        aria-hidden="true"></i></a>
                                                <a href="{{row?.linkedin}}" title="{{row?.linkedin}}" target="_blank"
                                                    *ngIf="row?.linkedin" class="ml-1"><i class="fab fa-linkedin"
                                                        aria-hidden="true"></i></a>
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-footer>
                                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                let-pageSize="pageSize" let-selectedCount="selectedCount"
                                                let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                                                <div class="pagination-v2">
                                                    <div class="page-count p-0">
                                                        <span class="page-total">
                                                            {{ rowCount?.toLocaleString() }} total entries
                                                        </span>
                                                    </div>
                                                    <datatable-pager class="custom-datatable-pager"
                                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                        [size]="pageSize" [count]="rowCount"
                                                        [hidden]="!((rowCount / pageSize) > 1)"
                                                        (change)="sortData($event)">
                                                    </datatable-pager>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </ng-container>
                    
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="lineTemplate"
                    *ngIf=" user.has_access_insurance && personProfile?.insurance && insurances">
                    <ng-template #lineTemplate>
                        <b>Lines & Carriers</b>
                    </ng-template>

                    <div class="px-4">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex flex-column">
                                    <h4 class="c-title">Carriers</h4>
                                    <div>
                                        <ul class="pl-3 m-0" *ngIf="personInsuranceCarriersLimit?.length > 0">
                                            <ng-container
                                                *ngFor="let carrier of personInsuranceCarriersLimit; let i = index">
                                                <li class="fs-12" *ngIf="carrier">{{ carrier.carrier }} </li>
                                            </ng-container>
                                        </ul>
                                        <span *ngIf="personInsurenceCarrierData?.length > 2">
                                            <a href="javascript:void(0)" class="badge badge-primary"
                                                (click)="seeMoreCarrier()">+{{ personInsurenceCarrierData.length - 2 }}
                                                more</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-flex flex-column">
                                    <h4 class="c-title">Lines</h4>
                                    <div>
                                        <ul class="pl-3 m-0" *ngIf="personInsuranceLinesLimit?.length > 0">
                                            <ng-container *ngFor="let line of personInsuranceLinesLimit let i = index">
                                                <li class="fs-12" *ngIf="line">{{ line.line_type | uppercase}} </li>
                                            </ng-container>
                                        </ul>
                                        <span *ngIf="personInsuranceLines?.length > 2">
                                            <a href="javascript:void(0)" class="badge badge-primary"
                                                class="badge badge-primary" (click)="seeMoreLines()">+{{
                                                personInsuranceLines.length - 2 }} more</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div>
                            <div class="row m-0 justify-content-between align-items-center">
                                <h4 class="c-title m-0">Carrier Appointments</h4>
                                <div class="row seach-export-section">
                                    <div class="col-lg-8">
                                        <input type="text" name="apt_search" #apt_search
                                            class="form-control search-location" placeholder="Search">
                                    </div>
                                    <div class="col-lg-4 text-right pl-0" *ngIf="!isDownload">
                                        <a icon="fa-file-pdf-o" class="excle-export-btn" [disabled]="exportStatus"
                                            iconPos="right" (click)="exportModel()" href="javascript:void(0)">
                                            <img src="assets/img/xlsx.ico" width="21px" />
                                            <span *ngIf="exportStatus" class="fs-11">Exporting...</span>
                                            <span *ngIf="!exportStatus" class="fs-11">Excel Export</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <ngx-datatable class='table table-hover table-v2 c-carrier-appt'
                                            [rows]="personInsuranceAppointments?.data" [columnMode]="columnModeSetting"
                                            [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'"
                                            [scrollbarH]="scrollBarHorizontal" [externalPaging]="true"
                                            [count]="personInsuranceAppointments?.total"
                                            [offset]="paginateApt.pageNumber" [limit]="paginateApt.limit"
                                            (page)='aptPageChange($event)' [externalSorting]="true"
                                            (sort)="onAptSort($event)">

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true" prop="state"
                                                name="State">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <span> {{ row?.state | uppercase}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true" prop="carrier"
                                                name="Carrier">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <span> {{ row?.carrier | uppercase}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true"
                                                prop="license_type" name="License Type">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <span> {{ row?.license_type | uppercase}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true" prop="loa_type"
                                                name="Lines">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <span> {{ row?.loa_type | uppercase}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true"
                                                prop="appointment_date" name="Appointment Date">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <div class="">
                                                        <span> {{ row?.appointment_date | date:'MM/dd/yy' }}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true"
                                                prop="effective_date" name="Effective Date">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <span>{{row?.effective_date | date:'MM/dd/yy'}} </span>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-column [resizeable]="false"
                                                cellClass="d-flex align-items-center" [sortable]="true"
                                                prop="expiration_date" name="Expiration Date">
                                                <ng-template let-row="row" let-value="value"
                                                    ngx-datatable-cell-template>
                                                    <span>{{row?.expiration_date | date:'MM/dd/yy'}} </span>
                                                </ng-template>
                                            </ngx-datatable-column>

                                            <ngx-datatable-footer>
                                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                    let-pageSize="pageSize" let-selectedCount="selectedCount"
                                                    let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                                                    <div class="pagination-v2" *ngIf="rowCount > 0">
                                                        <div class="page-count p-0">
                                                            <span class="page-total">
                                                                {{ rowCount?.toLocaleString() }} total entries
                                                            </span>
                                                        </div>
                                                        <datatable-pager class="custom-datatable-pager"
                                                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                                            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage"
                                                            [size]="pageSize" [count]="rowCount"
                                                            [hidden]="!((rowCount / pageSize) > 1)"
                                                            (change)="aptPageChange($event)">
                                                        </datatable-pager>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-footer>
                                        </ngx-datatable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="noteTemplate" *ngIf="user.has_access_advisor && profileType != 'insurance'">
                    <ng-template #noteTemplate>
                        <b>Notes & Tasks</b>
                    </ng-template>
                    <div class="row">
                        <div class="col-lg-12">
                            <div id="notes_tasks" class="scrollsection"
                                *ngIf="user.has_access_advisor && profileType != 'insurance'">
                                <advizor-notes-and-tasks *ngIf="crd" type="person"
                                    profile_id="{{crd}}"></advizor-notes-and-tasks>
                                <hr>
                            </div>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
</ng-container>

<team-modal [id]="dbaContactId" [dbaData]="dbaData"></team-modal>

<nz-modal nzCentered [(nzVisible)]="advisor_follow_type_model" nzWidth="600px" [nzContent]="advFollowTypeContent"
    [nzFooter]="advFollowTypeContentFooter" [nzTitle]="advFollowTypeTitle" (nzOnCancel)="advFollowTypeContentCancel()"
    (nzOnOk)="onAdvisorFollowType()">
    <ng-template #advFollowTypeTitle>
        Follow {{isScrollMenuType | titlecase}}
    </ng-template>
    <ng-template #advFollowTypeContent>
        <div class="row">
            <div class="col-md-12">
                <h5>Select Status</h5>
                <pg-select style="width: 100%;" [Mode]="'single'" [PlaceHolder]="'Select'"
                    [(ngModel)]="advisor_follow_status" [NotFoundContent]="'Not found'" name="advisor_follow_status">
                    <pg-option *ngFor="let option of statusList" [Label]="option" [Value]="option">
                    </pg-option>
                </pg-select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-3 form-group">
                <input type="radio" id="private_label" name="advisor_follow_type" [(ngModel)]="advisor_user_follow_type"
                    value="private">
                <label for="private_label" style="margin-left:0.5rem;">Private</label>
            </div>
            <div class="col-lg-3 form-group">
                <input type="radio" id="firm_label" name="advisor_follow_type" [(ngModel)]="advisor_user_follow_type"
                    value="firm">
                <label for="firm_label" style="margin-left:0.5rem;">Firm</label>
            </div>
        </div>
    </ng-template>
    <ng-template #advFollowTypeContentFooter>
        <div class="row">
            <div class="col-lg-12 m-t-20">
                <p class="pull-right">
                    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn"
                        (click)="onAdvisorFollowType()">Save</button>
                </p>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="licence_modal" nzWidth="500px" [nzContent]="licenceContent" [nzFooter]="null"
    (nzOnCancel)="licenceModalCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #licenceContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b class="fs-12">Licenses & Exams</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-lg-3 pl-0"
                            *ngFor="let licenses of personProfile?.person_licenses_registrations; let i = index">
                            <span *ngIf="licenses" class="fs-12">{{ licenses }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="registration_modal" nzWidth="500px" [nzContent]="registrationContent"
    [nzFooter]="null" (nzOnCancel)="registrationModalCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #registrationContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b class="fs-12">Registrations</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-lg-6 pl-0 mb-2"
                            *ngFor="let registration of person_registered_state.data; let i = index">
                            <div class="d-inline">
                                <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                    'advizor': registration?.advizor,
                                    'broker': registration?.broker,
                                    'insurance': registration?.insurance && insurances && user.has_access_insurance
                                })" [iconSize]="'normal'" />
                                <span class="mr-2 fs-12"> {{ registration?.state }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="carrier_modal" nzWidth="500px" [nzContent]="carrierContent" [nzFooter]="null"
    (nzOnCancel)="carrierModalCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #carrierContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b class="fs-12">Carriers</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-lg-12 pl-0" *ngFor="let carrier of personInsurenceCarrierData; let i = index">
                            <span *ngIf="carrier?.carrier" class="fs-12">{{ carrier?.carrier }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="line_modal" nzWidth="500px" [nzContent]="lineContent" [nzFooter]="null"
    (nzOnCancel)="lineModalCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #lineContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b class="fs-12">Lines</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-lg-12 pl-0" *ngFor="let line of personInsuranceLines; let i = index">
                            <span *ngIf="line" class="mr-2 fs-12">{{ line.line_type | uppercase }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>
