
import {map,  mergeMap, debounceTime } from 'rxjs/operators';
import { Auth } from './../../../shared/services/auth.service';

import { Component, OnInit, OnDestroy, ViewChild, Input, HostListener, ElementRef, Inject, ChangeDetectorRef, AfterContentChecked, ViewContainerRef} from '@angular/core';
import { Subscription , interval, Observable, Subject } from 'rxjs';
import { pagesToggleService } from '../../services/toggler.service';
import { Router, Event,ActivatedRoute, NavigationEnd } from '@angular/router';
import { PlanService } from '../../../shared/services/plan.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DOCUMENT } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Helper } from './../../../shared/helper';
import { Settings } from './../../../settings';

import { ProfileService } from '../../../shared/services/profile.service';

declare var pg: any;
import { makeRandomString } from '../../../utils';
import { UserService } from '../../../shared/services/user.service';
import { AdvizorProService } from '../../../shared/services/advizor-pro.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ReportService } from '../../../shared/services/report.service';
import { UserNotificationService } from '../../../shared/services/user-notification.service';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { getIconsByRowKeys, getIconsDataset } from '../../../shared/utils/icon-utils';
import { Icon } from '../../../shared/components/icon-dataset/icons';

@Component({
  selector: 'root-layout',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootLayout implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('root') root;
  layoutState:string;
  profileActive = true;
  extraLayoutClass:string;
  _boxed = false
  _menuPin = true;
  _enableHorizontalContainer:boolean;
  _pageContainerClass = "";
  _contentClass = "";
  _footer = true;
  // _menuDrawerOpen = false;
  //Mobile
  _secondarySideBar = false;
   //Mobile
  _mobileSidebar = false;
  //Mobile
  _mobileHorizontalMenu = false;
  _pageTitle:string;
  //Sub layout - eg: email
  _layoutOption:string;
  _subscriptions:  Array<Subscription> = [];
  _layout

  @Input()
  public contentClass = "";

  @Input()
  public pageWrapperClass = "";

  @Input()
  public footer = true;

  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<any>;

  id: string;
  ack_id: string;
  href: string;
  loading = false;
  user: any;
  download: any = {};
  download_plan_data: any = [];
  createreport: any = {};
  settings: any;
  follows_data: boolean;
  follow_status:any;
  follow: any = {};
  indeterminate  = true;
  uploading = false;
  fee_analysis_result_selected_request: any = { limit : 5, page : 1};
  fee_analysis: any = { limit : 5, page : 1};
  settings_data:any={};
  profile_data:any={};
  fee_analysis_result_selected: any[] = [];
  fee_analysis_result_selected_total = 0;
  fee_analysis_result = [];
  fee_analysis_result_total = 0;
  save_search = false;

  adminView = false;
  isDisplaySelected = false;
  isDSelectedAll = false;
  loading_search: boolean;
  // keyupSub:  any;
  openAch = true;
  // advisorType: [];

  support_data:any={};
  planYears:any = [];
  // advizorPdf = '';
  tab_recognised_id = null;

  hubspotAccess = false;
  salesforceAccess = false;

  years:any = {};
  pdfDetail = {'link':'', 'name':''};
  pdfAdvisorDetail = {'link':'', 'name':''};
  pdfFormDetail = {'link':'', 'name':''};
  excelDetail = {'link':''};
  user_follow_type:string = 'private';
  notificationList:any = [];

  // integrationSubs: Subscription;

  isVisible = false;
  isUploadTicker = false;
  isCustomLineuModel = false;
  isUserNotificationModel = false;
  profileExport: Subscription;
  planExport: Subscription;

  downloadview = false;
  create_report = false;
  header_basic_access = false;
  @ViewChild('select_all_checkbox') select_all_checkbox:ElementRef;
  limit_over_model = false;
  follow_type_model = false;
  waitingBox = false;
  profileModal = false;
  supportModal = false;
  settingModelChanged: Subject<string> = new Subject<string>();

  isScrollMenu = false;
  isScrollMenuType: any;
  person_id:any;

  crd: string | number = null;
  // npn: any = null;
  firm_id:string | number;
  invalidProfileField = [];
  planProAccess = true;
  loadingMessage = false;
  advisorFollowData: any = {};
  isAdvisorContactData = false;
  sliderTooltip = false;
  strengthColor: any;
  progress = 0;
  existUser:any;
  pdf_link: any;
  planName: any;
	renderChart = false;
  tab_sort = { fee_sort: {}, fund_sort: {} };
  user_notifications: any = [];
  user_notification_count = 0;
  open_user_notification: any = {};
  notificationDrawer = false;
  percent_complete = 25;

  @ViewChild('clientUpload') clientUpload: ElementRef<HTMLElement>;
  @ViewChild('companyUpload') companyUpload: ElementRef<HTMLElement>;
  client_logo = '';
  user_note: string = '';
  plan_sponsor_ein = '';
  topSearchActive = true;
  downloadMessage = { status_message: 'Your report is processing...', status: 1 };
  statusList = ['Cold Lead', 'Warm Lead', 'Hot Lead', 'Client', 'Closed Lost'];
  follow_status_type: string = '';
  advisorMenuLinks:any = [];
  activeInsurance = false;

  activeAdvSetting = false;
  activeNewSearch = false;
  activeNewProfile = false;
  activeNewDashboard = false;
  pageLabel = 'Dashboard';
  ai_enable = false
  toolTipIconOrnament: any;

  constructor(
    public toggler: pagesToggleService,
    public router: Router,
    public auth: Auth,
    public planService: PlanService,
    public activatedRoute: ActivatedRoute,
    public profileService: ProfileService,
    private _helper: Helper,
    public userService: UserService,
    public reportService: ReportService,
    public advizorProService: AdvizorProService,
    @Inject(DOCUMENT) private docHtml: any,
    private cdr: ChangeDetectorRef,
    private notification: NzNotificationService,
    private userNotificationService: UserNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.existUser = this.auth.getExistUser();
    this.settings = Settings;

    this.layOutChange();

    this.layoutLoad();

    this.getTooltip();

  	if(this.layoutState){
  		pg.addClass(document.body,this.layoutState);
    }

    this.advizorProService.advMenu.subscribe(value => {
      this.advisorMenuLinks = value;
    });

    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        var root = this.router.routerState.snapshot.root;
        while (root) {
          if (root.children?.length) {
              root = root.children[0];
          } else if (root.data) {
              //Custom Route Data here
              this._pageTitle = root.data["title"]
              this._layoutOption = root.data["layoutOption"];
              this._boxed = root.data["boxed"]
              break;
          } else {
              break;
          }
        }
        //Reset Any Extra Layouts added from content pages
        pg.removeClass(document.body,this.extraLayoutClass);
        //Close Sidebar and Horizonta Menu
        if(this._mobileSidebar){
          this._mobileSidebar = false;
          pg.removeClass(document.body,"sidebar-open");
          this.toggler.toggleMobileSideBar(this._mobileSidebar);
        }
        this._mobileHorizontalMenu = false;

        this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
        //Scoll Top
        this.scrollToTop();

        this.layOutChange();
      }

      //Subscribition List
      this._subscriptions.push(this.toggler.pageContainerClass
      .subscribe(state => {
        this._pageContainerClass = state;
      }));

      this._subscriptions.push(this.toggler.contentClass
      .subscribe(state => {
        this._contentClass = state;
      }));

      this._subscriptions.push(this.toggler.bodyLayoutClass
      .subscribe(state => {
        if(state){
          this.extraLayoutClass = state;
          pg.addClass(document.body,this.extraLayoutClass);
        }
      }));

      this._subscriptions.push(this.toggler.Applayout
      .subscribe(state => {
        this.changeLayout(state);
      }));

      this._subscriptions.push(this.toggler.Footer
      .subscribe(state => {
        this._footer = state;
      }));

      this._subscriptions.push(this.toggler.mobileHorizontaMenu
      .subscribe(state => {
        this._mobileHorizontalMenu = state;
      }));
    });

    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected.toLowerCase());
    })
    .pipe(
      debounceTime(500),
      mergeMap((token: string) => this.getStatesAsObservable(token))
    )

    if (this.ack_id && this.ack_id != 'person' && this.ack_id != 'firm') {
      this.getPlanYears();
    }

    this.user = this.auth.getUser();
    
    if (this.user.user_access.find(x => (x.module_id == 2 && (x.subscription_id == 1 || x.subscription_id == 2)))) {
      this.planProAccess = false;
    }

    if (this.user.salesforce) {
        this.salesforceAccess = this.user.salesforce
    }

    if (this.user.hubspot) {
      this.hubspotAccess = this.user.hubspot;
    }

    this.profileService.$profile.subscribe((data: any) => {

      if (data.profile) {
        if (data.profile.id) {
          this.plan_sponsor_ein = data.profile.plan_sponsor_ein;
          this.client_logo = data.profile.logo_url;
          this.user_note = data.profile.notes;
          this.planName = data.profile.plan_name;
          this.ack_id = data.profile.ack_id;
        }
      }
    });

    this.settingModelChanged.
      pipe(
        debounceTime(500),
      ).subscribe(() => {
        if (this.isDisplaySelected) {
          this.fee_analysis_result_selected_request.keyword = this.fee_analysis.keyword;
          this.getUserSettings();
        } else {
          this.fee_analysis_result_selected_request.keyword = "";
          this.searchTicker()
        }
      });

    this.planService.dashEvent.subscribe(value => {
      if (value && value == 'ok') {
        setTimeout(() => {
          if(this.layoutState != 'menu-pin') {
            this._menuPin = true;
            this.toggleMenuPin();
          }
        }, 200);
      }
    });

    this.getUserNotification();

    //Check for notifications every 10 minutes
    interval(600000)
      .subscribe(() => {
        this.getUserNotification();
      });

  }

  getStatesAsObservable(token: string): Observable<any> {
    return this.planService.getAutoSearch({
      search: token
    }).pipe(map(this.extractData));
  }
  private extractData(res) {
    let body = res.data;
    return body || { };
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  public changeTypeaheadNoResults(e:boolean):void {
    this.typeaheadNoResults = e;
  }
  
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.asyncSelected = "";

    if (e.item.fo_id && !e.item.crd) {
      window.open(`${location.origin}/family/${e.item.type}/${e.item.fo_id}`, '_blank');
    }
    else if (e.item.bt_id && this.user.has_access_bank_and_trust) {
      window.open(`${location.origin}/bank/${e.item.type}/${e.item.bt_id}`, '_blank');
    }
    else if (e.item.insurance && this.user.has_access_insurance) {
      window.open(`${location.origin}/insurance/${e.item.type}/${e.item.txt_npn}`, '_blank');
    }
    else if (e.item.type) {
      window.open(`${location.origin}/advisor/${e.item.type}/${e.item.crd}`, '_blank');
    }
    else {
      window.open(`${location.origin}/profile/${e.item.ack_id}`, '_blank');
    }
  }

  layoutLoad() {
    this.href = this.router.url;
    var dataUrl = this.href.split('/');

    if (['insurance'].includes(dataUrl[1]) ) {
      this.activeInsurance = true;
    }

    if (dataUrl[2] != undefined && !['users', 'dashboard', 'v2', 'organizations', 'plan', 'admin'].includes(dataUrl[2]) && !['advisor', 'my-data', 'insurance', 'family'].includes(dataUrl[1])) {
      this.ack_id = dataUrl[2];
      this.getFollow();
    }
  }

  layOutChange(){
    this.pageLabel = '';
    let url = this.router.url.split('/');



    this.activeNewSearch = false;
    this.activeNewProfile = false;
    this.activeNewDashboard = false;
    this.adminView = false;
    this.ai_enable = false;

    
    if (url[1] == 'advisor' && url[2] == 'search' && url.length > 1) {
      this.activeNewDashboard = true;
      this.activeNewProfile = false;
      this.activeNewSearch = true;
      this.pageLabel = 'AdvizorPro Search';
    }
    
    if ((url[2] == 'firm' || url[2] == 'person' ) && url.length == 4) {
      this.activeNewProfile = true;
      this.pageLabel = '';
    }
    
    if (url[2] && url[2] == 'v2' && url.length > 1) {
      this.activeNewDashboard = true;
      this.pageLabel = 'Dashboard';
    }
    
    if (this.user?.chat_enabled && this.user.has_access_advisor && ['Dashboard', 'AdvizorPro Search'].includes(this.pageLabel)) {
      this.ai_enable = true;
    }
    this.isScrollMenu = false;
    this.isScrollMenuType = null;
    this.activeAdvSetting = false;

    if (url[1] == 'advisor' && ['search'].includes(url[2]) && url.length > 1) {
      this.activeAdvSetting = true;
      this.pageLabel = 'AdvizorPro Search';
    }

    if (['insurance'].includes(url[1]) && ['firm'].includes(url[2]) && url.length > 1) {
      this.isScrollMenu = false;
      this.isScrollMenuType = 'in-firm';
      this.firm_id = url[3];
      this.pageLabel = '';
      // this.advizorPdf = 'in-firm';
    } else if (['insurance'].includes(url[1]) && ['person'].includes(url[2]) && url.length > 1) {
        this.isScrollMenu = false;
        this.isScrollMenuType = 'in-person';
        this.person_id = url[3];
        this.pageLabel = '';
    } else if (['firm'].includes(url[2]) && url.length > 1) {
      this.isScrollMenu = false;
      this.isScrollMenuType = url[2];
      this.firm_id = url[3];
      this.pageLabel = '';
    }else if(['person'].includes(url[2]) && url.length > 1){
      this.isScrollMenu = false;
      this.isScrollMenuType = url[2];
      this.person_id = url[3];
      this.crd = url[3];
      this.pageLabel = '';
    }

    if ((url[1] == 'admin' || url[1] == 'admin-view' || url[1] == 'client') && url.length > 1) {
      this.isScrollMenu = true;
      this.isScrollMenuType = url[1];
      this.adminView = true;
      this.pageLabel = 'Admin';
    }

    if (url[1] == 'search' && url.length == 2) {
      this.pageLabel = 'PlanPro Search';
    }
    
    if (['insurance', 'advisor', 'search', 'family', 'bank', 'defined-contribution', 'score'].includes(url[1])) {
      this.topSearchActive = false;
    } else {
      this.profileActive = true;
      this.topSearchActive = true;
    }

    if (url.length > 1) {
      this.activeNewDashboard = true;
      if (url[1] == 'my-data') {
        this.pageLabel = 'My Data';
      } else if (url[1] == 'report') {
        this.pageLabel = 'Exports';
      } else if (url[1] == 'crm') {
        this.pageLabel = 'CRM Integration';
      }
    }

    if (url[1] == 'profile' && url.length > 1){
      this.profileActive = false;
      this.isScrollMenu = true;
      this.isScrollMenuType = url[1];
      this.topSearchActive = false;
      this.activeNewDashboard = false;
    }

    if (this.auth.loggedIn()) {
      this.user = this.auth.getUser();

      if (this.user.no_notes_tasks && url[3] && url[3] == 'notes-and-tasks') {
        this.router.navigate(['/profile', url[2]]);
      }
    }

    let urlString = window.location.href;
    if (urlString.indexOf('profile') > 0) {
      this.id = urlString;
    }
  }

  /** @function changeLayout
  *   @description Add Document Layout Class
  */
  changeLayout(type:string){
    this.layoutState = type;

    if (this.activeNewProfile) {
      setTimeout(() => {
        const el = document.querySelector('.header').classList;
        el.add('header-padding');
      }, 100);
    }
    
    if (this.user.has_access_advisor && !this.activeNewProfile) {
      if(type != 'menu-pin') {
        this._menuPin = false;
        pg.addClass(document.body,"menu-unpinned");
        pg.removeClass(document.body,"menu-pin");
        /* if(pg.hasClass(document.body,"menu-pin")) {
          pg.addClass(document.body,"menu-unpinned");
          pg.removeClass(document.body,"menu-pin");
        } */
        
        setTimeout(() => {
          const el = document.querySelector('.header').classList;
          el.add('header-unpin');
          el.remove('header-pin');
          const sh = document.querySelector('.h-logo-icon').classList;
          sh.add('h-logo-icon-pin');
          sh.remove('h-logo-icon-unpin');
        }, 100);
      }
    } else {
      if(type){
        pg.addClass(document.body,type);
      }
    }
  }

  /** @function removeLayout
  *   @description Remove Document Layout Class
  */
  removeLayout(type:string){
  	pg.removeClass(document.body,type);
  }

  ngOnInit() {
    this.profileService.$fee_sort.subscribe((data: any) => {
      if (data) {
        if (data.fee_sort) {
          this.tab_sort.fee_sort = data.fee_sort;
        }

        if (data.fund_sort) {
          this.tab_sort.fund_sort = data.fund_sort;
        }
      }
    });
  }

  ngOnDestroy() {
    for(const sub of this._subscriptions){
      sub.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /** @function scrollToTop
  *   @description Force to scroll to top of page. Used on Route
  */
  scrollToTop(){
    let top = window.pageYOffset;
    if(top == 0){
      let scroller = document.querySelector(".page-container");
      if(scroller)
        scroller.scrollTo(0,0);
    }
    else{
      window.scrollTo(0, 0)
    }
  }

  /** @function openQuickView
  *   @description Show Quick View Component / Right Sidebar - Service
  */
  /* openQuickView($e){
    $e.preventDefault();
		this.toggler.toggleQuickView();
  } */

  /** @function openSearch
  *   @description Show Quick Search Component - Service
  */
	/* openSearch($e){
		$e.preventDefault();
		this.toggler.toggleSearch(true);
  } */

  /** @function toggleMenuPin
  *   @description Permanently Open / Close Main Sidebar
  */
  toggleMenuPin(){
    if(pg.isVisibleSm()){
      this._menuPin = false;
      return;
    }
    
    const el = document.querySelector('.header').classList;
    const sh = document.querySelector('.h-logo-icon').classList;

    if(this._menuPin){
      pg.removeClass(document.body,"menu-pin");
      this._menuPin = false;
      el.add('header-unpin');
      el.remove('header-pin');
      sh.add('h-logo-icon-pin');
      sh.remove('h-logo-icon-unpin');
    }else{
      pg.addClass(document.body,"menu-pin");
      this._menuPin = true;
      el.add('header-pin');
      el.remove('header-unpin');
      sh.add('h-logo-icon-unpin');
      sh.remove('h-logo-icon-pin');
    }

    setTimeout(() => {
      if(window.innerWidth > 1025){
        window.dispatchEvent(new Event('resize'));
      }
    }, 300);
  }

  /** @function toggleMenuDrawer
  *   @description Open Main Sidebar Menu Drawer - Service
  */
  /* toggleMenuDrawer(){
    this._menuDrawerOpen = (this._menuDrawerOpen == true ? false : true);

    this.toggler.toggleMenuDrawer();
  } */

  /** @function toggleMobileSidebar
  *   @description Open Main Sidebar on Mobile - Service
  */
  toggleMobileSidebar(){
    const el = document.querySelector('.header').classList;
    el.remove('header-pin');
    el.remove('header-unpin');

    if(this._mobileSidebar){
      this._mobileSidebar = false;
      pg.removeClass(document.body,"sidebar-open");
    }
    else{
      this._mobileSidebar = true;
      pg.addClass(document.body,"sidebar-open");
    }
    this.toggler.toggleMobileSideBar(this._mobileSidebar);
  }

  /** @function toggleHorizontalMenuMobile
  *   @description Open Secondary Sidebar on Mobile - Service
  */
  toggleSecondarySideBar(){
    this._secondarySideBar = (this._secondarySideBar == true ? false : true);
    this.toggler.toggleSecondarySideBar(this._secondarySideBar);
  }

  /** @function toggleHorizontalMenuMobile
  *   @description Call Horizontal Menu Toggle Service for mobile
  */
  toggleHorizontalMenuMobile(){
    this._mobileHorizontalMenu = (this._mobileHorizontalMenu == true ? false : true);
    this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
  }

  @HostListener("window:resize", [])
	onResize() {
    this.autoHideMenuPin();
	}

  //Utils
  autoHideMenuPin(){

    if (!this.activeNewProfile) {
      if(window.innerWidth < 1025){
        if(pg.hasClass(document.body,"menu-pin")) {
          pg.addClass(document.body,"menu-unpinned");
          pg.removeClass(document.body,"menu-pin");
        }
  
        setTimeout(() => {
          const sh = document.querySelector('.h-logo-icon').classList;
          sh.add('h-logo-icon-pin');
          sh.remove('h-logo-icon-unpin');
        }, 100);
      }
      else{
        if (this.user.has_access_advisor) {
          if(pg.hasClass(document.body,"menu-unpinned")){
            pg.removeClass(document.body,"menu-unpinned");
          }
        } else {
          if(pg.hasClass(document.body,"menu-unpinned")){
            pg.addClass(document.body,"menu-pin");
          }
        }
      }
  
      if (window.innerWidth < 770) {
        setTimeout(() => {
          const sh = document.querySelector('.h-logo-icon').classList;
          sh.add('h-logo-icon-unpin');
        }, 100);
      }
    }
  }


  getFollow() {
    this.follow.id = this.ack_id;
    this.profileService.getFollow(this.follow).subscribe(
      (data: any) => {
        if (data.response?.status){
          this.follow_status = data.response.status;
        }
        if (data.response == null) {
          this.follows_data = false
        } else {
          this.follows_data = true
        }
      },
    error => {
      this._helper.errorMessage(error, "Error while login, Please try again.");
    });
  }

  /* getAdvizorType(params) {
    this.profileService.getAdvizorType(params).subscribe(
      (data: any) => {
        if (data) {
          this.advisorType = data.response;
        }
      },
    error => {
      this._helper.errorMessage(error, "Error while login, Please try again.");
    });
  } */

  getPlanYears() {
    this.profileService.getYears(this.ack_id).subscribe(
      (res: any) => {
        if (res.data) {
          this.planYears = res.data;
        }
      },
      error => {
        this._helper.errorMessage(error, "Error while login, Please try again.");
      }
    );
  }

  ViewDownload(event) {
    this.years.ackId = this.ack_id;
    this.download.type = event;
    this.downloadview = true;
    this.loading = true;
    this.download_plan_data = [];
    this.downloadMessage.status_message = 'Your report is processing...';
    this.downloadMessage.status = 1;
    this.percent_complete = 0;
    setTimeout(() => {
      this.doDownload(this.ack_id);
    }, 500);
  }

  downloadContentCancel() {
    this.downloadview = false;
  }

  doDownload(id) {
    this.download_plan_data = [];
    let split_str = this.id.split('/');
    let rep_str = split_str[split_str.length - 1];
    rep_str = this.id.replace(rep_str, '');

    this.download.act_id = rep_str+id;

    if (id != 'undefined') {
      this.profileService.downloadFile(this.download).subscribe((response: any) => {
          this.planExport = interval(1500)
            .subscribe(() => {
              this.checkPlanReport(response.reportId);
            });
          },error => {
            this._helper.errorMessage(error, "Error while login, Please try again.");
          }
        );
      }
  }

  checkPlanReport(id) {
    this.reportService.getReportStatus(id).subscribe((data: any) => {
      this.percent_complete = data.percent_complete;
      if (data.status == 'complete' && data.response) {
        this.download_plan_data = data.response;
        this.loading = false;
        this.loadingMessage = false;
        this.planExport.unsubscribe();
      } else if (data.status == 'error') {
        // this.loading = false;
        // this.loadingMessage = false;
        this.downloadMessage.status_message = data.status_message;
        this.downloadMessage.status = 0;
        this.planExport.unsubscribe();
        // this._helper.errorMessage('', data.status_message);
      }
    });
  }

  openPDFModel() {
    this.userService.userReportCount().subscribe((data:any) => {
      if (!this.user.user_limit_pdf || (this.user.user_limit_pdf && this.user.user_limit_pdf <= data.pdf_count)) {
        this.limit_over_model = true;
        return false;
      }

      this.loading = false;
      this.create_report = true;
      this.createreport = {};
      this.createreport = Object.assign({}, this.user);
      this.createreport.pdf_report_name = 'Plan Summary Report';
      this.createreport.overview = true;
      this.createreport.contact = false;
      this.createreport.benchmarking = true;
      this.createreport.fee = true;
      this.createreport.investment = true;
      this.createreport.diversification = true;
      this.createreport.tab_sort = {};

      this.createreport.company_logo = this.user.logo ? this.user.logo : null;
      this.createreport.client_logo = this.client_logo ? this.client_logo : null;
      this.createreport.notes = this.user_note ? this.user_note : null;
      this.pdf_link = '';

      let element = this.docHtml.getElementsByClassName('upload-complete');
      if (element[0]) {
        element[0].style.display = 'none';
      }
    })
  }

  BasicAccess() {
    let ackId = null;

    if (this.ack_id) {
      ackId = this.ack_id;
    } else if (this.firm_id) {
      ackId = this.firm_id;
    } else if (this.person_id) {
      ackId = this.person_id;
    }

    this.profileService.setActivityTrack({'data':ackId, 'activity':'upgrade-to-premium'});
    this.header_basic_access = true;
  }

  headerBasicContentCancel() {
    this.header_basic_access = false;
  }

  onFollow() {
    if(this.user.basic_access){
      this.BasicAccess();
      return false;
    }

    if (this.user.account_number && !this.follows_data) {
      this.follow_type_model = true;
    } else {
      this.onFollowUpdate();
    }
  }

  onFollowUpdate() {
    this.follow.id = this.ack_id;

    this.profileService.userFollow(this.follow).subscribe(
      (data: any) => {
        if (data.response == null) {
          this.follows_data = false;
          this.follow_status = '';
        } else {
          this.follows_data = true;
        }
      },
      error => {
        this._helper.errorMessage(error, "Error while login, Please try again.");
      }
    );
  }

  onFollowType() {
    this.follow_type_model = false;
    this.follow.follow_type = this.user_follow_type;
    this.follow.status = this.follow_status_type;
    this.follow_status = this.follow_status_type;
    this.onFollowUpdate();
  }

  followContentCancel() {
    this.follow_type_model = false;
  }

  redirectByPageName(page) {
    const el = document.querySelector('.header').classList;
    el.remove('header-padding');
    this.router.navigate([page]);
  }

  settingsPopup(){
    this.loading_search = true;
    this.showModal();
    this.fee_analysis_result_selected_request.type = '';
    this.feeAnalsisSettings();
    this.searchTicker();
  }

  supportPopup() {
    let url = this.router.url.split('/');
    this.support_data.phone = this.user.phone;
    let access_type = url[1];

    if (access_type == 'advisor') {
        access_type = url[1] + '-' + url[2];
    }

    this.supportModal = true;
  }

  profilePopup(){
    if(this.user){
      this.profile_data.id = this.user.id;
      this.profile_data.first_name = this.user.first_name;
      this.profile_data.last_name = this.user.last_name;
      this.profile_data.title = this.user.title;
      this.profile_data.company = this.user.company;
      this.profile_data.company_logo = this.user.logo;
      this.profile_data.email = this.user.email;
      this.profile_data.password = this.user.password;
      this.profile_data.phone = this.user.phone;
      this.profile_data.is_subscribed = this.user.is_subscribed;
     // this.profile_data.company = this.user.company;
      this.profile_data.is_password_update = false;
      this.invalidProfileField = [];
    }
    this.progress = 0;
    this.profileModal = true;
  }

  company_autocomplete = (text) => {
    return this.planService.getUserCompany({
      keyword: text.toLowerCase(),
      limit: 30
    })
  }

  updateUserData(){
    // let pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}');
    // let pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&\'()*+,-./:;<=>?@[\\\]^_`{|}~].{7,}');
    // let pattern = new RegExp('^(?=.*[\w])(?=.*[\W])[\w\W]{8,}$');
    // let pattern = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[`~!@#$%^&\\\\*\\\\(\\\\)\\\\-_=\\\\+\\\\{\\\\}\\\\[\\\\]|\\\\\\\\:;"\\\' <>, \\\\.?\\\\/]).{8,}');
    // let pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&\'()*+,-./:;<=>?@[\\\]^_`{|}~\\\\\\\\])[A-Za-z\d!"#$%&\'()*+,-./:;<=>?@[\\\]^_`{|}~\\\\\\\\].{7,}');

    let pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

    this.invalidProfileField = [];

    if(!this.profile_data.first_name){
      this.invalidProfileField.push('first_name');
    }
    if(!this.profile_data.last_name){
      this.invalidProfileField.push('last_name');
    }
    if(!this.profile_data.title){
      this.invalidProfileField.push('title');
    }
    if(!this.profile_data.company){
      this.invalidProfileField.push('company');
    }
    if(!this.profile_data.phone){
      this.invalidProfileField.push('phone');
    }

    if(this.profile_data.is_password_update){
      if(!pattern.test(this.profile_data.password)){
        this.invalidProfileField.push('password');
      }
      if(this.profile_data.password != this.profile_data.confirm_password){
        this.invalidProfileField.push('confirm_password');
      }
    }

    if(this.invalidProfileField.length){
      return false;
    }

    this.userService.updateCurrentUser(this.profile_data).subscribe((response: any) => {
        this.profile_data = {};
        this.invalidProfileField = [];
        this.profileModal = false;
        if(response.data){
          this.auth.updateUser(response.data);
          this.user = this.auth.getUser();
        }
        this._helper.successMessage(response, "user successfully updated.");
      }, error => {
        this._helper.errorMessage(error, "Error while update users data, Please try again.");
      });
  }

  profileModalCancel() {
    this.profileModal = false;
  }

  feeAnalsisSettings(){
    this.profileService.getUserSettings(this.fee_analysis_result_selected_request).subscribe(
      (data: any) => {
        this.settings_data = data.response;
        this.openAch = (this.settings_data.provider_universe == 'Open Achitecture') ? false : true;
        this.fee_analysis_result_selected = this.settings_data.investment_universes_data;
        this.fee_analysis_result_selected_total = this.settings_data.investment_universes_count;
        if(this.fee_analysis_result_selected.length > 0 ){
          this.isDisplaySelected = true;
        }else{
          this.isDisplaySelected = false;
        }
      },
      error => {
        this._helper.errorMessage(error, "Error while login, Please try again.");
      }
    );
  }

  searchTicker(){
    this.profileService.getSearchTicker(this.fee_analysis).subscribe(
      (data: any) => {
        this.fee_analysis_result = data.response.data;
        const ticker_array =  this.settings_data.ticker;
        let tick = true;
        this.fee_analysis_result.forEach((val: { Symbol: string }) => {
          const index = ticker_array?.indexOf(val.Symbol) ?? -1;
          if (index === -1) {
            tick = false;
          }
        });
        if(this.select_all_checkbox && this.select_all_checkbox.nativeElement.checked != tick){
            this.select_all_checkbox.nativeElement.checked = tick
        }
        this.fee_analysis_result_total = data.response.count;
        this.loading_search = false;
        this.isUploadTicker = false;
      },
      error => {
        this._helper.errorMessage(error, "Error while login, Please try again.");
      }
    );
  }

  getUserSettings(){
    this.profileService.getUserSettings(this.fee_analysis_result_selected_request).subscribe(
      (data: any) => {
        this.settings_data = data.response;
        this.fee_analysis_result_selected = this.settings_data.investment_universes_data;
        this.fee_analysis_result_selected_total = this.settings_data.investment_universes_count;
      },
      error => {
        this._helper.errorMessage(error, "Error while login, Please try again.");
      }
    );
  }

  paginate(event: number) {
    this.fee_analysis.page = event;
    this.searchTicker();
  }

  paginateSettings(event: number) {
    this.fee_analysis_result_selected_request.page = event;
    this.getUserSettings();
  }
  
  displaySelected(){
      this.isDisplaySelected = (this.isDisplaySelected) ? false : true;
      this.fee_analysis_result_selected_request.limit = 10;
      this.fee_analysis_result_selected_request.page = 1;
      this.getUserSettings();
      this.fee_analysis.limit = 10;
      this.fee_analysis.page = 1;
      this.searchTicker();
    }

    selectSearchList(value:any, $event?:any) {

      let ticker_array =  this.settings_data.ticker;
      if (value == 'selall') {
        this.isDSelectedAll = !this.isDSelectedAll;
        ticker_array = this.settings_data.ticker = [];
      } else if (value=='all'){
        if(this.fee_analysis_result.length>1500){
          this._helper.errorMessage('', "Please limit your selection to less than 1,500 investment options.");
          return false;
        }else {
              if($event.checked && !this.isDSelectedAll){
                  this.fee_analysis_result.forEach((val:any) => {
                      if(ticker_array.indexOf(val.Symbol) == -1){
                        ticker_array.push(val.Symbol);
                      }
                  });
              }else{
                  this.fee_analysis_result.forEach((val:any) => {
                      let index = ticker_array.indexOf(val.Symbol);
                      if(index != -1){
                        ticker_array.splice(index, 1);
                      }
                  });
              }

              this.isDSelectedAll = false;
            }
        }else if(value=='clear'){
          ticker_array.splice(0, ticker_array.length);
          this.isDSelectedAll = false;
          //this.saveLineupName();
        //  this.fee_analysis_result_selected.splice(0, this.fee_analysis_result_selected.length);
        }else{
          if(ticker_array.length<=1500){
              if(ticker_array.indexOf(value.Symbol) !== -1){
                ticker_array.splice(ticker_array.indexOf(value.Symbol), 1);
              //  this.fee_analysis_result_selected.splice(this.fee_analysis_result_selected.indexOf(value), 1);
              }else{
                ticker_array.push(value.Symbol);
               // this.fee_analysis_result_selected.push(value);
              }
          }else{
              this._helper.errorMessage('', "Please limit your selection to less than 1,500 investment options.");
              return false;
          }

        }

        this.settings_data.ticker = ticker_array;
        return true;
    }

    saveSettings() {
      if(this.settings_data.provider_universe == 'Add User Selected') {
        this.settings_data.custom_lineup_name = "";
        this.isCustomLineuModel = true;
      } else {
        this.saveLineupName();
      }
    }

    sendSupportMessage() {

      if (this.support_data.message.trim()) {
        this.profileService.supportLog(this.support_data).subscribe({
          next: () => {
            this._helper.successMessage('', 'Message has been sent successfully.');
            this.supportModal = false;
          },
          error: (error) => {
            this._helper.errorMessage(error, "Error while logging, Please try again.");
          }
        });
      } else {
        this._helper.errorMessage('', "Please enter message.");
      }
    }

    lineupChange(event) {
      this.fee_analysis_result_selected_request.type = event;
      if (event == 'Open Achitecture') {
        this.openAch = false;
      } else {
        this.openAch = true;
      }
      this.getUserSettings();
      this.searchTicker();
    }

    saveGlobalSettings() {

      this.settings_data.save_type = 'user_setting';

      this.profileService.setUserSettings(this.settings_data).subscribe({
        next: () => {
          this.getUserSettings();
          this._helper.successMessage('', 'settings has been saved successfully.');
          window.location.href = window.location.href;
        },
        error: (error) => {
          this._helper.errorMessage(error, "Error while login, Please try again.");
        }
      });
    }

    saveLineupName() {
      this.save_search = true;

      var valid = this.settingsValidation();

      if (valid) {
        this.save_search = false;
        return false;
      }

      this.isCustomLineuModel = false;

      this.settings_data.save_type = 'fee_setting';

      this.profileService.setUserSettings(this.settings_data).subscribe({
        next: () => {
          this.save_search = false;
          this.fee_analysis_result_selected_request.type = this.settings_data.custom_lineup_name;
          this.getUserSettings();
          // this._helper.successMessage('', 'settings has been saved successfully.');
          this.notification.blank('', 'Settings111 has been saved successfully.', {
            nzStyle: {
              top: '65px'
            },
            nzClass: 'alert alert-success'
          });
        },
        error: () => {
          this.save_search = false;
          // this._helper.errorMessage(error, "Error while login, Please try again.");
          this.notification.blank('', 'Error while login, Please try again.', {
            nzStyle: {
              top: '65px'
            },
            nzClass: 'alert alert-error'
          });
        }
      });
    }

  settingsValidation(){
    var flag = false;

    if(!this.settings_data.custom_lineup_name && this.settings_data.provider_universe == 'Add User Selected'){
      // this._helper.errorMessage('', "Please enter custom lineup name.");
      this.notification.blank('', 'Please enter custom lineup name.', {
        nzStyle: {
          top: '65px'
        },
        nzClass: 'alert alert-error'
      });
      flag = true;
    }
    if(!this.settings_data.provider_universe){
      // this._helper.errorMessage('', "Please select provider universe.");
      this.notification.blank('', 'Please select provider universe.', {
        nzStyle: {
          top: '65px'
        },
        nzClass: 'alert alert-error'
      });
      flag = true;
    }
    if(this.settings_data.ticker.length==0 && this.settings_data.provider_universe!='Open Achitecture'){
      // this._helper.errorMessage('', "Please select at least one investment option to use a user selected provider universe.");
      this.notification.blank('', 'Please select at least one investment option to use a user selected provider universe.', {
        nzStyle: {
          top: '65px'
        },
        nzClass: 'alert alert-error'
      });
      flag = true;
    }

    if(this.settings_data.ticker.length >1500){
      // this._helper.errorMessage('', "Please limit your selection to less than 1,500 investment options.");
      this.notification.blank('', 'Please limit your selection to less than 1,500 investment options.', {
        nzStyle: {
          top: '65px'
        },
        nzClass: 'alert alert-error'
      });
      flag = true;
    }

    return flag;
  }

  profileFileChange($event) {
    if ($event.status == "onSuccess") {
      this.profile_data.company_logo = $event.file.response.file;
    }
  }

  waitingBoxContentCancel() {
    this.waitingBox = false;
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  uploadTickerModel() {
    this.isUploadTicker = true;
  }

  uploadTickerOk(): void {
    this.isUploadTicker = false;
  }

  uploadTickerCancel(): void {
    this.isUploadTicker = false;
  }

  showModal(): void {
    this.isVisible = true;
    setTimeout(() => {
      this.sliderTooltip = true;
    }, 200);
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  customLineupCancel(): void {
    this.isCustomLineuModel = false;
  }

  customLineupOk(): void {
    this.isCustomLineuModel = false;
  }

  userNotificationCancel(id): void {
    let params = { 'ids':[]};
    params.ids.push(id);
    this.readUserNotification(params);
    this.isUserNotificationModel = false;
  }

  userNotificationOk(id): void {
    let params = { 'ids':[]};
    params.ids.push(id);
    this.readUserNotification(params);
    this.isUserNotificationModel = false;
  }

  settingTabSelect(val) {
    if (val == 'global') {
      this.sliderTooltip = true;
    } else {
      this.sliderTooltip = false;
    }
  }

  onCustomSettingSearch(text) {
    this.settingModelChanged.next(text);
  }

  checkPasswordStrength(password) {
    var pwd = password;
    // Reset if password length is zero
    if (pwd.length === 0) {
        this.progress = 0;
        return;
    }
    // var prog = [/[$@$!%*#?&]/, /[A-Z]/, /[0-9]/, /[a-z]/].reduce(
    //   (memo: any, test: any) => memo + test.test(pwd),
    //   0
    // );
    // Check progress
    var prog = [/[!"#$%&\'()*+,-./:;<=>?@[\\\]^_`{|}~\\\\\\\\]/, /[A-Z]/, /[0-9]/, /[a-z]/].reduce(
      (memo: number, test: RegExp) => memo + Number(test.test(pwd)),
      0
    );

    // Length must be at least 8 chars
    if (prog > 2 && pwd.length > 7) {
        prog++;
    }
    // Display it
    switch (prog) {
        case 0:
        case 1:
        case 2:
            this.progress = 25;
            this.strengthColor = "danger";
            break;
        case 3:
            this.progress = 50;
            this.strengthColor = "warning";
            break;
        case 4:
            this.progress = 75;
            this.strengthColor = "warning";
            break;
        case 5:
            this.progress = 100;
            this.strengthColor = "success";
            break;
    }
  }

  resumeUser() {
    let user = this.auth.getExistUser();
    this.auth.login(this.auth.getExistToken());
    this.auth.setUser(user);

    this.auth.setExistToken('');
    this.auth.setExistUser('');

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin/users']);
  }

  createPdfReport(selection) {
    this.pdf_link = '';
    if (localStorage.getItem('fees_data')) {
      let fees_data = JSON.parse(localStorage.getItem('fees_data'));
      this.createreport.fees_data = fees_data;
    }

    this.createreport.id = this.ack_id;
    this.createreport.selectionAll = false;

    this.loading = true;
    this.downloadMessage.status_message = 'Your report is processing...';
    this.downloadMessage.status = 1;
    this.percent_complete = 25;

    this.user_note =  JSON.parse(JSON.stringify(this.createreport.notes));

    if (Object.keys(selection).length > 0) {
      if (Object.values(selection).includes(true) && selection.benchmarking != true) {
        this.generatePdf();
        this.createreport.selectionAll = false;
      } else {
        this.createreport.selectionAll = (selection.benchmarking) ? false : true;
        this.renderChart = true;
        this.getChart();
      }
    } else {
      this.createreport.selectionAll = true;
      this.renderChart = true;
      this.getChart();
    }
  }

  limitContentCancel() {
    this.limit_over_model = false;
  }

  supportContentCancel() {
    this.supportModal = false;
  }

  generatePdf() {

    this.create_report = false;
    this.waitingBox = true;

    this.createreport.tab_recognised_id = this.tab_recognised_id = makeRandomString();

    this.createreport.tab_sort = this.tab_sort;

    this.profileService.generateProfilePdf(this.createreport).subscribe((res: any) => {
      this.renderChart = false;
      var element = document.getElementById("chartImg");

      if (element){
        element.classList.remove("chart-default");
        element.classList.add("chart-none");
      }

      if (res.code == 400 && res.errors == 'Your download limit is exceed.') {
        this.waitingBox = false;
        this.limit_over_model = true;
        return false;
      }

      if (res.status == 200) {
        this.profileExport = interval(1500)
          .subscribe(() => {
            this.checkProfileReport(res.reportId);
          });
      }
    });
  }

  checkProfileReport(id) {
    this.reportService.getReportStatus(id).subscribe((data: any) => {
      this.percent_complete = data.percent_complete;
      if (data.status == 'complete') {
        this.waitingBox = false;
        this.loading = false;
        this.profileExport.unsubscribe();
        window.open(data.link);
      } else if (data.status == 'error') {
        this.downloadMessage.status_message = data.status_message;
        this.downloadMessage.status = 0;
        // this.waitingBox = false;
        // this.loading = false;
        this.profileExport.unsubscribe();
        // this._helper.errorMessage('', data.status_message);
      }
    });
  }

  getChart() {
    this.create_report = false;
    this.createreport.tab_recognised_id = this.tab_recognised_id = makeRandomString();
    this.createreport.tab_sort = this.tab_sort;
    this.profileService.renderChart.next(this.createreport);
  }

  reportContentCancel() {
    this.create_report = false;
  }

  FileChange($event) {
    if ($event.status == "onSuccess") {
      this.createreport.company_logo = $event.file.response.file;
      this.user.logo = this.createreport.company_logo;
      this.auth.updateUser(this.user);
    }
  }

  ClientFileChange($event) {
    if ($event.status == "onSuccess") {
      this.createreport.client_logo = $event.file.response.file;
    }
  }

  accessMenu() {
    if (this.user.basic_access) {
      this.BasicAccess();
    }
  }

  openDrawer() {
    let params = { 'ids':[]};
    this.notificationList = JSON.parse(JSON.stringify(this.user_notifications.notifications));
    this.notificationList.forEach(ele => {
      params.ids.push(ele.id);
    });
    this.readUserNotification(params);
    this.notificationDrawer = true;
  }

  checkForModalNotification(){
    if (this.user_notifications.modal) {
      this.isUserNotificationModel = true;
      this.open_user_notification = this.user_notifications.modal;
    }
  }

  closeDrawer(): void {
    this.notificationDrawer = false;
  }

  readUserNotification(ids) {
    this.userNotificationService.getRead(ids).subscribe((res: any) => {
      if (res.status == 200) {
        this.getUserNotification();
      }
    });
  }

  getUserNotification() {
    this.userNotificationService.getNotification().subscribe((res: any) => {
      if (res.status == 200) {
        this.user_notifications = res.data;
        this.user_notification_count = res.data.count;
        this.checkForModalNotification();
      }
    });
  }

  dateFormat(date) {
    return moment(date).format("MMM D");
  }

  checkPassword() {
    this.invalidProfileField = [];
    if (this.profile_data.password != this.profile_data.confirm_password) {
      this.invalidProfileField.push('confirm_password');
    }
  }

  deleteLogo(type) {
    let args = {
      type: type,
      plan_sponsor_ein: this.plan_sponsor_ein
    };
    this.profileService.deleteLogo(args).subscribe((res: any) => {
      if (res.status == 200) {
        if (type == 'company') {
          this.user.logo = '';
          this.createreport.company_logo = '';
          this.auth.updateUser(this.user);
        } else {
          this.client_logo = '';
          this.createreport.client_logo = '';
        }
      }
    });
  }

  editCompanyLogo() {
    let el: HTMLElement = this.companyUpload.nativeElement;
    el.click();
  }

  editClientLogo() {
    let el: HTMLElement = this.clientUpload.nativeElement;
    el.click();
  }

  preEvent($event, data) {
    $event.stopPropagation();

    if (data.type) {
      window.open(location.origin + '/advisor/' + data.type + '/' + data.crd, '_blank');
    } else {
      window.open(location.origin + '/profile/' + data.ack_id, '_blank');
    }
  }

  advSearchSetting() {
    this.advizorProService.advSetting(true);
  }

  getIcons(row: {}): Icon[] {
		return getIconsByRowKeys(row);
	}

  getTooltip() {
    this.advizorProService.getTooltip().subscribe((response: {}) => {
      if(response){
        this.toolTipIconOrnament = getIconsDataset(response as Record<string, string>);
      }
    })
  }
}
