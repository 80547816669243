import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ProgressModule } from '../../../../@pages/components/progress/progress.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { pgSelectModule } from '../../../../@pages/components/select/select.module';
import { FormsModule } from '@angular/forms';
import { Providers401kTable } from './providers-401k-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [Providers401kTable],
  exports: [Providers401kTable],
  imports: [CommonModule, NgxDatatableModule, ProgressModule, NzModalModule, NzTabsModule, NzDropDownModule, NzIconModule, pgSelectModule, FormsModule]
})

export class Providers401kTableModule {
  static forRoot(): ModuleWithProviders<Providers401kTableModule> {
    return {
      ngModule: Providers401kTableModule
    };
  }
}
