import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ProgressModule } from '../progress/progress.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { pgSelectModule } from '../select/select.module';
import { FormsModule } from '@angular/forms';
import { InsightsContainer } from './insights-container.component';

@NgModule({
  declarations: [InsightsContainer],
  exports: [InsightsContainer],
  imports: [CommonModule, ProgressModule, NzModalModule, NzTabsModule, NzDropDownModule, NzIconModule, pgSelectModule, FormsModule]
})

export class InsightsContainerModule {
  static forRoot(): ModuleWithProviders<InsightsContainerModule> {
    return {
      ngModule: InsightsContainerModule
    };
  }
}
