export interface Icon {
  key: string;
  type: string;
  className: string;
  label: string;
  color: string
}

export const Icons: ReadonlyArray<Icon> = Object.freeze([
  {key: 'advizor', type: 'independent_advisor', className: 'circle', label: 'IA', color: '#000'}, 
  {key: 'broker', type: 'broker_dealer', className: 'circle cir-broker', label: 'B', color: '#000'}, 
  {key: 'insurance', type: 'insurance', className: 'circle-ins', label: 'INS', color: '#000'}, 
  {key: 'team', type: 'team', className: 'circle circle-pink', label: 'T', color: '#000'}, 
  {key: 'fo', type: 'family_office', className: 'circle family_office_icon', label: 'FO', color: '#000'}, 
  {key: 'bt', type: 'bank_trust', className: 'circle bank_and_trust_icon', label: 'BT', color: '#000'}, 
  {key: 'dc', type: 'defined_contribution', className: 'circle circle-dc', label: 'DC', color: '#000'}, 
  {key: 'db', type: 'defined_benefit', className: 'circle circle-db', label: 'DB', color: '#000'}, 
  {key: 'o&e', type: 'firm_ownership', className: 'circle', label: 'O&E', color: '#000'}
]);