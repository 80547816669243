import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Auth } from '../../../shared/services/auth.service';
import { Helper } from '../../../shared/helper';
import { DatePipe } from '@angular/common';
import { AdvizorProService } from '../../../shared/services/advizor-pro.service';

@Component({
	selector: 'advizor-notes-and-tasks',
	templateUrl: './notes-and-tasks.component.html',
    styleUrls: ['./notes-and-tasks.component.scss'],
    providers: [DatePipe]
})
export class AdvizorNotesAndTasksComponent implements OnInit {
	user: any = {};
	notes: any = [];
	tasks: any = [];
	errorMessage: string;
	create_task: any = {};
	basic_access = false;
	timepickerVisible = false;
  	currentTime: Date = new Date();
  	minDate: Date = new Date();
	year: any;
	edittask: any = {};
	select_visibility = [{id:0, name:'Private'}, {id:1, name:'Team'}];

	edit_task = false;
	edit_note = false;
	@Input() type: string;
	@Input() profile_id: string;

	editnote: any = {};

	constructor(
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public advizorProService: AdvizorProService,
		private _helper: Helper,
        public auth: Auth,
        private datePipe: DatePipe
	) {
	}

	ngOnInit() {
		if (this.auth.loggedIn()) {
			this.user = this.auth.getUser();
			if (this.user.basic_access) {
				this.basic_access = true;
			}

			this.getNotes();
			this.getTasks();
		}
		var year1 = moment().year();
		var year2 = moment().add(30, 'y').year();
		this.year = year1 + ':' + year2;

	}

	getNotes() {
		this.advizorProService.getNotes(this.profile_id, this.type).subscribe((response: any) => {
			this.notes = response.data.notes;
		});
	}

	getTasks() {
		this.advizorProService.getTasks(this.profile_id, this.type).subscribe((response: any) => {
			this.tasks = response.data.tasks;

			this.tasks.map(item => {
				item.alert_due = item.alert_due.replace(/-/g, '/');
				return item;
			});
		});
	}

	saveNote() {
		if (this.editnote != null) {
			this.editnote.profile_id = this.profile_id;
			let data = {
				profile_id: this.editnote.profile_id,
				visibility: this.editnote.visibility,
				note: this.editnote.note_text,
			};
			this.advizorProService.addNote(data, this.type).subscribe(
				(res: any) => {
					if (res.status == 200) {
						this.notes = [res.response].concat(this.notes);
					}
					this.edit_note = false;
					this.editnote = {};
				}, error => {
					this._helper.errorMessage(error, 'Something is wrong.');
				}
			);
		}
	}

	saveTask() {

		if (this.edittask != null) {
            // let now = moment();
            if(this.currentTime && typeof this.currentTime == 'object'){
	            this.edittask.alert_due = this.datePipe.transform(this.currentTime, 'yyyy-MM-dd HH:mm:ss');
			}

            this.edittask.profile_id = this.profile_id;

            this.advizorProService.addTask(this.edittask, this.type).subscribe(
				(res: any) => {
					if (res.status == 200) {
						res.response.alert_due = res.response.alert_due.replace(/-/g, '/');
						this.tasks = [res.response].concat(this.tasks);
					}
					this.edit_task = false;
					this.edittask = {};
					this.currentTime = new Date();
				}, error => {
					this._helper.errorMessage(error, 'Something is wrong.');
				}
			);
		}
    }

	deleteNote(id) {
		this.advizorProService.deleteNote(id).subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.notes = this.notes.filter(ele => ele.id !== res.response.id);
				}
			}, error => {
				this._helper.errorMessage(error, 'Something is wrong.');
			}
		);
    }

	deleteTask(id) {
		this.advizorProService.deleteTask(id).subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.tasks = this.tasks.filter(ele => ele.id !== res.response.id);
				}
			}, error => {
				this._helper.errorMessage(error, 'Something is wrong.');
			}
		);
    }

	dateFormat(date) {
		return moment(date).format("LLL");
	}

	openTask(data=null) {
		this.edit_task = true;
		this.edittask = {};
		this.edittask.alert_due = new Date();
		if (data) {
			this.edittask.id = data.id;
			this.edittask.task_name = data.task_name;
			this.edittask.visibility = data.visibility;
			this.edittask.alert_due = new Date(data.alert_due);
		}else{
			// Set default visibility to 'Team' (id: 1)
			this.edittask.visibility = 1;  // Default to 'Team'
		}
	}

	submitTask() {
		if (this.edittask.id) {
			this.updateTask();
		} else {
			this.saveTask();
		}
	}

	submitNote() {
		if (this.editnote.id) {
			this.updateNote();
		} else {
			this.saveNote();
		}
	}

	updateTask() {
		let data = {
			task_name: this.edittask.task_name,
			visibility: this.edittask.visibility,
			alert_due: this.datePipe.transform(this.edittask.alert_due, 'yyyy-MM-dd HH:mm:ss'),
		};

		this.advizorProService.updateTask(data, this.edittask.id).subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.edit_task = false;
					this.getTasks();
				}
			}, error => {
				this._helper.errorMessage(error, 'Something is wrong.');
			}
		);
	}

	openNote(data=null) {
		this.edit_note = true;
		this.editnote = {};
		if (data) {
			this.editnote.note_text = data.note_text;
			this.editnote.visibility = data.visibility;
			this.editnote.id = data.id;
		}else{
			// Set default visibility to 'Team' (id: 1)
			this.editnote.visibility = 1;  // Default to 'Team'
		}
	}

	updateNote() {
		let data = {
			note_text: this.editnote.note_text,
			visibility: this.editnote.visibility
		};

		this.advizorProService.updateNote(data, this.editnote.id).subscribe(
			(res: any) => {
				if (res.status == 200) {
					this.edit_note = false;
					this.getNotes();
				}
			}, error => {
				this._helper.errorMessage(error, 'Something is wrong.');
			}
		);
	}

	closeModal(type) {
        switch(type) {
            case 1:
                this.edit_task = false;
                break;
			case 2:
				this.edit_note = false;
				break;
            default:
        }
    }
}
