import { Icons, Icon } from "../components/icon-dataset/icons";

export function getIconsByRowKeys(row: Record<string, unknown>): Icon[] {
  const icons: Icon[] = [];
  for (const key in row) {
    if (row[key]) {
      const icon = Icons.find(icon => icon.key === key);
      if (icon) {
        icons.push(icon);
      }
    }
  }
  return icons;
}

export function getIconsDataset(value: Record<string, string>): Record<string, string> {
  const iconDataset = [
    'independent_advisor', 'broker_dealer', 'family_office', 'insurance', 
    'bank_trust', 'defined_contribution', 'defined_benefit', 'team', 
    'firm_ownership'
  ];

  return iconDataset.reduce((acc, key) => {
    if (value[key]) {
      acc[key] = value[key];
    }
    return acc;
  }, {} as Record<string, string>);
}