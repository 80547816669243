import {
	Component,
	Input,
	Output,
	OnChanges,
	SimpleChanges,
	EventEmitter,
} from '@angular/core'

import { Dba, DbaContactPhotos } from '../../interfaces/dba.interface'

@Component({
	selector: 'team-card',
	templateUrl: './team-card.component.html',
	styleUrls: ['./team-card.component.scss'],
})
export class TeamCardComponent implements OnChanges {
	private readonly totalMiniAvatars: number = 3
	public miniAvatars: string[] = []

	@Input() dba: Dba | null = null
	@Output() readonly openDbaContact = new EventEmitter<void>()

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.dba) {
			this.miniAvatars = []
			this.processMiniAvatars(this.dba?.photos, this.dba?.number_of_employees)
		}
	}

	private processMiniAvatars(
		photos: DbaContactPhotos[] | null,
		number_of_employees: number | null
	): void {
		let arrPhotos: string[] = photos?.map((value) => value?.photo_url) ?? []
		arrPhotos = this.ensureThreeElements(arrPhotos)

		if (number_of_employees > this.totalMiniAvatars) {
			arrPhotos.push(`+${number_of_employees - this.totalMiniAvatars}`)
		} else {
			arrPhotos = arrPhotos.splice(0, number_of_employees)
		}

		this.miniAvatars = arrPhotos
	}

	private ensureThreeElements(arr: string[]): string[] {
		const neededElements = this.totalMiniAvatars - arr.length
		if (neededElements > 0) {
			const randomLetters = Array.from({ length: neededElements }, () =>
				this.getRandomLetter()
			)
			arr.push(...randomLetters)
		}
		return arr
	}

	private getRandomLetter(): string {
		const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		return letters.charAt(Math.floor(Math.random() * letters.length))
	}

	public getInitials(name: string | null): string {
		if (!name) return ''
		const words: string[] = name.split(' ')
		return words
			.slice(0, 1)
			.map((word) => word.charAt(0).toUpperCase())
			.join('')
	}

	public simplifyUrl(url: string | null): string {
		if (!url) return ''
		return url.replace(/^(https?:\/\/)?(www\.)?/, '').split(/[/?#]/)[0]
	}

	public openModal(): void {
		this.openDbaContact.emit()
	}

	public isAnUrl(string: string): boolean {
		return (
			string.includes('http://') ||
			string.includes('https://') ||
			string.includes('www')
		)
	}

	public trackByFn(index: number): string {
		return `${index}`
	}

	public labelNumberAdvisors(num: number | null): string {
		if (!num) return ''
		if (num === 1) {
			return `${num} advisor on this team`
		} else {
			return `${num} advisors on this team`
		}
	}
}
