import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
@NgModule({
  imports: [
    CommonModule,
    ObserversModule
  ],
  declarations: []
})
export class pgListViewModule { }
