
<div class="row mt-3">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-4">
                <label>Number of Recipients:</label>
            </div>
            <div class="col-md-8">
                <p>{{recipient_data['count']}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Template:</label>
            </div>
            <div class="col-md-8">
                <pg-select style="width: 100%;" [Mode]="'single'" [PlaceHolder]="'Template'" [(ngModel)]="email_template.template" [NotFoundContent]="'Not found'" name="template" (ngModelChange)="deail($event)">
                    <pg-option *ngFor="let option of email_templates" [Label]="option.name" [Value]="option.id" [Disabled]="option?.disabled">
                    </pg-option>
                </pg-select>
            </div>
        </div>
        
        <div class="row mt-2" *ngIf="email_template.template || custom_template">
            <div class="col-md-4">
                <label>Subject *:</label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" name="subject" [(ngModel)]="email_temaplate_form.subject"  id="subject-text" required />
                <label class="error" *ngIf="invalidTemplateField.indexOf('subject') != -1">This field is required.</label>
            </div>
        </div>
        <div class="row" *ngIf="email_template.template || custom_template">
            <div class="col-md-12 mt-2">
                <label>Body *:</label>
                <div class="form-control mt-2 p-0">
                    <quill-editor [(ngModel)]="email_temaplate_form.body" id="ckeditorid1" [modules]="modules" theme="snow"></quill-editor>
                    <label class="error" *ngIf="invalidTemplateField.indexOf('body') != -1">This field is required.</label>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4" *ngIf="email_template.template || custom_template">
                <button [disabled]="!email_template.template" class="btn btn-complete" type="button" (click)="sendTestEmail();"> Send Test Email
                </button>
            </div>
            <div [ngClass]="!(email_template.template || custom_template) ? 'col-md-12' : 'col-md-8'">
                <button [disabled]="!email_template.template" class="btn btn-complete pull-right" type="button" (click)="sendEmail();"> Send
                </button>
            </div>
        </div>
    </div>
</div>
