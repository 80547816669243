export function toFormData(obj:any, form?:any, namespace?:any) {
    let fd = form || new FormData();

    let formKey;

    for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            }
            else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
}

export function objectToParams(object: any): string {
    return Object.keys(object).map((key) => {
        if (isJsObject(object[key])) {
            return subObjectToParams(encodeURIComponent(key), object[key])
        } else {
            if (object[key] != null)
                return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
            else
                return `${encodeURIComponent(key)}=`;
        }
    }
    ).join('&');
}

export function subObjectToParams(key: any, object: any): string {
    return Object.keys(object).map((childKey) => {
        if (isJsObject(object[childKey]))
            return subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]);
        else
            return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`;
    }
    ).join('&');
}

export function isJsObject(object: any) {
    let type: string = typeof object;
    return (type == 'object') && object != null;
}

export function makeRandomString() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
}

