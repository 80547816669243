import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    paginationData: {};
    search: {};
    public _subject = new Subject<object>();
    public event = this._subject.asObservable();
    constructor(private http: HttpClient) { }


    public publish(data: any) {
        this._subject.next(data);
    }

    deleteReport(id, type=null) {
        return this.http.delete('/api/users-report/' + id, { params: { type: type }});
    }

    getUsersReport(extraData: any = {}, sortField: any = '', sortOrder: any = '') {
        return this.http.get('/api/users-report',
            { params: { limit: extraData.per_page, page: extraData.current_page, user_id: extraData.user_id, sortField: sortField, sortOrder: sortOrder }
            });
    }

    getReportStatus(id) {
        return this.http.get('/api/report/status/' + id);
    }

}
