<nz-modal [(nzVisible)]="isActive" nzWidth="90%" [nzFooter]="null" (nzAfterOpen)="initMap()" 
          [nzBodyStyle]="{height:'95vh', minHeight: 'fit-content'}" [nzStyle]="{top:'2rem'}" (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <p class="bold fs-16 mb-0 pull-left inline">Map</p> 
    <div class="mt-1 mb-1 mr-4 pull-right">
        <app-saved-search-selector
            [selectedSaved]="searchNewComponent.selectedSaved"
            [savedSearcheOptions]="searchNewComponent.saveOfGroupOption"
            (searchChange)="searchNewComponent.updateSaveFilter($event) || saveFilterChange()"
        />
    </div>
    <div class="filters-container">
        <div class="search-tag d-inline">
            <ng-container *ngFor="let tag of searchNewComponent.search | keyvalue;">
                <ng-container *ngIf="tag.value">
                    <ng-container
                        *ngIf="tag.key == 'type' && tag.value?.length > -2">
                        <div class="d-inline-block">
                            <div class="d-block mb-1">
                                <div class="d-inline tagsize">
                                    {{ searchNewComponent.keyFilter(searchNewComponent.replace(tag),"key") }} :
                                </div>
                                <ng-container *ngFor="let val of tag.value;">
                                    <pg-tag [Closable]="true"
                                        (Close)="onRemoveFilter({key:'type', value:val}, true)">{{
                                        searchNewComponent.keyFilter(searchNewComponent.replace({key:'type',
                                        value:val})) }}
                                    </pg-tag>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
        
                    <ng-container
                        *ngIf="tag.key == 'quick_search' && tag.value?.length > -2">
                        <div class="d-inline-block">
                            <div class="d-block mb-1">
                                <div class="d-inline tagsize">
                                    {{ searchNewComponent.keyFilter(searchNewComponent.replace(tag),"key") }} :
                                </div>
                                <ng-container *ngFor="let val of tag.value;">
                                    <pg-tag *ngIf="searchNewComponent.preCheckSearch(val)"
                                        [Closable]="true"
                                        (Close)="onRemoveFilter({key:'quick_search', value:val}, true)">{{
                                        searchNewComponent.keyFilter(searchNewComponent.replace({key:'quick_search',
                                        value:val})) }}
                                    </pg-tag>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
        
                    <ng-container
                        *ngIf="tag.key !='crd_autocomplete' && tag.key !='file_name' && tag.key != 'save_search_id' && tag.key !='search_option' && tag.key != 'search_type' && tag.key !='quick_search_crd_name' && tag.key !='quick_full_search' && tag.key !='firm_keyword_category' && tag.key !='person_keyword_category' && tag.key !='select_title_type' && tag.key != 'select_carrier_type' && tag.key != 'select_company_type' && tag.key !='shared_type' && tag.key !='my_advizor_option' && tag.key !='firm_my_advizor_option' && tag.key != 'custodian_option' && tag.key !='licenses_filter_switch' && tag.key != 'designation_filter_switch' && tag.key != 'ticker_filter_switch' && tag.key != 'people_with_filter_switch' && tag.key != 'exclude_firms' && tag.key != 'exclude_persons' && tag.key != 'ins_line_filter_switch' && tag.key != 'ins_register_filter_switch' && tag.key !='firm_name_search' && tag.key !='exclude_firm' && tag.key !='bulk_filter_option' && tag.key != 'email_quality' && tag.key != 'email_quality_A' && tag.key != 'email_quality_B' && tag.key != 'type' && tag.key != 'quick_search'">
                        <div class="d-inline-block">
                            <div class="d-block mb-1">
                                <div class="d-inline  tagsize">
                                    {{ searchNewComponent.keyFilter(searchNewComponent.replace(tag),"key") }} :
                                </div>
                                <pg-tag [Closable]="true"
                                    (Close)="onRemoveFilter(tag)">{{
                                    searchNewComponent.keyFilter(searchNewComponent.replace(tag),"value") }}</pg-tag>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div> 

    <div class="map-modal">
      <div #map class="map" [ngStyle]="{position: 'relative', maxWidth: '100%', width:'100%', height:'95%'}" id="map" class="match-parent">  
        <div *ngIf="isLoadingData" class="spinner-container">
          <nz-spin class="centered-spinner" nzTip="Loading..."  [nzSize]="'large'"></nz-spin>
        </div>
      </div>
    </div>

    <div class="card lottie-card" [ngClass]="showNoResultsCard ? 'd-block' : 'd-none'">
        <div class="lottie-content-detail text-center">
            <canvas #dotlottieCanvas class="dotlottie-canvas"></canvas>
            <h2>{{ title }}</h2>
            <p>{{ description }}</p>
        </div>
    </div>
    
  </ng-container>
</nz-modal>
