import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ProfileService } from "../../../services/profile.service";

interface FundData {
  id: number;
  ack_id: string;
  ticker: string;
  fund: string;
  value: number;
  category: string | null;
  issuer: string | null;
  asset_class: string | null;
  region: string | null;
  style: string | null;
  size: string | null;
  proxy_ticker: string | null;
  CLASSMATCH: number;
  CONFIDENCE: number;
  type: string;
  track_record: string | null;
  fund_size: string | null;
  expense_ratio: string | null;
  three_year_return: string | null;
  three_year_sharpe_ratio: string | null;
  category_consistency: string | null;
  rating: string | null;
  created_at: string;
  updated_at: string;
}

interface Summary {
  ack_id: string;
  Weak_Count: number;
  created_at: string;
  updated_at: string;
}

interface Fund {
  data: FundData[];
  summary: Summary | null;
}

interface FundResponse {
  status: string;
  response: Fund;
}

interface Sort {
  column: any;
  newValue: string;
  prevValue: string | undefined;
  sorts: {
    dir: string;
    prop: string;
    type?: string;
  }[];
}

@Component({
  selector: "afm-tab-k-fund-analysis",
  templateUrl: "./tab-401k-fund-analysis.component.html",
  styleUrls: ["./tab-401k-fund-analysis.component.scss"],
})
export class Tab401kFundAnalysisComponent implements OnChanges {
  public loading = true;
  public viewData: Fund | null = null;

  public columnModeSetting: string =
    window.innerWidth < 960 ? "standard" : "force";
  public scrollBarHorizontal: boolean = window.innerWidth < 960;
  public messages: { emptyMessage: string } = {
    emptyMessage: "",
  };

  @Input() ackId: string | null = null;

  constructor(private readonly profileService: ProfileService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ackId) {
      if (!this.ackId) {
        return;
      }

      this.loading = true;
      this.viewData = null;
      this.getFund(this.ackId);
    }
  }

  private getFund(ackId: string): void {
    this.profileService
      .getFunds({ id: ackId })
      .subscribe((res: FundResponse) => {
        if (res?.status === "200" && res?.response)
          this.viewData = res?.response;
        this.loading = false;
      });
  }

  public onSort($event: Sort): void {
    if ($event.sorts[0].prop === "value") {
      $event.sorts[0].type = "int";
    }
    this.profileService.fee_sort = { fund_sort: $event.sorts[0] };
    if (this.ackId) this.getFund(this.ackId);
  }
}
