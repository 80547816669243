<div class="table-responsive">
    <ngx-datatable class='table table-hover table-v2 c-export-data' [rows]="followed_report?.data"
        [columnMode]="columnModeSetting" [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'"
        [scrollbarH]="scrollBarHorizontal" [externalPaging]="true" [count]="followed_report?.total"
        [offset]="paginate.current_page" [limit]="paginate.per_page" (page)='sortData($event)'
        [messages]="messages" (sort)="onSort($event)">
        <ngx-datatable-column [minWidth]="200" [sortable]="false" [draggable]="false" [resizeable]="false"
            name="Name" cellClass="d-flex align-items-center text-primary" prop="subject_name">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="search-tag" *ngIf="row?.row_type==0">
                    <pg-tag *ngIf="row?.subject_name" (click)="onSearch(row?.search)">
                        {{ row?.subject_name }}
                    </pg-tag>
                </div>
                <a class="grey-3 text-capitalize" target="_blank" *ngIf="row?.row_type==1" [routerLink]="['/advisor/firm', row?.crd]">
                    {{ row?.subject_name }}</a>
                <a class="grey-3 text-capitalize" target="_blank" *ngIf="row?.row_type==2" [routerLink]="['/advisor/person', row?.crd]">
                    {{ row?.subject_name }}</a>
                <a class="grey-3 text-capitalize" target="_blank" *ngIf="row?.row_type==3" routerLink="/profile/{{row?.ack_id}}">
                    {{ row?.subject_name }}</a>
                <a class="grey-3 text-capitalize" target="_blank" *ngIf="row?.row_type==4"> {{ row?.subject_name }}</a>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="200" [sortable]="true" [draggable]="false" [resizeable]="false"
            name="Type" cellClass="d-flex align-items-center" prop="type">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="grey-3" *ngIf="row">{{ row?.type.replace('-', ' ') }}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="130" [sortable]="true" [draggable]="false" [resizeable]="false"
            name="date" cellClass="d-flex align-items-center" prop="date">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="grey-3" *ngIf="row">{{ row?.created_at | date:'MM/dd/yyyy hh:mm a' }}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="100" [sortable]="true" [draggable]="false" [resizeable]="false"
            name="Exported By" cellClass="d-flex align-items-center" prop="created_by">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="grey-3" *ngIf="row">{{ row?.created_by }}</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="50" [sortable]="false" [draggable]="false" [resizeable]="false"
            name="" cellClass="d-flex align-items-center" prop="link">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <ng-container *ngIf="row.link; else statusSection">
                    <span>
                        <a class="c-download-btn" href="{{row?.link}}" target="_blank">Download</a>
                    </span>
                </ng-container>
                <ng-template #statusSection>
                    <ng-container *ngIf="row.status=='pending'; else failedMessage">
                        <span class="c-running-btn">Running…</span>
                    </ng-container>
                    <ng-template #failedMessage>
                        <span class="c-failed-btn">Failed</span>
                    </ng-template>
                </ng-template>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [minWidth]="50" [sortable]="false" [draggable]="false" [resizeable]="false"
            name="" cellClass="d-flex align-items-center">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.search_data">
                    <a href="javascript:void(0)" (click)="runAgain(row)">Run Search</a>
                </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible">
                <div class="pagination-v2">
                    <div class="page-count p-0">
                        <span class="page-total grey-3">
                            {{ rowCount?.toLocaleString() }} total entries
                        </span>
                    </div>
                    <datatable-pager
                        class="custom-datatable-pager"
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!((rowCount / pageSize) > 1)"
                        (change)="sortData($event)">
                    </datatable-pager>
                </div>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>

<nz-modal nzCentered [(nzVisible)]="search_model" nzWidth="600px" [nzContent]="searchModelContent" nzTitle="Search Filters" [nzFooter]="null" (nzOnCancel)="closeModal(1)" >
    <ng-template #searchModelContent>
		<div class="row">
            <div class="col-lg-12">
                <div class="search-tag">
                    <div *ngFor="let search of search_data_obj;">
                        <pg-tag>
                            {{ search }}
                        </pg-tag>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>