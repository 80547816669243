import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ProfileService } from "../../../services/profile.service";

interface BenchmarkChartBar {
  bar1?: number;
  bar2?: number;
  bar3?: number;
}

interface BenchmarkChartData {
  participation: BenchmarkChartBar;
  employer: BenchmarkChartBar;
  avgEmployee: BenchmarkChartBar;
  rateReturn: BenchmarkChartBar;
  percentRetirees: BenchmarkChartBar;
  adminFees: BenchmarkChartBar;
}

interface BenchmarkChartResponse {
  status: string;
  data: BenchmarkChartData;
}

interface XAxisData {
  value: string;
  textStyle: {
    fontSize: number;
  };
}

interface SeriesData {
  value: number | undefined;
  label: {
    show: boolean;
    position: string;
    color: string;
    formatter: (data: any) => string;
  };
  itemStyle: {
    color: string;
  };
}

interface ChartOption {
  title: object;
  tooltip: {
    formatter: (params: any) => string;
    trigger: string;
    axisPointer: {
      type: string;
    };
  };
  grid: {
    left: string;
    right: string;
    bottom: string;
    containLabel: boolean;
  };
  xAxis: {
    data: XAxisData[];
    nameLocation: string;
    nameGap: number;
    axisLabel: {
      interval: number;
    };
  };
  yAxis: {
    type: string;
    axisLabel: {
      align: string;
    };
  };
  series: {
    type: string;
    data: SeriesData[];
    itemStyle: object;
    label: {
      show: boolean;
    };
    smooth: boolean;
  }[];
}

@Component({
  selector: "afm-tab-k-benchmark",
  templateUrl: "./tab-401k-benchmark.component.html",
  styleUrls: ["./tab-401k-benchmark.component.scss"],
})
export class Tab401kBenchmarkComponent implements OnChanges {
  public participationRate: ChartOption | null = null;
  public adminFees: ChartOption | null = null;
  public averageParticipantContribution: ChartOption | null = null;
  public employerContribution: ChartOption | null = null;
  public percentOfRetirees: ChartOption | null = null;
  public rateOfReturn: ChartOption | null = null;
  public loading = true;

  @Input() ackId: string | null = null;

  constructor(private readonly profileService: ProfileService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ackId) {
      if (!this.ackId) {
        return;
      }

      this.participationRate = null;
      this.adminFees = null;
      this.averageParticipantContribution = null;
      this.employerContribution = null;
      this.percentOfRetirees = null;
      this.rateOfReturn = null;
      this.loading = true;

      this.getChartData(this.ackId);
    }
  }

  private getChartData(ackId: string): void {
    this.profileService
      .getBenchmarkChart(ackId)
      .subscribe((response: BenchmarkChartResponse) => {
        if (response && response?.status === "200" && response?.data)
          this.drawCharts(response?.data);
      });
  }

  private drawCharts(data: BenchmarkChartData): void {
    if (data?.participation)
      this.participationRate = this.chartOption(
        "Participation Rate",
        data?.participation
      );

    if (data?.adminFees)
      this.adminFees = this.chartOption(
        "Employer Contribution",
        data?.adminFees
      );

    if (data?.avgEmployee)
      this.averageParticipantContribution = this.chartOption(
        "Average Participant Contribution",
        data?.avgEmployee,
        "${c}"
      );

    if (data?.employer)
      this.employerContribution = this.chartOption(
        "Employer Contribution",
        data?.employer
      );

    if (data?.percentRetirees)
      this.percentOfRetirees = this.chartOption(
        "Percent of Retirees",
        data?.percentRetirees
      );

    if (data?.rateReturn)
      this.rateOfReturn = this.chartOption("Rate of Return", data?.rateReturn);

    this.loading = false;
  }

  private chartOption(
    title: string,
    bars: BenchmarkChartBar,
    formatter = "{c}%"
  ): ChartOption {
    const xAxis: XAxisData[] = [
      {
        value: "This Plan",
        textStyle: {
          fontSize: 10,
        },
      },
      {
        value: "Peer Average- Size",
        textStyle: {
          fontSize: 10,
        },
      },
    ];

    const seriesData: SeriesData[] = [
      {
        value: bars?.bar1,
        label: {
          show: true,
          position: "top",
          color: "#1E88E5",
          formatter: (data: any) => {
            return this.formatNumber(data.value, formatter);
          },
        },
        itemStyle: {
          color: "#1E88E5",
        },
      },
      {
        value: bars?.bar2,
        label: {
          show: true,
          position: "top",
          color: "#F44336",
          formatter: (data: any) => {
            return this.formatNumber(data.value, formatter);
          },
        },
        itemStyle: {
          color: "#F44336",
        },
      },
    ];

    if (bars?.bar3) {
      xAxis.push({
        value: "Peer Average- Industry",
        textStyle: {
          fontSize: 10,
        },
      });

      seriesData.push({
        value: bars?.bar3,
        label: {
          show: true,
          position: "top",
          color: "#10CFBD",
          formatter: (data: any) => {
            return this.formatNumber(data.value, formatter);
          },
        },
        itemStyle: {
          color: "#10CFBD",
        },
      });
    }

    const opt: ChartOption = {
      title: {},
      tooltip: {
        formatter: (params: any) => {
          const icon1 = `<span data-tooltip="implied-high" style="background-color:rgba(255,255,255,.);border-radius: 2px;display: inline-block;height: 12px;margin-right:5px;width: 20px;"><span style="background-color:${params[0].color};border: 1px solid ${params[0].color};border-radius:50%;display:block;height:10px;margin-left:7px;margin-top:3px;width:10px;"></span></span>`;
          return `${params[0].name} <br />
              ${icon1}  ${params[0].value}`;
        },
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        data: xAxis,
        nameLocation: "middle",
        nameGap: 2,
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          align: "center",
        },
      },
      series: [
        {
          type: "bar",
          data: seriesData,
          itemStyle: {},
          label: {
            show: true,
          },
          smooth: true,
        },
      ],
    };

    return opt;
  }

  private formatNumber(value: number, formatter: string): string {
    if (formatter !== "{c}%") {
      let parts = value.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$" + parts.join(".");
    }

    return value + "%";
  }
}
