import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";


function initSentry() {

  const isProduction = environment.slug === "production";

  Sentry.init({
    environment: environment.slug,
    dsn: "https://e5ff6cb3e7ec39edd6d44db74e1e97c4@o4508297440002048.ingest.us.sentry.io/4508416036306944",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: isProduction ? 0.1: 1.0,
    tracePropagationTargets: [
      "localhost",
      "https://app.planprofessional.com",
      "https://app.advizorpro.com",
      "https://testingapi.planprofessional.com",
      "https://apisl.planprofessional.com",
      /^https:\/\/testingapi\.planprofessional\.com\/api/,
      /^https:\/\/apisl\.planprofessional\.com\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: isProduction ? 0.1 : 1.0, // 5% in production, 100% in testing
    replaysOnErrorSampleRate: 1.0, // Always capture 100% of sessions with errors
  });
}

if (environment.production) {
  enableProdMode();
  initSentry();
}

platformBrowserDynamic().bootstrapModule(AppModule);
