import { Component, Input, Output, EventEmitter, Inject, ElementRef, ViewChild, OnInit } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import Geohash from "latlon-geohash";

import { Feature } from "geojson/index";
import { DynamicComponentService } from "./dynamic-component.service";
import { FirmContactPopupComponent } from "../firm-contact-popup/firm-contact-popup.component";
import { environment } from "../../../../../environments/environment";
import { AdvizorProService } from "../../../../shared/services/advizor-pro.service";
import { MapService } from "../../../../shared/services/map.service";
import {
  clusterBase,
  clusterCount,
  statesBase,
  statesCluster,
  statesCounter,
  statesOutline,
  unclusteredPoint,
} from "./layers";
import { SearchNewComponent } from "../../search-new.component";
import { DotLottie } from '@lottiefiles/dotlottie-web';

interface mapQueryOptions {
  isDataNeeded: boolean; //is needed the data of the contacts
  areAggregationNeeded: boolean; //if the aggregations of elastic search  are needed
  aggregationClusterType: "grid" | "states"; //to do the query for grid or for states
  bounds: {
    //bounds where elastic will does the query
    top_left: number[];
    bottom_right: number[];
  };
  precision?: number;
}

interface ElasticBounds {
  bottom_right: {
    lon: number;
    lat: number;
  };
  top_left: {
    lon: number;
    lat: number;
  };
}
 
@Component({
  selector: "location-modal",
  templateUrl: "./location-modal.component.html",
  styleUrls: ["./location-modal.component.scss"],
})
export class LocationModal implements OnInit {
  @Input() isActive: boolean;
  @Output() isActiveChange = new EventEmitter();

  @Input() searchNewComponent: SearchNewComponent

  @Input() advizorProService: AdvizorProService;
  @Input() searchData: any;

  //data
  contactsData: any[];
  firmsData: any[];

  markers: Feature[] = [];
  states: any[] = [];
  stagingStates: {} = {};

  //map
  map: mapboxgl.Map | undefined;
  popup: mapboxgl.Popup | undefined;
  geocoder: MapboxGeocoder | undefined;
  style = "mapbox://styles/mapbox/light-v11?optimize=true";
  accessToken = environment.mapboxAccessToken;

  //auxiliary
  hoverTimer: any 
  bounds: mapboxgl.LngLatBounds = new mapboxgl.LngLatBounds();
  hoveredPolygonId = null;
  idStateCounter = 0;
  stateFilter = null;
  statesBounds: any;
  zoomModesLimit = 5.0;

  //flags
  clickStateFlag: boolean;
  fitBoundsFlag = false;
  isLoadingData = true;
  showNoResultsCard = false;
  focusFlag = true;

  //constants
  MAX_SHOW_POPUP_DOC_COUNT = 20
  MAX_SHOW_POPUP_ZOOM = 14.5
  HOVER_DELAY = 800 
  MAX_ZOOM = 16 
  MAX_BOUNDS: mapboxgl.LngLatBoundsLike = [
    [-179.90972527576585, 15.550660028773164], // Southwest coordinates
    [-35.913636910649753, 66.51801942055434], // Northeast coordinates
  ];  
  INITIAL_BOUNDS: mapboxgl.LngLatBoundsLike = [  
    [-125.0011, 24.9493],  
    [-66.9326, 49.5904],
  ]

  buttonRemover: HTMLElementTagNameMap[keyof HTMLElementTagNameMap];
  //this is necesary because function.bind always return a new function, so after is not possible shutdown the event with map.off
  //states events
  onMoveendStateFunc: (
    e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent
  ) => void;
  onClickStateFunc: (e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent) => void;
  //clusters events
  onZoomMoveMapFunc: (
    e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent
  ) => void;
  onClickClusterFunc: (
    e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent
  ) => void;
  onHoverActionFunc: (
    e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent
  ) => void;
  onMouseLeaveClusterFunc: (
    e : mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent
  ) => void
  onHoverStateFunc: (e: mapboxgl.MapMouseEvent & mapboxgl.MapDataEvent) => void;

  @ViewChild('dotlottieCanvas', { static: false }) dotlottieCanvas: ElementRef;

  title = 'No results';
  description = 'No contacts with an address meet this search'; 
  isLottieInitialized = false;
  savedSearchActive = false;

  constructor(
    private dynamicComponentService: DynamicComponentService,
    @Inject("MAP_SERVICE") public mapService: MapService
  ) {}

  ngOnInit(): void  {
    this.advizorProService.savedSearchChange$.subscribe(async (res:boolean) => {
      if (res && this.savedSearchActive) {
        await this.initMap();
        this.savedSearchActive = false;
      }
    });
  }

  async initMap() {
    this.markers = [];
    this.states = [];
    this.isLoadingData = true;
    this.showNoResultsCard = false;

    this.map = new mapboxgl.Map({
      accessToken: this.accessToken,
      container: "map",
      style: this.style,
      projection: "mercator",
      bounds: this.INITIAL_BOUNDS,
    });
    this.map.on("load", () => {
      this.initGeocoder();
      this.map.setMaxBounds(this.MAX_BOUNDS);
      this.map.setMaxZoom(this.MAX_ZOOM);
      this.map.on("moveend", () => {  
        if (this.fitBoundsFlag) {
          this.isLoadingData = false;
        }
      });
    });

    await this.initStates(true);
  }

  initGeocoder() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.accessToken,
      mapboxgl: mapboxgl,
      countries: "US",
      minLength: 4,
      marker: {
        color: "#e33939",
      },
    });

    this.geocoder.on("result", () => {
      this.stateFilter = null;
      this.fitBoundsFlag = true;
      this.focusFlag = false;
      this.showNoResultsCard = false;
      this.isLoadingData = true;
    });
    this.map.addControl(this.geocoder);
  }

  initializeLottieAnimation(): void {
    if (this.dotlottieCanvas && this.dotlottieCanvas.nativeElement) {
      new DotLottie({
        autoplay: true,
        loop: true,
        canvas: this.dotlottieCanvas.nativeElement, // Pass the canvas element
        src: "https://lottie.host/3da393d8-f892-45cb-a555-012742b9223e/xsv6fjfUqc.json"
      });
    } 
  }

  //states methods
  async initStates(fitBounds = true) {
    const queryOptions: mapQueryOptions = {
      isDataNeeded: false,
      areAggregationNeeded: true,
      aggregationClusterType: "states",
      bounds: null,
    };

    await this.mapQuery(queryOptions as mapQueryOptions).then(async () => {
      if (this.contactsData.length === 0) {
        this.isLoadingData = false;
        this.showNoResultsCard = true;
        this.initializeLottieAnimation()
        return;
      }

      for (let state of this.contactsData) {
        //relation between boundaries and contacts data
        const stateKey: string = state.key;
        this.stagingStates[stateKey] = state;
      }
      const states = this.contactsData.map((state: any) => state.key);
      await this.boundariesQuery(states).then(() => {
        if (fitBounds) {
          this.fitBounds();
        }
        this.map.addSource("state", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: this.states,
          },
        });
        this.map.addSource("state-counter", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: this.states.map((item) => {
              return {
                ...item.properties.center,
                properties: {
                  count: item.properties.count,
                  id: item.properties.id,
                },
              };
            }),
          },
        });

        this.map.addLayer(statesBase as mapboxgl.AnyLayer);
        this.map.addLayer(statesOutline as mapboxgl.AnyLayer);
        this.map.addLayer(statesCluster as mapboxgl.AnyLayer);
        this.map.addLayer(statesCounter as mapboxgl.AnyLayer);
        this.onClickStateFunc = this.onClickState.bind(this);
        this.onMoveendStateFunc = this.onMoveendState.bind(this);
        this.map.on("click", "states-fill", this.onClickStateFunc);
        this.map.on("moveend", this.onMoveendStateFunc);

        this.isLoadingData = false;
      });
    });
  }

  createState(data: any) {
    this.idStateCounter++;
    const state: Feature = {
      id: this.idStateCounter,
      type: "Feature",
      geometry: data.location,
      properties: {
        count: data.doc_count,
        name: data.name,
        id: data.USPSState,
        hover: false,
        center: data.center,
      },
    };
    this.states.push(state);
  }

  restartStates() {
    this.focusFlag = true;
    this.map.setLayoutProperty("states-fill", "visibility", "visible");
    this.map.setLayoutProperty("states-counter", "visibility", "visible");
    this.map.setLayoutProperty("states-bubble", "visibility", "visible");

    this.map.on("click", "states-fill", this.onClickStateFunc);
    this.map.on("moveend", this.onMoveendStateFunc);
    this.map.on("mouseenter", "states-bubble", this.onHoverStateFunc);
  }

  offStates() {
    this.map.setLayoutProperty("states-fill", "visibility", "none");
    this.map.setLayoutProperty("states-counter", "visibility", "none");
    this.map.setLayoutProperty("states-bubble", "visibility", "none");

    this.map.off("click", "states-fill", this.onClickStateFunc);
    this.map.off("moveend", this.onMoveendStateFunc);
    this.map.off("mouseenter", "states-bubble", this.onHoverStateFunc);
  }

  async onMoveendState() {
    if (this.map.getZoom() > this.zoomModesLimit) {
      this.offStates();
      await this.initClusters(this.focusFlag);
    }
  }

  async onClickState(e: mapboxgl.MapMouseEvent) {
    this.clickStateFlag = true;
    this.expandBounds(e.features[0].geometry);
    this.offStates();
    this.fitBounds();
    this.stateFilter = e.features[0].properties.id;
    await this.initClusters(false);
  }

  //Clusters methods
  async initClusters(fitBounds = true) {
    let bounds = this.map.getBounds();
    const topLeft = bounds.getNorthWest();
    const bottomRight = bounds.getSouthEast();
    const boundsObj = {
      top_left: [
        this.normalizeLon(topLeft.lng),
        this.normalizeLat(topLeft.lat),
      ],
      bottom_right: [
        this.normalizeLon(bottomRight.lng),
        this.normalizeLat(bottomRight.lat),
      ],
    };

    const queryOptions: mapQueryOptions = {
      isDataNeeded: false,
      areAggregationNeeded: true,
      aggregationClusterType: "grid",
      bounds: boundsObj,
    };

    await this.mapQuery(queryOptions as mapQueryOptions).then(() => {
      for (let i = 0; i < this.contactsData.length; i++) {
        this.createMarker(this.contactsData[i], "contact");
      }
      if (fitBounds) {
        this.fitBounds();
      }
      this.addClustersMapEvents();
      // this.buttonFilterRemover();
    });
  }

  createMarker(data: any, type: string) {
    const coordinates = [
       Number(data.centroid.location.lon),
       Number(data.centroid.location.lat),
    ]; 

    const point: Feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates,
      },
      properties: { ...data, type: type },
    };
    this.markers.push(point);
  }

  addClustersMapEvents() {
    this.map.addSource("advisor", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: this.markers,
      },
    });

    this.map.addLayer(clusterBase as mapboxgl.AnyLayer);
    this.map.addLayer(clusterCount as mapboxgl.AnyLayer);
    this.map.addLayer(unclusteredPoint as mapboxgl.AnyLayer);

    this.onZoomMoveMapFunc = this.onZoomMoveClustersMap.bind(this);
    this.onClickClusterFunc = this.onClickCluster.bind(this);
    this.onHoverActionFunc = this.onHoverAction.bind(this);
    this.onMouseLeaveClusterFunc = this.onMouseLeaveCluster.bind(this)

    this.map.on("moveend", this.onZoomMoveMapFunc);
    this.map.on("click", "clusters", this.onClickClusterFunc);
    this.map.on("mouseenter", "clusters", this.onHoverActionFunc);
    this.map.on("mouseenter", "unclustered-point", this.onHoverActionFunc);
    this.map.on("mouseleave", "unclustered-point", this.onMouseLeaveClusterFunc)
    this.map.on("mouseleave", "clusters", this.onMouseLeaveClusterFunc)
    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  removeClustersMapEvents() {
    if (!!this.map.getSource("advisor"))
      this.map.off("moveend", this.onZoomMoveMapFunc);
    this.map.off("click", "clusters", this.onClickClusterFunc);
    this.map.off("mouseenter", "clusters", this.onHoverActionFunc);
    this.map.off("mouseenter", "unclustered-point", this.onHoverActionFunc);

    this.map.removeLayer("clusters");
    this.map.removeLayer("cluster-count");
    this.map.removeLayer("unclustered-point");
    this.map.removeSource("advisor");
  }

  async onZoomMoveClustersMap() {
    if (this.clickStateFlag) {
      this.zoomModesLimit = this.map.getZoom() - 0.1;
      this.clickStateFlag = false;
    }
    if (this.map.getZoom() < this.zoomModesLimit) {
      this.stateFilter = null;
      this.removeClustersMapEvents();
      this.restartStates();
      //this.buttonRemover.remove();
    }
    this.updateClusters()
  }

  onHoverAction(e: mapboxgl.MapMouseEvent) {
    if(e.features[0].properties.doc_count <= this.MAX_SHOW_POPUP_DOC_COUNT  || this.map.getZoom() > this.MAX_SHOW_POPUP_ZOOM ){

      if (this.popup !== undefined) this.popup.remove(); 

      this.hoverTimer = setTimeout(()=>{
        if (e.features[0].geometry.type === "Point") {
          const coordinates = e.features[0].geometry.coordinates.slice();
          if (
            ["mercator", "equirectangular"].includes(this.map.getProjection().name)
          ) {
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
          }
          const popupContent = this.dynamicComponentService.injectComponent(
            FirmContactPopupComponent,
            (instance) => {
              instance.data = this.searchNewComponent.search;
              instance.key = e.features[0].properties.key;
              instance.state = e.features[0].properties.id; 
              instance.contactsCounter = e.features[0].properties.doc_count;
              instance.advizorProService = this.advizorProService;
            }
          );
  
          this.popup = new mapboxgl.Popup({ maxWidth: "1000px" })
            .setLngLat([coordinates[0], coordinates[1]])
            .setDOMContent(popupContent)
            .addTo(this.map); 

        }
      }, this.HOVER_DELAY)
    } 
  }

  onMouseLeaveCluster(){
    clearTimeout(this.hoverTimer)
  } 

  async onClickCluster(e: mapboxgl.MapMouseEvent) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });

    let geohash = features[0].properties.key;
    // Decode the geohash to get its bounding box
    const bounds = Geohash.bounds(geohash); 
    this.bounds = new mapboxgl.LngLatBounds(
      [bounds.sw.lon, bounds.sw.lat],
      [bounds.ne.lon, bounds.ne.lat]
    );

    const startMovement = async () => {
      const queryOptions = {
        isDataNeeded: false,
        areAggregationNeeded: true,
        aggregationClusterType: "grid",
        bounds: {
          top_left: [bounds.sw.lon, bounds.ne.lat],
          bottom_right: [bounds.ne.lon, bounds.sw.lat],
        },
      }; 


      await this.mapQuery(queryOptions as mapQueryOptions).then(async () => {
        //if the new geohash has the same length it means that needs consult more deeper
        const inTheSameScope = this.sameHashScope(geohash); 
        if (!inTheSameScope) {
          const coords = JSON.parse(features[0].properties.centroid).location;
          geohash = this.getSubGeohashForCoordinate(
            coords.lat,
            coords.lon,
            geohash
          ); 
          if (!geohash && !this.savedSearchActive) return;
          await startMovement();
        }
        this.fitBounds();
      });
    }; 

    await startMovement();
  }

  async updateClusters(){
    const bounds = this.map.getBounds();
    const topLeft = bounds.getNorthWest();
    const bottomRight = bounds.getSouthEast();
    const boundsObj = {
      top_left: this.enlargeBoundaryTopLeft([topLeft.lng, topLeft.lat]),
      bottom_right: this.enlargeBoundaryBottomRight([bottomRight.lng, bottomRight.lat]),
    };

    const queryOptions = {
      isDataNeeded: false,
      areAggregationNeeded: true,
      aggregationClusterType: "grid",
      bounds: boundsObj,
    };
    await this.mapQuery(queryOptions as mapQueryOptions).then(() => {
      this.markers = [];
      for (let i = 0; i < this.contactsData.length; i++) {
        this.createMarker(this.contactsData[i], "contact");
      }
      const source = this.map.getSource("advisor");
      if (source) {
        if (source.type === "geojson")
          source.setData({
            type: "FeatureCollection",
            features: this.markers,
          });
      }
    });
  }

  buttonFilterRemover() {
    this.buttonRemover = document.createElement("button");
    this.buttonRemover.innerText = `  States View  `;
    this.buttonRemover.style.position = "absolute";
    this.buttonRemover.style.top = "20px";
    this.buttonRemover.style.left = "20px";
    this.buttonRemover.style.backgroundColor = "#C8E6FF";
    this.buttonRemover.style.color = "#133C5C";
    this.buttonRemover.style.borderRadius = "20px";
    this.buttonRemover.style.minHeight = "40px";
    this.buttonRemover.style.borderWidth = "0px";
    this.buttonRemover.style.padding = "10px 20px";
    this.buttonRemover.style.fontFamily = "Arial, sans-serif";
    this.buttonRemover.style.fontSize = "14px";
    this.buttonRemover.style.fontWeight = "bold";
    this.buttonRemover.style.cursor = "pointer";
    this.buttonRemover.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
    this.buttonRemover.style.transition = "all 0.3s ease";
    this.buttonRemover.addEventListener("click", () => {
      this.stateFilter = null;
      this.zoomModesLimit = 5.6;
      this.buttonRemover.remove();
      this.removeClustersMapEvents();
      //no state filter
      this.stateFilter = null;
      //states clusters view without movement
      this.restartStates();
      this.bounds = this.statesBounds;
      this.fitBounds();
      if (this.popup) {
        this.popup.remove();
      }
    });

    this.map.getContainer().appendChild(this.buttonRemover);
  }

  //utils
  fitBounds() {
    this.fitBoundsFlag = true;
    this.showNoResultsCard = false;
    this.isLoadingData = true;
    const ne = this.bounds.getNorthEast();
    const sw = this.bounds.getSouthWest();
    this.bounds = new mapboxgl.LngLatBounds(
      [this.normalizeLon(sw.lng), this.normalizeLat(sw.lat)],
      [this.normalizeLon(ne.lng), this.normalizeLat(ne.lat)]
    );
    this.map.fitBounds(this.bounds, { padding: 10 });
  }

  async mapQuery(mapQueryOptions: mapQueryOptions): Promise<void> {
    return new Promise((resolve) => {
      let search_args = Object.assign({}, this.searchNewComponent.search);
      if (!search_args.firm_contains) {
        delete search_args.firm_contains_includes;
      }
      if (this.stateFilter) search_args.person_state = [this.stateFilter];
      let args: any = {
        limit: 1,
        page: 1,
        isMapSearch: true,
        searchData: search_args,
        searchMapData: {
          areAggregationNeeded: mapQueryOptions.areAggregationNeeded,
          isDataNeeded: mapQueryOptions.isDataNeeded,
          aggregationClusterType: mapQueryOptions.aggregationClusterType,
          bounds: mapQueryOptions.bounds,
          precision: mapQueryOptions.precision
            ? mapQueryOptions.precision
            : this.getGeohashPrecision(),
        },
        sortField: "last_name",
        sortOrder: 1,
      };
      this.advizorProService.searchPerson(args).subscribe((response: any) => {
        if (response.data?.bounds) {
          const top_left = response.data.bounds.top_left;
          const bottom_right = response.data.bounds.bottom_right;
          this.bounds = new mapboxgl.LngLatBounds(
            [top_left.lon, bottom_right.lat],
            [bottom_right.lon, top_left.lat]
          ); 
        } 
        if (response.data?.grids) {
          this.contactsData = response.data.grids; 
        }
        if (response.data?.states) {
          this.contactsData = response.data.states;
        }
        resolve();
      });
    });
  }

  async boundariesQuery(states: string[]): Promise<void> {
    return new Promise((resolve) => {
      this.mapService.getStatesBoundaries(states).subscribe((response: any) => {
        const statesSolved = response.data.data;
        for (let stateSolved of statesSolved) {
          const data = {
            ...this.stagingStates[stateSolved.USPSState],
            ...stateSolved,
          };
          this.createState(data);
        }
        this.bounds = this.elasticBoundsToMapboxBounds(response.data.bounds);
        this.statesBounds = this.bounds;
        resolve();
      });
    });
  }

  getGeohashPrecision(): number {
    // the maximun precision is 12
    // the maximun zoom is around 19-20
    const zoomLevel = this.map.getZoom();
    if (zoomLevel <= 3) return 2;
    if (zoomLevel <= 4) return 2;
    if (zoomLevel <= 5) return 2;
    if (zoomLevel <= 6) return 3;
    if (zoomLevel <= 7) return 3;
    if (zoomLevel <= 8) return 3;
    if (zoomLevel <= 9) return  4;
    if (zoomLevel <= 10) return 4;
    if (zoomLevel <= 12) return 5;
    if (zoomLevel <= 13) return 5;
    if (zoomLevel <= 14) return 6;
    if (zoomLevel <= 15) return 6;
    if (zoomLevel <= 16) return 7;
    if (zoomLevel <= 17) return 7;
    if (zoomLevel <= 18) return 8;
    return 9;
  }

  expandBounds(geometry) {
    this.bounds = new mapboxgl.LngLatBounds();
    if (geometry.type === "Point") {
      this.bounds.extend(geometry.coordinates);
    } else if (
      geometry.type === "LineString" ||
      geometry.type === "MultiPoint"
    ) {
      geometry.coordinates.forEach((coord) => this.bounds.extend(coord));
    } else if (
      geometry.type === "Polygon" ||
      geometry.type === "MultiLineString"
    ) {
      geometry.coordinates.forEach((ring) =>
        ring.forEach((coord) => this.bounds.extend(coord))
      );
    } else if (geometry.type === "MultiPolygon") {
      geometry.coordinates.forEach((polygon) => {
        polygon.forEach((ring) =>
          ring.forEach((coord) => this.bounds.extend(coord))
        );
      });
    }
  } 

  getSubGeohashForCoordinate(lat, lon, parentGeohash) {
    if (parentGeohash > 10) {
      return null;
    }
    const parentPrecision = parentGeohash.length;

    const fullGeohash = Geohash.encode(lat, lon, parentPrecision + 1);

    return fullGeohash.slice(0, parentPrecision + 1);
  }

  sameHashScope(hash: string) {
    for (let x of this.contactsData) {
      if (x.key.length === hash.length) {
        return true;
      }
    }
    return false;
  }

  elasticBoundsToMapboxBounds(bounds: ElasticBounds): mapboxgl.LngLatBounds {
    return new mapboxgl.LngLatBounds(
      [bounds.top_left.lon, bounds.bottom_right.lat],
      [bounds.bottom_right.lon, bounds.top_left.lat]
    );
  } 

  enlargeBoundaryTopLeft(coordinate) {
    const enlargeFactor = 0.01; // 5% enlargement
    const [lng, lat] = coordinate;
    
    // For top-left, we want to decrease longitude and increase latitude
    const enlargedLng = lng - Math.abs(lng * enlargeFactor);
    const enlargedLat = lat + Math.abs(lat * enlargeFactor);
    
    return [enlargedLng, enlargedLat];
  }
  
  enlargeBoundaryBottomRight(coordinate) {
    const enlargeFactor = 0.01; // 5% enlargement
    const [lng, lat] = coordinate;
    
    // For bottom-right, we want to increase longitude and decrease latitude
    const enlargedLng = lng + Math.abs(lng * enlargeFactor);
    const enlargedLat = lat - Math.abs(lat * enlargeFactor);
    
    return [enlargedLng, enlargedLat];
  }

  normalizeLat(lat) {
    return Math.max(-90, Math.min(90, lat));
  }

  normalizeLon(lon) {
    return (((lon % 360) + 540) % 360) - 180;
  }

  isMapInitialized(): boolean {
    return this.map.getSource('state') !== undefined;
  }

  async onRemoveFilter(tag: any, closeType: false) {  
    this.states = []
    if (closeType)
      this.searchNewComponent.handleHeaderClose(tag)
    else
      this.searchNewComponent.handleClose(tag)

    this.showNoResultsCard = false;
    this.isLoadingData = true

    // Check if the map is already initialized
    if (!this.isMapInitialized()) {
      await this.initStates();
    } else {
      const auxiliarStateFilter = this.stateFilter
      this.stateFilter = null
      //update states 
      const queryOptions: mapQueryOptions = {
        isDataNeeded: false,
        areAggregationNeeded: true,
        aggregationClusterType: "states",
        bounds: null,
      };   
  
      await this.mapQuery(queryOptions as mapQueryOptions).then(async () => { 
        this.stagingStates = {};
        for (let state of this.contactsData) {
          //relation between boundaries and contacts data
          const stateKey: string = state.key;
          this.stagingStates[stateKey] = state;
        }
        const states = this.contactsData.map((state: any) => state.key); 
        await this.boundariesQuery(states).then(() => { 
          const stateSource = this.map.getSource('state') as mapboxgl.GeoJSONSource;
          if (stateSource) {
            stateSource.setData({
              type: "FeatureCollection",
              features: this.states,
            });
          }
  
          const stateCounterSource = this.map.getSource('state-counter') as mapboxgl.GeoJSONSource;
          if (stateCounterSource) {
            stateCounterSource.setData({
              type: "FeatureCollection",
              features: this.states.map((item) => {
                return {
                  type: "Feature",
                  ...item.properties.center,
                  properties: {
                    count: item.properties.count,
                    id: item.properties.id,
                  },
                };
              }), 
            });
          }
        }); 
      });
      
      if (this.map.getZoom() > this.zoomModesLimit ){
        this.stateFilter = auxiliarStateFilter
        // if is in cluster mode  reload the clusters 
        await this.updateClusters()
      } 
      this.map.triggerRepaint()
      this.addClustersMapEvents();
      this.isLoadingData = false
    }
  }

  handleOk(): void {
    this.isActive = false;
  } 

  handleCancel(): void {
    this.stateFilter = null;
    this.zoomModesLimit = 5.6;
    this.isActiveChange.emit();
  }

  saveFilterChange() {
    this.savedSearchActive = true;
  }
}
