import { Injectable } from '@angular/core';

@Injectable()
export class Settings {
	public static apiUrl(path?:string){
		var hostname = window.location.hostname;
		if('localhost' == hostname){
			return 'http://localhost:4200' + path;
		}

		return path;
	}
	public static baseUrl(path?:string){ 
		var hostname = window.location.hostname; 
		let url = ""; 
		if('localhost' == hostname){ 
			url =  'http://localhost/plan_professional/' + path; 
		}else if('testing.planprofessional.com' == hostname){ 
			url = '../' + path; 
		}else{ 
			url =  'http://localhost/plan_professional/' + path; 
		} 
		return url;
		//return url.replace(/\\\\/g, '\\').replace(/\/\//g, '\/'); 
	}
}