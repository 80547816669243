import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminDashboardService {

	constructor(
		private http: HttpClient
	) { }

    getActivity(extraData: any) {
        return this.http.post('/api/admin/activity', extraData);
    }

    getPlanStat(extraData: any) {
        return this.http.post('/api/admin/plan-stat', extraData);
    }

    getAdvizorStat(extraData: any) {
        return this.http.post('/api/admin/advizor-stat', extraData);
    }

    getDailyActivity() {
        return this.http.get('/api/admin/daily-activity');
    }
}
