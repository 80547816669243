import { Injectable, Injector, ApplicationRef, ComponentRef, Type, createComponent, EnvironmentInjector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService {
  private compRef: ComponentRef<any>;

  constructor(
    private injector: Injector,
    private appRef: ApplicationRef,
    private environmentInjector: EnvironmentInjector
  ) {}

  public injectComponent<T>(component: Type<T>, propertySetter?: (type: T) => void): HTMLDivElement {
    if (this.compRef) {
      this.compRef.destroy();
    }

    this.compRef = createComponent(component, {
      environmentInjector: this.environmentInjector,
      elementInjector: this.injector
    });

    if (propertySetter) {
      propertySetter(this.compRef.instance);
    }

    this.appRef.attachView(this.compRef.hostView);

    const div = document.createElement('div');
    div.appendChild(this.compRef.location.nativeElement);

    return div;
  }
}
