import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UserNotificationService {

	constructor(
		private http: HttpClient
	) { }

    getNotification() {
        return this.http.get('/api/user/notifications');
    }
    getAllNotification() {
        return this.http.get('/api/user/notifications/all');
    }
    getRead(ids) {        
        return this.http.post('/api/user/notifications/read', ids);
    }
    getCount() {
        return this.http.get('/api/user/notifications/count');
    }
    getAllCount() {
        return this.http.get('/api/user/notifications/all-count');
    }
    addNotification(data) {
        return this.http.post('/api/user/notifications', data);
    }
}
