
      <!-- BEGIN SIDEBAR MENU TOP TRAY CONTENT-->
      <div class="sidebar-overlay-slide from-top pace-done sidebar-visible " [class.show]="drawer" >
        <ng-template [ngTemplateOutlet]="sideBarOverlay"></ng-template>
      </div>
      <!-- END SIDEBAR MENU TOP TRAY CONTENT-->
      <!-- BEGIN SIDEBAR MENU HEADER-->
      <div class="sidebar-header">
        <ng-template [ngTemplateOutlet]="sideBarHeader"></ng-template>
      </div>
      <!-- END SIDEBAR MENU HEADER-->
      <!-- START SIDEBAR MENU -->
      <div class="sidebar-menu">
        <ng-template [ngTemplateOutlet]="menuItems"></ng-template>
        <!-- BEGIN SIDEBAR MENU ITEMS-->
        <div class="clearfix"></div>
      </div>
      <!-- END SIDEBAR MENU -->
