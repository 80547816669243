import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modalStateSubjects: { [key: string]: BehaviorSubject<boolean> } = {};

  constructor() {}

  public initModal(id: string): void {
    if (!this.modalStateSubjects[id]) {
      this.modalStateSubjects[id] = new BehaviorSubject<boolean>(false);
    }
  }

  public openModal(id: string): void {
    this.initModal(id);
    this.modalStateSubjects[id].next(true);
  }

  public closeModal(id: string): void {
    this.initModal(id);
    this.modalStateSubjects[id].next(false);
  }

  public getModalState(id: string): Observable<boolean> {
    this.initModal(id);
    return this.modalStateSubjects[id].asObservable();
  }

  public isModalOpen(id: string): boolean {
    this.initModal(id);
    return this.modalStateSubjects[id].value;
  }
}
