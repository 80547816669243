import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InsuranceProService {
    paginationData: {};
    search: {};

    constructor(private http: HttpClient) {
    }

    searchFirm(data:any) {
        return this.http.post('/api/insurance/searchFirm', data);
    }

    searchPerson(data:any) {
        return this.http.post('/api/insurance/searchPerson', data);
    }

    getSearchFilter() {
        return this.http.get('/api/insurance/search-filter');
    }

    getCompany(data: any) {
        return this.http.post('/api/insurance/company', data);
    }

    getCity(data: any) {
        return this.http.post('/api/insurance/city', data);
    }

    saveExportCheckBox(data) {
        return this.http.post('/api/user/advisor-export-checkbox', data);
    }

    getFirmProfile(firm_id: any) {
        this.http.get(`/api/insurance/firm-profile/${firm_id}`);
    }

    getPersonProfile(person_id: any) {
        return this.http.get(`/api/insurance/person-profile/${person_id}`);
    }
}
