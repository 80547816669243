<nz-modal
  nzCentered
  [(nzVisible)]="isOpen"
  nzWidth="500px"
  [nzContent]="bulkContent"
  [nzFooter]="bulkFooter"
  [(nzTitle)]="title"
  (nzOnCancel)="closeModal()"
>
  <ng-template #bulkContent>
    <div class="row mt-3">
      <ng-content></ng-content>
      <div class="col-md-12">
        <div class="custom-inputs">
          <label *ngIf="label" class="custom-input-label">{{ label }}</label>
          <textarea
            class="form-control"
            rows="5"
            [placeholder]="placeholder"
            [(ngModel)]="search[name]"
            [ngModelOptions]="{ standalone: true }"
            [name]="name"
          ></textarea>
        </div>
        <span><strong>Note:</strong> {{ note }}</span>
      </div>
    </div>
  </ng-template>
  <ng-template #bulkFooter>
    <div class="row mt-3">
      <div class="col-md-12">
        <button
          class="btn btn-rounded primary-btn-grn pull-right"
          type="button"
          (click)="executeSearch()"
          [disabled]="!search[name]"
        >
          Search
        </button>
      </div>
    </div>
  </ng-template>
</nz-modal>
