import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MapService {
    constructor(private http: HttpClient){

    } 

    public getStatesBoundaries(states: string[]){
        const url = '/api/geo/searchStatesBoundaries';
        const body = { states: states };
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
       });
    
        return this.http.post(url, body, { headers: headers });
    }
}
