
<ng-container [ngSwitch]="IconType">
    <svg
    class="feather"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    *ngSwitchCase="'fi'"
    >
        <use attr.xlink:href="assets/fonts/feather/feather-sprite.svg#{{IconName}}"/>
    </svg>
    <ng-container *ngSwitchCase="'letter'">
        {{IconName}}
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="IconName">
            <ng-container *ngSwitchCase="'addashboard'">
                <img src="/assets/img/addashboard.svg">
            </ng-container>
            <ng-container *ngSwitchCase="'ad_search_status'">
                <img src="/assets/img/ad_search_status.svg">
            </ng-container>
            <ng-container *ngSwitchCase="'ad_document_download'">
                <img src="/assets/img/ad_document_download.svg">
            </ng-container>
            <ng-container *ngSwitchCase="'ad_folder_open'">
                <img src="/assets/img/ad_folder_open.svg">
            </ng-container>
            <ng-container *ngSwitchCase="'ad_format_circle'">
                <img src="/assets/img/ad_format_circle.svg">
            </ng-container>
            <i *ngSwitchDefault class="{{IconType}} {{IconType}}-{{IconName}}"></i>
        </ng-container>
    </ng-container>
</ng-container>
