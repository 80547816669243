import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PlanService {
    paginationData: {};
    search: {};
    public _subject = new Subject<object>();
    public event = this._subject.asObservable();

    public _dashSubject = new Subject<any>();
    public dashEvent = this._dashSubject.asObservable();

    constructor(private http: HttpClient) { }

    public publish(data: any) {
        this._subject.next(data);
    }

    public dashPublish(data: any) {
        this._dashSubject.next(data);
    }

    getAutoSearch(data) {
        return this.http.post('/api/search/autocomplete', data);
    }

    saveSearch(data) {
        return this.http.post('/api/search/search-data', data);
    }

    saveAdvisorSearch(data) {
        return this.http.post('/api/search/advisor-search-data', data);
    }

    getSearch(data) {
        return this.http.post('/api/search', data);
    }
    getCompany(data) {
        return this.http.post('/api/company', data);
    }
    getUserCompany(data) {
        return this.http.post('/api/admin/users/company', data);
    }
    getCity(data) {
        return this.http.post('/api/city', data);
    }
    getPlan(data) {
        return this.http.post('/api/plan', data);
    }
    getLatestPlan(data) {
        return this.http.post('/api/latest-plan', data);
    }
    getRecordKeeper(data) {
        return this.http.post('/api/recordkeeper', data);
    }
    getAdvisor(data) {
        return this.http.post('/api/advisor', data);
    }
    getAuditor(data) {
        return this.http.post('/api/auditor', data);
    }

    getPlanType() {
        return this.http.get('/api/search/plan-types');
    }

    getTotalAssetsGroup() {
        return this.http.get('/api/search/total-assets-group');
    }

    getEligibleParticipantsGroup() {
        return this.http.get('/api/search/eligible-participants-group');
    }

    getIndustry() {
        return this.http.get('/api/search/industry');
    }

    getIndustryCategory() {
        return this.http.get('/api/search/industry_category');
    }

    getPlanFeatures() {
        return this.http.get('/api/search/plan-features');
    }
    getPlanFeaturesPost(data) {
        return this.http.post('/api/search/plan-features-post', data);
    }
    exportToAll(data) {
        return this.http.post('/api/search/export-all', data);
    }
    getYear() {
        return this.http.get('/api/search/year');
    }
    getState() {
        return this.http.get('/api/search/state');
    }
    searchFilter() {
        return this.http.get('/api/search/search-filter');
    }
    getProviderName(data) {
        return this.http.post('/api/provider-name', data);
    }
    getInvestmentManager(data) {
        return this.http.post('/api/investment-manager', data);
    }
    getRedFlags() {
        return this.http.get('/api/red-flags');
    }
    getServiceCodes() {
        return this.http.get('/api/service-codes');
    }
    getCategories() {
        return this.http.get('/api/category');
    }
    getCounty(data) {
        return this.http.post('/api/county', data);
    }
    getAffiliation(data) {
        return this.http.post('/api/advisor/affiliation', data);
    }
    getCustodian(data) {
        return this.http.post('/api/advisor/custodian', data);
    }
    getFirmName(data) {
        return this.http.post('/api/advisor/firm-name', data);
    }
    getTicker(data) {
        return this.http.post('/api/ticker', data);
    }
    getIssuer(data) {
        return this.http.post('/api/issuer', data);
    }

    getAssetClass() {
        return this.http.get('/api/asset-class');
    }
    getShareClass(data) {
        return this.http.post('/api/share-class', data);
    }
    updateExtractJob(data, id) {
        return this.http.put('/api/investment-pdf/extract-job/' + id, data);
    }
    getTitleContain(data) {
        return this.http.post('/api/advisor/title-contain', data);
    }
    saveExportCheckBox(data) {
        return this.http.post('/api/user/export-checkbox', data);
    }
    getExportCheckBox(data = { type: ''}) {
        return this.http.get('/api/user/export-checkbox', { params: { type: data?.type } });
    }
  
    getContactName(data) {
        return this.http.post('/api/conatct-name', data);
    }
    getContactTitle(data) {
        return this.http.post('/api/conatct-title', data);
    }
    firmAutocomplete(data) {
        return this.http.post('/api/advisor/firm-autocomplete', data);
    }
    personFirmAutocomplete(data) {
        return this.http.post('/api/advisor/person-firm-autocomplete', data);
    }
    sendNoPlanEmail(data) {
        return this.http.post('/api/search/send-no-plan-email', data);
    }
}
