<!-- WRAP LAYOUT IF BOXED -->
<div class="container" *ngIf="_boxed; else basicLayoutBlock">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
    <!-- <pg-sidebar *ngIf="profileActive"> -->
    <pg-sidebar *ngIf="!activeNewProfile">
        <ng-template #sideBarOverlay>

        </ng-template>
        <ng-template #sideBarHeader>
          <div class="sidebar-header-controls col-lg-12" style="padding-left: 0px; padding-top: 5px;">
            <a [routerLink]="['/dashboard' ]"><img src="assets/img/advizor-new-logo.svg" alt="logo" pgRetina
                  src1x="assets/img/advizor-new-logo.svg" src2x="assets/img/advizor-new-logo.svg" width="190px"></a>

            <a class="h-logo-icon" [class]="_menuPin?'h-logo-icon-unpin':'h-logo-icon-pin'" [routerLink]="['/dashboard' ]"><img src="assets/img/pl-logo-icon.png" alt="logo" pgRetina src1x="assets/img/pl-logo-icon.png" src2x="assets/img/pl-logo-icon.png" width="auto" height="22"></a>
          </div>
        </ng-template>
        <ng-template #menuItems>
            <span *ngIf="!user.has_access_advisor && !user.has_access_insurance && !user.has_access_family_office" class="btn mr-3 btn-link d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none menu-pin-top"
              data-toggle-pin="sidebar" (click)="toggleMenuPin()"><i class="fa fs-16"></i>
            </span>
            <!-- (click)="menuChange($event)" -->
            <pg-menu-items *ngIf="!isScrollMenu" [Items]="menuLinks"></pg-menu-items>
            <ul id="mainNav" *ngIf="isScrollMenu && isScrollMenuType=='in-firm' && user.has_access_insurance"
                class="menu-items p-t-25 ps firm-profile">
                <li><a [pageScrollOffset]="70" pageScroll
                        [routerLink]="['/insurance/firm/'+ firm_id]" href="#investment_advisor_profile"
                        class="active">Basic Info</a></li>
            </ul>
            <ul id="mainNav" *ngIf="isScrollMenu && isScrollMenuType=='firm' && user.has_access_advisor"
                class="menu-items p-t-25 ps firm-profile">
                <li *ngFor="let item of advisorMenuLinks.firm">
                  <a [pageScrollOffset]="70" pageScroll [routerLink]="[item.link]" href="{{item.id}}">{{item.label}}</a>
                </li>
            </ul>
            <ul id="mainNavProfile" *ngIf="isScrollMenu && isScrollMenuType=='person' && user.has_access_advisor"
                class="menu-items p-t-25 ps firm-profile">
                <li><a [pageScrollOffset]="70" pageScroll [routerLink]="['/advisor/person/'+person_id]"
                        href="#basic_info" class="active">Basic Info</a></li>
                <li *ngFor="let item of advisorMenuLinks.person">
                  <a [pageScrollOffset]="70" pageScroll [routerLink]="['/advisor/person/'+person_id]" href="{{item.id}}">{{item.label}}</a>
                </li>
                <li><a [pageScrollOffset]="70" pageScroll [routerLink]="['/advisor/person/'+ person_id]"
                        href="#notes_tasks">Notes & Tasks</a></li>
            </ul>

            <ul *ngIf="isScrollMenu && isScrollMenuType=='in-person' && user.has_access_insurance"
                class="menu-items p-t-25 ps firm-profile">
                <li><a [pageScrollOffset]="70" pageScroll [routerLink]="['/insurance/person/'+person_id]"
                        href="#basic_info" class="active">Basic Info</a></li>
                <li *ngFor="let item of advisorMenuLinks.insurance"><a [pageScrollOffset]="70" pageScroll [routerLink]="['/insurance/person/'+person_id]" href="{{item.id}}">{{item.label}}</a></li>
            </ul>

            <ul *ngIf="isScrollMenuType=='admin-view' || isScrollMenuType=='admin' || isScrollMenuType=='client'"
                class="menu-items p-t-25 ps firm-profile">
                <!--<li routerLinkActive="active"><a [routerLink]="['/admin/organizations']">Organizations</a></li>-->
                <li routerLinkActive="active" *ngIf="user?.is_admin"><a [routerLink]="['/admin/dashboard']">Dashboard<span class="icon-thumbnail text-white"><i class="fas fa-tachometer-alt"></i></span></a></li>
                <li routerLinkActive="active" *ngIf="user?.is_admin"><a [routerLink]="['/admin/users']">Users<span class="icon-thumbnail text-white"><i class="fas fa-users"></i></span></a></li>
                <li routerLinkActive="active" *ngIf="!user?.is_admin && user?.org_admin"><a [routerLink]="['/client/admin']">Users<span class="icon-thumbnail text-white"><i class="fas fa-users"></i></span></a></li>
                <li routerLinkActive="active" *ngIf="user?.is_admin"><a [routerLink]="['/admin/firm-logo-review']">Firm Logo Review<span class="icon-thumbnail text-white"><i class="fa fa-building" aria-hidden="true"></i></span></a></li>
                <!--<li routerLinkActive="active"><a [routerLink]="['/admin/reports']">Reports</a></li>-->
            </ul>

            <ul *ngIf="isScrollMenuType=='profile'" class="menu-items p-t-25 ps firm-profile">
              <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/profile/'+ack_id]">Overview <span class="icon-thumbnail text-white"><i class="fas fa-file-invoice"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/contacts']">Contacts <span class="icon-thumbnail text-white"><i class="fas fa-phone-alt"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/benchmark']">Benchmarking <span class="icon-thumbnail text-white"><i class="fas fa-chart-line"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/fund-analysis']">Investments <span class="icon-thumbnail text-white"><i class="fas fa-hand-holding-usd"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/fees-analysis']">Fees <span class="icon-thumbnail text-white"><i class="fas fa-money-check-alt"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/diversification']">Diversification <span class="icon-thumbnail text-white"><i class="fas fa-file-invoice-dollar"></i></span></a></li>
              <li routerLinkActive="active" (click)="accessMenu()"><a [class.disabled]="user.basic_access ? true : null" [routerLink]="['/profile/'+ack_id+'/notes-and-tasks']">Notes and Tasks <span class="icon-thumbnail text-white"><i class="far fa-file-alt"></i></span></a></li>
            </ul>
            <button *ngIf="!user.has_access_advisor && !user.has_access_insurance && !user.has_access_family_office" (click)="supportPopup()" class="btn btn-complete btn-rounded btn-cons support-btn">Help</button>
            <img *ngIf="user.has_access_advisor || user.has_access_insurance || user.has_access_family_office" src="assets/img/divider.svg" class="support-btn-hr" alt="hr">
            <!-- <img *ngIf="user.has_access_advisor || user.has_access_insurance || user.has_access_family_office" src="assets/img/question-help.svg" (click)="supportPopup()" class="support-btn-call" alt="Help" title="Help"> -->
            <img *ngIf="user.has_access_advisor || user.has_access_insurance || user.has_access_family_office" src="assets/img/message-question.svg" (click)="supportPopup()" class="support-btn-call" alt="Help" title="Help">
        </ng-template>
    </pg-sidebar>

    <page-container [ngClass]="{'c-new-firm-page advisor-profile':activeNewProfile}">
        <pg-header [boxed]="_boxed" [extraClass]="notificationDrawer?'header-index':''">
            <!-- START MOBILE SIDEBAR TOGGLE -->
            <a href="javascript:void(0);" class="btn-link toggle-sidebar d-lg-none pg pg-menu"
                (click)="toggleMobileSidebar()"></a>
            <!-- END MOBILE SIDEBAR TOGGLE -->
            <div class="container-fluid header-container">
              <div class="row align-items-center justify-content-between m-0">
                <ng-container *ngIf="activeNewDashboard; else nonNewDash">
                    <div class="" *ngIf="pageLabel">
                        <label class="bold fs-16 m-0">{{ pageLabel }}</label>
                    </div>
                    <ng-container *ngIf="activeNewProfile">
                      <div class="row adv-search-header">
                        <div class="col-lg-4 pl-0 d-flex align-items-center">
                          <a href="javascript:void(0)" (click)="redirectByPageName('dashboard')">
                            <img src="assets/img/advizor_pro_logo_white.png" alt="logo" pgRetina
                                  src1x="assets/img/advizor_pro_logo_white.png" src2x="assets/img/advizor_pro_logo_white.png" width="190px">
                          </a>
                        </div>
                      </div>
                    </ng-container>

                    <div class="c-search-header" *ngIf="!adminView">
                      <i class="pg pg-search pg-search-icon hint-text" *ngIf="topSearchActive"></i>
                      <div class="form-group typehead new-search m-0" *ngIf="topSearchActive" (click)="changeTypeaheadLoading(false)">
                        <div class="multiple-control cus-multiple-control c-adv-search">
                          <input [(ngModel)]="asyncSelected" [typeaheadAsync]="true" [typeahead]="dataSource"
                            (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                            [typeaheadOptionsLimit]="10" [typeaheadItemTemplate]="typeaheadItemTemplate"
                            (typeaheadNoResults)="changeTypeaheadNoResults($event)" [placeholder]="'Search people, advisors, and firms...'"
                            class="form-control search-bar search-bar-cursor c-adv-search-autocomplete" id="inputSearch" type="search"
                            autocomplete="off">
                      
                          <ng-template #typeaheadItemTemplate let-model="item" let-index="index">
                            <div class="row py-1">
                              <div class="col-1-5">
                                <div class="circle-adv line-h-21" style="display: grid">
                                  <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                    'advizor': model?.advizor,
                                    'broker': model?.broker,
                                    'insurance': model?.insurance && user.has_access_insurance,
                                    'fo': model?.fo_id && user.has_access_family_office,
                                    'bt': model?.bt_id && user.has_access_bank_and_trust,
                                    'dc': model?.dc && user.has_access_defined_contribution,
                                    'db': model?.db && user.has_access_defined_benefit,
                                  })" />
                                </div>
                              </div>
                              <div class="col-6 r-bor pl-0">
                                <div class="row">
                                  <div class="col-12">
                                    <div class="col-12 pl-0 bold lh-25">
                                      <span class="fs-12" style="white-space: normal;" *ngIf="!model?.type"><b>{{model.plan_sponsor }} </b></span>
                                      <span class="fs-12" style="white-space: normal;" *ngIf="model?.type"> {{ model.name
                                        }} </span>
                                    </div>
                                    <div class="col-12 pl-0 lh-25" *ngIf="!model?.type">
                                      <span class="fs-12" style="white-space: normal;">{{ model.plan_name }}</span>
                                    </div>
                                    <div class="col-12 pl-0 lh-25">
                                      <span class="fs-10" style="white-space: normal;"> {{
                                        model.city_state }} </span>
                                    </div>
                                    <div class="col-12 pl-0 lh-25">
                                      <span *ngIf="model.txt_crd" class="fs-10 inline" style="white-space: normal;">CRD: {{ model.txt_crd
                                        }}{{(model.txt_npn ?
                                        ',':'')}}&nbsp;</span>
                                      <span *ngIf="model.txt_npn" class="fs-10 inline" style="white-space: normal;">NPN: {{ model.txt_npn
                                        }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4 r-item">
                                <div class="row pl-2" *ngIf="!model?.type">
                                  <div class="col-12 pl-0 lh-25" *ngIf="model?.total_assets">
                                    <i class="fas fa-dollar-sign color-grn mr-2"></i>
                                    <span class="inline fs-12" style="white-space: normal;">{{ (model?.total_assets |
                                      currency:'USD':'symbol':'1.0-2')+' M'
                                      }}</span>
                                    <!-- <span class="inline fs-12" style="white-space: normal;" *ngIf="!model?.total_assets">$0 M</span> -->
                                  </div>
                                  <div class="col-12 pl-0 lh-25" *ngIf="model?.total_participants">
                                    <i class="far fa-user-circle text-danger mr-1"></i>
                                    <span class="inline fs-12" style="white-space: normal;">{{ model?.total_participants }}</span>
                                  </div>
                                </div>
                                <div class="row pl-2" *ngIf="model.type=='firm'">
                                  <div class="col-12 pl-0 lh-25" *ngIf="model?.aum">
                                    <i class="fas fa-dollar-sign color-grn mr-2"></i>
                                    <span class="inline fs-12" style="white-space: normal;">{{ (model?.aum |
                                      currency:'USD':'symbol':'1.0-2')+' M'
                                      }}</span>
                                    <!-- <span class="inline fs-12" style="white-space: normal;" *ngIf="!model?.aum">$0 M</span> -->
                                  </div>
                                  <div class="col-12 pl-0 lh-25" *ngIf="model.persons">
                                    <i class="far fa-user-circle text-danger mr-1"></i>
                                    <span class="inline fs-12" style="white-space: normal;"> {{
                                      model.persons | number : 0 }} </span>
                                  </div>
                                </div>
                                <div class="row pl-2" *ngIf="model.type=='person'">
                                  <div class="col-12 pl-0 firm-bld lh-25" *ngFor="let team of model.team">
                                    <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                      'team': true
                                    })" [iconExtraClass]="'team-person-auto'" />
                                    <span class="d-inline fs-12" style="white-space: normal;"> {{ team | uppercase }}
                                    </span>
                                  </div>
                                  <div class="col-12 pl-0 firm-bld lh-25" *ngFor="let frm of model.firm_name">
                                    <i class="far fa-building text-primary mr-2" *ngIf="frm"></i>
                                    <span class="d-inline fs-12" style="white-space: normal;"> {{ frm }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                        <h3 *ngIf="isScrollMenuType == 'profile'" class="bold plan_title">{{planName}}</h3>
                      </div>
                    </div>
                </ng-container>

                <ng-template #nonNewDash>
                    <div class="col-sm-8 col-md-8" [ngClass]="[ isScrollMenuType == 'profile' && !follows_data ? 'col-lg-9' : 'col-lg-8' ]">
                        <!-- <ng-container *ngIf="activeNewSearch">
                          <div class="row adv-search-header">
                              <div class="col-lg-4 pl-0 d-flex align-items-center">
                                  <label class="bold fs-16">AdvizorPro Search</label>
                              </div>
                          </div>
                        </ng-container> -->
                        <i class="pg pg-search pg-search-icon m-t-10" *ngIf="topSearchActive"></i>
                        <div class="form-group typehead search-link d-lg-inline-block m-t-10 col-8" *ngIf="topSearchActive" (click)="changeTypeaheadLoading(false)">
                            <input
                            [(ngModel)]="asyncSelected"
                            [typeaheadAsync]="true"
                            [typeahead]="dataSource"
                            (typeaheadLoading)="changeTypeaheadLoading($event)"
                            (typeaheadOnSelect)="typeaheadOnSelect($event)"
                            [typeaheadOptionsLimit]="10"
                            [typeaheadItemTemplate]="typeaheadItemTemplate"
                            (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                            class="form-control search-bar mw-185 font-montserrat col-12 search-bar-cursor"
                            id="inputSearch"
                            type="search" autocomplete="off">
                            <span class="placeholder font-montserrat" (click)="hidePlaceholder()"
                                *ngIf="!this.asyncSelected"></span>
                            <div class="typeahead-loading font-montserrat" *ngIf="typeaheadLoading">Loading...</div>

                            <ng-template #typeaheadItemTemplate let-model="item" let-index="index">
                                <span (click)="preEvent($event, model)">
                                <span class="font-montserrat" *ngIf="!model?.type"><strong>{{model.plan_sponsor }} </strong> -
                                    {{ model.plan_name }}</span>
                                <span class="font-montserrat" *ngIf="model?.type">
                                    <div class="circle-adv line-h-21 mr-1">
                                      <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                        'advizor': model?.advizor,
                                        'broker': model?.broker,
                                        'insurance': model?.insurance && user.has_access_insurance,
                                        'fo': model?.fo_id && user.has_access_family_office,
                                        'bt': model?.bt_id && user.has_access_bank_and_trust,
                                        'dc': model?.dc && user.has_access_defined_contribution,
                                        'db': model?.db && user.has_access_defined_benefit,
                                      })" />
                                    </div>
                                    <strong>{{model.advisor_name }} </strong>
                                    <span *ngIf="model?.city"> - {{ model.city }} </span><span *ngIf="model?.state"> - {{ model.state }}</span> - {{ model.crd }}
                                </span>
                                </span>
                            </ng-template>
                        </div>
                        <h3 *ngIf="isScrollMenuType == 'profile'" class="bold plan_title">{{planName}}</h3>
                    </div>
                </ng-template>

                <div class="align-self-center" [ngClass]="[ isScrollMenuType == 'profile' && !follows_data ? '' : '' ]">
                    <div class="d-flex align-items-center justify-content-end" *ngIf="(profileActive || adminView) && !isScrollMenu">
                        <!-- START User Info-->
                        <!-- <div class="pull-left p-r-10 fs-14 font-heading d-lg-block d-none">
                        </div> -->

                        <!--<button class="btn-xs btn mr-2 primary-btn-blue btn-rounded btn-align pd-sup" (click)="supportPopup()" type="button">Support</button>-->

                        <span class="notification c-noti-icon" (click)="openDrawer()">
                            <i class="fa-bell ml-1 fs-16 cur-pointer" [ngClass]="[ user.has_access_advisor || user.has_access_insurance || user.has_access_family_office ? 'far' : 'fa']"></i>
                            <span class="badge" *ngIf="user_notification_count">{{user_notification_count}}</span>
                        </span>

                        <nz-drawer [nzVisible]="notificationDrawer" [nzWidth]="400" nzPlacement="right" nzTitle="Notifications" (nzOnClose)="closeDrawer()">
                            <ng-container *nzDrawerContent>
                                <span *ngFor="let item of notificationList">
                                    <div>
                                        <span class="d-flex justify-content-between align-items-center">
                                            <b class="text-primary">{{item?.subject | titlecase}}</b>
                                            <small class="fw-600 grey-3">{{dateFormat(item?.created_at)}}</small>
                                        </span>
                                        <div [innerHtml]="item?.message  | noSanitize"></div>
                                    </div>
                                    <hr>
                                </span>
                                <span style="display: table;margin: 0 auto;" *ngIf="notificationList.length == 0">No Notifications</span>
                            </ng-container>
                        </nz-drawer>

                        <div class="dropdown d-lg-block d-none" dropdown>
                            <button class="profile-dropdown-toggle p-0" type="button" dropdownToggle id="profileDropdown">
                                <span class="semi-bold user-section">
                                    <span class="user-img">
                                      <i class="user-initial">{{ getUserInitials() }}</i>
                                    </span>
                                    <span class="user-name">
                                        {{user?.name}}
                                    </span>
                                    <span class="user-dropdown">
                                        <i class="fa fa-angle-down fs-16"></i>
                                    </span>
                                </span>
                            </button>

                            <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
                                <ng-container *ngIf="!user?.basic_access || user?.basic_access_advizor =='0'">
                                    <a href="javascript:void(0)" (click)="profilePopup()" class="dropdown-item">
                                        <i class="far fa-user fs-16"></i> Profile
                                    </a>
                                </ng-container>

                                <ng-container *ngIf="activeAdvSetting && (user?.has_access_advisor || user?.has_access_insurance || user?.has_access_family_office)">
                                    <a href="javascript:void(0)" (click)="advSearchSetting()" class="dropdown-item">
                                        <i class="fa fa-cog fs-16"></i> Settings
                                    </a>
                                </ng-container>

                                <ng-container *ngIf="existUser?.name">
                                    <a href="javascript:void(0)" class="dropdown-item" (click)="resumeUser()">
                                        <i class="far fa-user fs-16"></i> Resume
                                    </a>
                                </ng-container>

                                <ng-container>
                                    <a href="javascript:void(0)" *ngIf="user?.is_admin" class="dropdown-item" (click)="redirectByPageName('admin/users')">
                                        <i class="far fa-user fs-16"></i> Admin
                                    </a>
                                    <a href="javascript:void(0)" class="dropdown-item" *ngIf="!user?.is_admin && user?.org_admin" (click)="redirectByPageName('client/users')">
                                        <i class="far fa-user fs-16"></i> Admin
                                    </a>
                                </ng-container>
                                <a href="javascript:void(0)" (click)="onLoggedout()" class="clearfix bg-master-lighter dropdown-item">
                                    <span class="pull-left">Sign Out</span>
                                    <span class="pull-right"><i class="fs-13 pg pg-power"></i></span>
                                </a>
                            </div>
                        </div>
                        <!-- END User Info-->
                        <!-- <a href="javascript:void(0)" class="header-icon pg pg-alt_menu btn-link m-l-10 sm-no-margin d-inline-block" (click)="openQuickView($event)"></a> -->
                  </div>
                  <div class="d-flex align-items-center pull-right" *ngIf="isScrollMenuType == 'profile'">
                    <div class="mr-2" style="display: inline-block;" *ngIf="follow_status">
                      <span class="mr-2 follow-status badge">{{follow_status}}</span>
                    </div>

                    <div class="mr-2 mt-1" style="display: inline-block;">
                      <a href="javascript:void(0)" (click)="onFollow()" [title]="'Follow Plan'" style="font-family: Montserrat; font-weight: bold;"
                        class="pull-right btn btn-xs " [ngClass]="{'primary-btn-grey': follows_data, 'primary-btn-grn' : !follows_data}">{{
                        (follows_data)?'Unfollow':'Follow'}}</a>
                    </div>

                    <div class="mr-2 mt-2">

                      <a *ngIf="!user.basic_access" style="cursor: pointer;background: none;" (click)="openPDFModel()" class="pdf-download">
                        <i class="far fa-file-pdf pdf-fs" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div class="profile-cus-dropdown dropdown mt-1" dropdown *ngIf="!profileActive && !adminView">
                        <button class="btn profile-dropdown-toggle primary-btn-grn btn-rounded w-125 btn-align" style="font-family: Montserrat;" type="button"
                            dropdownToggle>Action</button>
                        <div *dropdownMenu class="dropdown-menu profile-dropdown" role="menu">
                            <a class="dropdown-item" href="javascript:void(0)" (click)="ViewDownload('short')"><i class="fas fa-download"></i> Download 5500</a>
                            <ng-container *ngIf="!user?.basic_access">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="settingsPopup()"><i class="fas fa-cog"></i> Settings</a>
                            </ng-container>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </pg-header>
        <!-- <div [ngClass]="{'page-content-wrapper':profileActive}" class="{{_pageContainerClass}}"> -->
        <div class="page-content-wrapper {{_pageContainerClass}}">
            <!-- START PAGE CONTENT -->
            <div class="pt-0 {{_contentClass}}" [ngClass]="{'content':!activeNewProfile}">
                <router-outlet></router-outlet>
            </div>
            <!-- END PAGE CONTENT -->
            <ng-template [ngIf]="_footer">
                <!-- START COPYRIGHT -->
                <div class="container-fluid footer">
                    <div class="copyright sm-text-center">
                        <div class="small d-flex align-items-center justify-content-between">
                            <div>
                                <span class="hint-text">Copyright &copy; {{current_year}}</span>&nbsp;<span class="hint-text">PlanPro Solutions LLC.</span>&nbsp;<span class="hint-text">All rights reserved.</span>
                            </div>
                            <div>
                                <span class="sm-block">
                                    <a class="m-l-10 m-r-10" href="https://planprofessional.com/terms-and-conditions/" target="_blank">Terms of use</a>
                                    <span class="muted">|</span>
                                    <a class="m-l-10" href="https://planprofessional.com/privacy-policy/" target="_blank">Privacy Policy</a>
                                </span>
                            </div>
                        </div>

                        <!-- <p class="small no-margin pull-right sm-pull-reset">
              Hand-crafted <span class="hint-text">&amp; made with Love</span>
            </p> -->

                        <div class="clearfix">
                        </div>
                    </div>
                </div>
                <!-- END COPYRIGHT -->
            </ng-template>
        </div>
    </page-container>
    <!-- <app-search-overlay></app-search-overlay> -->
    <app-quickview></app-quickview>
</ng-template>


<nz-modal nzCentered [(nzVisible)]="header_basic_access" nzWidth="600px" [nzContent]="headerBasicContent"
  [nzFooter]="null" nzTitle="Upgrade to Premium" (nzOnCancel)="headerBasicContentCancel()">
  <ng-template #headerBasicContent>
    <p>A premium subscription is required to view this page</p>
    <p class="btnlink"><a href="https://planprofessional.com/request-demo/" target="_blank" class="btn btn-md btn-primary btn-success">Learn More</a></p>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="supportModal" nzWidth="820px" [nzContent]="supportContent" 
 [nzFooter]="null" [nzStyle]="{ borderTopLeftRadius: '14px', borderTopRightRadius: '14px', overflow: 'hidden' }"
 [nzTitle]="modalTitle" (nzOnCancel)="supportContentCancel()">`
  <ng-template #modalTitle>
    <div class="custom-modal-title" style="
      position: absolute;
      left: 0; top: 0;
      background: linear-gradient(67.42deg, #8167CE 8.17%, #CAB9FB 87.91%);
      min-width: 100%;
      width: 100%;
      height: 10%;
      padding: 0;
      margin: 0">
      <span style="
        position: absolute; 
        margin-top: 2%; 
        margin-left: 2%; 
        color: white;
        font-size: 1.2rem;"
      >
        AdvizorPro Support
      </span>
    </div>
  </ng-template>
  <ng-template #supportContent>
    <div class="flex-column flex-row-modifier">
      <div >
        <div class=" support-data">

          <div class="support-call">
            <ul>
              <li>
                <p><a href="https://support.advizorpro.com/en/advizorpro-knowledge-base" target="_blank"><i class="fas fa-check-circle"></i></a> Check out our knowledge base <b><a href="https://support.advizorpro.com/en/advizorpro-knowledge-base" target="_blank">here</a></b></p>
              </li>
            </ul>
          </div>                                                                                                                                                                                                                         
          <div class="support-call">
            <ul>
              <li>
                <p><a href="https://survey.hsforms.com/11P_5M3nIR6ScX27j2T96zg35c71" target="_blank"><i class="fas fa-comment-dots"></i></a> Send us your feedback <a href="https://survey.hsforms.com/11P_5M3nIR6ScX27j2T96zg35c71" target="_blank"><b>here</b> </a></p>
              </li>
            </ul>
          </div>
          <div class="support-message">
            <ul>
              <li>
                <p > <i class="fas fa-comment-dots"></i> Send support a message </p>
              </li>
            </ul>
          </div>
          <div class=" mb-2">
            <div class="mb-2">
              <input type="text" class="form-control" name="phone" [(ngModel)]="support_data.phone" required  placeholder="How can we reach you?"/>
            </div>
            <div>
              <textarea  class="form-control" placeholder="How can we assist you?" [(ngModel)]="support_data.message" rows="6"></textarea>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 text-right">
                <button type="button" class="mt-3 btn  primary-btn-grn" (click)="sendSupportMessage()"
                  [disabled]="!support_data.message">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="
      position: relative;
      min-width: 1rem;
      height: 450px;
      margin-left: 3%;
      margin-right: 3%;
      display: flex;
      justify-content: center;
      align-items: center;">
  </div>
      <app-ai-search-chat></app-ai-search-chat>
    </div>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="profileModal" nzWidth="800px" [nzContent]="profileModalContent"
  [nzFooter]="profileModalFooter" nzTitle="User Profile" (nzOnCancel)="profileModalCancel()"
  (nzOnOk)="updateUserData()">
  <ng-template #profileModalContent>
    <div class="row">
      <div class="col-xl-6 mb-2" [class.has-error]="invalidProfileField.indexOf('first_name') != -1">
        <label class="pull-left">First Name *</label>
        <input type="text" class="form-control" name="first_name" [(ngModel)]="profile_data.first_name"
          required />
        <label class="error" *ngIf="invalidProfileField.indexOf('first_name') != -1">This field is
          required.</label>
      </div>
      <div class="col-xl-6 mb-2" [class.has-error]="invalidProfileField.indexOf('last_name') != -1">
        <label class="pull-left">Last Name *</label>
        <input type="text" class="form-control" name="last_name" [(ngModel)]="profile_data.last_name" required />
        <label class="error" *ngIf="invalidProfileField.indexOf('last_name') != -1">This field is
          required.</label>
      </div>
      <div class="col-xl-6 mb-2">
        <label class="pull-left">Email</label>
        <input type="text" class="form-control" name="email" [(ngModel)]="profile_data.email" disabled />
      </div>
      <div class="col-xl-6 mb-2" [class.has-error]="invalidProfileField.indexOf('phone') != -1">
        <label class="pull-left">Cell Phone Number *</label>
        <input type="text" class="form-control" name="phone" [(ngModel)]="profile_data.phone"
          #profilePhone="ngModel" required />
        <label class="error" *ngIf="invalidProfileField.indexOf('phone') != -1">This field is required.</label>
      </div>

      <div class="col-xl-6 mb-2" [class.has-error]="invalidProfileField.indexOf('title') != -1">
        <label class="pull-left">Title *</label>
        <input type="text" class="form-control" name="title" [(ngModel)]="profile_data.title" />
        <label class="error" *ngIf="invalidProfileField.indexOf('title') != -1">This field is required.</label>
      </div>
      <div class="col-xl-6 mb-2" [class.has-error]="invalidProfileField.indexOf('company') != -1">
        <label class="pull-left">Company *</label>
        <app-autocomplete [(ngModel)]="profile_data.company" [getValue]="true" [callback]="company_autocomplete"
          name="company" placeholder="Company">
        </app-autocomplete>
        <label class="error" *ngIf="invalidProfileField.indexOf('company') != -1">This field is required.</label>
      </div>
      <div class="col-xl-12 mb-2" [class.has-error]="invalidProfileField.indexOf('company_logo') != -1">
        <label class="pull-left">Upload Company Logo *</label>
        <input type="text" readonly class="form-control col-lg-12" name="company_logo"
          [(ngModel)]="profile_data.company_logo" />
        <div class="col-lg-12 mt-2 border">
          <pg-upload Type="select" Limit="1" Name="upfiledata"
            Action="{{'/api/profile/company_logo?id='+profile_data.id}}" extraClass="dropzone"
            (Change)="profileFileChange($event)" progressType="circle">
            <div class=" d-flex flex-column align-items-center">
              <h4 class="semi-bold no-margin">Upload Logo</h4>
            </div>
          </pg-upload>
        </div>
      </div>
      <div class="col-xl-12 mt-3 mb-2">
        <a class="text-primary" href="javascript:void(0)"
          (click)="profile_data.is_password_update = !profile_data.is_password_update">Change Password</a>
      </div>
      <div class="col-xl-12 mb-2">
        <div class="card p-3" *ngIf="profile_data.is_password_update">
          <span><i>**Password should contain 8 or more characters and must be a combination of the following (1
              uppercase, 1 lowercase, 1 special character, 1 number)</i></span>
          <div class="row">
            <div class="col-xl-6 mt-2 mb-2" [class.has-error]="invalidProfileField.indexOf('password') != -1">
              <label class="pull-left">Password</label>
              <input type="password" class="form-control" name="password" [(ngModel)]="profile_data.password" (ngModelChange)="checkPasswordStrength($event)"/>
              <label class="error" *ngIf="invalidProfileField.indexOf('password') != -1">Password must be contain
                combination as per above mention.</label>
              <div class="m-t-10">
                <pg-progress class="password-progress" [type]="'bar'" color="{{ strengthColor }}" value="{{progress}}" thick="true">
                </pg-progress>
              </div>
            </div>
            <div class="col-xl-6 mt-2 mb-2"
              [class.has-error]="invalidProfileField.indexOf('confirm_password') != -1">
              <label class="pull-left">Repeat Password</label>
              <input type="password" class="form-control" name="confirm_password"
                [(ngModel)]="profile_data.confirm_password" (ngModelChange)="checkPassword()" />
              <label class="error" *ngIf="invalidProfileField.indexOf('confirm_password') != -1">Passwords do not
                match.</label>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #profileModalFooter>
    <div class="row">
      <div class="col-xl-12 mb-2">
        <button type="button" class="btn btn-cons" (click)="profileModalCancel()">Close</button>
        <button id="add-app" type="button" class="btn btn-primary  btn-cons" [disabled]="profilePhone?.invalid"
            (click)="updateUserData()">Update</button>
      </div>
    </div>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="waitingBox" nzWidth="600px" [nzContent]="waitingBoxContent" [nzFooter]="null" (nzOnCancel)="waitingBoxContentCancel()">
  <ng-template #waitingBoxContent>
    <h4 class="p-b-5">{{downloadMessage.status_message}}</h4>
    <div class="mr-3 mb-4">
      <div *ngIf="downloadMessage.status; else erroIcon">
        <nz-progress [nzPercent]="percent_complete" nzStatus="active"></nz-progress>
      </div>
      <ng-template #erroIcon>
        <i class="fa fa-exclamation-triangle d-flex justify-content-center fa-2x text-danger " aria-hidden="true"></i>
      </ng-template>
    </div>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="limit_over_model" nzWidth="600px" [nzContent]="limitContent"
  [nzFooter]="null" nzTitle="Download Limit Reached" (nzOnCancel)="limitContentCancel()">
  <ng-template #limitContent>
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="text-primary">Your report creation limit has been reached for this month. It will reset on
            the first day of next month.</p>
      </div>
    </div>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="follow_type_model" nzWidth="600px" [nzContent]="followContent"
  [nzFooter]="followContentFooter" nzTitle="Please Choose Follow Type" (nzOnCancel)="followContentCancel()"
  (nzOnOk)="onFollowType()">
  <ng-template #followContent>
    <div class="row">
      <div class="col-md-12">
        <h5>Select Status</h5>
        <pg-select style="width: 100%;" [Mode]="'single'" [PlaceHolder]="'Select'" [(ngModel)]="follow_status_type"
          [NotFoundContent]="'Not found'" name="follow_status_type">
          <pg-option *ngFor="let option of statusList" [Label]="option" [Value]="option">
          </pg-option>
        </pg-select>
      </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-3 form-group">
            <input type="radio" name="follow_type" [(ngModel)]="user_follow_type" value="private">
            <label for="private_label" style="margin-left:0.5rem;">Private</label>
        </div>
        <div class="col-lg-3 form-group">
            <input type="radio" name="follow_type" [(ngModel)]="user_follow_type" value="firm">
            <label for="firm_label" style="margin-left:0.5rem;">Firm</label>
        </div>
    </div>
  </ng-template>
  <ng-template #followContentFooter>
    <div class="row">
      <div class="col-lg-12 m-t-20">
          <p class="pull-right">
              <button type="button" class="btn btn-cons btn-rounded primary-btn-grn"
                  (click)="onFollowType()">Save</button>
          </p>
      </div>
    </div>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isUploadTicker" nzWidth="500px" [nzContent]="uploadContent" [nzFooter]="uploadFooter"
  nzTitle="Upload Ticker List" (nzOnCancel)="uploadTickerCancel()" (nzOnOk)="uploadTickerOk()">
  <ng-template #uploadContent>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-transparent flex-row">
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="custom-inputs">
                <label class="custom-input-label">Ticker List</label>
                <textarea class="form-control" rows="5" placeholder="Upload Ticker"
                  [(ngModel)]="fee_analysis.upload_ticker" [ngModelOptions]="{standalone: true}"
                  name="upload_ticker"></textarea>
              </div>
              <span><strong>Note:</strong> Please add Ticker with comma or space.</span>
            </div>
            <div class="col-md-12">
              <button class="btn btn-rounded primary-btn-grn pull-right  mt-3" type="button" (click)="searchTicker();">
                Search </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #uploadFooter>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isCustomLineuModel" nzWidth="500px" [nzContent]="customLineupContent"
  [nzFooter]="customLineupFooter" nzTitle="Custom Lineup Name" (nzOnCancel)="customLineupCancel()"
  (nzOnOk)="customLineupOk()">
  <ng-template #customLineupContent>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-transparent flex-row">
          <div class="row mt-3">
            <div class="col-lg-12">
              <label class="pull-left">Lineup Name</label>
              <input type="text" class="form-control" name="custom_lineup_name"
                [(ngModel)]="settings_data.custom_lineup_name" />
            </div>
            <div class="col-lg-12 m-t-20">
              <p class="pull-right">
                <button type="button" class="btn btn-cons btn-rounded primary-btn-grn" (click)="saveLineupName()"
                  [disabled]="save_search || loading_search">Save</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #customLineupFooter>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isVisible" nzWidth="800px" [nzContent]="modalContent" [nzFooter]="modalFooter"
  nzTitle="Fee Analysis Settings" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-template #modalContent>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-transparent flex-row">
          <nz-tabset class="vertical-tab" [nzTabPosition]="'left'">
            <nz-tab nzTitle="Global" (nzSelect)="settingTabSelect('global')">
              <div class="row column-seperation explorer-rows">
                <div class="col-lg-6 mt-2 mb-3">
                  <label>Alternative must have >= 3 Year Return </label>
                </div>
                <div class="col-lg-4 mt-2 mb-3">
                  <input [(ngModel)]="settings_data.outperform_3yr" id="outperform_3yr" type="checkbox"
                    class="form-check-input ml-1">
                </div>

                <div class="col-lg-6 mt-3">
                  <label>Alternative minimum correlation </label>
                </div>
                <div class="col-lg-4 mt-3">
                  <div *ngIf="sliderTooltip">
                    <nz-slider [nzMin]="0" [nzMax]="1" [nzStep]="0.01" [(ngModel)]="settings_data.min_cor"
                      [nzTooltipVisible]="'always'">
                    </nz-slider>
                  </div>
                </div>
                <div class="col-lg-6 mt-2">
                  <label>Exclude ETFs </label>
                </div>
                <div class="col-lg-4 mt-2">
                  <pg-select [PlaceHolder]="'Exclude ETFs'" style="width: 100%;" [(ngModel)]="settings_data.ETF"
                    [ngModelOptions]="{standalone: true}">
                    <pg-option *ngFor="let option of ['Yes', 'No']" [Label]="option" [Value]="option"></pg-option>
                  </pg-select>
                </div>
                <div class="col-lg-6 mt-2">
                  <label> Assume revenue sharing equal to 12b-1 </label>
                </div>
                <div class="col-lg-4 mt-2">
                  <input [(ngModel)]="settings_data.twelve_bone" type="checkbox" class="form-check-input ml-1">
                </div>
                <div class="col-lg-12 m-t-20">
                  <p class="pull-right">
                    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn"
                      (click)="saveGlobalSettings()">Save</button>
                  </p>
                </div>
              </div>
            </nz-tab>
            <nz-tab nzTitle="Custom Lineups" (nzSelect)="settingTabSelect('custom')">
              <div class="row explorer-rows-package">
                <div class="col-lg-4 pull-left">
                  <pg-select [PlaceHolder]="'Provider Universe'" style="width: 100%;"
                    [(ngModel)]="settings_data.provider_universe" (ngModelChange)="lineupChange($event)"
                    [ngModelOptions]="{standalone: true}">
                    <pg-option *ngFor="let option of settings_data.customLineups" [Label]="option" [Value]="option">
                    </pg-option>
                  </pg-select>
                </div>

                <div class="col-lg-8 pull-right">
                  <div class="form-group">
                    <div class="form-group typehead search-link d-lg-inline-block d-none pull-right">
                      <div class="input-group transparent mr-5">
                        <input placeholder="Search for a ticker, firm or product" [hidden]="!openAch"
                          (ngModelChange)='onCustomSettingSearch($event)' type="text" [(ngModel)]="fee_analysis.keyword"
                          [ngModelOptions]="{standalone: true}" class="form-control">
                        <div class="input-group-append sponsor-ein-upd" [hidden]="!openAch">
                          <span class="input-group-text transparent"><a href="javascript:void(0);"
                              (click)="uploadTickerModel()" class="upd-crd"><i class="fas fa-cloud-upload-alt"
                                aria-hidden="true"></i></a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <i *ngIf="openAch" class="pg pg-search pull-right p-search"></i>
                  </div>
                </div>
                <div class="col-lg-12">
                  <form class="p-1">
                    <div class="row" *ngIf="openAch">
                      <div class="col-md-3 ml-3 mr-2">
                        <div class="form-group">
                          <input [checked]="this.isDisplaySelected" id="checkbox_curr" type="checkbox" value="1"
                            (change)="displaySelected()" class="form-check-input"> <label class="form-check-label"
                            for="checkbox_curr">Currently Selected</label>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <input [checked]="isDSelectedAll" id="checkboxn" type="checkbox" value="1"
                            class="form-check-input" (click)="selectSearchList('selall')"> <label
                            class="form-check-label" for="checkboxn">Select
                            All</label>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive" *ngIf="openAch">
                      <table class="table table-hover custom-lineup-table" *ngIf="!loading_search">
                        <thead>
                          <th class="form-group-checkbox" *ngIf="!isDisplaySelected">
                            <input type="checkbox" #select_all_checkbox
                              [checked]="settings_data.ticker != undefined && settings_data.ticker.length==fee_analysis_result.length || isDSelectedAll"
                              (click)="selectSearchList('all', select_all_checkbox)" id="css">
                            <label id="css" (click)="select_all_checkbox.click()"></label>
                          </th>
                          <th class="form-group-checkbox" *ngIf="isDisplaySelected"> &nbsp;
                          </th>
                          <th>Ticker</th>
                          <th>Investment</th>
                          <th>Type</th>
                        </thead>
                        <tbody *ngIf="!isDisplaySelected && fee_analysis_result.length">
                          <tr *ngFor="let result of fee_analysis_result">
                            <td class="form-group-checkbox">
                              <input type="checkbox" value="result.Symbol" (click)="selectSearchList(result)"
                                [checked]="settings_data.ticker && settings_data.ticker.indexOf(result.Symbol) !== -1 || isDSelectedAll">
                              <label (click)="selectSearchList(result)"></label>
                            </td>
                            <td>{{ result.Symbol }}</td>
                            <td>{{ result.fund_name }}</td>
                            <td>{{ result.Type }}</td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="!isDisplaySelected && !fee_analysis_result.length">
                          <tr>
                            <td colspan="4" class="center">Investment option is not found.
                            </td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="isDisplaySelected && fee_analysis_result_selected?.length ">
                          <tr *ngFor="let result of fee_analysis_result_selected">
                            <td class="form-group-checkbox">
                              <input type="checkbox" value="result.Symbol" (click)="selectSearchList(result)"
                                [checked]="settings_data.ticker.indexOf(result.Symbol) !== -1">
                              <label (click)="selectSearchList(result, $event)"></label>
                            </td>
                            <td>{{ result.Symbol }}</td>
                            <td>{{ result.fund_name }}</td>
                            <td>{{ result.Type }}</td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="isDisplaySelected && !fee_analysis_result_selected?.length">
                          <tr>
                            <td colspan="4" class="center">You have not select any
                              investment options.</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="padding-15 " *ngIf="!isDisplaySelected">
                        <pg-pagination class="pull-right" (PageIndexClickChange)="paginate($event)"
                          [Total]="fee_analysis_result_total" [PageIndex]="fee_analysis?.page"
                          [PageSize]="fee_analysis?.limit">
                        </pg-pagination>
                      </div>
                      <div class="padding-15 " *ngIf="isDisplaySelected">
                        <pg-pagination class="pull-right" (PageIndexClickChange)="paginateSettings($event)"
                          [Total]="fee_analysis_result_selected_total"
                          [PageIndex]="fee_analysis_result_selected_request?.page"
                          [PageSize]="fee_analysis_result_selected_request?.limit">
                        </pg-pagination>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-12">
                  <p class="m-3 semi-bold" *ngIf="!openAch">To add custom lineup for fee analysis,
                    please select "Add User
                    Selected" from the dropdown menu.</p>
                </div>
                <br>
                <div class="col-lg-12">
                  <p class="pull-right">
                    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn"
                      *ngIf="settings_data.ticker!=undefined && settings_data.ticker.length && openAch"
                      [disabled]="save_search || loading_search" (click)="selectSearchList('clear')">Clear Saved
                      Selections</button>&nbsp;
                    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn" (click)="saveSettings()"
                      [disabled]="save_search || loading_search">Save</button>
                  </p>
                </div>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="create_report" nzWidth="500px" [nzContent]="reportContent"
  [nzFooter]="null" nzTitle="Create Plan Report" (nzOnCancel)="reportContentCancel()"
  (nzOnOk)="createPdfReport(createreport)">
  <ng-template #reportContent>
      <form name="create_report" id="create_report">
          <div class="form-group">
            <div class="row">
              <label>PDF Report Name</label>
              <input type="text" class="form-control col-lg-12" name="pdf_report_name"
                [(ngModel)]="createreport.pdf_report_name" />
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="sections">SELECT PDF SECTIONS TO EXPORT</label>
            </div>
            <div class="row pl-2">
              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="overview" [(ngModel)]="createreport.overview" id="overview_checkbox">
                  <label for="overview_checkbox">Overview</label>
                </div>
              </div>

              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="contact" [(ngModel)]="createreport.contact" id="contact_checkbox">
                  <label for="contact_checkbox">Contacts</label>
                </div>
              </div>

              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="Benchmarking" [(ngModel)]="createreport.benchmarking"
                    id="bench_checkbox">
                  <label for="bench_checkbox">Benchmarking</label>
                </div>
              </div>
            </div>

            <div class="row pl-2">
              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="fee" [(ngModel)]="createreport.fee" id="fee_checkbox">
                  <label for="fee_checkbox">Fees</label>
                </div>
              </div>
              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="investment" [(ngModel)]="createreport.investment" id="inv_checkbox">
                  <label for="inv_checkbox">Investments</label>
                </div>
              </div>

              <div class="col-4 no-padding">
                <div class="checkbox">
                  <input type="checkbox" name="diversification" [(ngModel)]="createreport.diversification"
                    id="diver_checkbox">
                  <label for="diver_checkbox">Diversification</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label>Company</label>
              <input type="text" class="form-control col-lg-12" name="company" [(ngModel)]="createreport.company" />
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label>Name</label>
              <input type="text" class="form-control col-lg-12" name="name" [(ngModel)]="createreport.name" />
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label>Email Address</label>
              <input type="text" class="form-control col-lg-12" name="email" [(ngModel)]="createreport.email" />
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-9 profile-logo">
                <pg-upload Type="select" Limit="1" Name="upfiledata"
                  Action="{{'/api/profile/company_logo?id='+createreport.id}}" extraClass="dropzone"
                  (Change)="FileChange($event)" progressType="circle">
                  <div class="align-items-center">
                    <label #companyUpload>Upload Your Company Logo <i class="ml-1 cur-pointer fas fa-cloud-upload-alt"></i></label>
                  </div>
                </pg-upload>
              </div>
              <div class="col-3" *ngIf="createreport.company_logo">
                <label class="pull-right">
                  <a class="text-danger" href="javascript:void(0);" (click)="deleteLogo('company')"><i class="far fa-trash-alt"></i></a>
                </label>
              </div>
              <div *ngIf="createreport.company_logo" style="width: 400px;">
                <img [src]="createreport.company_logo" class="img-scale-down" />
              </div>


            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-9 profile-logo">
                <pg-upload Type="select" Limit="1" Name="upClientfiledata" Action="{{'/api/profile/client_logo?id='+plan_sponsor_ein}}"
                extraClass="dropzone" (Change)="ClientFileChange($event)" progressType="circle">
                  <div class="align-items-center">
                    <label>Upload Prospect Logo <i #clientUpload class="ml-1 cur-pointer fas fa-cloud-upload-alt"></i> </label>
                  </div>
                </pg-upload>
              </div>
              <div class="col-3" *ngIf="createreport.client_logo">
                <label class="pull-right">
                  <a class="text-danger" href="javascript:void(0);" (click)="deleteLogo('client')"><i class="far fa-trash-alt"></i></a>
                </label>
              </div>

              <div *ngIf="createreport.client_logo" style="width: 400px;">
                <img [src]="createreport.client_logo" class="img-scale-down" />
              </div>

            </div>
          </div>
          <div class="form-group">
            <label>Add note to recipient</label>
            <quill-editor [(ngModel)]="createreport.notes" name="notes" id="ckeditorid" [modules]="modules" theme="snow"></quill-editor>
          </div>
          <button type="submit" [disabled]="loading" (click)="createPdfReport(createreport)"
            class="btn btn-rounded primary-btn-grn btn-block create-repo">Create Report</button>
          <div *ngIf="pdf_link" class="down-repo mt-2">
            <a href="{{pdf_link}}" target="_blank" class="btn btn-success" download>Download Report</a>
          </div>
          <pg-progress *ngIf="loading" type="circle" indeterminate="true" thick="true"></pg-progress>
        </form>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="downloadview" nzWidth="600px" [nzContent]="downloadContent"
  [nzFooter]="null" [nzTitle]="downloadTitle" (nzOnCancel)="downloadContentCancel()">
  <ng-template #downloadTitle>
      Download 5500

      <pg-select class="ml-4" style="width: 40%;" [PlaceHolder]="'Year'" [NotFoundContent]="'Not found'" name="year"
        [(ngModel)]="years.ackId" [Mode]="'single'" (ngModelChange)="doDownload($event)">
        <pg-option *ngFor="let option of planYears" [Label]="option.year" [Value]="option.ack_id"
          selected="ack_id == option.ack_id">
        </pg-option>
      </pg-select>
  </ng-template>
  <ng-template #downloadContent>
      <div *ngIf="loadingMessage">
        <div class="row">
          <div class="col-md-12 ml-3">
            <b class="text-success pl-2">File is downloading...</b>
          </div>
        </div>
      </div>
      <pg-progress *ngIf="loading" type="circle" indeterminate="true" thick="true"></pg-progress>
      <div *ngIf="!loading && download_plan_data.length">
        <div class="row" *ngFor="let form of download_plan_data">
          <div class="col-md-12">
            <a href="{{form.url}}" target="_blank" download>{{form.readable_type}}</a>
          </div>
        </div>
      </div>
  </ng-template>
  <ng-template #downloadContentFooter>
  </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="isUserNotificationModel" nzWidth="500px" [nzContent]="notificationContent"
  [nzFooter]="notificationFooter" nzTitle="{{open_user_notification.subject | titlecase }}" (nzOnCancel)="userNotificationCancel(open_user_notification.id)"
  (nzOnOk)="userNotificationOk(open_user_notification.id)">
  <ng-template #notificationContent>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-transparent flex-row">
          <div class="row">
            <div class="col-lg-12">
              <div [innerHtml]="open_user_notification.message | noSanitize"  ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #notificationFooter>
    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn" (click)="userNotificationOk(open_user_notification.id)">Ok</button>
  </ng-template>
</nz-modal>
