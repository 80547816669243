<div class="centered-spinner minimun-size"> 
        <!-- <div *ngIf="contactsList.length > 0 && firmsList.length===0"> --> 
        
        <nz-spin  class= "centered-spinner" [nzSpinning]="isLoadingData" [nzSize]="'large'"> 
            <div class="minimun-size">
                <div  class="contacts-count">{{contactsCounter}} CONTACTS NEARBY</div>
                <div class="center-content card-container "> 
                        <profile-contact-card 
                            *ngFor="let contact of sliceContactsData()"
                            [dbaContact]="toDbaContact(contact)" 
                        />
                </div> 
                <div class="center-content flex pagination-container">
                    <pg-pagination *ngIf="contactsPagination.total > contactsPagination.per_page"  
                        (PageIndexClickChange)="contactsHandlePagination($event)"
                        [Total]="contactsPagination?.total" [PageIndex]="contactsPagination?.current_page"
                        [PageSize]="contactsPagination?.per_page" pageScroll="" 
                        [ShowTotal]="false"
                        [ShowSizeChanger]="false"
                        [ShowQuickJumper]="false">
                    </pg-pagination > 
                </div>
            </div> 
        </nz-spin>
        <!--
        <div *ngIf="contactsList.length === 0 && firmsList.length > 0 && false">
            <div class="row center-content">
                <div *ngFor="let firm of firmsPagination.data">
                    <firm-card class="contact-card" [dba]="toDbaFirm(firm.properties)"></firm-card>
                </div>
                <pg-pagination class="pull-right mt-2 custom-pg-pagination" *ngIf="true"
                    (PageIndexClickChange)="firmsHandlePagination($event)"
                    [Total]="firmsPagination?.total" [PageIndex]="firmsPagination?.current_page"
                    [PageSize]="firmsPagination?.per_page ">
                </pg-pagination>
            </div>
        </div>

        <nz-tabset *ngIf="contactsList.length > 0 && firmsList.length > 0 && false" class="horizontal-tab">
            <nz-tab nzTitle="Contacts">
                <div class="row center-content">
                    <div *ngFor="let contact of contactsPagination.data">
                        <contact-card class="contact-card" [dbaContact]="toDbaContact(contact.properties)"></contact-card>
                    </div>
                    <pg-pagination class="pull-right mt-2 custom-pg-pagination" *ngIf="true"
                        (PageIndexClickChange)="contactsHandlePagination($event)"
                        [Total]="contactsPagination?.total" [PageIndex]="contactsPagination?.current_page"
                        [PageSize]="contactsPagination?.per_page">
                    </pg-pagination>
                </div>
            </nz-tab>
            <nz-tab nzTitle="Firms">
                <div class="row center-content">        
                    <div *ngFor="let firm of firmsPagination.data">
                        <firm-card class="contact-card" [dba]="toDbaFirm(firm.properties)"></firm-card>
                    </div>
                    <pg-pagination class="pull-right mt-2 custom-pg-pagination" *ngIf="true"
                        (PageIndexClickChange)="firmsHandlePagination($event)"
                        [Total]="firmsPagination?.total" [PageIndex]="firmsPagination?.current_page"
                        [PageSize]="firmsPagination?.per_page">
                    </pg-pagination>
                </div>
            </nz-tab>
        </nz-tabset>
        -->
</div>
