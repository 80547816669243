<ng-container *ngIf="insights?.length > 0">
    <div class="row m-0 mb-3">
        <div class="col-md-12 new-firm-update">
            <div class="new-firm-update-header">
                <img class="ant-modal-animation" src="assets/img/insight.gif">
                <span class="c-title"><b>{{title}}</b></span>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <ul class="pl-3 m-0 ul-2-columns">
                        <li class="fs-12 fw-400" *ngFor="let row of insights; let i = index" [innerHtml]="row.insight"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <hr class="w-100" />
</ng-container>