import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';

import { SecondarySidebarComponent } from './secondary-sidebar/secondary-sidebar.component';

// import { QuickviewService} from './quickview/quickview.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ParallaxDirective } from './parallax/parallax.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FormGroupDefaultDirective } from './forms/form-group-default.directive';
import { ViewDirective } from './view/view.directive';

import { pgCollapseModule } from './collapse/collapse.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ContainerComponent } from './container/container.component';
import { pageContainer } from './container/page-container.component';
import { RouterModule } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { MenuAltComponent } from './menu/menu-alt.component';
import { MenuIconComponent } from './menu/menu-icon.component';

import { ListItemComponent} from './list-view/list-item/list-item.component';
import { ListViewContainerComponent } from './list-view/list-view-container/list-view-container.component';
import { pgRetinaDirective } from './retina/retina.directive';
//import { pgTabsModule } from './tabs/tabs.module';

import { pgSelectModule } from './select/select.module';
// import { pgSliderModule } from './slider/slider.module';
import { pgTagModule } from './tag/tag.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { QuillModule } from 'ngx-quill';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { BooleanOrDefaultPipe, TitleCasePipe, CurrencyMillionPipe, CurrencyOrDefaultPipe, PercentOrDefaultPipe, ValueOrDefaultPipe } from '../../shared/pipes/formatting-pipes';
import { Title } from '@angular/platform-browser';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    ObserversModule,
    TypeaheadModule.forRoot(),
    PerfectScrollbarModule,
    RouterModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule
  ],
  declarations: [
    CurrencyMillionPipe,
    TitleCasePipe,
    CurrencyOrDefaultPipe,
    ValueOrDefaultPipe,
    PercentOrDefaultPipe,
    BooleanOrDefaultPipe,
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective
  ],
  exports: [
    CurrencyMillionPipe,
    TitleCasePipe,
    CurrencyOrDefaultPipe,
    ValueOrDefaultPipe,
    PercentOrDefaultPipe,
    BooleanOrDefaultPipe,
    SecondarySidebarComponent,
    ParallaxDirective,
    BreadcrumbComponent,
    FormGroupDefaultDirective,
    ViewDirective,
    pgCollapseModule,
    NzTabsModule,
    //pgTabsModule,
    // pgSliderModule,
    NzSliderModule,
    pgSelectModule,
    ContainerComponent,
    pageContainer,
    MenuComponent,
    MenuAltComponent,
    MenuIconComponent,
    ListItemComponent,
    ListViewContainerComponent,
    pgRetinaDirective,
    pgTagModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    NzModalModule,
    NzDrawerModule,
    NzProgressModule,
    NzCollapseModule,
    QuillModule,
    NzRadioModule,
    NzTimelineModule
  ],
  providers:[
    CurrencyPipe,
    // QuickviewService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]

})
export class SharedModule { }
