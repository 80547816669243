import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lead-score-badge',
  templateUrl: './lead-score-badge.component.html',
  styleUrls: ['./lead-score-badge.component.scss']
})
export class LeadScoreBadgeComponent {
  @Input("lead-score-type") leadScore: string
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  getBadgeClasses(): string {
    return `badge size-${this.size}`;
  }

  getIconSize(): number {
    const sizes = {
      sm: 11,
      md: 13,
      lg: 18
    };
    return sizes[this.size];
  }

  constructor(){}
}
