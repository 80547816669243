<div
  *ngIf="loading; else elseBlock"
  class="col align-self-center d-flex justify-content-center pt-4 pb-4"
>
  <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
</div>
<ng-template #elseBlock>
  <div class="row" class="content-fund-analysis pt-1 pb-4">
    <div class="col-sm-12 p-0">
      <h4 class="fs-12 bold m-0 mb-3">Fund Analysis</h4>
    </div>
    <div class="col-lg-9 p-0 mb-3">
      <p class="fs-12">
        This Plan has
        <span
          class="label label-success p-t-5 ml-1 p-b-5 inline fs-12 mr-1"
          *ngIf="
            viewData?.summary?.Weak_Count !== undefined ||
            viewData?.summary?.Weak_Count !== null
          "
          >{{ viewData?.summary?.Weak_Count ?? '0' }}</span
        >
        funds with poor fiduciary ratings due to issues with performance, fees,
        size or track record. Replacing low rated funds could provide
        participants with lower investment risk.
      </p>
    </div>
    <div class="col-sm-12 p-0 mb-4">
      <h4 class="d-inline fs-12 bold">Current Plan Investments</h4>
      <br />
      <div class="table-responsive">
        <ngx-datatable
          class="table table-hover"
          [rows]="viewData?.data"
          [columnMode]="columnModeSetting"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="1000"
          [messages]="messages"
          routeTransformer
          [scrollbarH]="scrollBarHorizontal"
          [externalSorting]="true"
          (sort)="onSort($event)"
        >
          <ngx-datatable-column
            [width]="65"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
            name="Ticker"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [width]="190"
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            [canAutoResize]="false"
            name="Fund"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [width]="110"
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            [canAutoResize]="false"
            name="Current Value"
            cellClass="d-flex align-items-center fs-12"
            prop="value"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span>
                {{ row?.value | currency : "USD" : "symbol" : "1.0-2" }}</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [width]="100"
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            [canAutoResize]="false"
            name="Category"
            cellClass="d-flex align-items-center fs-12"
          ></ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="Track Record"
            cellClass="d-flex align-items-center fs-12"
            prop="track_record"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span> {{ row?.track_record }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="Fund Size"
            cellClass="d-flex align-items-center fs-12"
            prop="fund_size"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span> {{ row?.fund_size }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="Expenses"
            cellClass="d-flex align-items-center fs-12"
            prop="expense_ratio"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span> {{ row?.expense_ratio }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="3-Year Return"
            cellClass="d-flex align-items-center fs-12"
            prop="three_year_return"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span> {{ row?.three_year_return }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="3-Year Sharpe Ratio"
            cellClass="d-flex align-items-center fs-12"
            prop="three_year_sharpe_ratio"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span> {{ row?.three_year_sharpe_ratio }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="true"
            [draggable]="false"
            [resizeable]="false"
            name="Fiduciary Rating"
            cellClass="d-flex align-items-center fs-12"
            prop="rating"
          >
            <ng-template
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span class="large-text grade-text grade-{{ row?.rating }}">{{
                row?.rating
              }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <div class="col-sm-12 p-0">
      <p class="fs-12">
        Fiduciary ratings are based on 6 metric to help determine if an
        investment option meets a minimum fiduciary standard of care. The
        metrics include track record, expense ratio, 3-year return and 3-year
        sharpe ratio. Funds are ranked against their category peers, which
        determines their ratings. Ratings are standerdized on an A - F grading
        scale with A being the best.
      </p>
    </div>
    <div class="col-sm-12 p-0">
      <p class="fs-12">
        This analysis does not constitute professional investment advice and is
        provided solely for informational purposes. Past performance does not
        guarantee future results.
      </p>
    </div>
  </div>
</ng-template>
