
	<div class="ai-search-modal" >
		<div class="ai-body demo-card-scrollable" #chatContainer>
			<div *ngFor="let message of messages">
				<div class="ai-reciver-side" *ngIf="message.role == 'assistant'">
					<div class="message-row">
						<div class="message-avatar">
							<img src="assets/img/ai-chat-bot.png" alt="ai-search" class="ai-chat-icon-user" />
						</div>
						<div class="message">
							<span  *ngIf="message?.content" [innerHTML]="boldFormat(message?.content)"></span>
							<div class="references" *ngIf="message?.references">
								<span>References:</span>
								<br>
								<ul>
								  <li *ngFor="let ref of message?.references">
									<a [href]="getReferenceLink(ref)" target="_blank">{{ getReferenceName(ref)}}</a>
								  </li>
								</ul>
							</div>
						</div>
					</div>
					<div class="message-options">
						<span *ngFor="let option of message.options" class="msg-option" (click)="selectOption($event)">{{ option }}</span>
					</div>
				</div>
				<div class="ai-sender-side" *ngIf="message.role == 'user'">
					<div class="message-row">
						<span class="message" [innerHTML]="boldFormat( message?.content)"></span>
						<div class="message-avatar">
							<img src="assets/img/ai-chat-user.png" alt="ai-search" class="ai-chat-icon-user" />
						</div>
					</div>
				</div>
			</div>
			<div class="ai-reciver-side" *ngIf="isLoading">
				<div class="message-row">
					<div class="message-avatar">
						<img src="assets/img/ai-chat-bot.png" alt="ai-chat-bot" class="ai-chat-icon-user" />
					</div>
					<span class="message msg-loading">
						<img src="assets/img/ai-loading.gif" alt="ai-loading" class="ai-chat-loading" />
					</span>
				</div>
			</div>
		</div>

		<div style="width: 100%; height: 5%; min-height: 46px; position: relative;">
			<div class="ai-footer">
				<pre>{{searchQuery}}<br></pre>
				<textarea #chatTextarea type="text" class="ai-search-input" placeholder="Ask a question about our platform ..." [(ngModel)]="searchQuery" (keydown.enter)="onKeydown($event)" (keyup.enter)="submitInput()"></textarea>
				<i class="fa fa-arrow-right" *ngIf="!searchQuery"></i>
				<i class="fa fa-arrow-right purple-arrow" *ngIf="searchQuery" (click)="submitInput()"></i>
			</div>
		</div>
	</div>
