<div class="table-responsive">
    <ngx-datatable class='table table-hover table-v2' [rows]="providers" [columnMode]="columnModeSetting"
        [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="scrollBarHorizontal"
        [externalPaging]="true" [count]="providers?.length" [externalSorting]="true">

        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center address-location"
            [sortable]="false" prop="provider_name" name="Name">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.provider_name | uppercase}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false"
            prop="provider_role" name="Role">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.provider_role | uppercase}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false" prop="year"
            name="Year">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.year}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                <div class="pagination-v2">
                    <div class="page-count p-0">
                        <span class="page-total">
                            {{ rowCount?.toLocaleString() }} total entries
                        </span>
                    </div>
                    <datatable-pager class="custom-datatable-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                        [hidden]="!((rowCount / pageSize) > 1)">
                    </datatable-pager>
                </div>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>
</div>