import { Component, OnInit, Input, Output, EventEmitter, forwardRef, TemplateRef, HostListener } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PlanService } from '../../services/plan.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AdvizorProService } from '../../services/advizor-pro.service';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers : [
    {
      provide : NG_VALUE_ACCESSOR,
      useExisting : forwardRef(()=> AutocompleteComponent),
      multi : true
    }
  ]
})
export class AutocompleteComponent implements OnInit,ControlValueAccessor {

  dataSource: Observable<any>;
  elastic_search: any = [];
  private _model: any;
  isDisabled: boolean;
  isEnterDisabled = true;
  public get model(): any {

    return this._model;
  }
  public set model(value: any) {
    this.onChange(value);
    this._model = value;
  }

  @Input('typeaheadOptionField') typeaheadOptionField:any;
  @Input('autoSelectionData') autoSelectionData: any = [];
  @Input('name') name:any = "autocomplete";
  @Input('placeholder') placeholder:any = "Start typing for search";
  @Input('typeaheadOptionsLimit') typeaheadOptionsLimit:any = 30;
  @Input('callback') callback:any;
  @Input('disable') disable = false;
  @Input('getValue') getValue = false;
  @Input('removeValue') removeValue = false;
  @Input('isMultiple') isMultiple = false;
  @Input('isEnter') isEnter = false;
  @Input('displayBy') displayBy: any = (e: any) => e;
  @Input('typeaheadItemTemplate') typeaheadItemTemplate:TemplateRef<any>;
  @Input() isSearchButtonVisible = false;
  @Input() isSearchButtonLoading = false;
  @Input() searchIconClass = 'fa fa-search';
  @Input() searchButtonPrimaryColor = '#8A919C';
  @Input() searchButtonSecondaryColor = '#133c5c';
  searchButtonColor: string;
  @Output('typeaheadOnSelect') typeaheadSelect:EventEmitter<any> = new EventEmitter();
  @Output('typeaheadOnBlur') typeaheadBlur:EventEmitter<any> = new EventEmitter();
  @Output('typeaheadOnMultipleSelect') typeaheadMultipleSelect:EventEmitter<any> = new EventEmitter();
  @Output('searchButtonClicked') readonly searchButtonClicked: EventEmitter<string> = new EventEmitter();
  @Input() iconTitle = "Searching...";
  typeaheadLoading = false;
  chips: Array<string> = [];
  
  constructor(
    public planService: PlanService,
    public advizorProService: AdvizorProService,
  ) {
    this.dataSource = new Observable((observer: any) => {
      observer.next(this.model);
    }).pipe(
      debounceTime(500),
      mergeMap((token: string) => {
        return this.getStatesAsObservable(token || '');
      })
    )

    this.advizorProService.openSearchSubject$.subscribe((data) => {
      if (data) {
        this.typeaheadLoading = false;
      }
    });

    this.planService.event.subscribe(() => {
      this.chips = [];
    });
  }

  ngOnInit() {
    this.searchButtonColor = this.searchButtonPrimaryColor;
    if (this.autoSelectionData) {
      this.chips = this.autoSelectionData;
      this.typeaheadMultipleSelect.emit(this.chips);
    }
  }

  onChange = (_value: string | null) => { /* empty method */ }
  onTouched = () => {}

  writeValue(obj: string | null): void {
    this.model = obj;
    this.onChange(this.model);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  getStatesAsObservable(token: string): Observable<any> {
    // this.chips = [];
    /* if ((token).length < 3) {
      return of(false);
    } */

    this.isEnterDisabled = false;

    if (typeof this.callback == 'object'){
      this.elastic_search = this.callback;
      return of(this.callback);
    }else{
      return this.callback(token)
    }
  }

  changeTypeaheadLoading(e: boolean): void {
      this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    if (this.isEnterDisabled && this.isEnter) {
      this.model = '';
    } else {
      this.typeaheadSelect.emit(e);

      if (this.getValue) {
        this.model = this.displayBy(e.value);
      } else {
        this.model = this.displayBy(e);
      }

      if (this.removeValue) {
        this.model = '';
      }
    }
  }

  preEvent($event) {
    $event.stopPropagation();

    var index = this.chips.indexOf($event.target.value);
    if ($event.target.checked) {
      if (index == -1) {
        this.chips.push($event.target.value);
      }
    } else if (!$event.target.checked) {
      if (index > -1) {
        this.chips.splice(index, 1);
      }
    }
    this.typeaheadMultipleSelect.emit(this.chips);

    this.model = '';
  }

  checkiItem(item) {
    var index = this.chips.indexOf(item);
    if (index > -1) {
      this.chips.splice(index, 1);
      return true;
    }
    return false;
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.isEnterDisabled = true;
      this.typeaheadBlur.emit(this.model);
    }
  }

  onSearchClick() {
    this.searchButtonClicked.emit(this.model);
  }

  onMouseEnter() {
    this.searchButtonColor = this.searchButtonSecondaryColor;
  }

  onMouseLeave() {
    this.searchButtonColor = this.searchButtonPrimaryColor;
  }
}
