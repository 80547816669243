import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-saved-search-selector',
  templateUrl: './saved-search-selector.component.html',
  styleUrls: ['./saved-search-selector.component.scss']
})
export class SavedSearchSelectorComponent {
  @Input() savedSearcheOptions: string[] = [];
  @Input() selectedSaved: number;
  @Output() readonly searchChange = new EventEmitter<number>();
  
  changeSavedSearch(event): void {
    this.searchChange.emit(event);
  }
}