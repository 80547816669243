import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'

import { DbaContact } from '../../interfaces/dba.interface'
import { getIconsByRowKeys } from '../../utils/icon-utils'
import { Icon } from '../icon-dataset/icons'
import { Auth } from '../../services/auth.service'

@Component({
	selector: 'contact-card',
	templateUrl: './contact-card.component.html',
	styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent implements AfterViewInit {
	@Input() dbaContact: DbaContact | null = null
	@Input() toolTipIconOrnament: {} = {}
	@ViewChild('locationTitle', { static: false }) locationTitle: ElementRef;

	isTextOverflowing = false;
	onlyInsuranceAccess = false;
	user = {};

	constructor(
		public auth: Auth,
	) {}

	public getInitials(name: string | null): string {
		if (!name) return ''
		const words: string[] = name.split(' ')
		return words
			.slice(0, 1)
			.map((word) => word.charAt(0).toUpperCase())
			.join('')
	}

	getIcons(row: Record<string, unknown>): Icon[] {
		return getIconsByRowKeys(row);
	}
	
	ngAfterViewInit() {
		this.user = this.auth.getUser();
		this.checkTextOverflow();
	}

	checkTextOverflow() {
		const element = this.locationTitle.nativeElement;
		this.isTextOverflowing = element.scrollWidth > element.clientWidth;
	}

	getLinkType() {
		let linkType = [];
		if (this.onlyInsuranceAccess) {
			linkType = ['/insurance/person', this.dbaContact.npn];
		} else if (this.dbaContact?.person_crd && !this.onlyInsuranceAccess) {
			linkType = ['/advisor/person', this.dbaContact.person_crd];
		} else if (this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.onlyInsuranceAccess) {
			linkType = ['/insurance/person', this.dbaContact.npn];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && this.dbaContact?.fo_id) {
			linkType = ['/family/person', this.dbaContact.fo_id];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && this.dbaContact?.bt_id) {
			linkType = ['/bank/person', this.dbaContact.bt_id];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.dbaContact?.bt_id && this.dbaContact?.db) {
			linkType = ['/db/person', this.dbaContact.db];
		} else if (!this.dbaContact?.npn && !this.dbaContact?.person_crd && !this.dbaContact?.bt_id && this.dbaContact?.dc) {
			linkType = ['/dc/person', this.dbaContact.dc];
		}

		return linkType;
	}
}
