import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SalesForceService {

    constructor(
        private http: HttpClient
    ) { }

    getAccessToken(data) {
        return this.http.post('/api/salesforce/token', data);
    }
    disconnect() {
        return this.http.delete('/api/salesforce');
    }

    getSaveImport() {
        return this.http.get('/api/integration/import');
    }
    submitImport(data) {
        return this.http.post('/api/integration/', data);
    }
    getIntegration() {
        return this.http.get('/api/integration/integration');
    }
    getActivity(data) {
        return this.http.post('/api/integration/crm-activity', data);
    }
    getLogDetail(data) {
        return this.http.post('/api/integration/activity-log-detail', data);
    }
    submitToken(data) {
        return this.http.post('/api/integration/integration', data);
    }
    getUserSavedSearches() {
        return this.http.get('/api/integration/user-saved-search');
    }
    getImportData(crd) {
        return this.http.get('/api/integration/import-data/'+crd);
    }
    runImport(data) {
        return this.http.post('/api/salesforce/import/run', data);
    }
    getActivityStatus(data) {
        return this.http.post('/api/integration/activity-status', data);
    }
}
