import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../@pages/components/shared.module';
import { SharedMainModule } from '../../shared/shared-main.module';
import { pgSelectModule } from '../../@pages/components/select/select.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { pgUploadModule } from '../../@pages/components/upload/upload.module';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { CountUpModule } from 'ngx-countup';

import { AccountBookFill, AlertFill, AlertOutline } from '@ant-design/icons-angular/icons';
import { SearchNewComponent } from './search-new.component';
import { SearchNewRoutingModule } from './search-new-routing.module';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { SidebarModule } from 'primeng/sidebar';

import { BulkModalComponent } from './components/bulk-modal/bulk-modal.component'; 
import { LocationModal } from './components/location-modal/location-modal.component'
import { FirmContactPopupComponent } from './components/firm-contact-popup/firm-contact-popup.component';
import { MapService } from '../../shared/services/map.service';
import { SavedSearchSelectorComponent } from './components/saved-search-selector/saved-search-selector.component';

const icons: IconDefinition[] = [ AccountBookFill, AlertOutline, AlertFill ];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedMainModule,
    SearchNewRoutingModule,
    FormsModule,
    HttpClientModule,
    pgSelectModule,
    NgxDatatableModule,
    TextMaskModule,
    ProgressModule,
    pgUploadModule,
    NzSwitchModule,
    NzDatePickerModule,
    NzSpinModule,
    NzSelectModule,
    NzUploadModule,
    NzIconModule.forRoot(icons),
    NzToolTipModule,
    NzDropDownModule,
    CountUpModule,
    NzCollapseModule,
    SidebarModule
  ],
  declarations: [SearchNewComponent, BulkModalComponent, LocationModal, FirmContactPopupComponent, SavedSearchSelectorComponent],
  providers: [ { provide: 'MAP_SERVICE', useClass: MapService} ] 
})
export class SearchNewModule { }

