
<ul class="menu-items p-t-25" [perfectScrollbar]="config" [disabled]="isPerfectScrollbarDisabled" style="overflow-x: visible;">
   
    <li class="menu-item-single"
    *ngFor="let item of menuItems" [class.open]="item.toggle ==='open'"
    [routerLink]="item.routerLink ? [item.routerLink] : null"
    routerLinkActive="active"
    
    (mouseleave)="onMouseLeave()"
    (mousemove)="onMouseMove($event)"
    (mouseenter)="onMouseEnter($event)"
     >
        <div
            class="hide-tooltip tooltip"
            style="
            z-index: 99;
            border-radius: 5px;
            border-width: 2px;
            border-color: rgb(7, 9, 32);
            background-color: rgb(8, 10, 32, 0.9);
            position: fixed;
            font-weight:normal;
            padding: 5px 0px 5px 0px;
            justify-content: center;
            align-content: center;
            height: 45px;
            "><div style="

            font-size: 14px;
            color: whitesmoke;
            display: flex;
            justify-content: center;
            align-content: center;
            margin: auto;">
                {{item.label}}
            </div> 
        </div>
        <a  href="javascript:;" *ngIf="item.submenu;else singleLink" (click)="toggleNavigationSub($event,item)" [class.detailed]="item.details">
            <span class="title">{{ item.label }}</span>
            <span  *ngIf="item.details" class="details">{{item.details}}</span>
            <span  *ngIf="item.submenu" class="arrow" [class.open]="item.toggle ==='open'"></span>
        </a>
        <ng-template #singleLink>
        <a *ngIf="item.routerLink" [routerLink]="[item.routerLink]" [class.detailed]="item.details">
            <span class="title">{{ item.label }}</span>
            <span  *ngIf="item.details" class="details">{{item.details}}</span>
        </a>
        <a *ngIf="item.externalLink" href="{{item.externalLink}}" [attr.target]="item.target" [class.detailed]="item.details">
            <span class="title">{{ item.label }}</span>
            <span  *ngIf="item.details" class="details">{{item.details}}</span>
        </a>
        </ng-template>
        <ng-container *ngIf="item.submenu;else singleLinkIcon">
            <pg-menu-icon *ngIf="item.iconType" (click)="toggleNavigationSub($event,item)" [IconType]="item.iconType" [IconName]="item.iconName" [ExtraClass]="item.thumbNailClass" [IconAltName]='item.label'></pg-menu-icon>
        </ng-container>
        <ng-template #singleLinkIcon>
            <pg-menu-icon *ngIf="item.iconType" [routerLink]="[item.routerLink]" [IconType]="item.iconType" [IconName]="item.iconName" [ExtraClass]="item.thumbNailClass" [IconAltName]='item.label'></pg-menu-icon>
        </ng-template>
        
        <ul class="sub-menu" *ngIf="item.submenu" [@toggleHeight]="item.toggle">
            <li *ngFor="let child of item.submenu" [class.open]="child.toggle ==='open'" routerLinkActive="active">
                <a href="javascript:;" *ngIf="child.submenu;else singleLinkChild" (click)="toggleNavigationSub($event,item)"><span class="title">{{ child.label }}</span>
                    <span  *ngIf="child.submenu" class=" arrow" [class.open]="item.toggle ==='open'"></span>
                </a>
                <ng-template #singleLinkChild>
                <a *ngIf="child.routerLink" [routerLink]="[child.routerLink]"><span class="title">{{ child.label }}</span>
                </a>
                <a *ngIf="child.externalLink" href="{{child.externalLink}}" [attr.target]="item.target"><span class="title">{{ child.label }}</span>
                </a>
                <pg-menu-icon *ngIf="child.iconType" [routerLink]="[child.routerLink]" [IconType]="child.iconType" [IconName]="child.iconName" [IconAltName]='child.label'></pg-menu-icon>
                </ng-template>
                <ul class="sub-menu" *ngIf="child.submenu" [@toggleHeight]="child.toggle">
                    <li *ngFor="let lvl2 of child.submenu">
                        <a *ngIf="lvl2.routerLink" [routerLink]="[lvl2.routerLink]"><span class="title">{{ lvl2.label }}</span>
                            <span  *ngIf="lvl2.submenu" class=" arrow" [class.open]="lvl2.toggle"></span>
                        </a>
                        <a *ngIf="lvl2.externalLink" [routerLink]="[lvl2.externalLink]"><span class="title">{{ item.label }}</span></a>
                        <pg-menu-icon *ngIf="lvl2.iconType" [IconType]="lvl2.iconType" [IconName]="lvl2.iconName" [IconAltName]='lvl2.label'></pg-menu-icon>
                        
                    </li>
                </ul>
            </li>
        </ul>
    </li>
</ul>