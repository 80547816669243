<ng-container>
    <div class="row c-main-advisor-firm">
        <div class="col-lg-4 advisor-details advisor-details-left scrollsection" id="investment_advisor_profile">
            <div class="card card-default">
                <div class="p-4 card-body firm-icon-container">
                        <div *ngIf="!firmProfile" class="text-center mb-2">
                                <div class="skeleton-logo"></div>
                        </div>
                    <div *ngIf="firmProfile" class="text-center mb-2">
                        <span class="firm-header-logo" *ngIf="firmProfile?.logo_url">
                            <img [src]="firmProfile?.logo_url" alt="firm logo" class="header-img"/>
                        </span>

                        <span class="header-logo" *ngIf="!firmProfile?.logo_url">
                            <i class="user-initial">{{ firmProfile?.logo_name }}</i>
                        </span>
                    </div>

                    <div class="firm-icon firm-profile d-header-padding c-person-icon-header">
                        <div>
                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                'advizor': firmProfile?.advizor,
                                'broker': firmProfile?.broker
                            })"
                            [href]="{'advizor':iaProfile, 'broker': brokerProfile}"
                            [isAnchor]="'true'"
                            [iconSize]="'normal'" />

                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                'fo': firmProfile?.fo && user.has_access_family_office,
                                'bt': firmProfile?.bt && user.has_access_bank_and_trust,
                                'dc': firmProfile?.dc,
                                'db': firmProfile?.db,
                            })" [iconSize]="'normal'" />
                        </div>
                        <div class="crd-npn">
                            <span class="fs-12" *ngIf="firmProfile?.crd">CRD: {{ firmProfile.crd }}</span>
                        </div>
                    </div>
                    <div class="firm-profile w-65 d-header-padding">
                        <h2 class="font-weight-bold m-0">{{ firmProfile?.firm_name | uppercase }}</h2>
                        <p class="m-0 location d-block">
                            <span class="d-block">{{ firmProfile?.advizor_firm_addresses?.address_1 }} {{ firmProfile?.advizor_firm_addresses?.address_2 }}</span>
                            <span class>{{ firmProfile?.advizor_firm_addresses?.city }} {{ firmProfile?.advizor_firm_addresses?.state }} {{ firmProfile?.advizor_firm_addresses?.zip }}</span>
                        </p>
                    </div>
                    <div class="profile_link d-header-padding">
                        <ul class="p-0 m-0">
                            <li class="profile-link-item">
                                <a href="javascript:void(0)" *ngIf="main_url.length > 1" (click)="mainUrlLog(main_url)"><i class="icon-size fa fa-2x fa-globe"></i></a>
                                <a href="javascript:void(0)" *ngIf="main_url.length == 1" (click)="logAttribute(main_url[0])"><i class="icon-size fa fa-2x fa-globe"></i></a>
                                <p class="inactive m-0 lh-0" *ngIf="main_url.length == 0" title="No URL for this firm"><i class="icon-size fa fa-2x fa-globe"></i></p>
                            </li>
                            <li class="profile-link-item" *ngIf="(firmProfile?.fo && user.has_access_family_office) || profileType === 'bank'">
                                <a href="javascript:void(0)" *ngIf="linkedin.length != 0" (click)="logAttribute(linkedin)"><i class="icon-size fab fa-2x fa-linkedin"></i></a>
                                <p class="inactive m-0 lh-0" *ngIf="linkedin.length == 0" title="No linkedin for this firm"><i class="icon-size fab fa-2x fa-linkedin"></i></p>
                            </li>
                            <li class="profile-link-item" *ngIf="profileType != 'family' && profileType != 'bank'">
                                <a href="javascript:void(0)" *ngIf="twitter.length != 0" (click)="logAttribute(twitter)"><i class="icon-size fab fa-2x fa-twitter"></i></a>
                                <p class="inactive m-0 lh-0" *ngIf="twitter.length == 0" title="No twitter for this firm"><i class="icon-size fab fa-2x fa-twitter"></i></p>
                            </li>
                            <li class="profile-link-item" *ngIf="profileType != 'family' && profileType != 'bank'">
                                <a href="javascript:void(0)" *ngIf="facebook.length != 0" (click)="logAttribute(facebook)"><i class="icon-size fab fa-2x fa-facebook"></i></a>
                                <p class="inactive m-0 lh-0" *ngIf="facebook.length == 0" title="No facebook for this firm"><i class="icon-size fab fa-2x fa-facebook"></i></p>
                            </li>
                            <li class="profile-link-item no-bg w-auto" *ngIf="profileType != 'family' && profileType != 'bank'">
                                <a href="javascript:void(0)" *ngIf="firmProfile?.advizor" (click)="advBadge()" class="firm-badge"><span class="badge badge-complete">ADV</span></a>
                                <!--<p class="inactive m-0 lh-0 firm-badge" *ngIf="!firmProfile?.advizor" title="ADV"><span class="badge badge-secondary">Advisors</span></p>-->
                            </li>
                        </ul>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="firm-profile-button d-header-padding d-inline">
                                <button type="button" class="btn c-btn-blue-dark mr-2" [ngClass]="{'c-btn-blue-light':advisorFollow,'c-btn-blue-dark':!advisorFollow}" (click)="followAdvizor()">{{ (advisorFollow)?'Unfollow':'Follow'}}</button>
                            </div>
                            <a *ngIf="hubspotAccess" href="javascript:void(0)" class="pull-right mr-3 float-right" style="margin-top: 3px;">
                                <img *ngIf="!hubspotImportData" src="assets/img/hubspot-off.png"  alt="socail"
                                    (click)="importContact('hubspot', advizorPdf)">
                                <img *ngIf="hubspotImportData" src="assets/img/hubspot-on.png"  alt="socail"
                                    (click)="importContact('hubspot', advizorPdf)">
                            </a>
                            <a *ngIf="salesforceAccess" href="javascript:void(0)" class="pull-right mr-3 float-right" style="margin-top: 3px;">
                                <img *ngIf="!salesforceImportData" src="assets/img/salesforce-off.png" width="29px" alt="socail"
                                    (click)="importContact('salesforce', advizorPdf)">
                                <img *ngIf="salesforceImportData" src="assets/img/salesforce-on.png" width="29px" alt="socail"
                                    (click)="importContact('salesforce', advizorPdf)">
                            </a>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <div class="row d-firm-profile-rows ml-0">
                        <div class="col-md-12 pl-0">
                            <div class="row c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Email</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <a *ngIf="firmProfile?.general_email" href="mailto:{{firmProfile?.general_email}}">{{firmProfile?.general_email }} </a>
                                    <span *ngIf="!firmProfile?.general_email">-</span>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="profileType != 'bank'">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Phone</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber"><a href="tel:{{firmProfile.advizor_firm_addresses.phone | phoneMask}}" class="text-capitalize fs-12">{{firmProfile.advizor_firm_addresses.phone | phoneMask}}</a></span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Family Office AUM</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span *ngIf="firmProfile?.fo_firm_aum" class="fs-12"> {{ ((firmProfile?.fo_firm_aum / 1000000) | currency:'USD':'symbol':'1.0-2')+'M' }}</span>
                                    <span *ngIf="!firmProfile?.fo_firm_aum">-</span>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office && firmProfile?.direct_investments">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Direct Investments</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <i class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office && firmProfile?.external_managers">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">External Managers</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <i class="mr-2 far fa-check-circle color-grn" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.discretionary_aum && firmProfile?.total_aum">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Total AUM | Disc. AUM</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ (firmProfile?.total_aum/1000000 | currency:'USD':'symbol':'1.0-2')+'M' }}&nbsp;|&nbsp;{{ (firmProfile?.discretionary_aum/1000000 | currency:'USD':'symbol':'1.0-2')+'M' }}</span>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.staff && firmProfile?.staff?.length > 0">
                                <div class="col-md-4 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Employees</span>
                                </div>
                                <div class="col-md-8 text-right">
                                    <ng-container *ngFor="let staff of firmProfile?.staff; let i = index">
                                        <ng-container *ngIf="formatStringNumber(staff?.name?.split(':')[1]) != 0">
                                            <span *ngIf="i != 0"> | </span>
                                            <span class="fs-12"> {{ formatStringNumber(staff?.name?.split(':')[1]) }} {{ staff?.name?.split(':')[0] }} </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="offices?.total">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Office Locations</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ offices?.total?.toLocaleString() }}</span>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="advizorFirmDba?.total">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Teams</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ advizorFirmDba?.total?.toLocaleString() }}</span>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.custodians?.length > 0">
                                <div class="col-md-4 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Custodian</span>
                                </div>
                                <div class="col-md-8 text-right">
                                    <span class="fw-400 text-capitalize fs-12" *ngFor="let custodian of firmProfile?.custodians; let last = last; let i = index">
                                        {{ custodian.name }}
                                        <br />
                                    </span>
                                </div>
                            </div>

                            <div class="row c-row-details" *ngIf="firmProfile?.advizor">
                                <div class="col-md-4 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Clients</span>
                                </div>
                                <div class="col-md-8 text-right">
                                    <span *ngIf="firmProfile?.channel" class="fs-12">{{ firmProfile?.channel}}</span>
                                    <span *ngIf="!firmProfile?.channel">-</span>
                                </div>
                            </div>


                            <div class="row c-row-details" *ngIf="firmProfile?.advizor || firmProfile?.broker">
                                <div class="col-md-4 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Platforms</span>
                                </div>
                                <span *ngIf="platforms.length <= 0" class="col-md-8 text-right">-</span>
                                <div  *ngIf="platforms?.length > 0" class="col-md-8 text-right">
                                    <div class="height-set">
                                        <a class="ml-2 mb-2 badge badge-primary float-right" (click)="advTechStack()" *ngIf="platforms.length - 2 > 0">
                                            +{{ platforms.length - 2 }} more
                                        </a>
                                        <ng-container *ngFor="let keyword of platforms.slice(0,2)">
                                            <span *ngIf="!keyword?.url" [style.background-color]="keyword?.color" class="ml-2 mb-2 badge badge-primary">
                                                {{keyword?.keyword_display}}
                                            </span>

                                            <a *ngIf="keyword?.url" href="{{keyword.url}}" target="_blank" [style.background-color]="keyword?.color" class="ml-2 mb-2 badge badge-primary">
                                                {{keyword?.keyword_display}}
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row c-row-details" *ngIf="firmProfile?.establish_date && profileType != 'bank'">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Firm Age</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ calculateDateDifference(firmProfile?.establish_date) }}</span>
                                </div>
                            </div>
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.ein && firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">EIN</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{firmProfile?.ein}}</span>
                                </div>
                            </div>
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.cik && firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">CIK</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{firmProfile?.cik}}</span>
                                </div>
                            </div>
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Wealth Creator</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span *ngIf="firmProfile?.family_name" class="fs-12">{{ firmProfile?.family_name }}</span>
                                    <span *ngIf="!firmProfile?.family_name">-</span>
                                </div>
                            </div>
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Wealth Origin</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span *ngIf="firmProfile?.wealth_origin" class="fs-12">{{ firmProfile?.wealth_origin }}</span>
                                    <span *ngIf="!firmProfile?.wealth_origin">-</span>
                                </div>
                            </div>
                            
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Year Founded</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ checkYear(firmProfile?.year_founded) }}</span>
                                </div>
                            </div>
                            <div class="row crd-details c-row-details" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Family Office Type</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">{{ ((firmProfile?.firm_type == 'MFO')) ? 'Multi-family Office' : 'Single-family Office' }}</span>
                                </div>
                            </div>

                            <div class="row c-row-details" *ngIf="firmProfile?.bt && user.has_access_bank_and_trust && !firmProfile.crd">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Associated B-D</span>
                                </div>
                                <div class="col-md-7 text-right" >
                                    <span class="fs-14" *ngIf="firmProfile?.bank_trust?.associated_broker_dealer_crd.length else showDashes">
                                        <span *ngFor="let brokerDealer of firmProfile?.bank_trust?.associated_broker_dealer_crd let last = last; let i = index">
                                            <span *ngIf="i != 0">
                                                |
                                            </span>
                                            <a [routerLink]="['/advisor/firm', brokerDealer?.crd]" target="_blank" class="fs-12">
                                                {{brokerDealer?.name}}
                                            </a>
                                        </span>
                                    </span>
                                    <ng-template #showDashes>
                                        <span class="c-desc">--</span>
                                    </ng-template>
                                </div>

                            </div>

                            <ng-template #bankTrustClassificationCode let-firmProfile="firmProfile">
                                <span *ngIf="firmProfile?.bank_trust?.classification_code_mapping?.classification_code;else showDashes" class="fs-12">{{firmProfile?.bank_trust?.classification_code_mapping?.short_description}} </span>
                                <i *ngIf="firmProfile?.bank_trust?.classification_code_mapping?.classification_code" class="fa fa-question-circle" nz-tooltip
                                    nzTooltipPlacement="right"
                                    [nzTooltipTitle]="firmProfile?.bank_trust?.classification_code_mapping?.description">
                                </i>
                                <span *ngIf="!firmProfile?.bank_trust?.classification_code_mapping?.classification_code" class="fs-12">--</span>
                            </ng-template>

                            <div class="row c-row-details" *ngIf="firmProfile?.bt && user.has_access_bank_and_trust">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Bank Classification</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <ng-template *ngIf="firmProfile?.bt && user.has_access_bank_and_trust" [ngTemplateOutlet]="bankTrustClassificationCode" [ngTemplateOutletContext]="{firmProfile}"></ng-template>
                                </div>
                            </div>


                            <div class="row c-row-details"
                            *ngIf="firmProfile?.bt && user.has_access_bank_and_trust && (firmProfile?.bank_trust?.total_bank_assets || firmProfile?.bank_trust?.trust_assets)">
                                <div class="col-md-7 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">
                                        {{addTextIfValueExists([
                                            {value: firmProfile?.bank_trust?.total_bank_assets, text: "Total Assets"},
                                            {value: firmProfile?.bank_trust?.trust_assets, text: "Bank Assets"}])}}
                                    </span>
                                </div>
                                <div class="col-md-5 text-right">
                                    <span class="fs-12">{{addTextIfValueExists([
                                        {value: firmProfile?.bank_trust?.total_bank_assets, text: formatMoney(firmProfile?.bank_trust?.total_bank_assets)},
                                        {value: firmProfile?.bank_trust?.trust_assets, text: formatMoney(firmProfile?.bank_trust?.trust_assets)}])}}</span>
                                </div>
                            </div>

                            <div class="row c-row-details" *ngIf="firmProfile?.bt && user.has_access_bank_and_trust">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Trust Services</span>
                                </div>
                                <div class="col-md-7 text-right" >
                                    <span class="fs-14" *ngIf="firmProfile?.bank_trust?.trust_services; else showDashes"><i class="fa fa-check-circle" aria-hidden="true"></i></span>
                                    <ng-template #showDashes>
                                        <span class="c-desc">--</span>
                                    </ng-template>
                                </div>

                            </div>

                            <div class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">{{(firmProfile?.bt && user.has_access_bank_and_trust) ? 'Bank Established Date' : 'Established date'}}</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12">
                                        {{(firmProfile?.establish_date)?(firmProfile?.establish_date | date:'MM/dd/yyyy'):'--'}}
                                    </span>
                                </div>
                            </div>

                            <!-- DB and DC -->

                            <div *ngIf="firmProfile?.general_information?.plan_info?.year" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Year</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.year}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.latest_filling" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Latest Filling</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.latest_filling ? "Yes" : "No"}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.ein" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">EIN</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.ein}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.plan_type" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Plan Type</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.plan_type}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.plan_administrator" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Plan Administrator</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.plan_administrator}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.administrator_phone" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Administrator Phone</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.administrator_phone}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                            <div *ngIf="firmProfile?.general_information?.plan_info?.industry" class="row c-row-details">
                                <div class="col-md-5 text-left">
                                    <span class="c-text-color dm-sans-semibold fs-12">Industry</span>
                                </div>
                                <div class="col-md-7 text-right">
                                    <span class="fs-12" *ngIf="firmProfile?.advizor_firm_addresses?.phone;else dashNumber">{{firmProfile?.general_information?.plan_info?.industry}}</span>
                                    <ng-template #dashNumber>
                                        <span>-</span>
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 advisor-details-right">

            <nz-tabset class="horizontal-tab">
                <nz-tab [nzTitle]="titleTemplate">
                    <ng-template #titleTemplate>
                        <b>General Information</b>
                    </ng-template>
                    <div class="row m-0">
                        <div class="col-md-12 px-4 py-2">
                            <insights-container [insights]="insights" title="Insights" > </insights-container>

                            <ng-template *ngIf="(firmProfile?.db || firmProfile?.dc) && firmProfile?.general_information?.red_flags?.length"
                                    [ngTemplateOutlet]="redFlags401k"
                                    [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template #redFlags401k let-flags="firmProfile?.general_information?.red_flags" let-user="user">
                                <ng-container>
                                    <div class="row m-0 mb-3">
                                        <div class="col-md-12 new-firm-update">
                                            <div class="new-firm-update-header">
                                                <span class="c-title"><b>Plan Insights</b></span>
                                            </div>
                                            <div class="col-12 container">
                                                <ul class="pl-3 m-0 row">
                                                    <li class="col-12 fs-12 fw-400" *ngFor="let flag of getSortedFlags(flags);">
                                                        <div class="col-12 p-0">
                                                            <div [innerHTML]="flag"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="w-100" />
                                </ng-container>
                            </ng-template>

                            <ng-container *ngIf="profileType != 'family' && profileType != 'bank' && profileType != 'defined-benefit' && profileType != 'defined-contribution' ">
                                <div class="row mb-3">
                                    <div class="col-md-12 general-info c-section">
                                        <h4 class="c-title m-0">General Information</h4>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">CRD</span>
                                                <span class="c-desc">{{crd ? crd : '--'}}</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Established</span>
                                                <span class="c-desc">{{(firmProfile?.establish_date)?(firmProfile?.establish_date | date:'MM/dd/yyyy'):'--'}}</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">SEC Effective</span>
                                                <span class="c-desc">{{(firmProfile?.sec_registration)?(firmProfile?.sec_registration | date:'MM/dd/yyyy'):'--'}}</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Channel</span>
                                                <span class="c-desc">{{firmProfile?.channel ? firmProfile?.channel : '--'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-template #bankTrustTrustInformation let-firmProfile="firmProfile" let-user="user">
                            <ng-container *ngIf="firmProfile?.bt && user.has_access_bank_and_trust">
                                <div class="row mb-3">
                                    <div class="col-md-12 general-info c-section">
                                        <h4 class="c-title m-0">Trust Information</h4>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Trust Powers</span>
                                                <ng-container>
                                                    <span *ngIf="firmProfile?.bank_trust?.trust_powers_dropdown?.display_text; else showDashes" class="c-desc">{{firmProfile?.bank_trust?.trust_powers_dropdown?.display_text}}</span>
                                                    <ng-template #showDashes>
                                                        <span class="c-desc">--</span>
                                                    </ng-template>
                                                </ng-container>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Total Trust Accounts</span>
                                                <span class="c-desc">{{firmProfile?.bank_trust?.total_trust_accounts || "--"}}</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Trust Page</span>
                                                <a href="{{getAttribute('trust_page')}}" target="_blank" *ngIf="getAttribute('trust_page'); else showDashes"><i class="icon-size fa fa-globe"></i></a>
                                                <ng-template #showDashes>
                                                    <span class="c-desc">--</span>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>
                            </ng-template>
                            <ng-template *ngIf="firmProfile?.bt && user.has_access_bank_and_trust" [ngTemplateOutlet]="bankTrustTrustInformation" [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>

                            <ng-template #bankTrustFiduciatyAssetsAndAccounts let-firmProfile="firmProfile" let-user="user">
                            <ng-container *ngIf="firmProfile?.bt && user?.has_access_bank_and_trust">
                                <div class="row mb-3">
                                    <div class="col-md-12 general-info c-section">
                                        <h4 class="c-title m-0">Fiduciary Assets and Accounts</h4>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Total fiduciary and Related Assets</span>
                                                <span class="c-desc">{{firmProfile?.bank_trust?.total_fiduciary_and_related_assets | currencyMillion}}</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Fiduciary and Related Assets Accounts</span>
                                                <span class="c-desc">{{firmProfile?.bank_trust?.number_of_fiduciary_accounts_and_related_asset_accounts | valueOrDefault}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>
                            </ng-template>
                            <ng-template *ngIf="firmProfile?.bt && user?.has_access_bank_and_trust" [ngTemplateOutlet]="bankTrustFiduciatyAssetsAndAccounts" [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>

                            <ng-template *ngIf="(firmProfile?.db || firmProfile?.dc)"
                                    [ngTemplateOutlet]="assets401k"
                                    [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template #assets401k let-assets="firmProfile?.general_information?.assets" let-user="user">
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-md-12 general-info c-section">
                                            <h4 class="c-title m-0">Assets</h4>
                                            <div class="row mb-3">
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Total Assets</span>
                                                    <span class="c-desc">{{assets?.total_assets | currencyOrDefault}}</span>
                                                </div>
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Average Balance</span>
                                                    <span class="c-desc">{{assets?.average_balance | currencyOrDefault}}</span>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Employer Contribution ($)</span>
                                                    <span class="c-desc">{{assets?.employer_contrib | currencyOrDefault}}</span>
                                                </div>

                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Employee Contribution ($)</span>
                                                    <span class="c-desc">{{assets?.employee_contrib | currencyOrDefault}}</span>
                                                </div>

                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Total Contribution ($)</span>
                                                    <span class="c-desc">{{assets?.total_contrib | currencyOrDefault}}</span>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Employer Contribution (%)</span>
                                                    <span class="c-desc">{{assets?.employer_contrib_pct | percentOrDefault}}</span>
                                                </div>
                                                
                                                
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Employee Contribution (%)</span>
                                                    <span class="c-desc">{{assets?.employee_contrib_pct | percentOrDefault}}</span>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Fidelity Bond</span>
                                                    <span class="c-desc">{{assets?.fidelity_bond ? "Yes" : "No"}}</span>
                                                </div>
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Corrective Distribution</span>
                                                    <span class="c-desc">{{assets?.corrective_distribution ? "Yes" : "No"}}</span>
                                                </div>
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Profit shared</span>
                                                    <span class="c-desc">{{assets?.profitsharing ? "Yes" : "No"}}</span>
                                                </div>
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Autoenrollment</span>
                                                    <span class="c-desc">{{assets?.autoenrollment ? "Yes" : "No"}}</span>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Fidelity Bond ($)</span>
                                                    <span class="c-desc">{{assets?.fidelity_bond_amt | currencyOrDefault}}</span>
                                                </div>
                                                <div class="c-flex-col col-3">
                                                    <span class="c-label">Fidelity Bond (%)</span>
                                                    <span class="c-desc">{{assets?.fidelity_bond_pct | percentOrDefault}}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr />
                                </ng-container>
                            </ng-template>

                            <ng-template *ngIf="(firmProfile?.db || firmProfile?.dc)"
                                    [ngTemplateOutlet]="participants401k"
                                    [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template #participants401k let-part="firmProfile?.general_information?.participants" let-user="user">
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-md-12 general-info c-section">
                                            <h4 class="c-title m-0">Participants</h4>
                                                <div class="row mb-3">
                                                    <div class="c-flex-col col-3">
                                                        <span class="c-label">Total Participants</span>
                                                        <span class="c-desc">{{part?.total_participants | valueOrDefault}}</span>
                                                    </div>
                                                    <div class="c-flex-col col-3">
                                                        <span class="c-label">Active Participants</span>
                                                        <span class="c-desc">{{part?.active_participants | valueOrDefault}}</span>
                                                    </div>
                                                    <div class="c-flex-col col-3">
                                                        <span class="c-label">Retired Participants</span>
                                                        <span class="c-desc">{{part?.retired_participants | valueOrDefault}}</span>
                                                    </div>

                                                    <div class="c-flex-col col-3">
                                                        <span class="c-label">Participation Rate (%)</span>
                                                        <span class="c-desc">{{part?.participation_rate | percentOrDefault}}</span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="c-flex-col pr-40 col-3">
                                                        <span class="c-label">Active Participants W Balance</span>
                                                        <span class="c-desc">{{part?.active_participants_w_bal | valueOrDefault}}</span>
                                                    </div>
                                                    <div class="c-flex-col pr-40 col-3">
                                                        <span class="c-label">Total Participantes W Balance</span>
                                                        <span class="c-desc">{{part?.total_participants_w_bal | valueOrDefault}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <hr />
                                </ng-container>
                            </ng-template>

                            <ng-template *ngIf="(firmProfile?.db || firmProfile?.dc)"
                                    [ngTemplateOutlet]="planFeatures401k"
                                    [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template #planFeatures401k let-planf="firmProfile?.general_information?.plan_features" let-user="user">
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-md-12 general-info c-section">
                                            <h4 class="c-title m-0">Plan Features</h4>
                                                <div class="d-flex">
                                                    <div class="c-flex-col pr-40 flex-fill">
                                                        <span class="c-label">Autoenrollment</span>
                                                        <span class="c-desc">{{planf?.autoenrollment | booleanOrDefault}}</span>
                                                    </div>
                                                    <div class="c-flex-col pr-40 flex-fill">
                                                        <span class="c-label">Profit Sharing</span>
                                                        <span class="c-desc">{{planf?.profit_sharing | booleanOrDefault}}</span>
                                                    </div>
                                                    <div class="c-flex-col pr-41 flex-fill">
                                                        <span class="c-label">Features</span> 
                                                        <span class="c-desc" *ngIf="!planf.features">{{'--'}}</span>
                                                        <ul class="c-desc" *ngIf="planf.features">
                                                            <li *ngFor="let feature of planf.features">
                                                                {{feature.definition + ' ('+ feature.pension_codes +')' }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <hr />
                                </ng-container>
                            </ng-template>

                            <ng-template *ngIf="(firmProfile?.db || firmProfile?.dc)"
                                    [ngTemplateOutlet]="fees401k"
                                    [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template #fees401k let-fees="firmProfile?.general_information?.fees" let-user="user">
                                <ng-container>
                                    <div class="row mb-3">
                                        <div class="col-md-12 general-info c-section">
                                            <h4 class="c-title m-0">Fees</h4>
                                                <div class="d-flex">
                                                    <div class="c-flex-col pr-40 flex-fill">
                                                        <span class="c-label">Total Admin Expenses</span>
                                                        <span class="c-desc">{{fees?.total_admin_expenses | currencyMillion}}</span>
                                                    </div>
                                                    <div class="c-flex-col pr-40 flex-fill">
                                                        <span class="c-label">Fees Per Assets</span>
                                                        <span class="c-desc">{{fees?.fees_per_assets | currencyMillion}}</span>
                                                    </div>
                                                    <div class="c-flex-col pr-40 flex-fill">
                                                        <span class="c-label">Fee Per Participants</span>
                                                        <span class="c-desc">{{fees?.fee_per_participant | currencyMillion}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <hr />
                                </ng-container>
                            </ng-template>

                            <ng-container *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="row mb-3">
                                    <div class="col-md-12 staff-info c-section">
                                        <h4 class="c-title m-0">Firm Description</h4>
                                        <div style="white-space: pre-wrap;">{{firmProfile?.firm_description}}</div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <!-- <ng-container *ngIf="firmProfile?.staff && firmProfile?.staff?.length > 0">
                                <div class="row mb-3">
                                    <div class="col-md-12 staff-info c-section">
                                        <h4 class="c-title m-0">Staff</h4>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill" *ngFor="let staff of firmProfile?.staff; let i = index">
                                                <span class="c-label">{{ staff?.name?.split(':')[0] }}</span>
                                                <span class="c-desc">{{ formatStringNumber(staff?.name?.split(':')[1]) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container> -->

                            <ng-container *ngIf="firmProfile?.firm_tags && firmProfile?.firm_tags?.length > 0">
                                <div class="row mb-3">
                                    <ng-container *ngFor="let tags of firmProfile?.firm_tags;">
                                        <div class="col-md-3 tags-info c-section">
                                            <h4 class="c-title">{{ tags.tag_category }}</h4>
                                            <span *ngFor="let keyword of tags.firm_tags;">
                                                <span *ngIf="!keyword?.url" [style.background-color]="keyword?.color" class="ml-1 mb-1 badge badge-primary" >
                                                    {{keyword?.keyword_display}}
                                                </span>
                                                <a *ngIf="keyword?.url"  href="{{keyword.url}}" target="_blank" [style.background-color]="keyword?.color" class="ml-1 mb-1 badge badge-primary">
                                                    {{keyword?.keyword_display}}
                                                </a>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                                <hr />
                            </ng-container>


                              <ng-container *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="row mb-3">
                                    <div class="col-md-12 staff-info c-section">
                                        <h4 class="c-title m-0">Investment Criteria</h4>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Revenue Min</span>
                                                <span *ngIf="firmProfile?.investment_criteria?.revenue_min" class="c-desc">${{ firmProfile?.investment_criteria?.revenue_min }}</span>
                                                <span *ngIf="!firmProfile?.investment_criteria?.revenue_min" class="c-desc">--</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Revenue Max</span>
                                                <span *ngIf="firmProfile?.investment_criteria?.revenue_max" class="c-desc">${{ firmProfile?.investment_criteria?.revenue_max }}</span>
                                                <span *ngIf="!firmProfile?.investment_criteria?.revenue_max" class="c-desc">--</span>
                                            </div>
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">EBITDA</span>
                                                <span *ngIf="firmProfile?.investment_criteria?.ebitda" class="c-desc">${{ firmProfile?.investment_criteria?.ebitda }}</span>
                                                <span *ngIf="!firmProfile?.investment_criteria?.ebitda" class="c-desc">--</span>
                                                </div>
                                        </div>
                                        <br>
                                        <div class="d-flex">
                                            <div class="c-flex-col pr-40 flex-fill">
                                                <span class="c-label">Investment Criteria</span>
                                                <span *ngIf="firmProfile?.investment_criteria?.revenue_text" class="c-desc">{{ firmProfile?.investment_criteria?.revenue_text }}</span>
                                                <span *ngIf="!firmProfile?.investment_criteria?.revenue_text" class="c-desc">--</span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container> 

                 

                            <ng-container *ngIf="(firmProfile?.advizor || firmProfile?.broker) && ownerContacts">
                                <div class="row mb-3" id="owners" >
                                    <div class="col-md-12 owners-info c-section">
                                        <h4 class="c-title">Owners & Executives</h4>

                                        <div class="row scrollsection m-0 mb-4" id="owners-contacts">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-4 pl-0 d-flex" *ngFor="let row of ownerContacts?.data">
                                                        <div class="c-contact-box">
                                                            <div class="d-flex contact-header">
                                                                <ng-container *ngIf="row?.photo_url; else ownerPhoto">
                                                                    <div>
                                                                        <span class="contact-photo mr-3">
                                                                            <img [src]="row?.photo_url" alt="" class="w-100" />
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #ownerPhoto>
                                                                    <div>
                                                                        <span class="user-initial mr-3 fs-12">
                                                                            {{ personNameFilter(row) }}
                                                                        </span>
                                                                    </div>
                                                                </ng-template>
                                                                <div>
                                                                    <a [routerLink]="['/advisor/person', row?.person_crd]" target="_blank">
                                                                        <span class="fs-12">{{ row?.first_name | uppercase}} {{ row?.last_name | uppercase}}</span>
                                                                    </a>

                                                                    <p class="fs-10 title-txt mb-0 c-dots-overflow">
                                                                        <span *ngIf="row?.title">{{ row?.title.length > 34 ? (row?.title.slice(0, 34) + '...' | uppercase) : (row?.title | uppercase) }}</span>
                                                                        <span *ngIf="!row?.title"> &nbsp; </span>
                                                                    </p>
                                                                    <div class="d-flex">
                                                                        <a href="mailto:{{row?.email}}" target="_blank" *ngIf="row?.email" class="mr-3 c-contact-icons" title="{{row?.email}}">
                                                                            <i class="far fa-envelope" aria-hidden="true"></i>
                                                                        </a>
                                                                        <a href="mailto:{{row?.personal_email}}" target="_blank" *ngIf="row?.personal_email" class="mr-3 c-contact-icons" title="{{row?.personal_email}}">
                                                                            <i class="far fa-envelope personal_email" aria-hidden="true"></i>
                                                                        </a>
                                                                        <a href="tel:{{row?.phone}}" title="{{row?.phone}}" *ngIf="row?.phone" class="mr-3 c-contact-icons">
                                                                            <i class="fas fa-phone-alt" aria-hidden="true"></i>
                                                                        </a>
                                                                        <a href="{{row?.bio_url}}" title="{{row?.bio_url}}" target="_blank" *ngIf="row?.bio_url" class="mr-3 c-contact-icons">
                                                                            <i class="fas fa-user" aria-hidden="true"></i>
                                                                        </a>
                                                                        <a href="{{row?.linkedin}}" title="{{row?.linkedin}}" target="_blank" *ngIf="row?.linkedin" class="mr-3 c-contact-icons">
                                                                            <i class="fab fa-linkedin" aria-hidden="true"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr class="w-100" />
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <span class="grey-2 fs-12">CRD</span>
                                                                <span class="black-1 fs-12">{{ row?.crd }}</span>
                                                            </div>
                                                            <hr class="w-100" />
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <span class="grey-2 fs-12">Registration</span>
                                                                <ng-container *ngIf="row?.crd > 100000000; else ownerRegistration">
                                                                    <span class="black-1 fs-12">--</span>
                                                                </ng-container>
                                                                <ng-template #ownerRegistration>
                                                                    <span class="d-flex align-items-center">
                                                                        <div class="d-flex ai-center">
                                                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                                                'advizor': row?.advizor,
																				'broker': row?.broker,
                                                                                'fo': row?.fo,
                                                                                'bt': row?.bt,
                                                                                'dc': row?.dc,
                                                                                'db': row?.db,
                                                                            })" [iconSize]="'normal'" />
                                                                        </div>
                                                                    </span>
                                                                </ng-template>
                                                            </div>
                                                            <hr class="w-100" />
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <span class="grey-2 fs-12">Ownership</span>
                                                                <span class="black-1 fs-12">{{ row?.ownership ? row?.ownership : '--' }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mr-0">
                                                    <div class="pagination-v2">
                                                        <div class="page-count">
                                                            <span class="page-total ml-0">
                                                                {{ ownerContacts?.total?.toLocaleString() }} total entries
                                                            </span>
                                                        </div>
                                                        <pg-pagination class="pull-right mt-2 custom-pg-pagination" *ngIf="ownerContacts?.total > 0"
                                                            (PageIndexClickChange)="sortFirmOwnersData($event)"
                                                            [Total]="ownerContacts?.total" [PageIndex]="ownerContacts?.current_page"
                                                            [PageSize]="ownerContacts?.per_page"></pg-pagination>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-container *ngIf="firmProfile?.advizor_services && firmProfile?.advizor_services?.length > 0">
                                <div class="row mb-3">
                                    <div class="col-md-12 tags-info c-section">
                                        <h4 class="c-title">Advisory Services</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="pl-3 m-0">
                                                    <li class="fs-12 fw-400" *ngFor="let service of firmProfile?.advizor_services; let i = index">{{service.name}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-container *ngIf="firmProfile?.advizor_fee_structures && firmProfile?.advizor_fee_structures?.length > 0">
                                <div class="row mb-3" >
                                    <div class="col-md-12 tags-info c-section">
                                        <h4 class="c-title">Fee Structure</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="pl-3 m-0">
                                                    <li class="fs-12 fw-400" *ngFor="let fee of firmProfile?.advizor_fee_structures; let i = index">{{fee.name}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-container *ngIf="firmProfile?.custodians && firmProfile?.custodians?.length > 0">
                                <div class="row mb-3">
                                    <div class="col-md-12 tags-info c-section">
                                        <h4 class="c-title">Custodians</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="pl-3 m-0">
                                                    <li class="fs-12 fw-400" *ngFor="let custodian of firmProfile?.custodians; let i = index">{{custodian.name}} <span *ngIf="custodian.assets">${{ custodian.assets | number : '1.0-0' }}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-container *ngIf="firmProfile?.affiliations && firmProfile?.affiliations?.length > 0">
                                <div class="row mb-3">
                                    <div class="col-md-12 tags-info c-section">
                                        <h4 class="c-title">Industry Affiliations</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ul class="pl-3 m-0 ul-2-columns">
                                                    <li class="fs-12 fw-400" *ngFor="let affilation of firmProfile?.affiliations; let i = index">{{affilation.name}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </ng-container>

                            <ng-container *ngIf="(firmProfile?.db || firmProfile?.dc)">
                                <div class="row mb-3 scrollsection" id="office_locations">
                                    <div class="col-md-12 office-locations-info c-section">
                                        <div class="row m-0 justify-content-between align-items-center">
                                            <h4 class="c-title">Providers</h4>
                                        </div>
                                        <providers-401k-table [providers]="firmProfile?.general_information?.providers"></providers-401k-table>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="profileType != 'family' && profileType != 'bank'">
                                <div class="row mb-3 scrollsection" id="office_locations">
                                    <div class="col-md-12 office-locations-info c-section">
                                        <div class="row m-0 justify-content-between align-items-center">
                                            <h4 class="c-title">Office Locations</h4>
                                            <div class="row seach-export-section">
                                                <div class="col-lg-8">
                                                    <input type="text" name="office_search" (keyup)="firmOfficeKeyUp($event)" class="form-control search-location" placeholder="Search location...">
                                                </div>
                                                <div class="col-lg-4 text-right pl-0" *ngIf="user?.ap_user_limit_csv">
                                                    <a icon="fa-file-pdf-o" class="excle-export-btn" [disabled]="exportOfficeStatus" iconPos="right" (click)="exportOfficeModel()" href="javascript:void(0)">
                                                        <img src="assets/img/xlsx.ico" width="21px" />
                                                        <span *ngIf="exportOfficeStatus" class="fs-11">Exporting...</span>
                                                        <span *ngIf="!exportOfficeStatus" class="fs-11">Excel Export</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <ngx-datatable class='table table-hover table-v2' [rows]="offices?.data" [columnMode]="columnModeSetting"
                                                [headerHeight]="43" [footerHeight]="50" [rowHeight]="'auto'" [scrollbarH]="scrollBarHorizontal"
                                                [externalPaging]="true" [count]="offices?.total" [offset]="paginateOffice.pageNumber" [limit]="paginateOffice.limit"
                                                [externalSorting]="true" (page)='sortOfficeData($event)'>

                                                <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center address-location" [sortable]="false"
                                                    prop="address_1" name="Address">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                        <i class="fa fa-map-marker pr-2 text-red"></i>
                                                        <a href="javascript:void(0)">{{row.address_1 | uppercase}} {{ row.address_2 | uppercase}}</a>
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false" prop="city" name="City">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                        {{row.city | uppercase}}
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false"
                                                    prop="state" name="State">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                        {{row.state | uppercase}}
                                                    </ng-template>
                                                </ngx-datatable-column>

                                                <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false" name="Zip" prop="zip">
                                                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                        {{row.zip}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-footer>
                                                    <ng-template
                                                    ngx-datatable-footer-template
                                                    let-rowCount="rowCount"
                                                    let-pageSize="pageSize"
                                                    let-selectedCount="selectedCount"
                                                    let-curPage="curPage"
                                                    let-offset="offset"
                                                    let-isVisible="isVisible">
                                                        <div class="pagination-v2">
                                                            <div class="page-count p-0">
                                                                <span class="page-total">
                                                                    {{ rowCount?.toLocaleString() }} total entries
                                                                </span>
                                                            </div>
                                                            <datatable-pager
                                                                class="custom-datatable-pager"
                                                                [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                                                [pagerNextIcon]="'datatable-icon-skip'"
                                                                [page]="curPage"
                                                                [size]="pageSize"
                                                                [count]="rowCount"
                                                                [hidden]="!((rowCount / pageSize) > 1)"
                                                                (change)="sortOfficeData($event)">
                                                            </datatable-pager>
                                                        </div>
                                                    </ng-template>
                                                </ngx-datatable-footer>
                                            </ngx-datatable>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- <div class="row" *ngIf="firmProfile?.fo && user.has_access_family_office">
                                <div class="col-md-12 advisor_services">
                                    <div class="card card-default box-shadow mb28">
                                        <div class="ant-modal-header">
                                            <h4 class="ant-modal-title">Firm Description</h4>
                                        </div>
                                        <div class="p-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <span>{{firmProfile?.firm_description}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                

                                <div id="contacts" class="col-md-12 advisor_services scrollsection" *ngIf="profileType == 'family'">
                                    <div class="card card-default box-shadow mb28">
                                        <div class="ant-modal-header">
                                            <h4 class="ant-modal-title">Contacts</h4>
                                        </div>
                                        <div class="p-3">
                                            <div class="col-lg-12 table-responsive">
                                                <ngx-datatable class='table table-hover contact-tbl'
                                                    [rows]="contacts?.data"
                                                    [columnMode]="columnModeSetting"
                                                    [headerHeight]="43"
                                                    [footerHeight]="50"
                                                    [rowHeight]="'auto'"
                                                    [scrollbarH]="scrollBarHorizontal"
                                                    [externalPaging]="true"
                                                    [count]="contacts?.total"
                                                    [offset]="paginate.pageNumber"
                                                    [limit]="paginate.limit"
                                                    [externalSorting]="true"
                                                    (page)='sortData($event)'
                                                    >

                                                    <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="false" prop="name" name="Name">
                                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                            <a [routerLink]="['/family/person', row?.fo_id]" target="_blank"><span>{{ row?.first_name | uppercase}} {{ row?.last_name | uppercase}}</span>
                                                            </a>
                                                            <p class="fs-10 mb-0">{{row?.title | uppercase}}</p>
                                                        </ng-template>
                                                    </ngx-datatable-column>

                                                    <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center check_broker" [sortable]="false" name="">
                                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                            <a href="mailto:{{row?.email}}" target="_blank" *ngIf="row?.email" class="ml-2" title="{{row?.email}}"><i
                                                                    class="far fa-envelope" aria-hidden="true"></i></a>
                                                            <a href="tel:{{row?.phone}}" title="{{row?.phone}}" *ngIf="row?.phone" class="ml-2"><i class="fas fa-phone-alt"
                                                                    aria-hidden="true"></i></a>
                                                            <a href="{{row?.bio_url}}" title="{{row?.bio_url}}" target="_blank" *ngIf="row?.bio_url" class="ml-2"><i
                                                                    class="fas fa-user" aria-hidden="true"></i></a>
                                                            <a href="{{row?.linkedin}}" title="{{row?.linkedin}}" target="_blank" *ngIf="row?.linkedin" class="ml-2"><i
                                                                    class="fab fa-linkedin" aria-hidden="true"></i></a>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="firmProfile?.broker && !firmProfile?.advizor">
                                <div class="col-md-12 advisor_services">
                                    <div class="card card-default box-shadow mb28">
                                        <div class="ant-modal-header">
                                            <h4 class="ant-modal-title">Types of Business</h4>
                                        </div>
                                        <div class="p-3">
                                            <ul class="clearfix pl-3">
                                                <li class="w-100" *ngFor="let product of firmProfile?.products; let i = index">{{product.name}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        -->
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="contactTemplate">
                    <ng-template #contactTemplate>
                        <b>Contacts</b>
                    </ng-template>

                    <div class="row scrollsection m-0 mb-4" id="contacts">
                        <div class="col-md-12 pl-2 pr-2 mb-3 d-flex">
                            <div class="col-md-8" *ngIf="profileType != 'family'">
                                <div class="d-flex text">
                                    <!--
                                    <h4 class="d-inline pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">Total: </span>
                                        <span class= "fs-12 fw-700 semi-letter-spacing"> {{ firmProfile?.total_count | number }} </span>
                                    </h4>
                                -->
                                    <h4 class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'advizor': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class= "fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.ria_count | number }} </span>
                                    </h4>
                                    <h4 class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'broker': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class="fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.broker_dealer_count | number }} </span>
                                    </h4>
                                    <h4 *ngIf="user.has_access_family_office" class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'fo': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class="fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.family_office_count | number }} </span>
                                    </h4>
                                    <h4 *ngIf="user.has_access_bank_and_trust" class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'bt': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class="fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.bank_and_trust_count | number }} </span>
                                    </h4>
                                    <h4 *ngIf="user.has_access_defined_benefit" class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'db': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class="fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.db_count | number }} </span>
                                    </h4>
                                    <h4 *ngIf="user.has_access_defined_contribution" class="c-align-center pr-3">
                                        <span class="fs-12 fw-700 semi-letter-spacing">
                                            <app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
                                                'dc': true
                                            })" [iconExtraClass]="'top-0'" />
                                        </span>
                                        <span class="fs-12 fw-700 semi-letter-spacing ml-1"> {{ firmProfile?.dc_count | number }} </span>
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-4 pull-right">
                                <input type="text" name="contact_search" #contact_search class="form-control c-search-border" placeholder="Search contacts..">
                            </div>
                        </div>
                        <div class="col-md-12 pl-4 pr-2">
                            <div class="row m-0">
                                <nz-spin *ngIf="!contacts?.total" nzSimple [nzSize]="'default'" class="col-12 d-flex justify-content-center"></nz-spin>
                                <profile-contact-card 
                                    *ngFor="let contact of contacts?.data"
                                    [dbaContact]="contact" 
                                />
                            </div>
                            <div class="pagination-v2 pr-3">
                                <div class="page-count p-0">
                                    <span class="page-total">
                                        <span>{{ contacts?.total | number }} total entries</span>
                                    </span>
                                </div>
                                <div class="d-flex">
                                    <button class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1" (click)="previousPage()" [disabled]="currentContactPage === 1" [class.btn-disable]="currentContactPage === 1"><i class="fa fa-angle-left fs-12" aria-hidden="true"></i></button>
                                    <button class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1" (click)="nextPage()" [disabled]="currentContactPage === totalContactPages" title="Next" [class.btn-disable]="currentContactPage === totalContactPages"><i class="fa fa-angle-right fs-12" aria-hidden="true"></i></button>
                                </div>
                                <!-- <pg-pagination class="custom-pg-pagination" *ngIf="contacts?.total > 0"
                                    (PageIndexClickChange)="sortFirmContactPagination($event)"
                                    [Total]="contacts?.total" [PageIndex]="contacts?.current_page"
                                    [PageSize]="contacts?.per_page" [ShowSizeChanger]="'true'"></pg-pagination> -->
                            </div>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate" *ngIf="onlyTeamAccess && teamHide && profileType != 'family'">
                    <ng-template #titleTemplate>
                        <b>Teams</b>
                    </ng-template>

                    <div class="col-md-12 px-4">
                        <div class="row m-0 justify-content-between align-items-center">
                            <h4 class="ant-modal-title fs-12">Teams</h4>
                            <!-- <div class="col-sm-4 seach-export-section"> -->
                                

                                <div class="row align-items-center justify-content-end">
                                    <div class="col-md-7 pr-1">
                                        <input type="text" (keyup)="onKeyUp($event)" class="form-control c-search-border" placeholder="Search">
                                    </div>
                                    <div class="col-md-4 d-flex justify-content-center">
                                        <button type="button" icon="fa-file-pdf-o" class="btn btn-cons btn-search excle-export-btn" [disabled]="exportTeamStatus" iconPos="right" label="CSV" (click)="exportTeamModel()">
                                            <img src="assets/img/xlsx.ico" width="21px" class="mr-2" />
                                            <span *ngIf="!exportTeamStatus">Excel Export</span>
                                            <span *ngIf="exportTeamStatus">Exporting...</span>
                                        </button>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                            <div class="row pt-4 pl-2 pr-2">
                                    <nz-spin *ngIf="!advizorFirmDba?.total" nzSimple [nzSize]="'default'" class="col-12 d-flex justify-content-center"></nz-spin>
                                    <div *ngFor="let item of advizorFirmDba?.data; trackBy: trackByFn" class="col-md-6 mb-2">
                                            <team-card [dba]="item" (openDbaContact)="openDbaContact(item)"></team-card>
                                    </div>
                            </div>
                            <div class="pagination-v2 pr-1 pt-1 pb-2">
                                    <span class="pl-3 text-secondary">{{ advizorFirmDba?.total | number }} {{ advizorFirmDba?.total === 1 ? 'team' : 'teams' }}</span>
                                    <div class="d-flex">
                                            <button
                                                class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1"
                                                (click)="previousDbaPage()"
                                                [disabled]="currentDbaPage === 1"
                                                [class.btn-disable]="currentDbaPage === 1">
                                                    <i class="fa fa-angle-left fs-12" aria-hidden="true"></i>
                                            </button>
                                            <button
                                                class="btn contact-pagination-btn btn-xs d-flex align-items-center m-1"
                                                (click)="nextDbaPage()"
                                                [disabled]="currentDbaPage === totalDbaPages"
                                                title="Next"
                                                [class.btn-disable]="currentDbaPage === totalDbaPages">
                                                    <i class="fa fa-angle-right fs-12" aria-hidden="true"></i>
                                            </button>
                                    </div>
                            </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="aumTemplate" *ngIf="profileType != 'family' && firmProfile?.advizor">
                    <ng-template #aumTemplate>
                        <b>AUM</b>
                    </ng-template>

                    <div class="row m-0" id="aum" *ngIf="firmProfile?.advizor" [ngClass]="{'scrollsection' : firmProfile?.advizor}">
                        <div class="col-md-12 px-4">
                            <div class="row mb-3" *ngIf="firmProfile?.historical_aum && firmProfile?.historical_aum.date">
                                <div class="col-md-12">
                                    <h4 class="ant-modal-title mb-2 fs-12">Assets Under Management</h4>
                                    <div echarts [options]="aumTotalChart" class="demo-chart"></div>
                                </div>
                            </div>

                            <div class="row mb-3" *ngIf="firmProfile?.historical_aum && firmProfile?.historical_aum.date">
                                <div class="col-md-12">
                                    <h4 class="ant-modal-title mb-2 fs-12">Accounts Under Management</h4>
                                    <div echarts [options]="aumTotalChart" class="demo-chart"></div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table table-hover table-condensed dataTable text-left color-table table-v2">
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Assets</th>
                                                <th>Accounts</th>
                                                <th>Avg. Account Size</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let aum of firmProfile?.aum; let i = index">
                                                <td>{{(aum.category == 'Non Discretionary') ? 'Non-Discretionary' : aum.category}}</td>
                                                <td>{{aum.assets | currency:'USD':'symbol':'1.0-2' }}</td>
                                                <td>{{aum.accounts | number : '1.0-2'}}</td>
                                                <td>{{aum.avg_account_size ? (aum.avg_account_size | currency:'USD':'symbol':'1.0-2') : '--'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{firmProfile?.aum_total.aum_assets | currency:'USD':'symbol':'1.0-2' }}</td>
                                                <td>{{firmProfile?.aum_total.aum_accounts | number : '1.0-2'}}</td>
                                                <td>{{firmProfile?.aum_total.aum_assets_per | currency:'USD':'symbol':'1.0-2'}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="smaTemplate" *ngIf="profileType != 'family' && firmProfile?.advizor && firmProfile?.total_sma > 0">
                    <ng-template #smaTemplate>
                        <b>SMA Assets</b>
                    </ng-template>

                    <!-- <div class="row m-0" id="sma" *ngIf="firmProfile?.advizor" [ngClass]="{'scrollsection' : firmProfile?.advizor}">
                        <div class="col-md-12 m-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="mt-0 table table-hover table-condensed dataTable text-left color-table table-v2">
                                        <thead>
                                            <tr>
                                                <th>Asset Type</th>
                                                <th>Percent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of firmProfile?.sma; let i = index">
                                                <td>{{item.asset_type}}</td>
                                                <td>{{item.percent}}%</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td><strong class="font-weight-bold">Total</strong></td>
                                                <td><strong class="font-weight-bold">{{firmProfile?.total_sma}}%</strong></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row m-0"*ngIf="firmProfile?.advizor " [ngClass]="{'scrollsection' : firmProfile?.advizor}">
                        <div class="col-md-12 px-4">
                            <h4 class="ant-modal-title mb-2 fs-14">Separately Managed Accounts Asset Types</h4>
                            <div echarts [options]="smaPieChart" class="pie-chart"></div>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="clientTemplate" *ngIf="firmProfile?.advizor">
                    <ng-template #clientTemplate>
                        <b>Clients</b>
                    </ng-template>

                    <div class="row m-0" id="clients" [ngClass]="{'scrollsection' : firmProfile?.advizor}">
                        <div class="col-md-12 px-4 mb-3">
                            <div class="row justify-content-between align-items-center m-0">
                                <h4 class="ant-modal-title fs-12">Clients</h4>
                                <div class="text">
                                    <h4 class="d-inline pr-4">
                                        <span class="grey-5 fs-12 fw-700 semi-letter-spacing">Planning Clients: </span>
                                        <span class="grey-5 fs-12 fw-700 semi-letter-spacing">{{firmProfile?.planning_clients ?? "-"}}</span>
                                    </h4>
                                    <h4 class="d-inline">
                                        <span class="grey-5 fs-12 fw-700 semi-letter-spacing">Advisory Clients: </span>
                                        <span class="grey-5 fs-12 fw-700 semi-letter-spacing">{{firmProfile?.advisory_clients | number : '1.0-2'}}</span>
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table table-hover table-condensed dataTable text-left color-table table-v2">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Assets</th>
                                                <th>Clients</th>
                                                <th>Assets %</th>
                                                <th>Avg. Client Size</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let client of firmProfile?.clients; let i = index">
                                                <td>{{client.type}}</td>
                                                <td>{{client.assets | currency:'USD':'symbol':'1.0-2' }}</td>
                                                <td>{{ isNumber(client.accounts) ? (client.accounts | number : '1.0-2') : client.accounts }}</td>
                                                <td>{{client.assets_percentage | percent:'1.2-2' }}</td>
                                                <td>{{client.avg_account_size ? (client.avg_account_size | currency:'USD':'symbol':'1.0-2') : '--'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{firmProfile?.clients_total.total_assets | currency:'USD':'symbol':'1.0-2' }}</td>
                                                <td>{{firmProfile?.clients_total.total_accounts | number : '1.0-2' }}</td>
                                                <td>{{firmProfile?.clients_total.total_assets_per | percent }}</td>
                                                <td>{{firmProfile?.clients_total.avg_account_size_total | currency:'USD':'symbol':'1.0-2'}}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>
                <nz-tab [nzTitle]="bankTrustTemplate" *ngIf="user.has_access_bank_and_trust && firmProfile?.bt && firmProfile?.crd">
                    <ng-template #bankTrustTemplate>
                        <b>Bank & Trust</b>
                    </ng-template>

                    <div class="row m-0" id="clients" [ngClass]="{'scrollsection' : firmProfile?.advizor}">
                        <div class="col-md-12 px-4 mb-3">
                            <div class="row mb-3">
                                <div class="col-md-12 general-info c-section">
                                    <h4 class="c-title m-0">Classification</h4>
                                    <div class="d-flex">
                                        <div class="c-flex-col pr-40 flex-fill">
                                            <span class="c-label">Bank Classification</span>
                                            <span class="fs-12">
                                                <ng-template [ngTemplateOutlet]="bankTrustClassificationCode" [ngTemplateOutletContext]="{firmProfile}"></ng-template>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            <div class="row mb-3">
                                <div class="col-md-12 general-info c-section">
                                    <h4 class="c-title m-0">General Information</h4>
                                    <div class="d-flex">
                                        <div class="c-flex-col pr-40 flex-fill">
                                            <span class="c-label">Total Assets</span>
                                            <span class="fs-12">
                                                {{addTextIfValueExists([
                                                    {value: firmProfile?.bank_trust?.total_bank_assets, text: formatMoney(firmProfile?.bank_trust?.total_bank_assets)}]) || '--'}}
                                            </span>
                                        </div>
                                        <div class="c-flex-col pr-40 flex-fill">
                                            <span class="c-label">Bank Assets</span>
                                            <span class="fs-12">
                                                {{addTextIfValueExists([
                                                    {value: firmProfile?.bank_trust?.trust_assets, text: formatMoney(firmProfile?.bank_trust?.trust_assets)}]) || '--'}}
                                            </span>
                                        </div>
                                        <div class="c-flex-col pr-40 flex-fill">
                                            <span class="c-label">Website</span>
                                            <a href="{{getAttribute('bank_url')}}" target="_blank" *ngIf="getAttribute('bank_url'); else showDashes"><i class="icon-size fa fa-globe"></i></a>
                                            <ng-template #showDashes>
                                                <span class="c-desc">--</span>
                                            </ng-template>
                                        </div>

                                        <div class="c-flex-col pr-40 flex-fill">
                                            <span class="c-label">Associated B-D</span>
                                            <span class="fs-14" *ngIf="firmProfile?.bank_trust?.associated_broker_dealer_crd?.length; else showDashes">
                                                <span *ngFor="let brokerDealer of firmProfile?.bank_trust?.associated_broker_dealer_crd let last = last; let i = index">
                                                    <span *ngIf="i != 0">
                                                        |
                                                    </span>
                                                    <a [routerLink]="['/advisor/firm', brokerDealer?.crd]" target="_blank">
                                                        {{brokerDealer?.name}}
                                                    </a>
                                                </span>
                                            </span>


                                            <ng-template #showDashes>
                                                <span class="c-desc">--</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                            <ng-template [ngTemplateOutlet]="bankTrustFiduciatyAssetsAndAccounts" [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                            <ng-template [ngTemplateOutlet]="bankTrustTrustInformation" [ngTemplateOutletContext]="{firmProfile, user}"></ng-template>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="holdingTemplate" *ngIf="holdingListStatus">
                    <ng-template #holdingTemplate>
                        <b>13F Holdings</b>
                    </ng-template>

                    <div class="row" id="firm_holdings" class="scrollsection">
                        <div class="col-md-12 mb-3">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="row align-items-center">
                                        <div class="col-md-6 pr-0">
                                            <span class="fs-12 mr-3"><b>Holdings</b></span>
                                            <pg-select style="width: 70%;" [Mode]="'single'" [PlaceHolder]="'Type'" [(ngModel)]="holding_date"
                                                [NotFoundContent]="'Not found'" name="type" (ngModelChange)="changeHolding()">
                                                <pg-option *ngFor="let option of holdingDates" [Label]="option.label" [Value]="option.value"
                                                    [Disabled]="option.disabled">
                                                </pg-option>
                                            </pg-select>
                                        </div>
                                        <div class="col-md-2 p-0 fs-12">
                                            ETF: {{ (filling?.etf_percentage) ? filling?.etf_percentage : 0 }}%
                                        </div>
                                        <div class="col-md-1 p-0 fs-12">
                                            MF: {{ (filling?.mf_percentage) ? filling?.mf_percentage : 0 }}%
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="row align-items-center justify-content-end">
                                        <div class="col-md-7 pr-0">
                                            <input type="text" name="holding_search" (keyup)="onHoldingKeyUp($event)" class="form-control fs-12 border-light-grey" placeholder="Search">
                                        </div>
                                        <div class="col-md-4 d-flex justify-content-center" *ngIf="user?.ap_user_limit_csv">
                                            <!-- <a href="{{excel_hold_link}}" *ngIf="isHoldDownload" target="_blank" class="btn btn-success pr-2"
                                                (click)="downloadHoldingReport()">Download Report</a> -->
                                            <button type="button" icon="fa-file-pdf-o" class="btn btn-cons btn-search excle-export-btn" [disabled]="exportHoldStatus" iconPos="right" label="CSV" (click)="exportHoldModel()">
                                                <img src="assets/img/xlsx.ico" width="21px" class="mr-2" />
                                                <span *ngIf="!exportHoldStatus">Excel Export</span>
                                                <span *ngIf="exportHoldStatus">Exporting...</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-4">
                                <div class="col-lg-12 table-responsive">
                                    <ngx-datatable
                                        class='table table-hover table-v2'
                                        [rows]="holdings?.data"
                                        [columnMode]="columnModeSetting"
                                        [headerHeight]="43"
                                        [footerHeight]="50"
                                        [rowHeight]="'auto'"
                                        [scrollbarH]="scrollBarHorizontal"
                                        [externalPaging]="true"
                                        [count]="holdings?.total"
                                        [offset]="paginateHolding.pageNumber"
                                        [limit]="paginateHolding.limit"
                                        (page)='sortHoldingData($event)'
                                        [externalSorting]="true"
                                        (sort)="onHoldingSort($event)"
                                        >

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" prop="name_of_issuer" name="Issuer Name">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.name_of_issuer}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" prop="title_of_class" name="Class">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.title_of_class}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column cellClass="d-flex align-items-center" [sortable]="true" name="Cusip">
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" name="Ticker">
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" name="Value">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.value | currency:'USD':'symbol':'1.0-2' }}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" name="%" prop="percentage">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.percentage | percent:'1.2-2'}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" name="Shares" prop="shrs_or_prn_amt">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{row.shrs_or_prn_amt | number : '1.0-2'}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column [resizeable]="false" cellClass="d-flex align-items-center" [sortable]="true" name="M* Category" prop="mstar_category">
                                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                {{ row.mstar_category }}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-footer>
                                            <ng-template
                                              ngx-datatable-footer-template
                                              let-rowCount="rowCount"
                                              let-pageSize="pageSize"
                                              let-selectedCount="selectedCount"
                                              let-curPage="curPage"
                                              let-offset="offset"
                                              let-isVisible="isVisible">
                                                <div class="pagination-v2">
                                                    <div class="page-count p-0">
                                                        <span class="page-total">
                                                            {{ rowCount?.toLocaleString() }} total entries
                                                        </span>
                                                   </div>
                                                   <datatable-pager
                                                       class="custom-datatable-pager"
                                                       [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                       [pagerRightArrowIcon]="'datatable-icon-right'"
                                                       [pagerPreviousIcon]="'datatable-icon-prev'"
                                                       [pagerNextIcon]="'datatable-icon-skip'"
                                                       [page]="curPage"
                                                       [size]="pageSize"
                                                       [count]="rowCount"
                                                       [hidden]="!((rowCount / pageSize) > 1)"
                                                       (change)="sortHoldingData($event)">
                                                   </datatable-pager>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-footer>
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="contactTemplate" *ngIf="profileType == 'family' && firmProfile?.portfolio_companies.length > 0">
                    <ng-template #contactTemplate>
                        <b>Portfolio Companies</b>
                    </ng-template>

                    <div class="row"  class="scrollsection">
                        <div class="col-lg-12 table-responsive">
                                <!--  -->
                            <table class="table table-hover table-condensed dataTable text-left color-table table-v2" *ngIf="firmProfile?.portfolio_companies.length > 0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Industry</th>
                                        <th>URL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of firmProfile?.portfolio_companies; let i = index">
                                        <td>{{item.name}}</td>
                                        <td>{{item.type }}</td>
                                        <td>{{item.industry}}</td>
                                        <td><a href="javascript:void(0)" (click)="openPortLink(item.url)">{{item.url}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-condensed" *ngIf="!firmProfile?.portfolio_companies.length">
                                <tbody>
                                    <tr>
                                        <td class="all-caps fs-12 w-100 no-data text-left" colspan="2">Data not found.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </nz-tab>

                <nz-tab [nzTitle]="titleKBenchmark" *ngIf="firmProfile?.db || firmProfile?.dc">
                        <ng-template #titleKBenchmark>
                                <b>Benchmark</b>
                        </ng-template>
                        <afm-tab-k-benchmark [ackId]="firmProfile?.ack_id"></afm-tab-k-benchmark>
                </nz-tab>

                <nz-tab [nzTitle]="titleKDiversification" *ngIf="firmProfile?.db || firmProfile?.dc">
                        <ng-template #titleKDiversification>
                                <b>Diversification</b>
                        </ng-template>
                        <afm-tab-k-diversification [ackId]="firmProfile?.ack_id"></afm-tab-k-diversification>
                </nz-tab>

                <nz-tab [nzTitle]="titleKFundAnalysis" *ngIf="firmProfile?.db || firmProfile?.dc">
                        <ng-template #titleKFundAnalysis>
                                <b>Fund Analysis</b>
                        </ng-template>
                        <afm-tab-k-fund-analysis [ackId]="firmProfile?.ack_id"></afm-tab-k-fund-analysis>
                </nz-tab>

                <nz-tab [nzTitle]="titleKFeesAnalysis" *ngIf="firmProfile?.db || firmProfile?.dc">
                        <ng-template #titleKFeesAnalysis>
                                <b>Fees Analysis</b>
                        </ng-template>
                        <afm-tab-k-fees-analysis [ackId]="firmProfile?.ack_id"></afm-tab-k-fees-analysis>
                </nz-tab>

                <nz-tab [nzTitle]="noteTemplate" *ngIf="profileType != 'family'">
                    <ng-template #noteTemplate>
                        <b>Notes & Tasks</b>
                    </ng-template>

                    <div class="row">
                        <div class="col-md-12">
                            <div id="notes_tasks" class="scrollsection">
                                <advizor-notes-and-tasks *ngIf="crd" type="firm" profile_id="{{crd}}"></advizor-notes-and-tasks>
                            </div>
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </div>
    </div>
</ng-container>

<nz-modal nzCentered [(nzVisible)]="adv_model" nzWidth="600px" [nzContent]="advModelContent" nzTitle="ADV / ADV brochures" [nzFooter]="null" (nzOnCancel)="closeModal(1)" >
    <ng-template #advModelContent>
		<div class="row">
            <div class="col-12">
                <a href="https://reports.adviserinfo.sec.gov/reports/ADV/{{crd}}/PDF/{{crd}}.pdf" target="_blank">ADV</a>
            </div>
            <div class="col-12">
                <p *ngIf="!brochures?.brochure_reports"><b>Searching for ADV brochures...</b></p>
            </div>
		</div>
        <ng-container *ngIf="brochures.brochure_reports">
            <div class="row" *ngFor="let item of brochures.brochure_reports">
                <div class="col-12">
                    <a href="{{item.url}}" target="_blank">{{item.name}} (ADV Brochure)</a>
                </div>
            </div>
        </ng-container>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="limit_over_model" nzWidth="600px" [nzContent]="limitOverContent" nzTitle="Download Limit Reached" [nzFooter]="null" (nzOnCancel)="closeModal(2)" >
    <ng-template #limitOverContent>
		<div class="row">
            <p class="text-primary">Your report creation limit has been reached for this month. It will reset on the first day of next month.</p>
		</div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="waitingBox" [nzContent]="waitingBoxContent"  [nzFooter]="null" (nzOnCancel)="closeModal(3)">
    <ng-template #waitingBoxContent>
		<div class="row">
            <h4 class="p-b-5">{{downloadMessage.status_message}}</h4>
            <div class="mr-3">
                <div *ngIf="downloadMessage.status; else erroIcon">
                    <nz-progress [nzPercent]="percent_complete" nzStatus="active"></nz-progress>
                </div>
                <ng-template #erroIcon>
                    <i class="fa fa-exclamation-triangle d-flex justify-content-center fa-2x text-danger" aria-hidden="true"></i>
                </ng-template>
            </div>
		</div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="firm_web" nzWidth="600px" [nzContent]="firmWebContent" nzTitle="Websites" [nzFooter]="firmWebFooter" (nzOnCancel)="closeModal(4)" >
    <ng-template #firmWebContent>
		<div class="row">
            <div class="col-lg-12 mt-2" *ngFor="let keyword of firmAttributes;">
                <a href="javascript:void(0)" (click)="logAttribute(keyword)" class="fs-12">{{ keyword.value }}</a>
            </div>
        </div>
    </ng-template>
    <ng-template #firmWebFooter>
        <div class="row">
            <div class="col-lg-12 m-t-20">
                <p class="pull-right">
                    <button type="button" class="btn btn-cons btn-primary" (click)="closeModal(4)">Close</button>
                </p>
            </div>
        </div>
    </ng-template>
</nz-modal>

<team-modal [id]="dbaContactId" [dbaData]="dbaData"></team-modal>

<nz-modal nzCentered [(nzVisible)]="advisor_follow_type_model" nzWidth="600px" [nzContent]="advFollowTypeContent"
    [nzFooter]="advFollowTypeContentFooter" [nzTitle]="advFollowTypeTitle" (nzOnCancel)="advFollowTypeContentCancel()"
    (nzOnOk)="onAdvisorFollowType()">
    <ng-template #advFollowTypeTitle>
        Follow {{isScrollMenuType | titlecase}}
    </ng-template>
    <ng-template #advFollowTypeContent>
        <div class="row">
            <div class="col-md-12">
                <h5>Select Status</h5>
                <pg-select style="width: 100%;" [Mode]="'single'" [PlaceHolder]="'Select'"
                    [(ngModel)]="advisor_follow_status" [NotFoundContent]="'Not found'" name="advisor_follow_status">
                    <pg-option *ngFor="let option of statusList" [Label]="option" [Value]="option">
                    </pg-option>
                </pg-select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-3 form-group">
                <input type="radio" id="private_label" name="advisor_follow_type" [(ngModel)]="advisor_user_follow_type"
                    value="private">
                <label for="private_label" style="margin-left:0.5rem;">Private</label>
            </div>
            <div class="col-lg-3 form-group">
                <input type="radio" id="firm_label" name="advisor_follow_type" [(ngModel)]="advisor_user_follow_type"
                    value="firm">
                <label for="firm_label" style="margin-left:0.5rem;">Firm</label>
            </div>
        </div>
    </ng-template>
    <ng-template #advFollowTypeContentFooter>
        <div class="row">
            <div class="col-lg-12 m-t-20">
                <p class="pull-right">
                    <button type="button" class="btn btn-cons btn-rounded primary-btn-grn"
                        (click)="onAdvisorFollowType()">Save</button>
                </p>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="advisor_firm_tag_model" nzWidth="900px" [nzContent]="advFirmTagContent"
    [nzFooter]="null" (nzOnCancel)="advFirmTagCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #advFirmTagContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b>Firm Tags</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-md-6 pl-0" *ngFor="let tags of firmProfile?.firm_tags;">
                            <label class="d-block">{{ tags.tag_category }}</label>
                            <span *ngFor="let keyword of tags.firm_tags;">
                                <span *ngIf="!keyword?.url" [style.background-color]="keyword?.color"
                                    class="mr-2 mb-2 badge badge-primary">
                                    {{keyword?.keyword_display}}
                                </span>
                                <a *ngIf="keyword?.url" href="{{keyword.url}}" target="_blank"
                                    [style.background-color]="keyword?.color" class="mr-2 mb-2 badge badge-primary">
                                    {{keyword?.keyword_display}}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>

<nz-modal nzCentered [(nzVisible)]="tech_stack_modal" nzWidth="500px" [nzContent]="advTechStackContent"
    [nzFooter]="null" (nzOnCancel)="advTechStackCancel()" [nzWrapClassName]="'firmTagModal'">
    <ng-template #advTechStackContent>
        <div class="row m-0 c-modal">
            <div class="col-lg-12 p-0">
                <div class="c-modal-header pl-0">
                    <b>Platforms</b>
                </div>
                <div class="c-modal-body">
                    <div class="row m-0">
                        <div class="col-md-12 pl-0">
                            <span *ngFor="let keyword of platforms;">
                                <span *ngIf="!keyword?.url" [style.background-color]="keyword?.color"
                                    class="mr-2 mb-2 badge badge-primary">
                                    {{keyword?.keyword_display}}
                                </span>
                                <a *ngIf="keyword?.url" href="{{keyword.url}}" target="_blank"
                                    [style.background-color]="keyword?.color" class="mr-2 mb-2 badge badge-primary">
                                    {{keyword?.keyword_display}}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</nz-modal>
