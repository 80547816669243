import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-bulk-modal",
  templateUrl: "./bulk-modal.component.html",
  styleUrls: ["./bulk-modal.component.scss"],
})
export class BulkModalComponent implements OnInit {
  private _search: Record<string, any> = {};
  public isOpen = false;

  @Output() makeSearch = new EventEmitter<void>();
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() title = "";
  @Input() label?: string;
  @Input() placeholder = "";
  @Input() name = "";
  @Input() note = "";

  @Input()
  get search(): Record<string, any> {
    return this._search;
  }

  set search(value: Record<string, any>) {
    this._search = value;
  }

  constructor(private modalService: ModalService) {}

  public ngOnInit(): void {
    this.modalService.getModalState(this.name).subscribe((state: boolean) => {
      this.isOpen = state;
    });
  }

  public closeModal(): void {
    this.modalService.closeModal(this.name);
  }

  public executeSearch(): void {
    this.makeSearch.emit();
    this.closeModal();
  }
}
