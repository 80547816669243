<div class="tags-control" #wrapper>
    <pg-tag *ngFor="let tag of _tags; let i = index;"
    	(Close)="handleClose(tag)"
    	[Closable]="i !== -1">
        {{sliceTagName(tag)}}
    </pg-tag>
    <input
    	(blur)="handleInputConfirm()"
    	(focus)="handleFocus()"
    	(focusout)="handleFocusOut()"
    	(keydown.backspace)="handleInputBack()"
    	(keydown.enter)="handleInputConfirm()"
    	[(ngModel)]="inputValue"
    	class="form-control"
    	placeholder="{{_placeholder}}"
    	style="width: 78px;"
    	type="{{_inputType}}">
</div>