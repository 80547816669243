import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvestmentService {

    private _investments: BehaviorSubject<any>;
    private _status: Subject<any>;
    private dataSource: any = {
        investments : {}
    }
    checkData: any;

    constructor(private http: HttpClient) {
        this._investments = new BehaviorSubject(this.dataSource.investments);
        this._status = new Subject();
    }

    public get $investments(): Observable<any> {
        return this._investments.asObservable();
    }

    public get investments(): any {
        return this.dataSource.investments;
    }

    public set investments(value: any) {
        this.dataSource.investments = value;
        this._investments.next(Object.assign({}, this.dataSource).investments);
    }

    public get $status(): Observable<any> {
        return this._status.asObservable();
    }

    public get status(): Array<any> {
        return this.dataSource.status;
    }

    public set status(value: Array<any>) {
        this.dataSource.status = value;
        this._status.next(Object.assign({}, this.dataSource).value);
    }

    updateInvestment(ack_id, request) {
        return new Promise((resolve, reject) => {
            this.http.post('/api/investment-pdf/'+ack_id, request).subscribe(
                (res: any) => {
                    this.investments = {
                        job_id : res.data.job_id,
                        data : res.data,
                        count : 0,
                        isInProgress : true,
                        isLoader : true,
                        isError : false,
                        reportId: res.reportId
                    };
                    resolve(res.data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    loadInvestmentExtractedData(id){
        return this.http.get('/api/investment-pdf/'+id);
    }

    getStatusInvestmentPdf(id) {
        return this.http.get('/api/investment-pdf/status/'+id);
    }

}
