<div class="card">
	<div class="content-bd-ria">
		<app-icon-dataset [iconTooltip]="toolTipIconOrnament" [icons]="getIcons({
			'advizor': dbaContact?.advizor,
			'broker': dbaContact?.broker,
			'insurance': dbaContact?.insurance,
			'fo': dbaContact?.fo_id,
			'bt': dbaContact?.bt_id,
			'dc': dbaContact?.dc,
			'db': dbaContact?.db
		})" />
	</div>
	<div class="d-flex flex-column mt-3 w-50 mb-2">
		<div class="w-100 content-img relative">
			<div *ngIf="dbaContact?.photo_url" class="circle-container mt-2"
				[ngStyle]="{'animation': dbaContact?.score && dbaContact?.score!= 'Not a Fit'  ? 'leadScorePulse 2s infinite' : 'none'}">
				<a [routerLink]="getLinkType()" target="_blank">
					<img [src]="dbaContact.photo_url" alt="person photo" />
				</a>
				<app-lead-score-badge [lead-score-type]="dbaContact?.score" [size]="'sm'" />
			</div>
			<span *ngIf="!dbaContact?.photo_url" class="avatar mt-2"
				[ngStyle]="{'animation': dbaContact?.score && dbaContact?.score!= 'Not a Fit'  ? 'leadScorePulse 2s infinite' : 'none'}">
				<a [routerLink]="getLinkType()" target="_blank">
					<i class="avatar-initial">{{ getInitials(dbaContact?.first_name) +
						getInitials(dbaContact?.last_name) }}</i>
				</a>
				<app-lead-score-badge [lead-score-type]="dbaContact?.score" [size]="'sm'" />
			</span>
		</div>

	</div>
	<div class="content-detail mb-2 text-center">
		<a [routerLink]="getLinkType()" target="_blank">
			<span class="m-0 content-name">
				{{ dbaContact?.first_name | uppercase }}
				{{ dbaContact?.last_name | uppercase }}
			</span>
		</a>
		<p class="m-0 fs-10 d-block" style="line-height: 11px;">
			<span class="title fs-10" [innerHTML]="titleForDisplay ? (titleForDisplay | uppercase) : '&nbsp;'"></span>
			<br />
			<br />
			<span class="fs-11 firm-name d-block" *ngIf="dbaContact?.firm_name">
				<i class="far fa-building text-primary ng-star-inserted"></i>
				<a [routerLink]="dbaContact?.firm_url" target="_blank">
					{{ dbaContact?.firm_name | uppercase }}
				</a>
			</span>
		</p>
		<ng-container *ngIf="dbaContact?.firms">
			<p class="fs-10 d-block mt-2" style="line-height: 12px;">
				<span class="grey-2 fs-11">CRD </span>
				<span class="black-1 fs-11">{{ dbaContact?.crd }}</span>
			</p>
			<p class="fs-10 d-block mb-3" style="line-height: 12px;">
				<span class="grey-2 fs-11">Ownership </span>
				<span class="black-1 fs-11">{{ getOwnership() }}</span>
			</p>
		</ng-container>
		<p class="fs-10 d-block mt-2 mb-2" style="line-height: 12px;" *ngIf="dbaContact?.address">
			<span class="address">{{contactAddress.address}}</span>
			<br />
			{{contactAddress.extra}}
		</p>
		<div class="content-icons min-h-16 text-center flex">
			<a>
				{{' '}}
			</a>
			<a href="mailto:{{ dbaContact?.email }}" target="_blank" *ngIf="dbaContact?.email" class="ml-1"
				title="{{ dbaContact?.email }}"><i class="fas fa-envelope" aria-hidden="true"></i></a>
			<a href="mailto:{{ dbaContact?.personal_email }}" target="_blank" *ngIf="dbaContact?.personal_email"
				class="ml-1" title="{{ dbaContact?.personal_email }}"><i class="far fa-envelope personal_email"
					aria-hidden="true"></i></a>
			<a href="tel:{{ dbaContact?.phone }}" title="{{ dbaContact?.phone }}" *ngIf="dbaContact?.phone"
				class="ml-1"><i class="fas fa-phone-alt" aria-hidden="true"></i></a>
			<a href="{{ dbaContact?.bio_url }}" title="{{ dbaContact?.bio_url }}" target="_blank"
				*ngIf="dbaContact?.bio_url" class="ml-1"><i class="fas fa-user" aria-hidden="true"></i></a>
			<a href="{{ dbaContact?.linkedin }}" title="{{ dbaContact?.linkedin }}" target="_blank"
				*ngIf="dbaContact?.linkedin" class="ml-1"><i class="fab fa-linkedin" aria-hidden="true"></i></a>
			<a href="{{ dbaContact?.team_website }}" rel="noreferrer noopener nofollow"
				title="{{ dbaContact?.team_website }}" target="_blank" *ngIf="dbaContact?.team_website" class="ml-1"><i
					class="fa fa-globe" aria-hidden="true"></i></a>
			<a>
				{{' '}}
			</a>
		</div>
	</div>
</div>