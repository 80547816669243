import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {

  @ViewChild('root') root;

  unsubscribe = false;
  advPro = false;

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    let url = this.router.url.split('/');
    if (url && url[1] == 'unsubscribe') {
      this.unsubscribe = true;
    }


    let url_ap = this.router.url.split('?');
    if (url_ap && url_ap[1] == 'm=ap') {
      this.advPro = true;
    }
  }

}
