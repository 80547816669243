import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Subscription, interval } from "rxjs";

import { ProfileService } from "../../../services/profile.service";
import { ReportService } from "../../../services/report.service";
import { Helper } from "../../../helper";

interface FeesAnalysisResponse {
  status: string;
  response: FeesData;
}

interface FeesData {
  universe: string;
  min_cor: string;
  etf: number;
  outperform_3yr: number;
  twelve_bone: boolean;
  plan_id: number;
  list: FeesItem[];
  anul_horizon: string;
  anul_contribution: number;
  anul_rate_return: string;
  anul_fee_increase: string;
  anul_decrease_fee: string;
  anul_save_plan: number;
  total_calculation: TotalCalculation;
}

interface FeesItem {
  id: number;
  ack_id: string;
  current_ticker: string;
  current_fund: string;
  current_value: string;
  current_expense_ratio: string;
  alt_ticker: string;
  alt_low_fee: string;
  alt_correlation: string;
  alt_expense_ratio: string;
  saving: number;
  created_at: string;
  updated_at: string;
  fee_id: number;
  class: string | null;
  alt_class: string | null;
  ufa_id: string | null;
  ufa_type: string | null;
  twelveBone: string | null;
  alt_twelveBone: string | null;
  portfolio_percent: number;
}

interface TotalCalculation {
  saving: number;
  current_value: number;
  total_exp: number;
  total_alt_exp: number;
}

interface Sort {
  column: any;
  newValue: string;
  prevValue: string | undefined;
  sorts: {
    dir: string;
    prop: string;
    type?: string;
  }[];
}

@Component({
  selector: "afm-tab-k-fees-analysis",
  templateUrl: "./tab-401k-fees-analysis.component.html",
  styleUrls: ["./tab-401k-fees-analysis.component.scss"],
})
export class Tab401kFeesAnalysisComponent implements OnChanges {
  public loading = true;
  public viewData: FeesData | null = null;
  public waitingBox = false;
  public downloadMessage: { status_message: string; status: number } | null =
    null;
  public percent_complete = 0;

  public columnModeSetting: string =
    window.innerWidth < 960 ? "standard" : "force";
  public scrollBarHorizontal: boolean = window.innerWidth < 960;
  public messages: { emptyMessage: string } = {
    emptyMessage: "",
  };

  private feeExport: Subscription;

  @Input() ackId: string | null = null;

  constructor(
    private readonly profileService: ProfileService,
    private readonly reportService: ReportService,
    private readonly _helper: Helper
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.ackId) {
      if (!this.ackId) {
        return;
      }

      this.loading = true;
      this.viewData = null;

      this.getFees(this.ackId);
    }
  }

  public getFees(ackId: string): void {
    this.profileService
      .getFees({ id: ackId })
      .subscribe((res: FeesAnalysisResponse) => {
        if (res?.status === "200" && res?.response)
          this.viewData = res.response;
        this.loading = false;
      });
  }

  public export(): void {
    if (!this.ackId) return;

    this.waitingBox = true;
    this.downloadMessage = {
      status_message: "Your report is processing...",
      status: 1,
    };

    this.profileService.getFees({ id: this.ackId, isExport: 1 }).subscribe(
      (response: any) => {
        if (response.status === "200") {
          this.feeExport = interval(1500).subscribe(() => {
            this.checkPlanReport(response.reportId);
          });
        }
      },
      (error) => {
        this._helper.errorMessage(
          error,
          "Error while download excel, Please try again."
        );
        this.waitingBox = false;
      }
    );
  }

  private checkPlanReport(id: number): void {
    this.reportService.getReportStatus(id).subscribe((data: any) => {
      this.percent_complete = data.percent_complete;
      if (data.status === "complete") {
        this.waitingBox = false;
        this.feeExport.unsubscribe();
        window.open(data.link);
      } else if (data.status === "error") {
        this.downloadMessage.status_message = data.status_message;
        this.downloadMessage.status = 0;
        this.feeExport.unsubscribe();
      }
    });
  }

  public closeModal(): void {
    this.waitingBox = false;
  }

  public onSort($event: Sort): void {
    this.profileService.fee_sort = { fund_sort: $event.sorts[0] };
    if (this.ackId) this.getFees(this.ackId);
  }
}
