import { Auth } from './auth.service';
import { AuthGuard } from '../auth.guard';
import { JwtInterceptor } from './jwt-interceptor';
import { Settings } from '../../settings';
import { PlanService } from './plan.service';
import { InvestmentService } from './investment.service';
import { ProfileService } from './profile.service';
import { UserService } from './user.service';
import { AdvizorProService } from './advizor-pro.service'; 
import { MapService } from './map.service';
import { SalesForceService } from './salesforce.service';
import { VersionCheckService } from './version-check.service';
import { AdminDashboardService } from './admin-dashboard.service';
import { UserNotificationService } from './user-notification.service';
import { InsuranceProService } from './insurance-pro.service';

export const Services = [
    Settings,
    Auth,
    AuthGuard,
    JwtInterceptor,
    PlanService,
    InvestmentService,
    ProfileService,
    UserService,
    AdvizorProService, 
    MapService,
    SalesForceService,
    VersionCheckService,
    AdminDashboardService,
    UserNotificationService,
    InsuranceProService
]
