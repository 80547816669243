import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmailAutomationService {

    constructor(
        private http: HttpClient
    ) { }

    getAutomationSettings() {
        return this.http.get('/api/automation/automation-settings');
    }

    setAutomationSettings(data: any) {
        return this.http.post('/api/automation/automation-settings', data);
    }
    deleteAutomationSettings(){
        return this.http.delete('/api/automation/automation-settings');
    }

    getEmailTemplates(extraData) {
        return this.http.get('/api/automation/email-templates', { params: { limit: extraData.per_page, page: extraData.current_page } });
    }
    getEmailTemplatesPreview(tempId) {
        return this.http.get('/api/automation/preview/' + tempId);
    }
    setEmailTemplates(data: any) {
        return this.http.post('/api/automation/email-templates', data);
    }
    deleteEmailTemplate(id) {
        return this.http.delete('/api/automation/email-templates/' + id);
    }

    getEmailActivity(extraData) {
        return this.http.get('/api/automation/email-activity', { params: { limit: extraData.per_page, page: extraData.current_page, sortField: extraData.sortField, sortOrder: extraData.sortOrder, search: extraData.search } });
    }

    submitCode(data) {
        return this.http.post('/api/automation/office365', data);
    }

    getFields() {
        return this.http.get('/api/automation/fields').pipe(
            map((response:any) => {
                return response.response;
            }),
        );
    }

    getVerificationStatus() {
        return this.http.get('/api/automation/sendGrid');
    }

    resendVerificationEmail() {
        return this.http.post('/api/automation/sendGrid', {});
    }

    sendEmail(data) {
        return this.http.post('/api/automation/send', data);
    }

    uploadImage(data) {
        return this.http.post('/api/automation/image', data);
    }
}
