<div class="row" class="scrollsection content-k-benchmark pb-2">
  <div
    *ngIf="loading; else elseBlock"
    class="col align-self-center d-flex justify-content-center pt-4 pb-4"
  >
    <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
  </div>
  <ng-template #elseBlock>
    <div *ngIf="participationRate" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Participation Rate</h4>
      <div echarts [options]="participationRate" class="demo-chart"></div>
    </div>
    <div *ngIf="adminFees" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Admin Fees</h4>
      <div echarts [options]="adminFees" class="demo-chart"></div>
    </div>
    <div *ngIf="averageParticipantContribution" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Average Participant Contribution</h4>
      <div
        echarts
        [options]="averageParticipantContribution"
        class="demo-chart"
      ></div>
    </div>
    <div *ngIf="employerContribution" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Employer Contribution</h4>
      <div echarts [options]="employerContribution" class="demo-chart"></div>
    </div>
    <div *ngIf="percentOfRetirees" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Percent of Retirees</h4>
      <div echarts [options]="percentOfRetirees" class="demo-chart"></div>
    </div>
    <div *ngIf="rateOfReturn" class="col-sm-12 col-md-6 pt-2 pb-2">
      <h4 class="fs-12 bold m-0">Rate of Return</h4>
      <div echarts [options]="rateOfReturn" class="demo-chart"></div>
    </div>
  </ng-template>
</div>
