import {
	Component,
	Input,
	OnInit,
	OnChanges,
	SimpleChanges,
} from '@angular/core'

import { ModalService } from '../../services/modal.service'
import { AdvizorProService } from '../../services/advizor-pro.service'
import {
	DbaContactData,
	Dba,
	DbaContact,
	DbaContactResponse,
} from '../../interfaces/dba.interface'
import { getIconsDataset } from '../../utils/icon-utils'

interface Paginate {
	pageNumber: number
	limit: number
}

@Component({
	selector: 'team-modal',
	templateUrl: './team-modal.component.html',
	styleUrls: ['./team-modal.component.scss'],
})
export class TeamModalComponent implements OnInit, OnChanges {
	private paginate: Paginate = { pageNumber: 0, limit: 6 }

	public isOpen = false
	public isLoading = false
	public dbaContactData: DbaContactData | null = null
	public currentPage = 1
	public totalPages = 1

	@Input() id = ''
	@Input() dbaData: Dba | null = null
	toolTipIconOrnament: Record<string, string> | null = null

	constructor(
		private readonly modalService: ModalService,
		private readonly advizorProService: AdvizorProService
	) {
		this.advizorProService.$advTooltip.subscribe(value => {
            if (value) {
                this.toolTipIconOrnament = getIconsDataset(value as Record<string, string>);
            }
        });
	}

	public ngOnInit(): void {
		this.modalService.getModalState(this.id).subscribe((state: boolean) => {
			this.isOpen = state
		})
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.dbaData) {
			this.dbaContactData = null
			this.paginate = { pageNumber: 0, limit: 6 }
			this.currentPage = 1
			this.totalPages = 1
			this.getFirmDbaContact(this.dbaData?.id)
		}
	}

	private getFirmDbaContact(firm_dba_id: number): void {
		if (this.isOpen && firm_dba_id) {
			this.isLoading = true
			const args = {
				limit: this.paginate.limit,
				page: this.paginate.pageNumber,
				advizor_firm_dba_id: firm_dba_id,
			}
			this.advizorProService
				.dbaContact(args)
				.subscribe((res: DbaContactResponse) => {
					if (res.status === '200' && res.data) {
						this.dbaContactData = res.data
						this.totalPages = Math.ceil(res?.data?.total / this.paginate.limit)
						this.currentPage = res?.data?.current_page
					}
				})
			this.isLoading = false
		}
	}

	public nextPage(): void {
		if (this.currentPage < this.totalPages) {
			this.currentPage++
			this.paginate.pageNumber = this.currentPage
			this.getFirmDbaContact(this.dbaData?.id)
		}
	}

	public previousPage(): void {
		if (this.currentPage > 1) {
			this.currentPage--
			this.paginate.pageNumber = this.currentPage
			this.getFirmDbaContact(this.dbaData?.id)
		}
	}

	public closeModal(): void {
		this.modalService.closeModal(this.id)
	}

	public getInitials(name: string | null): string {
		if (!name) return ''
		const words: string[] = name.split(' ')
		return words
			.slice(0, 1)
			.map((word) => word.charAt(0).toUpperCase())
			.join('')
	}

	public simplifyUrl(url: string | null): string {
		if (!url) return ''
		return url.replace(/^(https?:\/\/)?(www\.)?/, '').split(/[/?#]/)[0]
	}

	public trackByFn(index: number, item: DbaContact): string {
		return `${item?.first_name}${index}`
	}
}
