import { Component, Input } from '@angular/core';
import { Icon } from './icons';
@Component({
  selector: 'app-icon-dataset',
  templateUrl: './icon-dataset.component.html',
  styleUrls: ['./icon-dataset.component.scss']
})
export class IconDatasetComponent {
  @Input() iconSize: 'small' | 'normal' = 'small';
  @Input() iconExtraClass = '';
  @Input() isAnchor = false;
  @Input() target = '_blank';
  @Input() href: object = {};
  @Input() icons: Icon[] = [];
  @Input() iconTooltip: object = {};

  getTooltip(type: string): string {
    return this.iconTooltip?.[type] || '';
  }
}
