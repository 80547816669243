import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable()
export class Auth {
  
  public redirectUrl: string;

  constructor(
    private http: HttpClient,
    private _router: Router,
    public jwtHelper: JwtHelperService,
  ) { }

  loggedIn() {
    const token: string = localStorage.getItem('token');
		if(token){
			return token != null && !this.jwtHelper.isTokenExpired(token);
    }

		return false;
  }

  login(token) {
    localStorage.setItem('token', token);
    //this.cookieService.put('token', token);
  }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
    //this.cookieService.putObject('current_user', user);
  }
  updateUser(user: any) {
    let old_user: any = this.getUser();
    let new_user: any = Object.assign({}, old_user, user);
    localStorage.setItem('user', JSON.stringify(new_user));
    //this.cookieService.putObject('current_user', new_user);
  }
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
    //return this.cookieService.getObject('current_user');
  }
  getToken() {
    return localStorage.getItem('token');
    //return this.cookieService.get('token');
  }

  setExistToken(token) {
    localStorage.setItem('existing_token', token);
  }

  getExistToken() {
    return localStorage.getItem('existing_token');
  }

  setExistUser(user:any) {
    localStorage.setItem('existing_user', JSON.stringify(user));
  }

  getExistUser() {
    return JSON.parse(localStorage.getItem('existing_user'));
  }

  setExcelCounter(count) {
    localStorage.setItem('excel_count', count);
  }

  setPdfCounter(count) {
    localStorage.setItem('pdf_count', count);
  }

  getExcelCounter() {
    return localStorage.getItem('excel_count');
  }

  getPdfCounter() {
    return localStorage.getItem('pdf_count');
  }

  /*
  setAdvisorExcelCounter(count) {
    localStorage.setItem('advisor_excel_count', count);
  }*/

  setAdvisorPdfCounter(count) {
    localStorage.setItem('advisor_pdf_count', count);
  }
  /*
  getAdvisorExcelCounter() {
    return localStorage.getItem('advisor_excel_count');
  }
  */

  getAdvisorPdfCounter() {
    return localStorage.getItem('advisor_pdf_count');
  }

  setVerification(userData: any) {
    localStorage.setItem('user_data', JSON.stringify(userData));
  }

  getVerification() {
    return JSON.parse(localStorage.getItem('user_data'));
  }


  logout() {

    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  refreshToken(): Observable<any> {
		const token: string = localStorage.getItem('token');
		return this.http.post<any>("/api/auth/refresh-token", { 'token': token })
			.pipe(
				map(user => {
					if (user?.access_token) {
						localStorage.setItem('token', user.access_token);
					}

					return user;
				})
			);
	}

  setInsuranceExcelCounter(count) {
    localStorage.setItem('insurance_excel_count', count);
  }
  getInsuranceExcelCounter() {
    return localStorage.getItem('insurance_excel_count');
  }
}

