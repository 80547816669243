import { CalendarModule } from 'primeng/calendar';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { pgTimePickerModule } from '../../@pages/components/time-picker/timepicker.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { pgPaginationModule } from '../../@pages/components/pagination/pagination.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';

export const Modules = [
    CalendarModule,
    pgDatePickerModule,
    pgTimePickerModule,
    NgxDatatableModule,
    NgxEchartsModule,
    NzTimelineModule,
    pgPaginationModule,
    NzSpinModule,
    RouterModule
]