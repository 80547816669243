// states layers 
export const statesBase = {
    id: 'states-fill',
    type: 'fill',
    source: 'state', 
    layout: {},
    paint: {
        'fill-color': '#133C5C', 
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0
      ]
    }
} 

export const statesOutline = {
    id: 'states-outline',
    type: 'line',
    source: 'state',
    layout: {
        'line-join': 'round',
        'line-cap': 'round'
    },
    paint: {
        'line-color': '#133C5C',
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            3, 1,  // At zoom level 3, line width is 0.5
            8, 2     // At zoom level 8 and above, line width is 2
        ],
        'line-opacity': 0.7
    }
};

const statesCounterBase = { 
  type: 'symbol' as const,
  source: 'state-counter',
  layout: {
    'text-field': [
      'let',
      'count', ['get', 'count'],
      [
        'case',
        ['>=', ['var', 'count'], 1000000],
        [
          'concat',
          ['to-string', ['round', ['/', ['var', 'count'], 100000]]],
          'M'
        ],
        ['>=', ['var', 'count'], 1000],
        [
          'concat',
          ['to-string', ['round', ['/', ['var', 'count'], 1000]]],
          'K'
        ],
        ['to-string', ['var', 'count']]
      ]
    ],
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      3, 10,   
      6, 12,  
      9, 16,  
      12, 18  
    ],
    'text-anchor': 'center',
    'text-justify': 'center',
    'symbol-placement': 'point',
    'text-padding': 5,
    'text-allow-overlap': true,
    'text-ignore-placement': true
  },
  paint: {
    'text-halo-width': 2,
    'text-halo-blur': 1,
    'text-opacity': 0.9
  }
};

export const statesCounter = {
  id: 'states-counter',
  type: 'symbol' as const,
  source: 'state-counter',
  layout: {...statesCounterBase.layout},
  paint: {
    ...statesCounterBase.paint,
    'text-color': '#FFFFFF',
  }
};

export const statesCluster = {
    id: 'states-bubble',
    type: 'circle',
    source: 'state-counter',
    filter: ['>', 'count', 0],
    paint: {
        'circle-color': [
            'step',
            ['get', 'count'],
            '#133C5C',
            100,
            '#133C5C',
            750,
            '#133C5C'
        ],
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            5, 18,
            10, 32,
            15, 42
          ]
    }
}


//clusters layers 
export const unclusteredPoint = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'advisor',
    filter: ['==', 'doc_count', 1],
    paint: {
        'circle-color': '#133C5C',
        'circle-radius': 10,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
    }
} 

export const clusterBase = {
    id: 'clusters',
    type: 'circle',
    source: 'advisor',
    filter: ['>', 'doc_count', 1],
    paint: {
        'circle-color': [
            'step',
            ['get', 'doc_count'],
            '#133C5C',
            100,
            '#133C5C',
            750,
            '#133C5C'
        ],
        'circle-radius': [
            'step',
            ['get', 'doc_count'],
            20,
            100,
            30,
            750,
            40
        ]
    }
}

export const clusterCount = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'advisor',
    filter: ['>', 'doc_count', 1],
    layout: {
      'text-field': [
        'let',
        'count', ['get', 'doc_count'],
        [
          'case',
          ['>=', ['var', 'count'], 1000000],
          [
            'concat',
            ['number-format', ['/', ['var', 'count'], 1000000], {'minimumFractionDigits': 1, 'maximumFractionDigits': 1}],
            'M'
          ],
          ['>=', ['var', 'count'], 1000],
          [
            'concat',
            ['number-format', ['/', ['var', 'count'], 1000], {'minimumFractionDigits': 1, 'maximumFractionDigits': 1}],
            'K'
          ],
          ['number-format', ['var', 'count'], {'maximumFractionDigits': 0}]
        ]
      ],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    },
    paint: {
      'text-color': '#ffffff'
    }
}